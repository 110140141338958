import React, { Component } from 'react';
import MenuItem from "./../components/MenuItem";
import './../css/menu.css';
import * as lang from './../lang';


class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuItems: this.getMenu()
        };
    }

    getModuleRoles = function (moduleName) {
        var moduleRolesArray = this.props.moduleRolesArray;
        for (var i = 0; i < moduleRolesArray.length; i++) {
            var moduleRole = moduleRolesArray[i];
            if (moduleRole.module_name == moduleName) {
                return moduleRole;
            }
        }

        return null;
    }

    isMenuHidden = function (menuId) {
        // return false;
        var moduleRoles = this.getModuleRoles(menuId);

        if (moduleRoles == null) {
            return true;
        }
        if (moduleRoles.ACTION_SELECT == 1) {
            return false;
        }
        if (moduleRoles.ACTION_INSERT == 1) {
            return false;
        }
        if (moduleRoles.ACTION_UPDATE == 1) {
            return false;
        }
        if (moduleRoles.ACTION_DELETE == 1) {
            return false;
        }

        return true;

    }

    getMenu = function () {
        return [
            {id: 'MunkaBeosztasNaptar', hidden: this.isMenuHidden("MunkaBeosztasNaptar"), title: lang.getTitle('MunkaBeosztasNaptar'), ico: 'calendar', childrens: []},
            {id: 'MunkaBeosztas', hidden: this.isMenuHidden("MunkaBeosztas"), title: lang.getTitle('MunkaBeosztas'), ico: 'autoBeosztas', childrens: []},
            {id: 'Munkalap', hidden: this.isMenuHidden("Munkalap"), title: lang.getTitle('Munkalap'), ico: 'munkalap', childrens: []},
            {id: 'Arajanlat', hidden: this.isMenuHidden("Arajanlat"), title: lang.getTitle('Arajanlat'), ico: 'ajanlat', childrens: [
               //     {id: 'Folyamatban', hidden: this.isMenuHidden("Arajanlat"), title: lang.getTitle('Folyamatban'), ico: 'folyamatban', childrens: []},
               //     {id: 'Elfogadott', hidden: this.isMenuHidden("Arajanlat"), title: lang.getTitle('Elfogadott'), ico: 'elfogadva', childrens: []},
               //     {id: 'Aktualizalt', hidden: this.isMenuHidden("Arajanlat"), title: lang.getTitle('Aktualizalt'), ico: 'aktualizalt', childrens: []},
               //     {id: 'Lezart', hidden: this.isMenuHidden("Arajanlat"), title: lang.getTitle('Lezart'), ico: 'lezart', childrens: []},
               //     {id: 'Meghiusult', hidden: this.isMenuHidden("Arajanlat"), title: lang.getTitle('Meghiusult'), ico: 'meghiusult', childrens: []},
                ]},
            {id: 'ProjektTabla', hidden: this.isMenuHidden("ProjektTabla"), title: lang.getTitle('ProjektTabla'), ico: 'projektTabla', childrens: [
                    {id: 'ProjektDolgozokTabla', hidden: this.isMenuHidden("ProjektTabla"), title: lang.getTitle('ProjektDolgozokTabla'), ico: 'users', childrens: []},
                    {id: 'ProjektJarmuvekTabla', hidden: this.isMenuHidden("ProjektTabla"), title: lang.getTitle('ProjektJarmuvekTabla'), ico: 'auto', childrens: []},
                ]},
            {id: 'Rendelesek', hidden: false, title: lang.getTitle('Rendelesek'), ico: 'rendeles', childrens: [
                    {id: 'BelsoRendelesek', hidden: this.isMenuHidden("BelsoRendelesek"), title: lang.getTitle('BelsoRendelesek'), ico: 'belsoRendelesek', childrens: []},
                    {id: 'FestekRendelesek', hidden: this.isMenuHidden("FestekRendelesek"), title: lang.getTitle('FestekRendelesek'), ico: 'festek', childrens: []}
                ]},
            {id: 'SzabadsagNaptar', hidden: this.isMenuHidden("SzabadsagokNaptar"), title: lang.getTitle('SzabadsagNaptar'), ico: 'szabadsagNaptar', childrens: [
                    {id: 'Szabadsag', hidden: this.isMenuHidden("Szabadsagok"), title: lang.getTitle('Szabadsag'), ico: 'szabadsag', childrens: []}
                ]},
            {id: 'Torzsadatok', hidden: false, title: lang.getTitle('Torzsadatok'), ico: 'doc', childrens: [
                    {id: 'UgyfelekKarbantartas', hidden: this.isMenuHidden("UgyfelekKarbantartas"), title: lang.getTitle('UgyfelekKarbantartas'), ico: 'users', childrens: []},
                    {id: 'Kapcsolattartok', hidden: this.isMenuHidden("Kapcsolattartok"), title: lang.getTitle('Kapcsolattartok'), ico: 'users', childrens: []},
                    {id: 'Felhasznalok', hidden: this.isMenuHidden("Felhasznalok"), title: lang.getTitle('Felhasznalok'), ico: 'users', childrens: []},
                    {id: 'Autok', hidden: this.isMenuHidden("Autok"), title: lang.getTitle('Autok'), ico: 'auto', childrens: []},
                    {id: 'Cikkcsoportok', hidden: this.isMenuHidden("Cikkcsoportok"), title: lang.getTitle('Cikkcsoportok'), ico: 'cikkcsoport', childrens: []},
                    {id: 'cikkcsoportokFelhasznalok', hidden: this.isMenuHidden("CikkcsoportokFelhasznalok"), title: lang.getTitle('CikkcsoportokFelhasznalok'), ico: 'cikkcsoportokFelhasznalok', childrens: []},
                    {id: 'SzovegSablonok', hidden: this.isMenuHidden("SzovegSablonok"), title: lang.getTitle('SzovegSablonok'), ico: 'sablon', childrens: []},
                    {id: 'Iranyitoszamok', hidden: this.isMenuHidden("Iranyitoszamok"), title: lang.getTitle('Iranyitoszamok'), ico: 'irsz', childrens: []},
                    {id: 'Jogosultsagok', hidden: this.isMenuHidden("Jogosultsag"), title: lang.getTitle('Jogosultsagok'), ico: 'jogosultsagok', childrens: []}

                ]},
            
            {id: 'GoogleDriveFolders', hidden: this.isMenuHidden("GoogleDriveFolders"), title: lang.getTitle('GoogleDriveFolders'), ico: 'googleDrive', childrens: []},
             {id: 'Mobil', hidden: false, title: lang.getTitle('Mobil'), ico: 'mobil', childrens: [
                 {id: 'MobilUgyfelekKarbantartas', hidden: this.isMenuHidden("MobilUgyfelekKarbantartas"), title: lang.getTitle('UgyfelekKarbantartas'), ico: 'users', childrens: []},    
             ]}
            
        ];
    }

    setActiveMenu = function (menuId) {
        this.props.setContent(menuId);
        this.setState({});
    }

    getMenuItems = function () {

        var menuItems = this.state.menuItems;
        var items = [];

        for (var i = 0; i < menuItems.length; i++) {
            var item = menuItems[i];
            if (item.hidden == false) {
                var menuChildrens = this.getMenuChidrens(item);
                items.push(
                        <MenuItem 
                            isChild={false}
                            activeMenuId={this.props.activeMenuId} 
                            setActiveMenu={this.setActiveMenu.bind(this)} 
                            key={"menu_item_" + i} 
                            item={item} 
                            collapsed={this.props.collapsed}
                            theme={this.props.theme}
                            >{menuChildrens}</MenuItem>

                        );
            }
        }

        return items;

    }

    getMenuChidrens = function (menuItem) {

        var menuItems = menuItem.childrens;
        var items = [];

        for (var i = 0; i < menuItems.length; i++) {
            var item = menuItems[i];
            if (item.hidden == false) {
                items.push(
                        <MenuItem 
                            isChild={true}
                            activeMenuId={this.props.activeMenuId} 
                            setActiveMenu={this.setActiveMenu.bind(this)} 
                            key={"menu_item_" + i} 
                            item={item} 
                            collapsed={this.props.collapsed}
                            theme={this.props.theme}
                            />

                        );
            }
        }

        return items;

    }

    render() {
        var menuItems = this.getMenuItems();

        return <div>{menuItems}</div>;
    }
}

export default Menu;
