import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import UgyfelCimekKarbantartasContent from './../../modules/UgyfelCimekKarbantartas/UgyfelCimekKarbantartasContent.js';
import TextInsertField from './../../components/TextInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import KapcsolattartokEmbeddedPanel from './../../modules/UgyfelekKarbantartas/KapcsolattartokEmbeddedPanel';
import BelsoRendelesUgyfelCombo from './../../modules/BelsoRendelesek/BelsoRendelesUgyfelCombo';
import MunkabeosztasUgyfelCimCombo from './../../componentsSpecial/MunkabeosztasUgyfelCimCombo';


import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';


class MobileTableFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ugyfelId: this.props.ugyfelId
            , kapcsolattartoId: this.props.kapcsolattartoId
            , ugyfelcimId: this.props.ugyfelcimId
        }

        this.references = [];
        this.references['ugyfelId'] = React.createRef();
        this.references['ugyfelcimId'] = React.createRef();
        this.references['kapcsolattartoId'] = React.createRef();
        
        this.references['component_ugyfelId'] = React.createRef();
        this.references['component_ugyfelcimId'] = React.createRef();
        this.references['component_kapcsolattartoId'] = React.createRef();
        
        this.forceUpdate();

    }

    componentDidMount() {
        var updateNeed = this.props.ugyfelId != this.state.ugyfelId ||
                this.props.ugyfelcimId != this.state.ugyfelcimId ||
                this.props.kapcsolattartoId != this.state.kapcsolattartoId;
        if (updateNeed == true) {
            this.setState({
                 ugyfelId: this.props.ugyfelId
            , kapcsolattartoId: this.props.kapcsolattartoId
            , ugyfelcimId: this.props.ugyfelcimId            });
        }else{

            this.references['component_ugyfelId'].current.loadItemByCurrentId(this.state.ugyfelId);
            this.references['component_ugyfelcimId'].current.loadItemByCurrentId(this.state.ugyfelcimId);
            this.references['component_kapcsolattartoId'].current.loadItemByCurrentId(this.state.kapcsolattartoId);
        }

    }
    
    componentDidUpdate(prevProps) {
    
    }

    reset = function () {
        this.state = {
            ugyfelId: ''
            , kapcsolattartoId: ''
            , ugyfelcimId: ''
        }

    }

    closeForm = function () {
        // this.reset();
        this.props.closeForm();

    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    setFilterValues = function () {

        var ugyfelId = this.state.ugyfelId;
        var ugyfelcimId = this.state.ugyfelcimId;
        var kapcsolattartoId = this.state.kapcsolattartoId;

        this.props.setFilterValues(ugyfelId, ugyfelcimId, kapcsolattartoId);
        this.props.closeForm();
        
    }
    
    resetFilterValues = function () {

        var ugyfelId = '';
        var ugyfelcimId = '';
        var kapcsolattartoId = '';

        this.props.setFilterValues(ugyfelId, ugyfelcimId, kapcsolattartoId);
        this.props.closeForm();
        
    }

    render() {
        


        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return <div style={{maxWidth:350}}>
            <div>
                <Row>
                    <Col sm="12"></Col>
                    <Col sm="12" style={{textAlign: 'center'}}>{lang.getTitleBold('UgyfelekKarbantartasFilter')}</Col>
                    <Col sm="12" style={{textAlign: 'right'}}>
                    <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col sm="12" style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelId')}</Col>
                <Col sm="12" style={{textAlign: 'right'}}>
                <BelsoRendelesUgyfelCombo 
                    id={'ugyfelId'} 
                    ref={this.references['component_ugyfelId']}
                    value={this.state.ugyfelId}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/BelsoRendelesek/ugyfel_idFormComboInsertData.php'}
                    reference={this.references['ugyfelId']}
                    editable={true}
                    theme={this.props.theme}
        
                    />
                </Col>
            </Row>
            <Row>
                <Col  sm="12" style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztas_ugyfel_cim_id')}</Col>
                <Col  sm="12" style={{textAlign: 'right'}}>
                <MunkabeosztasUgyfelCimCombo 
                    id={'ugyfelcimId'} 
                    ref={this.references['component_ugyfelcimId']}
                    value={this.state.ugyfelcimId}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/MunkaBeosztas/ugyfel_cim_idFormComboInsertData.php'}
                    reference={this.references['ugyfelcimId']}
                    ugyfel_id ={this.state.ugyfelId}
                    editable={true}
                    theme={this.props.theme}
                    style={{width: '100%'}}
                    />
                </Col>
            </Row>
        
        
            <Row>
                <Col  sm="12" style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelek_kapcsolattarto_id')}</Col>
                <Col  sm="12" style={{textAlign: 'right'}}>
                <ComboBoxInsertField 
                    id={'kapcsolattartoId'} 
                    ref={this.references['component_kapcsolattartoId']}
                    value={this.state.kapcsolattartoId}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/UgyfelekKarbantartas/kapcsolattarto_idFormComboInsertData.php'}
                    reference={this.references['kapcsolattartoId']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width: '100%'}}
                    />
                </Col>
            </Row>
            <div  className={"popupFooter" + className} >
                <Row>
                    <Col>
                    <div style={{whiteSpace: 'break-spaces'}}>
                        {this.msg} 
                    </div>
        
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Button className={'btn-sm'} id={'reset'} onClick={this.resetFilterValues.bind(this)} variant="info" style={{marginTop: '5px'}}>{lang.getTitle('reset_filter')}</Button>&nbsp;&nbsp;&nbsp;      
                    <Button className={'btn-sm'} id={'save'} onClick={this.setFilterValues.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('filter')}</Button>&nbsp;&nbsp;&nbsp;      
                    </Col>
                </Row>
                <Row>
                    <Col style={{minHeight: '10px'}}>
        
                    </Col>
                </Row>
        
            </div>
        </div>




    }

}


export default MobileTableFilter;