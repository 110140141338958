import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';
import * as ajax from './../ajax.js';


class RemoteDataDisplayField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            text: ''
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            if (this.props.value == "") {
                this.setState({
                    value: this.props.value,
                    text: ''

                });
            } else {
                this.loadDataByCurrentId(this.props.value);
            }
        }
    }

    loadDataByCurrentId = function (currentId) {
        
        try {
            const url = ajax.getUrlBase() + this.props.dataSourceUrl;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({filterText: '', currentId: currentId})
            }).then(response => {
                if (response.ok) {

                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null) {
                           
                            this.setState({
                                text: response.data.currentText
                            });
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }


    }

    render() {
        var className = (this.props.theme == "true") ? "Dark" : "Light";

        return  <div style={{position: 'relative'}} >
            <input 
                type={'text'} 
                value={this.state.text} 
                style={{textAlign: 'left'}}
                disabled={true}
                className ={"component" + className}
                />
        </div>;
    }
}

export default RemoteDataDisplayField;


