import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'




import {Button} from 'react-bootstrap';

import InsertForm from './MTOUgyfelCimekKarbantartasInsertForm.js';

class MTOUgyfelCimekKarbantartasTableMTO extends Component {

    constructor(props) {
        super(props);
        var columnNames = ['ugyfelCimek_id', 'ugyfelCimek_ugyfel_id', 'ugyfelCimek_elsodleges', 'ugyfelCimek_irsz', 'ugyfelCimek_telepules','ugyfelCimek_telepulesNev', 'ugyfelCimek_cim', 'ugyfelCimek_cim_tipus'];
        this.columnStateHandler = ajax.columnStateHandler(columnNames, "MTOUgyfelCimekKarbantartas", "ugyfelCimek");

        this.state = {
            columnOrder: columnNames,
            openedForm: 'NONE', // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRowId: -1,
            data: []
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.openedForm != nextState.openedForm) {
            return true;
        }
        if (this.state.theme != this.props.theme) {
            return true;
        }
        return false; // equals() is your implementation
    }

    setView = function (e) {
        this.setState({openedForm: e.target.id});
    }

    closeForm = function () {
        this.setState({openedForm: 'NONE'});
    }

    addRow = function (row, close) {
        var rowNumber = this.state.data.length + 1;
        row.ugyfelCimek_id = rowNumber;
        this.state.data.push(row);
        if (close) {
            this.closeForm();
        } else {
            this.setState({});
        }
        this.props.setBack('MTOUgyfelCimekKarbantartas', this.state.data);
    }
    onSelectionChange = function (selected) {
        var selectedRowId = selected.data.ugyfelCimek_id;
        this.setState({selectedRowId: selectedRowId});
    }

    getRowIndexById = function () {
        for (var i = 0; i < this.state.data.length; i++) {
            var currentData = this.state.data[i];
            if (currentData.ugyfelCimek_id == this.state.selectedRowId) {
                return i;
            }
        }
        return -1;
    }

    deleteRow = function () {

        if (this.state.selectedRowId == -1) {
            return false;
        }

        var rowIndexById = this.getRowIndexById(this.state.selectedRowId);
        if (rowIndexById == -1) {
            return false;
        }


        this.state.data.splice(rowIndexById, 1);
        this.state.selectedRowId = -1;
        this.setState({});
        this.props.setBack('MTOUgyfelCimekKarbantartas', this.state.data);
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();

    }

    render() {
        const columns = [
            {name: 'ugyfelCimek_id', sortable: true, header: lang.getTitle('ugyfelCimek_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('ugyfelCimek_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true}
            , {name: 'ugyfelCimek_ugyfel_id', sortable: true, header: lang.getTitle('ugyfelCimek_ugyfel_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('ugyfelCimek_ugyfel_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'ugyfelCimek_elsodleges', sortable: true, header: lang.getTitle('ugyfelCimek_elsodleges'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('ugyfelCimek_elsodleges', 150), defaultVisible: true,
               render: ({ value, data })=>{
                return <div>{(value=='0')?'Nem':'Igen'}</div>
                    
                }
            }
            , {name: 'ugyfelCimek_irsz', sortable: true, header: lang.getTitle('ugyfelCimek_irsz'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('ugyfelCimek_irsz', 150),  defaultVisible: true}
            , {name: 'ugyfelCimek_telepules', sortable: true, header: lang.getTitle('ugyfelCimek_telepules'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('ugyfelCimek_telepules', 150), defaultVisible: false}
            , {name: 'ugyfelCimek_telepulesNev', sortable: true, header: lang.getTitle('ugyfelCimek_telepulesNev'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('ugyfelCimek_telepulesNev', 150), defaultVisible: true}
            , {name: 'ugyfelCimek_cim', sortable: true, header: lang.getTitle('ugyfelCimek_cim'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('ugyfelCimek_cim', 150), defaultVisible: true}
            , {name: 'ugyfelCimek_cim_tipus', sortable: true, header: lang.getTitle('ugyfelCimek_cim_tipus'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('ugyfelCimek_cim_tipus', 150), defaultVisible: true}

        ];

        const filters = [
            {name: 'ugyfelCimek_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'ugyfelCimek_ugyfel_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'ugyfelCimek_elsodleges', operator: 'eq', type: 'boolean', value: ''}
            , {name: 'ugyfelCimek_irsz',  operator: 'contains', type: 'string', value: ''}
            , {name: 'ugyfelCimek_telepules', operator: 'contains', type: 'string', value: ''}
            , {name: 'ugyfelCimek_telepulesNev', operator: 'contains', type: 'string', value: ''}
            , {name: 'ugyfelCimek_cim', operator: 'contains', type: 'string', value: ''}
            , {name: 'ugyfelCimek_cim_tipus', operator: 'contains', type: 'string', value: ''}

        ];



        var openedForm = "";
        if (this.state.openedForm == 'INSERT') {
            openedForm = <InsertForm 
                addRow={this.addRow.bind(this)} 
                closeForm={this.closeForm.bind(this)} 
                additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
                formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                theme={this.props.theme}
                />
        }

        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';

        return (
                <div id="MTOUgyfelCimekKarbantartasTable">
                    <div>
                        &nbsp;<Button className={'btn-sm'} id={'INSERT'} onClick={this.setView.bind(this)} variant="success">{lang.getTitle('Insert')}</Button>
                        &nbsp;<Button className={'btn-sm'} id={'DELETE'} onClick={this.deleteRow.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button>
                    </div>
                    <ReactDataGrid 
                        idProperty="ugyfelCimek_id" 
                        i18n={ajax.getI18n()}
                        columns={this.columnStateHandler.myColumnsReorder(columns)}
                        dataSource={this.state.data}
                        theme={theme}
                        style={{height: 200, marginTop: 10}}  
                        enableSelection={true}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                
                        onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
                        onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
                        onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                        />
                
                    {openedForm}            
                </div>
                );
    }
}

export default MTOUgyfelCimekKarbantartasTableMTO;
