import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import TextFormatter from './../../components/TextFormatter';
import { Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';


class AktualizalSzovegbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            arajanlat_id: ''
            , arajanlat_aktualizalasMegjegyzes: ''
        }

        this.validators = [];
        this.error = false;
        this.msg = "";
        this.editable = true;
        this.id=this.getIdFromParam();
        this.load(this.id);

    }
    
     getIdFromParam = function () {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var id = urlParams.get('id');
        return id;
    }

    componentDidMount() {
        this.id = this.getIdFromParam();
        this.load(this.id);
    }

    reset = function () {

        this.setState({
              arajanlat_id: ''
            , arajanlat_aktualizalasMegjegyzes: ''
        });
    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    update = function (e) {
        this.id = this.getIdFromParam();
        var self = this;
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        try {
            const url = ajax.getUrlBase() + "modules/Arajanlat/aktualizaltSzovegboxUpdate.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({arajanlat_id:this.id,arajanlat_aktualizalasMegjegyzes:this.state.arajanlat_aktualizalasMegjegyzes})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                        } else {
                            self.transactionFail(response.fail);
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    load = function (id) {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Arajanlat/arajanlatFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            var arajanlat_statusz = response.data[0].arajanlat_statusz;
                            this.editable = arajanlat_statusz != 2;
                            //this.editable = true;
                            this.arajanlat_statusz_from_server = arajanlat_statusz;
                            self.setState(response.data[0]);


                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    closeForm = function(){
        window.close();
    }

    setBack = function(id,val){
        
         console.log(id,val);
        this.setState({arajanlat_aktualizalasMegjegyzes: val})
    }


    getTabbedForm = function () {
        return <Row>
                    <Col md={12}  style={{textAlign: 'left'}}>
                    <TextFormatter 
                        sablonKivalasztoval={false}
                        id={'arajanlat_aktualizalasMegjegyzes'} 
                        kategoria_konstans={'MEGJEGYZES'}
                        value={this.state.arajanlat_aktualizalasMegjegyzes}  
                        setBack={this.setBack.bind(this)}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
            
    }

    render() {
        var form = this.getTabbedForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";

         var   buttonUpdate = <Button className={'btn-sm'} id={'modify'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('modify')}</Button>;
        
        




        return (<PopUpWindow theme={this.props.theme}  style={{minWidth: 1100, minHeight: '100%'}}>
        <div className={'popupWrapper' + className} id="ArajanlatUpdate">
            <div  className={"popupHeader" + className} >
                <Row>
                    <Col style={{textAlign: 'left'}}>
                    
                    </Col>
                    <Col style={{textAlign: 'center'}}>{lang.getTitle('ArajanlatUpdate')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                    </Col>
                </Row>
            </div>
            <div  className={"popupBody" + className} >{form}</div>
            <div  className={"popupFooter" + className} >
                <Row>
                    <Col>
                    <div style={{whiteSpace: 'break-spaces'}}>
                        {this.msg} 
                    </div>
    
                    </Col>
                </Row>
                <Row>
                    <Col>
                    {buttonUpdate}
                    </Col>
                </Row>
                <Row>
                    <Col style={{minHeight: '10px'}}>
    
                    </Col>
                </Row>
            </div>
        </div>
    </PopUpWindow>);
    }
}
export default AktualizalSzovegbox;
