import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';
import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TextInsertField from './../../components/TextInsertField';
import NumberInsertField from './../../components/NumberInsertField';
import TextFormatter from './../../components/TextFormatter';


class MTOArajanlatTetelOpciokInsertMTO extends Component {

    constructor(props) {
        super(props);
        this.state = {
            arajanlatTetelOpciok_manualis_sorszam: ''
            , arajanlatTetelOpciok_megnevezes: ''
            , arajanlatTetelOpciok_hivatkozas: ''
            , arajanlatTetelOpciok_darabszam: this.props.arajanlatTetelek_darabszam
            , arajanlatTetelOpciok_nettoOsszesen: ''
            , arajanlatTetelOpciok_megjegyzes: ''

        }

        this.references = [];
        this.references['arajanlatTetelOpciok_manualis_sorszam'] = React.createRef();
        this.references['arajanlatTetelOpciok_megnevezes'] = React.createRef();
        this.references['arajanlatTetelOpciok_hivatkozas'] = React.createRef();
        this.references['arajanlatTetelOpciok_netto_ar'] = React.createRef();
        this.references['arajanlatTetelOpciok_darabszam'] = React.createRef();
        this.references['arajanlatTetelOpciok_nettoOsszesen'] = React.createRef();
        this.references['arajanlatTetelOpciok_megjegyzes'] = React.createRef();

    }
    componentDidMount() {
        this.reset();
    }

    setNettoOsszes = function () {
        var darabszam = (this.state.arajanlatTetelOpciok_darabszam == null || this.state.arajanlatTetelOpciok_darabszam == '') ? 1 : this.state.arajanlatTetelOpciok_darabszam;
        this.state.arajanlatTetelOpciok_nettoOsszesen = this.state.arajanlatTetelOpciok_netto_ar * darabszam;

    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({
            arajanlatTetelOpciok_manualis_sorszam: ''
            , arajanlatTetelOpciok_megnevezes: ''
            , arajanlatTetelOpciok_hivatkozas: ''
            , arajanlatTetelOpciok_netto_ar: ''
            , arajanlatTetelOpciok_darabszam: this.props.arajanlatTetelek_darabszam
            , arajanlatTetelOpciok_nettoOsszesen: ''
            , arajanlatTetelOpciok_megjegyzes: ''
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});

    }

    getForm = function () {
        this.setNettoOsszes();
        return <Container  theme={this.props.theme}>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelOpciok_manualis_sorszam')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    sablonKivalasztoval={false}
                    id={'arajanlatTetelOpciok_manualis_sorszam'} 
                    value={this.state.arajanlatTetelOpciok_manualis_sorszam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelOpciok_manualis_sorszam']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelOpciok_darabszam')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelOpciok_darabszam'} 
                    value={this.state.arajanlatTetelOpciok_darabszam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelOpciok_darabszam']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelOpciok_megnevezes')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextFormatter 
                    sablonKivalasztoval={false}
                    id={'arajanlatTetelOpciok_megnevezes'} 
                    value={this.state.arajanlatTetelOpciok_megnevezes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelOpciok_megnevezes']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="2" style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelOpciok_hivatkozas')}</Col>
                <Col md="10" style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'arajanlatTetelOpciok_hivatkozas'} 
                    value={this.state.arajanlatTetelOpciok_hivatkozas}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelOpciok_hivatkozas']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelOpciok_netto_ar')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelOpciok_netto_ar'} 
                    value={this.state.arajanlatTetelOpciok_netto_ar}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelOpciok_netto_ar']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelOpciok_nettoOsszesen')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelOpciok_nettoOsszesen'} 
                    value={this.state.arajanlatTetelOpciok_nettoOsszesen}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelOpciok_nettoOsszesen']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();
    }

    addRow = function () {
        this.props.addRow(this.state, false);
        this.reset();
    }

    addRowAndClose = function () {
        this.props.addRow(this.state, true);
        this.reset();
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{maxHeight: 500, minWidth: 1100}} >
            <div className={'popupWrapper' + className} id="MTOArajanlatTetelOpciokInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('MTOArajanlatTetelOpciokInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Button className={'btn-sm'} onClick={this.addRowAndClose.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                    <Button className={'btn-sm'} onClick={this.addRow.bind(this)} variant="info" style={{marginTop: '5px'}}>{lang.getTitle('new')}</Button>      
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default MTOArajanlatTetelOpciokInsertMTO;


