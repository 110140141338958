import React from 'react';
import './../../css/ProjektTabla.css';

import  DateInsertField from './../../components/DateInsertField';
import {Button, Row, Col} from 'react-bootstrap';

import rogzitveIco from './../../img/black/calendar_month_black_24dp.svg';
import kiirvaIco from './../../img/black/edit_note_black_24dp.svg';
import gyartasbaAdvaIco from './../../img/black/engineering_black_24dp.svg';
import festesreAtadvaIco from './../../img/black/volunteer_activism_black_24dp.svg';
import festoMuhelyAtvetteIco from './../../img/black/handshake_black_24dp.svg';
import osszeszerelesreKeszIco from './../../img/black/build_black_24dp.svg';
import szallitasraKeszIco from './../../img/black/inventory_2_black_24dp.svg';
import kiszallitvaIco from './../../img/black/local_shipping_black_24dp.svg';
import varhatoBefejezes from './../../img/black/schedule_black_24dp.svg';


import UpdateForm from './../Munkalap/MunkalapUpdateForm';


import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import * as ajax from './../../ajax';
import * as lang from './../../lang';

import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');


class ProjektTabla extends React.Component {

    constructor(props) {
        super(props);

        var fromDate = new Date();

        var toDate = moment(fromDate, "YYYY-MM-DD").add('days', 62);

        var fromDateString = moment(fromDate).format('YYYY-MM-DD');
        var toDateString = moment(toDate).format('YYYY-MM-DD');
        this.NONE = "NONE";
        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";

        this.state = {
            activeMunkalapId: null,
            openedForm: this.NONE, // DELETE,INSERT,UPDATE,VIEW
            fromDate: fromDateString,
            toDate: toDateString,
            opener: true
        }

        this.data = null;
        this.load();
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    load = function () {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/ProjektTabla/DataStore.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id, fromDate: this.state.fromDate, toDate: this.state.toDate})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.data = response.data;
                            self.setState({});
                        } else {
                            console.log(response);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    getIconByDateName = function (dateName) {
        var icon = null;
        var title = "";
        switch (dateName) {
            case 'munkalap_datumok_rogzitve':
                icon = rogzitveIco;
                title = "Rögzítve";
                break;
            case 'munkalap_datumok_kiirva':
                icon = kiirvaIco;
                title = "Kiírva";
                break;
            case 'munkalap_datumok_gyartasbaAdva':
                icon = gyartasbaAdvaIco;
                title = "Gyártásba adva";
                break;
            case 'munkalap_datumok_festesreAtadva':
                icon = festesreAtadvaIco;
                title = "Festésre átadva";
                break;
            case 'munkalap_datumok_festoMuhelyAtvette':
                icon = festoMuhelyAtvetteIco;
                title = "Feső műhely átvette";
                break;
            case 'munkalap_datumok_osszeszerelesreKesz':
                icon = osszeszerelesreKeszIco;
                title = "Összeszerelésre kész";
                break;
            case 'munkalap_datumok_szallitasraKesz':
                icon = szallitasraKeszIco;
                title = "Szállításra kész";
                break;
            case 'munkalap_datumok_kiszallitva':
                icon = kiszallitvaIco;
                title = "Kiszállítva";
                break;
            case 'munkalap_datumok_varhato_befejezes':
                icon = varhatoBefejezes;
                title = "Várható befejezés";
                break;
        }

        return {icon: icon, title: title}
    }

    getDayName = function (d) {
        var days = ['Va.', 'Hé.', 'Ke.', 'Sze.', 'Csü.', 'Pén.', 'Szo.'];
        return days[d.getDay()];
    }

    getDayColor = function (dayString) {
        var d = new Date(dayString);
        if (d.getDay() == 0) {
            return "#999999";
        }
        if (d.getDay() == 6) {
            return "#bbbbbb";
        }

        return '#FFFFFF'
    }

    isDateLinesExist = function (data) {
        if (typeof data == 'undefined') {
            return false;
        }
        if (data == null) {
            return false;
        }
        if (typeof data.dateLines == 'undefined') {
            return false;
        }
        if (data.dateLines == null) {
            return false;
        }
        if (data.dateLines.length == 0) {
            return false;
        }
        return true;
    }

    getMonthHeader = function () {
        var monthNames = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];
        var monthShortNames = ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sze', 'Okt', 'Nov', 'Dec'];


        var oneDay = 86400000;


        var fromDate = this.state.fromDate;
        var toDate = this.state.toDate;

        var fromDateMS = new Date(fromDate).getTime();

        var monthHeaders = [];
        var dayCount = Math.round((new Date(toDate).getTime() - new Date(fromDate)) / oneDay);


        var colspan = 1;
        var currentMonth = new Date(fromDate).getMonth();

        for (var i = 0; i < dayCount; i++) {
            fromDateMS = fromDateMS + oneDay;
            var currentDay = new Date(fromDateMS);
            var month = new Date(currentDay).getMonth();
            if (currentMonth == month) {
                colspan++;
            } else {
                var monthName = (colspan > 3) ? monthNames[currentMonth] : monthShortNames[currentMonth];
                var bg = (currentMonth % 2 == 0) ? 'white' : "#eeeeee"
                monthHeaders.push(<th colSpan={colspan} style={{backgroundColor: bg, color: 'black', textAlign: 'center', borderRight: 'solid 1px black'}}  key={"header_month_name_" + i}>{monthName}</th>);
                currentMonth++;
                colspan = 1;
                if (currentMonth > 11) {
                    currentMonth = 0;
                }
            }
        }

        if (colspan > 1) {
            var monthName = (colspan > 3) ? monthNames[currentMonth] : monthShortNames[currentMonth];
            var bg = (currentMonth % 2 == 0) ? 'white' : "#eeeeee"
            monthHeaders.push(<th colSpan={colspan} style={{backgroundColor: bg, color: 'black', textAlign: 'center', borderRight: 'solid 1px black'}}  key={"header_month_name_" + i}>{monthName}</th>);
        }

        return monthHeaders;
    }

    getHeader = function (data) {
        this.getMonthHeader();
        var isDateLinesExist = this.isDateLinesExist(data);
        if (isDateLinesExist == false) {
            return null;
        }

        var days = [];
        var dayNames = [];

        var dateLine = data.dateLines[0];
        for (var i = 0; i < dateLine.length; i++) {
            var properties = dateLine[i].properties;
            var dateString = properties.DateString;
            var currentDate = new Date(dateString);
            var day = currentDate.getDate();
            var dayName = this.getDayName(currentDate);
            var dateColor = this.getDayColor(dateString);
            days.push(<th style={{textAlign: 'center', backgroundColor: dateColor, color: 'black'}} key={"header_date_day_" + i}>{day}</th>);
            dayNames.push(<th style={{backgroundColor: dateColor, color: 'black'}}  key={"header_date_day_name_" + i}>{dayName}</th>);


        }

        var headerRows = [];

        var monthHeader = this.getMonthHeader();

        headerRows.push(<tr key="header_row_0">
    
        <th></th>
        <th></th>
        <th></th>
        {monthHeader}
    </tr>);

        headerRows.push(<tr key="header_row_1">
            <th>Munkalap</th>
            <th>{data.title2.title}</th>
            <th>{data.title3.title}</th>
            {dayNames}
        </tr>);
        headerRows.push(<tr key="header_row_2">
            <th></th>
            <th></th>
            <th></th>
            {days}
        </tr>);


        return headerRows;

    }

    getOffers = function (data) {
        var offers = [];
        for (var w = 0; w < data.length; w++) {
            var offer = this.getOffer(data[w]);
            offers.push(offer);
        }
        return offers;
    }
    showMunkalap = function (e) {
        var munkalapId = e.target.id;
        if (munkalapId != null && munkalapId != '') {
            this.setState({openedForm: this.FORM_UPDATE, activeMunkalapId: munkalapId});
        }


    }

    getOffer = function (data) {

        var ajanlatSzama = (typeof data.title1.title != 'undefined' && data.title1.title != null) ? data.title1.title : '';
        var cegNeve = (typeof data.title2.title != 'undefined' && data.title2.title != null) ? data.title2.title : '';
        var projektvezetoNeve = (typeof data.title3.title != 'undefined' && data.title3.title != null) ? data.title3.title : '';

        var isDateLinesExist = this.isDateLinesExist(data);
        if (isDateLinesExist == false) {
            return null;
        }

        var offerLines = [];
        for (var dl = 0; dl < data.dateLines.length; dl++) {
            var offers = [];
            var dateLine = data.dateLines[dl];
            var dateCells = dateLine.dateCells;

            var munkalapNeve = (typeof dateLine.title != 'undefined' && dateLine.title != null) ? dateLine.title : '';
            var buborekSugo = ajanlatSzama + "\n" + cegNeve + "\n" + projektvezetoNeve + "\n" + munkalapNeve + "\n";
            var icon_title = {icon: null, title: ''};



            offers.push(<th><div
                    style={{fontWeight: 'normal', cursor: 'pointer', textDecoration: 'underline'}}
                    onClick={this.showMunkalap.bind(this)} 
                    id={dateLine.id}>{dateLine.id}-{dateLine.title}</div></th>);
            //  offers.push(<th>&nbsp;&nbsp;&nbsp;{(dl == 0) ? data.title2.title : ''}&nbsp;&nbsp;&nbsp;</th>);
            //  offers.push(<th>&nbsp;&nbsp;&nbsp;{(dl == 0) ? data.title3.title : ''}&nbsp;&nbsp;&nbsp;</th>);
            offers.push(<th>&nbsp;&nbsp;&nbsp;{data.title2.title}&nbsp;&nbsp;&nbsp;</th>);
            offers.push(<th>&nbsp;&nbsp;&nbsp;{data.title3.title}&nbsp;&nbsp;&nbsp;</th>);



            for (var i = 0; i < dateCells.length; i++) {
                var properties = dateCells[i].properties;

                var dateColor = this.getDayColor(properties.DateString);
                if (typeof properties['munkafolyamat'] !== 'undefined') {
                    var current_icon_title = this.getIconByDateName(properties['munkafolyamat']);
                    if (current_icon_title.icon != null) {
                        icon_title = current_icon_title;
                    }
                    offers.push(<td title={buborekSugo} style={{backgroundColor: dateColor}} key={"offers_id_" + i}><img src={icon_title.icon} /></td>);
                    if (properties['munkafolyamat'] == 'munkalap_datumok_varhato_befejezes') {
                        icon_title.icon = null;
                        icon_title.title = '';
                    }

                } else {
                    offers.push(<td title={buborekSugo} style={{backgroundColor: dateColor}}  key={"offers_id_" + i}>
                    <div style={{textAlign: 'center'}}><img src={icon_title.icon} /></div>
                </td>);
                }

            }

            offerLines.push(
                    <tr key={"offer_line_id_" + dateLine.id}>
                        {offers}
                    </tr>);
        }
        return offerLines;

    }

    getTable = function () {
        if (this.data == null) {
            return null
        }


        var header = this.getHeader(this.data.header);


        var offers = this.getOffers(this.data.offers);

        return <table className={'projecktTabla'} border="1">
            <thead>{header}</thead>
            <tbody>
                {offers}
            </tbody>
        </table>
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    closeForm = function () {
        this.state.openedForm = this.NONE;
        this.load();
    }

    render() {
        var table = this.getTable();
        var openedForm = null;
        if (this.state.openedForm == this.FORM_UPDATE && this.state.activeMunkalapId != null) {
            openedForm = <UpdateForm 
                theme={this.props.theme} 
                key={'munkalap_KEY_UPDATE'} 
                formType={this.FORM_UPDATE} 
                additionalParams={[]} 
                id={this.state.activeMunkalapId} 
                closeForm={this.closeForm.bind(this)} 
                />
        }
        return <div className="projeckt">
            <div 
                style={{
                                display: 'flex',
                                textAlign: 'center',
                                alignItems: 'center',
                                fontSize: 16,
                                backgroundColor: 'white',
                                position: 'sticky',
                                left: 0,
                                zIndex: 222

                            }}
        
                >
                <div style={{
                                display: 'flex',
                                textAlign: 'center',
                                alignItems: 'center',
                                fontSize: 16,
                                backgroundColor: 'white',
                                position: 'sticky',
                                left: 0,
                                zIndex: 222
                                 }}>
                    <div style={{paddingLeft: 5}}>&nbsp;</div>
                    <div>Tól:</div>
                    <div>
                        <DateInsertField 
                            id={'fromDate'} 
                            value={this.state.fromDate}  
                            setBack={this.setBack.bind(this)}
                            editable={true}
        
                            />
                    </div>
                    <div style={{paddingLeft: 50}}>&nbsp;</div>
                    <div>Ig:</div>
                    <div>
                        <DateInsertField 
                            id={'toDate'} 
                            value={this.state.toDate}  
                            setBack={this.setBack.bind(this)}
                            editable={true}
        
                            />
                    </div>
                    <div style={{paddingLeft: 10}}>&nbsp;</div>
                    <div>
                        <Button onClick={this.load.bind(this)} variant="success" size="sm" >{lang.getTitle('Lekerdezes')}</Button>
                    </div>
                    <div style={{paddingLeft: 10}}>&nbsp;</div>
                    <div style={{paddingLeft: 10}}>Rögz.:&nbsp;<img src={this.getIconByDateName('munkalap_datumok_rogzitve').icon}/>&nbsp;|&nbsp;</div>
                    <div style={{paddingLeft: 10}}>Kiírva:&nbsp;<img src={this.getIconByDateName('munkalap_datumok_kiirva').icon}/>&nbsp;|&nbsp;</div>
                    <div style={{paddingLeft: 10}}>Gyárt. adva:&nbsp;<img src={this.getIconByDateName('munkalap_datumok_gyartasbaAdva').icon}/>&nbsp;|&nbsp;</div>
                    <div style={{paddingLeft: 10}}>Fest. átadva:&nbsp;<img src={this.getIconByDateName('munkalap_datumok_festesreAtadva').icon}/>&nbsp;|&nbsp;</div>
                    <div style={{paddingLeft: 10}}>Festő. átv.:&nbsp;<img src={this.getIconByDateName('munkalap_datumok_festoMuhelyAtvette').icon}/>&nbsp;|&nbsp;</div>
                    <div style={{paddingLeft: 10}}>Összeszerelésre kész:&nbsp;<img src={this.getIconByDateName('munkalap_datumok_osszeszerelesreKesz').icon}/>&nbsp;|&nbsp;</div>
                    <div style={{paddingLeft: 10}}>Száll. kész:&nbsp;<img src={this.getIconByDateName('munkalap_datumok_szallitasraKesz').icon}/>&nbsp;|&nbsp;</div>
                    <div style={{paddingLeft: 10}}>Kiszáll.:&nbsp;<img src={this.getIconByDateName('munkalap_datumok_kiszallitva').icon}/>&nbsp;|&nbsp;</div>
                    <div style={{paddingLeft: 10}}>Várható befejezés.:&nbsp;<img src={this.getIconByDateName('munkalap_datumok_varhato_befejezes').icon}/></div>
                </div>
            </div>
            {table}
            {openedForm}
        </div>;
    }

}

export default ProjektTabla;
