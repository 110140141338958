import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';
import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import ArajanlatTetelOpciokContent from './../../modules/ArajanlatTetelOpciok/ArajanlatTetelOpciokContent.js';
import MTOArajanlatTetelOpciokContent from './../../modules/MTOArajanlatTetelOpciok/MTOArajanlatTetelOpciokContent.js';
import TextInsertField from './../../components/TextInsertField';
import NumberInsertField from './../../components/NumberInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import ArengedmenyNumberField from './../../componentsSpecial/ArengedmenyNumberField';
import TextFormatter from './../../components/TextFormatter';

class MTOArajanlatTetelInsertMTO extends Component {

    constructor(props) {
        super(props);
        this.state = {
            arajanlatTetelek_manualis_tetel_sorszam: ''
            , arajanlatTetelek_megnevezes: ''
            , arajanlatTetelek_hivatkozas_megjegyzes: ''
            , arajanlatTetelek_hivatkozas: ''
            , arajanlatTetelek_netto_ar: ''
            , arajanlatTetelek_darabszam: '1'
            , arajanlatTetelek_mennyisegiEgyseg: 'db'
            , arajanlatTetelek_nettoOsszesen: ''


            , arajanlatTetelek_tetel_kibontas: ''
            , ArajanlatTetelOpciok: []
            , MTOArajanlatTetelOpciok: []

        }

        this.references = [];
        this.references['arajanlatTetelek_manualis_tetel_sorszam'] = React.createRef();
        this.references['arajanlatTetelek_megnevezes'] = React.createRef();
        this.references['arajanlatTetelek_hivatkozas'] = React.createRef();
        this.references['arajanlatTetelek_netto_ar'] = React.createRef();

        this.references['arajanlatTetelek_tetel_kibontas'] = React.createRef();
        this.references['arajanlatTetelek_MTO'] = React.createRef();
        this.references['arajanlatTetelek_MTO'] = React.createRef();

    }
    componentDidMount() {
        this.reset();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({
            arajanlatTetelek_manualis_tetel_sorszam: ''
            , arajanlatTetelek_megnevezes: ''
            , arajanlatTetelek_hivatkozas_megjegyzes: ''
            , arajanlatTetelek_hivatkozas: ''
            , arajanlatTetelek_netto_ar: ''
            , arajanlatTetelek_darabszam: '1'
            , arajanlatTetelek_netto_osszesen: ''

            , arajanlatTetelek_tetel_kibontas: ''
            , ArajanlatTetelOpciok: []
            , MTOArajanlatTetelOpciok: []
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});

    }

    getForm = function () {

        var kedvezmenyesAr = this.state.arajanlatTetelek_arengedmeny_ft;
        var isEmpty = this.props.arajanlat_arengedmeny == null || this.props.arajanlat_arengedmeny.trim() == '';
        var notNumber = isNaN(this.props.arajanlat_arengedmeny);
        var isFt = (this.props.arajanlat_arengedmeny >= 100) ? true : false;
        if (isEmpty == false && notNumber == false && isFt == false) {
            var netto = this.state.arajanlatTetelek_netto_ar;
            
            var kedvezmenySzazalek = this.props.arajanlat_arengedmeny;
            var kedvezmenyForint = (netto / 100) * kedvezmenySzazalek;
            var kedvezmenyesAr = netto - kedvezmenyForint;
            this.state.arajanlatTetelek_arengedmeny_ft = kedvezmenyesAr;
            
        }

        if (isEmpty == false && notNumber == false && isFt == true) {
            this.state.arajanlatTetelek_arengedmeny_ft = this.state.arajanlatTetelek_netto_ar;
        }


        var darabszam =(this.state.arajanlatTetelek_darabszam==null ||this.state.arajanlatTetelek_darabszam =='')?1:this.state.arajanlatTetelek_darabszam;
        this.state.arajanlatTetelek_nettoOsszesen = this.state.arajanlatTetelek_netto_ar*darabszam;
        
       const inactiveButtonStyle = {
            padding: '1px 4px',
            marginLeft: 5,
            borderRadius: '5px',
            border: '2px solid #007BFF',
            backgroundColor: '#007BFF',
            color: 'white',
            cursor: 'pointer',
            textAlign: 'center',
            transition: 'background-color 0.3s ease',
            fontWeight:'bold'
        };
        const selectedButtonStyle = {
            padding: '1px 4px',
            marginLeft: 5,
            borderRadius: '5px',
            border: '2px solid #007BFF',
            backgroundColor: '#b02a37',
            color: 'white',
            cursor: 'pointer',
            textAlign: 'center',
            transition: 'background-color 0.3s ease',
            fontWeight:'bold'
        };

        var dbButtonStyle = (this.state.arajanlatTetelek_mennyisegiEgyseg == 'db') ? selectedButtonStyle : inactiveButtonStyle;
        var fmButtonStyle = (this.state.arajanlatTetelek_mennyisegiEgyseg == 'fm') ? selectedButtonStyle : inactiveButtonStyle;
        var m2ButtonStyle = (this.state.arajanlatTetelek_mennyisegiEgyseg == 'm2') ? selectedButtonStyle : inactiveButtonStyle;

    
    

        return <Container>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_manualis_tetel_sorszam')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField
                    id={'arajanlatTetelek_manualis_tetel_sorszam'} 
                    value={this.state.arajanlatTetelek_manualis_tetel_sorszam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_manualis_tetel_sorszam']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            
            <Row>
                <Col style={{textAlign: 'left'}}>
                  <div style={{display: 'flex'}}>
                    <div><b>Me.:</b></div>
        
                    <div 
                        onClick={() => this.setState({arajanlatTetelek_mennyisegiEgyseg: 'db'})} 
                        style={dbButtonStyle}>db</div>
        
                    <div onClick={() => this.setState({arajanlatTetelek_mennyisegiEgyseg: 'fm'})}
                         style={fmButtonStyle}>fm</div>
                    <div onClick={() => this.setState({arajanlatTetelek_mennyisegiEgyseg: 'm2'})} 
                         style={m2ButtonStyle}>m2</div>
                </div>
                </Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelek_darabszam'} 
                    value={this.state.arajanlatTetelek_darabszam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_darabszam']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            
            
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_megnevezes')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField
        
                    id={'arajanlatTetelek_megnevezes'} 
                    value={this.state.arajanlatTetelek_megnevezes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_megnevezes']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_hivatkozas_megjegyzes')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'arajanlatTetelek_hivatkozas_megjegyzes'} 
                    value={this.state.arajanlatTetelek_hivatkozas_megjegyzes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_hivatkozas_megjegyzes']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_hivatkozas')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'arajanlatTetelek_hivatkozas'} 
                    value={this.state.arajanlatTetelek_hivatkozas}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_hivatkozas']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_netto_ar')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField
                    id={'arajanlatTetelek_netto_ar'} 
                    value={this.state.arajanlatTetelek_netto_ar}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_netto_ar']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_nettoOsszesen')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelek_nettoOsszesen'} 
                    value={this.state.arajanlatTetelek_nettoOsszesen}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_nettoOsszesen']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_tetel_kibontas')}</Col>
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextFormatter
                    sablonKivalasztoval={false}
                    id={'arajanlatTetelek_tetel_kibontas'} 
                    value={this.state.arajanlatTetelek_tetel_kibontas}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_tetel_kibontas']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        
            <Row>
                <Col style={{textAlign: 'right'}}>
                <div>
                    <MTOArajanlatTetelOpciokContent   
                        value={this.state.MTOArajanlatTetelOpciok} 
                        setBack={this.setBack.bind(this)} 
                        additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
                        editable={true}
                        formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                        theme={this.props.theme}
                        arajanlatTetelek_darabszam={this.state.arajanlatTetelek_darabszam}
                        />
                </div>
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    addRow = function () {

        this.props.addRow(this.state, false);
        this.reset();
    }

    addRowAndClose = function () {

        this.props.addRow(this.state, true);
        this.reset();
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{maxHeight: 740, minWidth: 1100}}>
            <div className={'popupWrapper' + className} id="MTOArajanlatTetelInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('MTOArajanlatTetelInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Button className={'btn-sm'} onClick={this.addRowAndClose.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                    <Button className={'btn-sm'} onClick={this.addRow.bind(this)} variant="info" style={{marginTop: '5px'}}>{lang.getTitle('new')}</Button>      
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default MTOArajanlatTetelInsertMTO;

