import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import huLocale from '@fullcalendar/core/locales/hu';
import interactionPlugin from '@fullcalendar/interaction';
import { createEventId } from './event-utils';
import "@fullcalendar/daygrid/main.css";
import "./../../css/fullcalendar.css";
import SzabadsagokInsertForm from './../Szabadsagok/SzabadsagokInsertForm';
import SzabadsagokUpdateForm from './../Szabadsagok/SzabadsagokUpdateForm';
import Dialog from './../../components/Dialog.js';
import timeIcon from './../../img/white/schedule_white_24dp.svg'
import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');

class SzabadsagokNaptar extends Component {

    constructor(props) {
        super(props);
        this.NONE = "NONE";
        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";
        this.calendarRef = React.createRef();
        this.state = {
            weekendsVisible: true,
            currentEvents: [],
            events: [],
            openedForm: this.NONE,
            selectedDate: null,
            selectedId: null,
            dialogText: null
        }

        this.dateRangeStart = null;
        this.dateRangeEnd = null;
    }

    getInitDateRange = function () {
        var currentDate = new Date();
    }

    componentDidMount() {
        this.dataLoader({start: this.dateRangeStart, end: this.dateRangeEnd});
    }

    closeForm = function () {
        this.setState({openedForm: this.NONE});
        this.dataLoader({start: this.dateRangeStart, end: this.dateRangeEnd});
    }

    addForeignKeyFilter = function (filterValues) {
        var additionalParams = this.props.additionalParams;
        if (typeof additionalParams == 'undefined') {
            return filterValues;
        }

        if (additionalParams == null) {
            return filterValues;
        }

        if (additionalParams.length == null) {
            return filterValues;
        }

        for (var ap = 0; ap < additionalParams.length; ap++) {
            var additionalParam = additionalParams[ap];
            for (const property in additionalParam) {
                var filter = null;
                for (var f = 0; f < filterValues.length; f++) {
                    var currentFilter = filterValues[f];
                    if (currentFilter.name == property) {
                        filter = currentFilter;
                    }
                }
                if (filter != null) {
                    filter.operator = 'eq';
                    filter.type = 'number';
                    filter.value = additionalParam[property];
                } else {
                    filter = {
                        name: property,
                        operator: 'eq',
                        type: 'number',
                        value: additionalParam[property]
                    };
                    filterValues.push(filter);
                }
            }
        }
        return filterValues;
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Szabadsagok/szabadsagokFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({szabadsagok_id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == true) {
                            self.setState({openedForm: 'HIDDEN', selectedRow: null});

                        } else {
                            self.setState({openedForm: this.DIALOG_ALERT, dialogText: response.fail})
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    dataLoader = function (dateRange) {

        this.dateRangeStart = dateRange.start;
        this.dateRangeEnd = dateRange.end;
        var filterValue = {from: this.dateRangeStart, to: this.dateRangeEnd}
        //filterValue = this.addForeignKeyFilter(filterValue);
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/SzabadsagokNaptar/naptarSelect.php";
            self.state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(filterValue)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({events: response.data});
                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
        }
    }

    handleWeekendsToggle = function () {
        this.setState({
            weekendsVisible: !this.state.weekendsVisible
        })
    }

    handleDateSelect = function (selectInfo) {
        this.setState({openedForm: this.FORM_INSERT, selectedDate: selectInfo.start});
        return false;
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }

    handleEventClick = function (clickInfo) {
        this.setState({openedForm: this.FORM_UPDATE, selectedId: clickInfo.event.id});
    }

    handleEvents = function (events) {
        this.setState({
            currentEvents: events
        })
    }

    renderEventContent = function (eventInfo) {

        var ugyfelId = eventInfo.event.extendedProps.szabadsagok_dolgozo_id;
        var ugyfelNev = eventInfo.event.extendedProps.users_name;
        var munkaraBeosztva = eventInfo.event.extendedProps.munkaraBeosztva;
        var idoStart = eventInfo.event.extendedProps.idoStart;
        var idoEnd = eventInfo.event.extendedProps.idoEnd;
        var megjegyzes = eventInfo.event.extendedProps.megjegyzes;

        var color = (munkaraBeosztva) ? 'red' : 'white';
        return <div style={{margin: 5}} key={'event_container_' + eventInfo.event.id}>
            <div>  
                <i><font style={{whiteSpace: 'break-spaces'}} color={color}><b>{ugyfelNev}</b></font></i>
            </div>  
            <div style={{display: 'flex', borderBottom: 'solid 1px #00000044'}}>  
                <div style={{flex: 1}} ><img style={{maxHeight: 18, margin: 2}} src={timeIcon}/></div>
                <div style={{flex: 4, textAlign: 'center'}} >{idoStart}</div>
                <div style={{flex: 1, textAlign: 'center'}} >|</div>
                <div  style={{flex: 4, textAlign: 'center'}} >{idoEnd}</div>  
            </div>  
            <div style={{display: 'flex'}}>  
                <div style={{flex: 1}} >Megjegyzés:</div>
            </div>  
            <div style={{display: 'flex', borderBottom: 'solid 1px #00000044'}}>  
                <div style={{flex: 1, whiteSpace: 'normal'}} >{megjegyzes}</div>
            </div>  
        
        </div>

    }

    renderSidebarEvent = function (event) {

        return (<li  key={event.id}>
            <b>{event.start}</b>
            <i>{event.title}</i>
        
        </li>
                )
    }

    transactionFail = function (reason) {

        this.setState({openedForm: 'ALERT', dialogText: reason});
    }

    update = function (id, toDate) {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/SzabadsagokNaptar/naptarUpdate.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: id, toDate: toDate})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.dataLoader({start: this.dateRangeStart, end: this.dateRangeEnd});
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    resize = function (id, fromDate, toDate) {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/SzabadsagokNaptar/naptarAtmeretezes.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({szabadsagok_dolgozoId: id, szabadsagok_nap: fromDate, szabadsagok_nap_ig: toDate})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.dataLoader({start: this.dateRangeStart, end: this.dateRangeEnd});
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    onDrop = function (info) {
        const {start, end} = info.oldEvent._instance.range;
        var id = info.event.id;
        //this.update(id, fromDate, toDate);

        const {
            start: newStart,
            end: newEnd
        } = info.event._instance.range;
        var fromDate = moment(newStart).format('YYYY-MM-DD');
        var toDate = moment(newEnd).format('YYYY-MM-DD');
        //console.log(newStart, newEnd);
        this.update(id, fromDate);
        if (new Date(start).getDate() === new Date(newStart).getDate()) {
            info.revert();
        }

    }

    eventResize = function (info) {
        var ugyfelId = info.event.extendedProps.szabadsagok_dolgozo_id;
        var start = moment(info.event.start).format("YYYY-MM-DD");
        var end = moment(info.event.end).utc().format("YYYY-MM-DD");
        this.resize(ugyfelId, start, end);
    }

    render() {
        var activeForm = "";
        if (this.state.openedForm == 'ALERT') {
            activeForm = <Dialog 
                type={'ALERT'} 
                dialogText={this.state.dialogText} 
                callback={() => {
                                    this.state.openedForm = 'NONE';
                                    this.dataLoader({start: this.dateRangeStart, end: this.dateRangeEnd});
                                }}
                theme={this.props.theme}
                />;
        }


        if (this.state.openedForm == this.FORM_INSERT) {
            activeForm = <SzabadsagokInsertForm 
                theme={this.props.theme} 
                key={'munkaBeosztas_KEY_INSERT'} 
                formType={this.FORM_INSERT} 
                closeForm={this.closeForm.bind(this)}
                additionalParams={[{}]}
                szabadsag_datum={this.state.selectedDate}
                />;
        }
        if (this.state.openedForm == this.FORM_UPDATE) {
            activeForm = <SzabadsagokUpdateForm
                theme={this.props.theme} 
                key={'munkaBeosztas_KEY_UPDATE'} 
                formType={this.FORM_UPDATE} 
                closeForm={this.closeForm.bind(this)}
                id ={this.state.selectedId}
                additionalParams={[
                                {munkaBeosztasCimek_munka_beosztas_id: this.state.selectedId},
                                {munkaBeosztasDolgozok_munka_beosztas_id: this.state.selectedId},
                                {munkaBeosztasUgyfelek_munka_beosztas_id: this.state.selectedId}
                            ]} 
                />;
        }

        if (this.state.openedForm == this.FORM_DELETE) {
            activeForm = <Dialog 
                type={this.DIALOG_CONFIRMATION} 
                dialogText={this.state.dialogText} 
                callback={this.delete.bind(this)}
                cancel={() => this.setView({target: {id: this.NONE}})}
            
                theme={this.props.theme}
                />;
        }

        // right: 'dayGridMonth,timeGridWeek,timeGridDay'
        return <div className={'surface'}>
        
            <div>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        
        
                    datesSet={(dateInfo) => {
                                    this.dataLoader(dateInfo);
                                }}
        
        
                    headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth dayGridWeek dayGridDay'
                                }}
                    views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}            
                    initialView='dayGridWeek'
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={this.state.weekendsVisible}
                    events={this.state.events}
        
                    initialEvents={this.state.events}
                    select={this.handleDateSelect.bind(this)}
                    eventContent={this.renderEventContent.bind(this)} 
                    eventClick={this.handleEventClick.bind(this)}
                    eventsSet={this.handleEvents.bind(this)} 
                    eventResize={this.eventResize.bind(this)}
                    locale={huLocale}
                    contentHeight={"auto"}
                    height={'auto'}
                    eventDrop={this.onDrop.bind(this)}
                    />
            </div>
            {activeForm}
        </div>
    }
}
export default SzabadsagokNaptar;
