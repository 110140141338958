import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import MunkaBeosztasCimekContent from './../../modules/MunkaBeosztasCimek/MunkaBeosztasCimekContent.js';
import MTOMunkaBeosztasCimekContent from './../../modules/MTOMunkaBeosztasCimek/MTOMunkaBeosztasCimekContent.js';
import MunkabeosztasMunkavallaloHozzarendelese from './../../componentsSpecial/MunkabeosztasMunkavallaloHozzarendelese';
import DateInsertField from './../../components/DateInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';
import MunkabeosztasUgyfelCombo from './../../componentsSpecial/MunkabeosztasUgyfelCombo';
import MunkabeosztasUgyfelCimCombo from './../../componentsSpecial/MunkabeosztasUgyfelCimCombo';
import UgyfelComboHozzaadassal from './../../componentsSpecial/UgyfelComboHozzaadassal';
import MunkabeosztasAutoCombo from './../../componentsSpecial/MunkabeosztasAutoCombo';




class MunkaBeosztasInsert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            munkaBeosztas_datum: ''
            , munkaBeosztas_auto_id: ''
            , munkaBeosztas_ugyfel_id: ''
            , munkaBeosztas_ugyfel_cim_id: ''
            , munkaBeosztas_megjegyzes: ''
            , MunkaBeosztasCimek: []
            , MTOMunkaBeosztasCimek: []
            , MunkaBeosztasDolgozok: []
            , MTOMunkaBeosztasDolgozok: []
            , MunkaBeosztasUgyfelek: []
            , MTOMunkaBeosztasUgyfelek: []

        }


        this.references = [];
        this.references['munkaBeosztas_datum'] = React.createRef();
        this.references['munkaBeosztas_auto_id'] = React.createRef();
        this.references['munkaBeosztas_ugyfel_id'] = React.createRef();
        this.references['munkaBeosztas_ugyfel_cim_id'] = React.createRef();
        this.references['munkaBeosztas_megjegyzes'] = React.createRef();
        this.references['munkaBeosztas_MTO'] = React.createRef();



        this.validators = [];
        this.validators['munkaBeosztas_datum'] = {fieldName: 'munkaBeosztas_datum', dataType: 'DATE', value: this.state.munkaBeosztas_datum, mandatory: true, min: null, max: null};
        this.validators['munkaBeosztas_auto_id'] = {fieldName: 'munkaBeosztas_auto_id', dataType: 'INT', value: this.state.munkaBeosztas_auto_id, mandatory: true, min: null, max: null};
        this.validators['munkaBeosztas_ugyfel_id'] = {fieldName: 'munkaBeosztas_ugyfel_id', dataType: 'INT', value: this.state.munkaBeosztas_ugyfel_id, mandatory: true, min: null, max: null};
        this.validators['munkaBeosztas_ugyfel_cim_id'] = {fieldName: 'munkaBeosztas_ugyfel_cim_id', dataType: 'INT', value: this.state.munkaBeosztas_ugyfel_cim_id, mandatory: true, min: null, max: null};
        this.validators['munkaBeosztas_megjegyzes'] = {fieldName: 'munkaBeosztas_megjegyzes', dataType: 'VARCHAR', value: this.state.munkaBeosztas_megjegyzes, mandatory: false, min: null, max: null};
        this.validators['munkaBeosztas_MTO'] = {fieldName: 'munkaBeosztas_MTO', dataType: 'MTO', value: this.state.munkaBeosztas_MTO, mandatory: false, min: null, max: null};

        this.befoglalas = false;
        this.error = false;
        this.msg = "";

    }

    componentDidMount() {
        this.reset();
        if (typeof this.props.munkaBeosztas_datum != 'undefined') {
            var selectedDate = this.props.munkaBeosztas_datum.toLocaleDateString();
            var formattedDate = this.formatDate(selectedDate);
            this.setState({munkaBeosztas_datum: formattedDate});
        }
    }

    formatDate = function (dateString) {
        if (typeof dateString == 'undefined') {
            return '';
        }
        if (typeof dateString == null) {
            return '';
        }
        if (typeof dateString == '') {
            return '';
        }
        var splited = dateString.split('.');
        var year = splited[0].trim();
        var month = splited[1].trim();
        var days = splited[2].trim();

        return year + "-" + month + "-" + days;



    }

    componentDidUpdate(prevProps) {
        if (this.props.munkaBeosztas_datum !== this.state.munkaBeosztas_datum) {
            //  var selectedDate = new Date(this.props.munkaBeosztas_datum).toISOString().slice(0, 10);
            //  this.setState({munkaBeosztas_datum: selectedDate});
        }
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({munkaBeosztas_datum: ''
            , munkaBeosztas_auto_id: ''
            , munkaBeosztas_ugyfel_id: ''
            , munkaBeosztas_ugyfel_cim_id: ''
            , munkaBeosztas_megjegyzes: ''
            , MunkaBeosztasCimek: []
            , MTOMunkaBeosztasCimek: []
            , MunkaBeosztasDolgozok: []
            , MTOMunkaBeosztasDolgozok: []
            , MunkaBeosztasUgyfelek: []
            , MTOMunkaBeosztasUgyfelek: []
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getFormMunka = function () {
        return <Container>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztas_datum')}</Col>
                <Col style={{textAlign: 'right', display: 'contents'}}>
                <DateInsertField 
                    id={'munkaBeosztas_datum'} 
                    value={this.state.munkaBeosztas_datum}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['munkaBeosztas_datum']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztas_auto_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <MunkabeosztasAutoCombo 
                    id={'munkaBeosztas_auto_id'} 
                    value={this.state.munkaBeosztas_auto_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/MunkaBeosztas/auto_idFormComboInsertData.php'}
                    reference={this.references['munkaBeosztas_auto_id']}
                    editable={true}
                    theme={this.props.theme}
                    munkaBeosztas_datum={this.state.munkaBeosztas_datum}
                    style={{minWidth:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztas_ugyfel_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <UgyfelComboHozzaadassal 
                    id={'munkaBeosztas_ugyfel_id'} 
                    value={this.state.munkaBeosztas_ugyfel_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/MunkaBeosztas/ugyfel_idFormComboInsertData.php'}
                    reference={this.references['munkaBeosztas_ugyfel_id']}
                    editable={true}
                    theme={this.props.theme}
                    openInsertForm={() => {
                                    alert('open')
                                }}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztas_ugyfel_cim_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <MunkabeosztasUgyfelCimCombo 
                    id={'munkaBeosztas_ugyfel_cim_id'} 
                    value={this.state.munkaBeosztas_ugyfel_cim_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/MunkaBeosztas/ugyfel_cim_idFormComboInsertData.php'}
                    reference={this.references['munkaBeosztas_ugyfel_cim_id']}
                    ugyfel_id ={this.state.munkaBeosztas_ugyfel_id}
                    editable={true}
                    theme={this.props.theme}
                    style={{width:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztas_megjegyzes')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextAreaInsertField 
                    id={'munkaBeosztas_megjegyzes'} 
                    value={this.state.munkaBeosztas_megjegyzes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['munkaBeosztas_megjegyzes']}
                    editable={true}   
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <div>
                    <MunkabeosztasMunkavallaloHozzarendelese
                        value={this.state.MunkaBeosztasDolgozok} 
                        setBack={this.setBack.bind(this)} 
                        additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : ajax.additionalParamFilter(this.props.additionalParams, 'munkaBeosztasCimek_munka_beosztas_id')}
                        editable={true}
                        formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                        theme={this.props.theme}
                        autoId ={this.state.munkaBeosztas_auto_id}
                        munkaBeosztas_datum={this.state.munkaBeosztas_datum}
                        isInsert={true}
        
                        />
                </div>
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <div>
                    <MTOMunkaBeosztasCimekContent   
                        value={this.state.MTOMunkaBeosztasCimek} 
                        setBack={this.setBack.bind(this)} 
                        additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : ajax.additionalParamFilter(this.props.additionalParams, 'munkaBeosztasCimek_munka_beosztas_id')}
                        editable={true}
                        formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                        theme={this.props.theme}
                        />
                </div>
                </Col>
            </Row>
        
        </Container>
    }

    getFormBefoglalas = function () {
        return <Container>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztas_datum')}</Col>
                <Col style={{textAlign: 'right', display: 'contents'}}>
                <DateInsertField 
                    id={'munkaBeosztas_datum'} 
                    value={this.state.munkaBeosztas_datum}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['munkaBeosztas_datum']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztas_megjegyzes')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextAreaInsertField 
                    id={'munkaBeosztas_megjegyzes'} 
                    value={this.state.munkaBeosztas_megjegyzes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['munkaBeosztas_megjegyzes']}
                    editable={true}   
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitleBold('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitleBold(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                stateClone[property] = additionalParam[property];
            }
        }
        return stateClone;
    }

    insert = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false && this.befoglalas == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        var params = this.concatAdditionalParams();

        try {

            var path = "";
            if (this.befoglalas == true) {
                path = ajax.getUrlBase() + "modules/MunkaBeosztas/munkaBeosztasBefoglalasFormInsert.php";
            } else {
                path = ajax.getUrlBase() + "modules/MunkaBeosztas/munkaBeosztasFormInsert.php";
            }

            const url = path;

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(params)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {

                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = (this.befoglalas == false) ? this.getFormMunka() : this.getFormBefoglalas();

        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{minWidth: 850}}>
            <div className={'popupWrapper' + className} id="MunkaBeosztasInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('MunkaBeosztasInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col 
                            style={{backgroundColor: (this.befoglalas) ? 'lightgrey' : 'grey', cursor: 'pointer'}}
                            onClick={() => {
                        this.befoglalas = false;
                        this.setState({});
                    }}
                            >Munka</Col>
                        <Col 
                            style={{backgroundColor: (this.befoglalas) ? 'grey' : 'lightgrey', cursor: 'pointer'}}
                            onClick={() => {
                        this.befoglalas = true;
                        this.setState({});
                    }}
                            >Befoglalás</Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.insert.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        
        </PopUpWindow>);
    }
}

export default MunkaBeosztasInsert;

