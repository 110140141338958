import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';
import PopUpWindow from './../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as lang from './../lang.js';
import * as ajax from './../ajax.js';
class MultiComment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ids: this.props.ids,
            text: ''
        }
    }
    
    transactionFail = function(){
        alert("Sikertelen tranzakció");
    }

    update = function () {
        var self=this;
        try {
            const url = ajax.getUrlBase() + "modules/BelsoRendelesek/multiCommentUpdate.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(this.state)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                         
                            self.props.callback();
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }
    
    onChange = function(e){
        this.setState({text:e.target.value});
    }

    render() {
        var style = {maxWidth: 300, maxHeight: 200};
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        if (typeof this.props.style != 'undefined') {
            style = this.props.style;
        }

        return <PopUpWindow style={style} theme={this.props.theme}>
            <div className={"popupWrapper" + className}>
                <div className={"dialogHeader" + className}>{this.props.header}</div>
                <div className={"popupBody" + className}><div style={{whiteSpace: 'break-spaces'}}>
                        <textarea 
                        onChange={this.onChange.bind(this)}
                        style={{minWidth: '90%', minHeight: 250}}
                        >{this.state.text}</textarea>
                    </div></div>
                <div style={{display: 'flex', textAlign: 'center', margin: '20px', justifyContent: 'center'}}>
                    <Button 
                        variant={'success'} 
                        onClick={this.update.bind(this)}
                        className={'btn-sm'}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;
                    <Button 
                        variant={'danger'} 
                        onClick={this.props.callback}
                        className={'btn-sm'}>{lang.getTitle('Cancel')}</Button>
                </div>
            </div>
        </PopUpWindow>

    }
}

export default MultiComment;
