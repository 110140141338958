import React from 'react';
import './../../css/ProjektJarmuvekTabla.css';

import  DateInsertField from './../../components/DateInsertField';
import {Button, Row, Col} from 'react-bootstrap';

import rogzitveIco from './../../img/black/calendar_month_black_24dp.svg';
import kiirvaIco from './../../img/black/edit_note_black_24dp.svg';
import gyartasbaAdvaIco from './../../img/black/engineering_black_24dp.svg';
import festesreAtadvaIco from './../../img/black/volunteer_activism_black_24dp.svg';
import festoMuhelyAtvetteIco from './../../img/black/handshake_black_24dp.svg';
import osszeszerelesreKeszIco from './../../img/black/build_black_24dp.svg';
import szallitasraKeszIco from './../../img/black/inventory_2_black_24dp.svg';
import kiszallitvaIco from './../../img/black/local_shipping_black_24dp.svg';
import szabadsagIco from './../../img/black/surfing_black_24dp.svg';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import * as ajax from './../../ajax';
import * as lang from './../../lang';

import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');


class ProjektTabla extends React.Component {

    constructor(props) {
        super(props);

        var fromDate = new Date();

        var toDate = moment(fromDate, "YYYY-MM-DD").add('days', 62);

        var fromDateString = moment(fromDate).format('YYYY-MM-DD');
        var toDateString = moment(toDate).format('YYYY-MM-DD');


        this.state = {
            fromDate: fromDateString,
            toDate: toDateString,
            opener: true
        }

        this.data = null;
        this.load();
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    load = function () {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/ProjektTabla/DataStore.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id, fromDate: this.state.fromDate, toDate: this.state.toDate})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.data = response.data;
                            this.setState({});
                        } else {
                            console.log(response);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    getIconByDateName = function (dateName) {
        var icon = null;
        var title = "";
        switch (dateName) {
            case 'munkalap_datumok_rogzitve':
                icon = rogzitveIco;
                title = "Rögzítve";
                break;
            case 'munkalap_datumok_kiirva':
                icon = kiirvaIco;
                title = "Kiírva";
                break;
            case 'munkalap_datumok_gyartasbaAdva':
                icon = gyartasbaAdvaIco;
                title = "Gyártásba adva";
                break;
            case 'munkalap_datumok_festesreAtadva':
                icon = festesreAtadvaIco;
                title = "Festésre átadva";
                break;
            case 'munkalap_datumok_festoMuhelyAtvette':
                icon = festoMuhelyAtvetteIco;
                title = "Feső műhely átvette";
                break;
            case 'munkalap_datumok_osszeszerelesreKesz':
                icon = osszeszerelesreKeszIco;
                title = "Összeszerelésre kész";
                break;
            case 'munkalap_datumok_szallitasraKesz':
                icon = szallitasraKeszIco;
                title = "Szállításra kész";
                break;
            case 'munkalap_datumok_kiszallitva':
                icon = kiszallitvaIco;
                title = "Kiszállítva";
                break;
        }

        return {icon: icon, title: title}
    }

    getDayName = function (d) {
        var days = ['Va.', 'Hé.', 'Ke.', 'Sze.', 'Csü.', 'Pén.', 'Szo.'];
        return days[d.getDay()];
    }

    getDayColor = function (dayString) {
        var d = new Date(dayString);
        if (d.getDay() == 0) {
            return "#999999";
        }
        if (d.getDay() == 6) {
            return "#bbbbbb";
        }

        return '#FFFFFF'
    }

    isDateLinesExist = function (data) {
        if (typeof data == 'undefined') {
            return false;
        }
        if (data == null) {
            return false;
        }
        if (typeof data.dateLines == 'undefined') {
            return false;
        }
        if (data.dateLines == null) {
            return false;
        }
        if (data.dateLines.length == 0) {
            return false;
        }
        return true;
    }

    getMonthHeader = function () {
        var monthNames = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];
        var monthShortNames = ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sze', 'Okt', 'Nov', 'Dec'];


        var oneDay = 86400000;


        var fromDate = this.state.fromDate;
        var toDate = this.state.toDate;

        var fromDateMS = new Date(fromDate).getTime();

        var monthHeaders = [];
        var dayCount = Math.round((new Date(toDate).getTime() - new Date(fromDate)) / oneDay);


        var colspan = 1;
        var currentMonth = new Date(fromDate).getMonth();

        for (var i = 0; i < dayCount; i++) {
            fromDateMS = fromDateMS + oneDay;
            var currentDay = new Date(fromDateMS);
            var month = new Date(currentDay).getMonth();
            if (currentMonth == month) {
                colspan++;
            } else {
                var monthName = (colspan > 3) ? monthNames[currentMonth] : monthShortNames[currentMonth];
                var bg = (currentMonth % 2 == 0) ? 'white' : "#eeeeee"
                monthHeaders.push(<th colSpan={colspan} style={{backgroundColor: bg, color: 'black', textAlign: 'center', borderRight: 'solid 1px black'}}  key={"header_month_name_" + i}>{monthName}</th>);
                currentMonth++;
                colspan = 1;
                if (currentMonth > 11) {
                    currentMonth = 0;
                }
            }
        }

        if (colspan > 1) {
            var monthName = (colspan > 3) ? monthNames[currentMonth] : monthShortNames[currentMonth];
            var bg = (currentMonth % 2 == 0) ? 'white' : "#eeeeee"
            monthHeaders.push(<th colSpan={colspan} style={{backgroundColor: bg, color: 'black', textAlign: 'center', borderRight: 'solid 1px black'}}  key={"header_month_name_" + i}>{monthName}</th>);
        }

        return monthHeaders;
    }

    getHeader = function (data) {
        this.getMonthHeader();
        var isDateLinesExist = this.isDateLinesExist(data);
        if (isDateLinesExist == false) {
            return null;
        }

        var days = [];
        var dayNames = [];

        var dateLine = data.dateLines[0];
        for (var i = 0; i < dateLine.length; i++) {
            var properties = dateLine[i].properties;
            var dateString = properties.DateString;
            var currentDate = new Date(dateString);
            var day = currentDate.getDate();
            var dayName = this.getDayName(currentDate);
            var dateColor = this.getDayColor(dateString);
            days.push(<th style={{textAlign: 'center', backgroundColor: dateColor, color: 'black'}} key={"header_date_day_" + i}>{day}</th>);
            dayNames.push(<th style={{backgroundColor: dateColor, color: 'black'}}  key={"header_date_day_name_" + i}>{dayName}</th>);


        }

        var headerRows = [];

        var monthHeader = this.getMonthHeader();

        headerRows.push(<tr key="header_row_0"><th></th>{monthHeader}</tr>);
        headerRows.push(<tr key="header_row_1"><th>Járművek</th>{dayNames}</tr>);
        headerRows.push(<tr key="header_row_2"><th></th>{days}</tr>);


        return headerRows;

    }

    getCars = function (data) {
        var cars = [];
        for (var w = 0; w < data.length; w++) {
            var car = this.getCar(data[w]);
            cars.push(car);
        }
        return cars;
    }
    getCar = function (data) {

        var isDateLinesExist = this.isDateLinesExist(data);
        if (isDateLinesExist == false) {
            return null;
        }

        var cars = [];

        var dateLine = data.dateLines[0];
        var dateCells = dateLine.dateCells;

        for (var i = 0; i < dateCells.length; i++) {
            var properties = dateCells[i].properties;
            var dateColor = this.getDayColor(properties.DateString);
            if (typeof properties['autok_id'] !== 'undefined') {
                var icon_title = this.getIconByDateName('munkalap_datumok_kiszallitva');
                cars.push(<td style={{backgroundColor: dateColor}} key={"cars_id_" + i}><img src={icon_title.icon}/></td>);
            } else {
                cars.push(<td style={{backgroundColor: dateColor}} key={"cars_id_" + i}><div style={{textAlign: 'center'}}>_</div></td>);
            }
        }

        return <tr key={"car_line_id_" + dateLine.id}>
        <th><b>{dateLine.title}</b></th>
        {cars}
    </tr>

    }

    getTable = function () {
        if (this.data == null) {
            return null
        }
        var header = this.getHeader(this.data.header);
        var cars = this.getCars(this.data.cars);


        return <table className={'projecktJarmuvekTable'} border="1">
        
            <thead>{header}</thead>
            <tbody>
                {cars}
            </tbody>
        </table>


    }
    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    render() {
        var table = this.getTable();
        return <div className="projecktJarmuvek">
            <div 
                style={{
                                display: 'flex',
                                textAlign: 'center',
                                alignItems: 'center',
                                fontSize: 16,
                                backgroundColor: 'white',
                                position: 'sticky',
                                left: 0,
                                zIndex: 222
                            }}
        
                >
                <div style={{
                                display: 'flex',
                                textAlign: 'center',
                                alignItems: 'center',
                                fontSize: 16,
                                backgroundColor: 'white',
                                position: 'sticky',
                                left: 0,
                                zIndex: 222
                                 }}>
                    <div style={{paddingLeft: 5}}>&nbsp;</div>
                    <div>Tól:</div>
                    <div>
                        <DateInsertField 
                            id={'fromDate'} 
                            value={this.state.fromDate}  
                            setBack={this.setBack.bind(this)}
                            editable={true}
        
                            />
                    </div>
                    <div style={{paddingLeft: 50}}>&nbsp;</div>
                    <div>Ig:</div>
                    <div>
                        <DateInsertField 
                            id={'toDate'} 
                            value={this.state.toDate}  
                            setBack={this.setBack.bind(this)}
                            editable={true}
        
                            />
                    </div>
                    <div style={{paddingLeft: 10}}>&nbsp;</div>
                    <div>
                        <Button onClick={this.load.bind(this)} variant="success" size="sm" >{lang.getTitle('Lekerdezes')}</Button>
                    </div>
                </div>
            </div>
            {table}
        </div>;
    }

}

export default ProjektTabla;
