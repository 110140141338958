import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';

import { Container, Row, Col, Button } from 'react-bootstrap';
import * as lang from './../lang.js';
import * as ajax from './../ajax.js';
import MobileActionCell from './../components/MobileActionCell';
import DriveLinkTableButton from './../components/DriveLinkTableButton';
import GoogleMapsButton from './../components/GoogleMapsButton';

class MobileTableCell extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRow: this.props.row['ugyfelek_id']

        };

        this.NONE = "NONE";

        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";
        this.FORM_MOBILE_FILTER = "MOBILE_FILTER";



        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";



    }

    render() {
        var style = {maxWidth: 200, maxHeight: 200};
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        if (typeof this.props.style != 'undefined') {
            style = this.props.style;
        }

        var row = this.props.row;

        return  <div className={'mobileTableCell'}>


    <Row style={{borderBottom:'solid 1px grey'}}><Col><b>{row["ugyfelek_nev"]} ({row["ugyfelek_monogram"]})</b></Col></Row>
    <Row><Col sm={4} style={{textDecoration:'underline'}} >{lang.getTitle('ugyfelek_adoszam')}</Col><Col sm={8}>{row["ugyfelek_adoszam"]}</Col></Row>
    <Row><Col sm={4} style={{textDecoration:'underline'}}>{lang.getTitle('ugyfelek_eu_adoszam')}</Col><Col sm={8}>{row["ugyfelek_eu_adoszam"]}</Col></Row>
    <Row><Col sm={4} style={{textDecoration:'underline'}}>{lang.getTitle('ugyfelek_cegjegyzek_szam')}</Col><Col sm={8}>{row["ugyfelek_cegjegyzek_szam"]}</Col></Row>
    <Row><Col sm={4} style={{textDecoration:'underline'}}>{lang.getTitle('ugyfelekcimkereso')}</Col><Col sm={8}>{row["ugyfelekcimkereso"]}</Col></Row>
    <Row><Col sm={4} style={{textDecoration:'underline'}}>{lang.getTitle('ugyfelek_kapcsolattarto_nev')}</Col><Col sm={8}>{row["ugyfelek_kapcsolattarto_nev"]}</Col></Row>
    <Row><Col sm={4} style={{textDecoration:'underline'}}>{lang.getTitle('ugyfelek_telefon')}</Col><Col sm={8}>{row["ugyfelek_telefon"]}</Col></Row>
    <Row><Col sm={4} style={{textDecoration:'underline'}}>{lang.getTitle('ugyfelek_email')}</Col><Col sm={8}>{row["ugyfelek_email"]}</Col></Row>
    <Row><Col sm={4} style={{textDecoration:'underline'}}>{lang.getTitle('ugyfelek_web')}</Col><Col sm={8}>{row["ugyfelek_web"]}</Col></Row>
    <Row><Col sm={4} style={{textDecoration:'underline'}}>{lang.getTitle('ugyfelek_megjegyzes')}</Col><Col sm={8}>{row["ugyfelek_megjegyzes"]}</Col></Row>
    <Row style={{borderTop:'solid 1px grey'}}><Col sm={4}></Col></Row>
    <Row   className={'mobileTableCellFooter'}>
        <Col style={{display:'flex'}}>
        
        <MobileActionCell 
            stateReference ={this.state}
            row_id={row["ugyfelek_id"]}   
            showForm={this.props.showForm.bind(this)}
            isViewForm ={true}
            theme={this.props.theme}
            />&nbsp;
        
        
        <DriveLinkTableButton
                        value={row["ugyfelek_safeLink"]}
        />&nbsp;
        <DriveLinkTableButton
                        value={row["ugyfelek_driveLink"]}
                        />&nbsp;
        <GoogleMapsButton
                        value={row["ugyfelek_mapsLink"]}
                        />
        </Col>
        

    </Row>





</div>;


    }
}

export default MobileTableCell;
