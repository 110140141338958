import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';
import * as ajax from './../ajax.js';


class MunkabeosztasTelepulesCombo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dropDownVisible: false,
            cursor: -1,
            items: [],
            value: this.props.value,
            text: ''

        }

        this.ESCAPE = 27;
        this.ARROW_UP = 38;
        this.ARROW_DOWN = 40;
        this.ENTER = 13;
        this.DELETE = 46;

        // Referenciák
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            if (this.props.value == "") {
                this.props.reference.current.value = "";


                this.setState({
                    value: this.props.value,
                    text: '',
                    items: [],
                    cursor: -1,
                    dropDownVisible: false
                });
            } else {
                this.loadItemByCurrentId(this.props.value);
            }
        }
    }

    loadItemByCurrentId = function (currentId) {
        try {
            const url = ajax.getUrlBase() + this.props.dataSourceUrl;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({filterText: '', currentId: currentId})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null && typeof response.data.data != 'undefined' && response.data.data != null) {
                            this.setState({
                                value: this.props.value,
                                text: response.data.currentText,
                                items: [],
                                cursor: -1,
                                dropDownVisible: false
                            });
                            this.props.setBack('munkaBeosztasCimek_telepulesNev', response.data.currentText);
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

        this.setState({dropDownVisible: true, hoveredId: -1});
    }

    loadItems = function (filterText) {
        try {
            const url = ajax.getUrlBase() + this.props.dataSourceUrl;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({filterText: filterText,irsz:this.props.irsz})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null && typeof response.data.data != 'undefined' && response.data.data != null) {
                            if (response.data.data.length === 0) {
                                this.setState({dropDownVisible: false});
                            } else {
                                this.setState({items: response.data.data, dropDownVisible: true, cursor: -1});
                                
                            }
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

        this.setState({dropDownVisible: true, hoveredId: -1});
    }

    onChange = function (e) {
        this.setState({text: e.target.value});
        this.loadItems(e.target.value);
    }

    onFocus = function (e) {
        e.target.select();
        this.loadItems("");

    }

    onBlur = function (e) {
        this.setState({dropDownVisible: false});
    }

    setBack = function () {
        
        this.props.setBack(this.props.id, this.state.value);
    }

    getItemById = function (id) {
        var items = this.state.items;
        for (var i = 0; i < items.length; i++) {
            if (id == items[i].id) {
                return items[i].value;
            }
        }
        return "";
    }

    onItemClick = function (e) {

        var id = e.target.id;
        var text = this.getItemById(id);
        this.state.value = id;
        this.setState({value: id, text: text, dropDownVisible: false});
        this.setBack();
    }

    onKeyDown = function (e) {
        var keyCode = e.keyCode;

        switch (keyCode) {
            case this.ESCAPE:
                this.state.value = '';
                this.setState({value: '', text: '', dropDownVisible: false, cursor: -1});
                this.setBack();
                break;
            case this.ARROW_UP:
                this.setCursor(this.ARROW_UP);
                break;
            case this.ARROW_DOWN:
                this.setCursor(this.ARROW_DOWN);
                break;
            case this.ENTER:
                this.setValueByDropdown();
                break;
            case this.DELETE:
                this.state.value = '';
                this.state.text = '';
                this.state.dropDownVisible = true;
                this.state.cursor = -1;
                this.setBack();
                this.loadItems('');
                break;
            default:
                break;
        }
    }

    setCursor = function (direction) {

        if (typeof this.state.items == 'undefined') {
            return '';
        }

        var items = this.state.items;

        if (items == null) {
            return '';
        }
        if (items.length == 0) {
            return '';
        }

        var cursor = this.state.cursor;

        if (direction == this.ARROW_DOWN) {
            cursor = cursor + 1;
        }

        if (direction == this.ARROW_UP) {
            cursor = cursor - 1;
        }

        if (cursor < 0) {
            cursor = 0;
        }

        if (cursor > items.length - 1) {
            cursor = items.length - 1;
        }

        this.setState({cursor: cursor});

    }

    setValueByDropdown = function () {

        if (typeof this.state.items == 'undefined') {
            return '';
        }

        var items = this.state.items;
        var cursor = this.state.cursor;

        if (items == null) {
            return '';
        }
        if (items.length == 0) {
            return '';
        }

        if (cursor < 0 || cursor > items.length - 1) {
            return '';
        }

        var value = items[cursor].id;
        var text = items[cursor].value;

        this.state.value = value;
        this.setState({value: value, text: text, cursor: -1, dropDownVisible: false});
        this.setBack();
    }

    comboItem = function (item, position) {
        var style = "";
        if (this.props.theme == "true") {
            style = (position == this.state.cursor) ? {backgroundColor: '#9ba7b4', color: '#25323b'} : {backgroundColor: '#25323b', color: '#9ba7b4'};
        } else {
            style = (position == this.state.cursor) ? {backgroundColor: '#25323b', color: '#9ba7b4'} : {backgroundColor: '#ffffff', color: '#25323b'};
        }
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return <div 
            className={'dropdown-list-item' + className}  
            key={'itemKey_' + item.id} 
            id={item.id}
            onMouseDown={this.onItemClick.bind(this)}
            style={style}
            >
            {item.value}
        </div>
    }

    comboItems = function () {
        var comboItems = [];
        for (var i = 0; i < this.state.items.length; i++) {
            var comboItem = this.state.items[i];
            comboItems.push(this.comboItem(comboItem, i));
        }
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var itemKey = "item_" + this.props.id + "_" + i;
        return <div key={itemKey} className={'dropdown-list' + className} >{comboItems}</div>
    }

    render() {

        var comboItems = "";
        if (this.state.dropDownVisible) {
            comboItems = this.comboItems();
        }

        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return  <div style={{position: 'relative'}} >
            <input 
                type={'text'} 
                onChange={this.onChange.bind(this)}  
                onFocus={this.onFocus.bind(this)}  
                onBlur={this.onBlur.bind(this)}  
                onKeyDown={this.onKeyDown.bind(this)}
                id={this.props.id} 
                value={this.state.text} 
                ref={this.props.reference}
                readOnly={this.props.editable == false}
                style={{textAlign: 'left'}}
                className ={"component" + className}
                autoComplete="off"
                />
        
            {comboItems}
        </div>;
    }
}

export default MunkabeosztasTelepulesCombo;


