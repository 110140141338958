import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';


class NumberInsertField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }

    onChange = function (e) {
        var value = e.target.value;
        var valid=this.isValidValue(value);
        if(valid==false){
            return false;
        }
        if (value.slice(-1) == ',') {
            this.setState({value: value});
            return value;
        }
        value = this.backToNormalNumber(value);
        this.props.setBack(e.target.id, value);
        this.setState({value: value});

    }

    backToNormalNumber = function (formatted) {
        if (formatted == null) {
            return '';
        }
        if (formatted == '') {
            return '';
        }

        formatted = formatted + "";
        formatted = formatted.replaceAll(".", "");
        formatted = formatted.replaceAll(",", ".");

        return formatted*1.0;

    }

    isValidValue = function (value) {
        var acceptedChars = "0123456789.,";

        if (typeof value == 'undefined') {
            return true;
        }
        if (typeof value == null) {
            return true;
        }
        value = value + "";
        for (var i = 0; i < value.length; i++) {
            var currentChar = value[i];
            if (acceptedChars.indexOf(currentChar) == -1) {
                return false;
            }
        }

        return true;

    }

    formatter = function (number) {



        if (number == null) {
            return '';
        }
        if (number == '') {
            return '';
        }
        number = number + "";
        if (number.slice(-1) == ',') {
            return number;
        }

        var negativeSign = "";



        if (number.length > 0 && number.charAt(0) == '-') {

            negativeSign = "-";
            number = number.substring(1);

        }



        var values = number.split('.');
        var integers = values[0];
        var decimals = (values.length > 1) ? values[1] : '';

        var formatted = "";
        var thousandCounter = 0;
        for (var i = integers.length - 1; i > -1; i--) {
            thousandCounter++;
            formatted = integers.charAt(i) + formatted;

            if (i > 0 && thousandCounter > 0 && thousandCounter % 3 == 0) {

                formatted = '.' + formatted;
            }

        }

        return negativeSign + formatted + ((decimals.length > 0) ? "," + decimals : '');
    }

    render() {

        var formatted = this.formatter(this.state.value);
        
        var style = {textAlign: 'right'};
        if (typeof this.props.style != "undefined" && this.props.style != null) {
            style = this.props.style;
        }

        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return  <div><input 
        onChange={this.onChange.bind(this)} 
        style={style}
        type={'text'} 
        id={this.props.id} 
        value={formatted} 
        ref={this.props.reference}
        readOnly={this.props.editable == false}
        className ={"component" + className}
        /></div>;
    }
}

export default NumberInsertField;

