import React, { Component } from 'react';
import * as lang from './../lang.js';
import {Button} from 'react-bootstrap';
import driveIcon from './../img/google-drive-social-media-svgrepo-com.svg';

class DriveLinkTableButton extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (typeof this.props.value == 'undefined') {
            return '';
        }
        if (this.props.value == null) {
            return '';
        }
        if (this.props.value == '') {
            return '';
        }

        return <Button 
            title={lang.getTitle('Google Drive Link')}
            className={'btn-sm'} 
            variant="info"
            onClick={() => {
                            window.open(this.props.value, '_blank');
                        }}
            >
            <img
                title={lang.getTitle('View')}
                style={{maxHeight: 20, maxWidth: 20}}
                onClick={() => {
                                window.open(this.props.value, '_blank');
                            }}
                src={driveIcon} 
        
                />
        </Button>
    }
}

export default DriveLinkTableButton;

