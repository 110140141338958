import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import PagingToolbar from './../../components/PagingToolbar';
import MobileTable from './../../componentsMobile/MobileTable';
import MobileTableFilter from './MobileTableFilter';


import InsertForm from './../UgyfelekKarbantartas/UgyfelekKarbantartasInsertForm.js';
import UpdateForm from './../UgyfelekKarbantartas/UgyfelekKarbantartasUpdateForm.js';
import ViewForm from './../UgyfelekKarbantartas/UgyfelekKarbantartasViewForm.js';
import Dialog from './../../components/Dialog.js';
import MobilePopUpWindow from './../../components/MobilePopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';




class MobilUgyfelekKarbantartas extends Component {

    constructor(props) {
        super(props);
        this.NONE = "NONE";

        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";
        this.FORM_MOBILE_FILTER = "MOBILE_FILTER";

        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";


        this.state = {
            theme: this.props.theme,
            openedForm: this.NONE,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 10,
                showingRowFrom: 0,
                showingRowTo: 10,
                totalRowCount: 0,
                skip: 0
            },
            dialogType: this.NONE,
            dialogText: ''
        };
        this.renderPaginationToolbar = null;
        this.ref = React.createRef();
        this.refreshTime = (new Date()).getTime();

        this.FORM_MOBILE_FILTER = "MOBILE_FILTER";


        this.filters = [];

        this.filterFormUgyfelId = null;
        this.filterFormUgyfelCimId = null;
        this.filterFormKapcsolattartoId = null;
        this.filterValue = [
            {
                "name": "ugyfelek_id",
                "operator": "eq",
                "type": "number",
                "value": ""
            },
            {
                "name": "ugyfelek_nev",
                "operator": "contains",
                "type": "string",
                "value": ""
            },
            {
                "name": "ugyfelekcimkereso",
                "operator": "contains",
                "type": "string",
                "value": ""
            },
            {
                "name": "ugyfelek_adoszam",
                "operator": "contains",
                "type": "string",
                "value": ""
            },
            {
                "name": "ugyfelek_eu_adoszam",
                "operator": "contains",
                "type": "string",
                "value": ""
            },
            {
                "name": "ugyfelek_monogram",
                "operator": "contains",
                "type": "string",
                "value": ""
            },
            {
                "name": "ugyfelek_cegjegyzek_szam",
                "operator": "contains",
                "type": "string",
                "value": ""
            },
            {
                "name": "ugyfelek_kapcsolattarto_id",
                "operator": "eq",
                "type": "number",
                "value": ""
            },
            {
                "name": "ugyfelek_kapcsolattarto_nev",
                "operator": "contains",
                "type": "string",
                "value": ""
            },
            {
                "name": "ugyfelek_telefon",
                "operator": "contains",
                "type": "string",
                "value": ""
            },
            {
                "name": "ugyfelek_email",
                "operator": "contains",
                "type": "string",
                "value": ""
            },
            {
                "name": "ugyfelek_web",
                "operator": "contains",
                "type": "string",
                "value": ""
            },
            {
                "name": "globalSearch",
                "operator": "contains",
                "type": "string",
                "value": ""
            }
        ];

        this.data = [];
        this.dataLoader();
        this.moduleVisible = true;
        this.filterOpen = false;
        




    }
    
    componentDidMount(){
        if(this.moduleVisible==false){
           this.moduleVisible=true ; 
           this.dataLoader();
        }
    }

    reload = function (skip) {
        this.state.paging.skip = skip;
        this.dataLoader();
    }

    dataLoader = function () {

        var skip = this.state.paging.skip;
        var limit = this.state.paging.pageRowCount;
        var sortInfo = {
            "dir": -1,
            "id": "ugyfelek_id",
            "name": "ugyfelek_id",
            "type": "number"
        };

        var groupBy = null;
        var filterValue = this.filterValue;
        var state = this.state;
        this.state.requestProps = {
            skip: state.paging.skip,
            limit: limit,
            sortInfo: sortInfo,
            filterValue: filterValue
        };
        try {
            const url = ajax.getUrlBase() + "modules/UgyfelekKarbantartas/ugyfelekTableSelect.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({
                    filterFormUgyfelId: this.filterFormUgyfelId,
                    filterFormUgyfelCimId: this.filterFormUgyfelCimId,
                    filterFormKapcsolattartoId: this.filterFormKapcsolattartoId,
                    skip: state.paging.skip,
                    limit: limit,
                    sortInfo: sortInfo,
                    filterValue: filterValue
                })
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        var totalCount = response.count;
                        this.data = response.data;
                        state.paging.totalRowCount = totalCount;
                        var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                        state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                        state.paging.showingRowFrom = skip;
                        state.paging.showingRowTo = skip + state.pageRowCount;


                        this.refreshTime = (new Date()).getTime();
                        this.forceUpdate();

                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                state.defaultUpdateBlocked = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
        }
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/UgyfelekKarbantartas/ugyfelekFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({ugyfelek_id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == true) {
                            window.open(ajax.getUrlBase() + 'modules/Drive/driveHandler.php?state=PT_' + this.state.selectedRow, '_blank');
                        } else {
                            alert(response.fail);
                        }
                        self.setState({openedForm: 'HIDDEN', selectedRow: null});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    showForm = function (formType, id) {


        if (formType == this.FORM_DELETE) {
            this.setState({openedForm: this.FORM_DELETE, selectedRow: id});
        }
        if (formType == this.FORM_INSERT) {
            this.setState({openedForm: this.FORM_INSERT, selectedRow: id});
        }
        if (formType == this.FORM_UPDATE) {
            this.setState({openedForm: this.FORM_UPDATE, selectedRow: id});
        }
        if (formType == this.FORM_VIEW) {
            this.setState({openedForm: this.FORM_VIEW, selectedRow: id});
        }
    }

    hasSelectedRow = function () {
        if (typeof this.state.selectedRow == 'undefined') {
            return false;
        }
        if (this.state.selectedRow == null) {
            return false;
        }


        if (this.state.selectedRow == -1) {
            return false;
        }
        return true;
    }

    setView = function (formType) {

        var formName = formType;

        var rowSelectionNeed = false;
        var hasSelectedRow = true;

        if (formName == this.FORM_MOBILE_FILTER || formName == this.FORM_MOBILE_FILTER || formName == this.FORM_MOBILE_FILTER) {
            this.setState({openedForm: this.FORM_MOBILE_FILTER});
        }

        if (formName == this.FORM_DELETE || formName == this.FORM_UPDATE || formName == this.FORM_VIEW) {
            rowSelectionNeed = true;
        }
        if (this.state.selectedRow == null || this.state.selectedRow == '') {
            hasSelectedRow = false;
        }
        if (rowSelectionNeed == true && hasSelectedRow == false) {
            this.setState({openedForm: this.DIALOG_ALERT, dialogText: lang.getTitle("ROW_SELECTION_NEED")});
        } else {
            var dialogText = "";
            if (formName == this.FORM_DELETE) {
                dialogText = lang.getTitle("DELETE_CONFIRMATION");



            }
            this.setState({openedForm: formName, dialogText: dialogText});
        }



    }

    closeForm = function () {
        this.moduleVisible=false;
        this.setState({openedForm: this.NONE});
    }
    
    closeFilter=function(){
        this.filterOpen=false;
        this.forceUpdate();
    }
    openFilter=function(){
        this.filterOpen=true;
        this.forceUpdate();
    }

    setFilterValues = function (ugyfelId, ugyfelcimId, kapcsolattartoId) {

        this.filterFormUgyfelId = (typeof ugyfelId != 'undefined' && ugyfelId != null) ? ugyfelId : "";
        this.filterFormUgyfelCimId = (typeof ugyfelcimId != 'undefined' && ugyfelcimId != null) ? ugyfelcimId : "";
        this.filterFormKapcsolattartoId = (typeof kapcsolattartoId != 'undefined' && kapcsolattartoId != null) ? kapcsolattartoId : "";
        this.dataLoader();

    }

    render() {

        var mobileFilterActive = (this.filterFormUgyfelId != null && this.filterFormUgyfelId != '') ||
                (this.filterFormUgyfelCimId != null && this.filterFormUgyfelCimId != '') ||
                (this.filterFormKapcsolattartoId != null && this.filterFormKapcsolattartoId != '');


        var mobilFilterButtonText = "Mobil Filter";
        var mobilFilterButtonVariant = "";
        if (mobileFilterActive) {
            mobilFilterButtonVariant = "warning";
            mobilFilterButtonText += " ( AKTÍV )";
        } else {
            mobilFilterButtonVariant = "secondary";
            mobilFilterButtonText += " ( INAKTÍV )";
        }

        var mobilFilter = "";
        var openedForm = "";

        if (this.filterOpen) {
            mobilFilter = <MobileTableFilter
                type={this.DIALOG_ALERT} 
                dialogText={this.state.dialogText} 
                callback={() => this.setView({target: {id: this.NONE}})}
                theme={this.props.theme}
                closeForm={this.closeFilter.bind(this)}
                setFilterValues={this.setFilterValues.bind(this)}
                ugyfelId ={this.filterFormUgyfelId}
                ugyfelcimId= {this.filterFormUgyfelCimId}
                kapcsolattartoId = {this.filterFormKapcsolattartoId}
                />;
        }




        var mobileFilterButton = <div key={'ugyfelek_FORM_MOBILE_FILTER'}>&nbsp;
            <Button 
                className={'btn-sm'} 
                id={this.FORM_MOBILE_FILTER} 
                onClick={this.openFilter.bind(this)} 
                variant={mobilFilterButtonVariant}
                style={{minWidth: 400, boxShadow: '0px 0px 8px #000000'}}
                >
                {mobilFilterButtonText}
            </Button>
        
        </div>;


        if (this.state.openedForm == this.FORM_VIEW) {
            openedForm = <ViewForm 
            theme={this.props.theme} key={'ugyfelek_KEY_VIEW'} 
            formType={this.FORM_VIEW} 
            additionalParams={[{ugyfelCimek_ugyfel_id: this.state.selectedRow}]} 
            id={this.state.selectedRow} 
            closeForm={this.closeForm.bind(this)} 
            
            />
        }

        if (this.state.openedForm == this.FORM_INSERT) {
            openedForm = <InsertForm  theme={this.props.theme} key={'ugyfelek_KEY_INSERT'} formType={this.FORM_INSERT} additionalParams={[{ugyfelCimek_ugyfel_id: this.state.selectedRow}]} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_UPDATE) {

            var hasSelectedRow = this.hasSelectedRow();
            if (hasSelectedRow == true) {
                openedForm = <UpdateForm theme={this.props.theme} key={'ugyfelek_KEY_UPDATE'} formType={this.FORM_UPDATE} additionalParams={[{ugyfelCimek_ugyfel_id: this.state.selectedRow}]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
            } else {
                console.log("Nincs kiválasztott sor");
                // Alert window
            }
        }

        if (this.state.openedForm == this.FORM_DELETE) {
            openedForm = <Dialog 
                type={this.DIALOG_CONFIRMATION} 
                dialogText={this.state.dialogText} 
                callback={this.delete.bind(this)}
                cancel={() => this.setView({target: {id: this.NONE}})}
                theme={this.props.theme}
                />;
        }

        var className = (this.props.theme == "true") ? "Dark" : "Light";

        if(this.moduleVisible==false){
            return "" ;
        }
        return (<div style={{overflowY:'hidden'}} id="MobilUgyfelekKarbantartasLista">
                {mobileFilterButton}
                {mobilFilter}
                <MobileTable 
                    data={this.data} 
                    theme={this.props.theme} 
                    refreshTime={this.refreshTime}
                    showForm={this.showForm.bind(this)}
                    />
                <PagingToolbar 
                    blackTheme={this.props.theme} 
                    tableReload={this.reload.bind(this)} 
                    paging={this.state.paging} />
                {openedForm}
            </div>

                );

    }
}

export default MobilUgyfelekKarbantartas;
