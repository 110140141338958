import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';
import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button, Tabs, Tab} from 'react-bootstrap';
import TextInsertField from './../../components/TextInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import DateInsertField from './../../components/DateInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';
import CheckBoxInsertField from './../../components/CheckBoxInsertField';
import ArjanlatUgyfelCimCombo from './../../componentsSpecial/ArajanlatUgyfelCimCombo';
import MunkalapUgyfelCombo from './MunkalapUgyfelCombo';
import MunkalapArajanlatCombo from './MunkalapArajanlatCombo';
import MunkalapMunkavallaloHozzarendelese from './MunkalapMunkavallaloHozzarendelese';
import  './../../css/StatuszokTabla.css';

class MunkalapView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            munkalap_id: ''
            , munkalap_sorszam: ''
            , munkalap_ugyfel_id: ''
            , munkalap_ugyfel_cim_id: ''
            , munkalap_ajanlat_id: ''
            , munkalap_datum: ''
            , munkalap_datum_festes: ''
            , munkalap_datum_festes_kesz: ''
            , munkalap_datumok_varhato_befejezes: ''

            , munkalap_leiras: ''
            , munkalap_szinkod: ''
            , munkalap_megjegyzes: ''
            , munkalap_megnevezes: ''
            , munkalap_lapszabasz_informaciok: ''
            , munkalap_reszletek: ''
            , munkalap_tervezett_osszeszereles: ''
            , munkalap_tervezett_szallitas: ''
            , munkalap_kiirva: ''
            , munkalap_festest_igenyel: ''
            , munkalap_festek_megrendelve: ''
            , munkalap_statusz: ''
            , munkalap_projektvezeto_id: ''
            , munkalap_tervezo_id: ''
            , MunkalapDolgozok: []
            , MTOMunkalapDolgozok: []
            , munkalap_datumok_rogzitve: ''
            , munkalap_datumok_kiirva: ''
            , munkalap_datumok_gyartasbaAdva: ''
            , munkalap_datumok_festesreAtadva: ''
            , munkalap_datumok_festoMuhelyAtvette: ''
            , munkalap_datumok_osszeszerelesreKesz: ''
            , munkalap_datumok_szallitasraKesz: ''
            , munkalap_datumok_kiszallitva: ''

        }


        this.references = [];
        this.references['munkalap_id'] = React.createRef();
        this.references['munkalap_ugyfel_id'] = React.createRef();
        this.references['munkalap_ugyfel_cim_id'] = React.createRef();
        this.references['munkalap_ajanlat_id'] = React.createRef();
        this.references['munkalap_datum'] = React.createRef();
        this.references['munkalap_datum_festes'] = React.createRef();
        this.references['munkalap_datum_festes_kesz'] = React.createRef();
        this.references['munkalap_datumok_varhato_befejezes'] = React.createRef();
        this.references['munkalap_leiras'] = React.createRef();
        this.references['munkalap_szinkod'] = React.createRef();
        this.references['munkalap_megjegyzes'] = React.createRef();
        this.references['munkalap_megnevezes'] = React.createRef();
        this.references['munkalap_lapszabasz_informaciok'] = React.createRef();
        this.references['munkalap_reszletek'] = React.createRef();
        this.references['munkalap_tervezett_osszeszereles'] = React.createRef();
        this.references['munkalap_tervezett_szallitas'] = React.createRef();
        this.references['munkalap_kiirva'] = React.createRef();
        this.references['munkalap_festest_igenyel'] = React.createRef();
        this.references['munkalap_festek_megrendelve'] = React.createRef();
        this.references['munkalap_statusz'] = React.createRef();
        this.references['munkalap_projektvezeto_id'] = React.createRef();
        this.references['munkalap_tervezo_id'] = React.createRef();
        this.references['munkalap_MTO'] = React.createRef();
        this.references['munkalap_MTO'] = React.createRef();


        this.validators = [];
        this.validators['munkalap_id'] = {fieldName: 'munkalap_id', dataType: 'INT', value: this.state.munkalap_id, mandatory: false, min: null, max: null};
        this.validators['munkalap_ugyfel_id'] = {fieldName: 'munkalap_ugyfel_id', dataType: 'INT', value: this.state.munkalap_ugyfel_id, mandatory: true, min: null, max: null};
        this.validators['munkalap_ugyfel_cim_id'] = {fieldName: 'munkalap_ugyfel_cim_id', dataType: 'INT', value: this.state.munkalap_ugyfel_cim_id, mandatory: true, min: null, max: null};
        this.validators['munkalap_ajanlat_id'] = {fieldName: 'munkalap_ajanlat_id', dataType: 'INT', value: this.state.munkalap_ajanlat_id, mandatory: true, min: null, max: null};
        this.validators['munkalap_datum'] = {fieldName: 'munkalap_datum', dataType: 'DATE', value: this.state.munkalap_datum, mandatory: false, min: null, max: null};
        this.validators['munkalap_datum_festes'] = {fieldName: 'munkalap_datum_festes', dataType: 'DATE', value: this.state.munkalap_datum_festes, mandatory: false, min: null, max: null};
        this.validators['munkalap_datum_festes_kesz'] = {fieldName: 'munkalap_datum_festes_kesz', dataType: 'DATE', value: this.state.munkalap_datum_festes_kesz, mandatory: false, min: null, max: null};
        this.validators['munkalap_datumok_varhato_befejezes'] = {fieldName: 'munkalap_datumok_varhato_befejezes', dataType: 'DATE', value: this.state.munkalap_datumok_varhato_befejezes, mandatory: false, min: null, max: null};
        this.validators['munkalap_leiras'] = {fieldName: 'munkalap_leiras', dataType: 'VARCHAR', value: this.state.munkalap_leiras, mandatory: false, min: null, max: null};
        this.validators['munkalap_szinkod'] = {fieldName: 'munkalap_szinkod', dataType: 'VARCHAR', value: this.state.munkalap_szinkod, mandatory: false, min: null, max: null};
        this.validators['munkalap_megjegyzes'] = {fieldName: 'munkalap_megjegyzes', dataType: 'VARCHAR', value: this.state.munkalap_megjegyzes, mandatory: false, min: null, max: null};
        this.validators['munkalap_megnevezes'] = {fieldName: 'munkalap_megnevezes', dataType: 'VARCHAR', value: this.state.munkalap_megnevezes, mandatory: true, min: null, max: null};
        this.validators['munkalap_lapszabasz_informaciok'] = {fieldName: 'munkalap_lapszabasz_informaciok', dataType: 'VARCHAR', value: this.state.munkalap_lapszabasz_informaciok, mandatory: false, min: null, max: null};
        this.validators['munkalap_reszletek'] = {fieldName: 'munkalap_reszletek', dataType: 'VARCHAR', value: this.state.munkalap_reszletek, mandatory: false, min: null, max: null};
        this.validators['munkalap_tervezett_osszeszereles'] = {fieldName: 'munkalap_tervezett_osszeszereles', dataType: 'DATE', value: this.state.munkalap_tervezett_osszeszereles, mandatory: false, min: null, max: null};
        this.validators['munkalap_tervezett_szallitas'] = {fieldName: 'munkalap_tervezett_szallitas', dataType: 'DATE', value: this.state.munkalap_tervezett_szallitas, mandatory: false, min: null, max: null};
        this.validators['munkalap_kiirva'] = {fieldName: 'munkalap_kiirva', dataType: 'VARCHAR', value: this.state.munkalap_kiirva, mandatory: false, min: null, max: null};
        this.validators['munkalap_festest_igenyel'] = {fieldName: 'munkalap_festest_igenyel', dataType: 'TINYINT', value: this.state.munkalap_festest_igenyel, mandatory: false, min: null, max: null};
        this.validators['munkalap_festek_megrendelve'] = {fieldName: 'munkalap_festek_megrendelve', dataType: 'TINYINT', value: this.state.munkalap_festek_megrendelve, mandatory: false, min: null, max: null};
        this.validators['munkalap_statusz'] = {fieldName: 'munkalap_statusz', dataType: 'INT', value: this.state.munkalap_statusz, mandatory: false, min: null, max: null};
        this.validators['munkalap_projektvezeto_id'] = {fieldName: 'munkalap_projektvezeto_id', dataType: 'INT', value: this.state.munkalap_projektvezeto_id, mandatory: false, min: null, max: null};
        this.validators['munkalap_tervezo_id'] = {fieldName: 'munkalap_tervezo_id', dataType: 'INT', value: this.state.munkalap_tervezo_id, mandatory: false, min: null, max: null};
        this.validators['munkalap_MTO'] = {fieldName: 'munkalap_MTO', dataType: 'MTO', value: this.state.munkalap_MTO, mandatory: false, min: null, max: null};
        this.validators['munkalap_MTO'] = {fieldName: 'munkalap_MTO', dataType: 'MTO', value: this.state.munkalap_MTO, mandatory: false, min: null, max: null};
        this.error = false;
        this.msg = "";
        this.load();
    }

    componentDidMount() {
        //this.setFocusedComponent();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];
                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();
        }
    }

    reset = function () {

        this.setState({
            munkalap_id: ''
            , munkalap_sorszam: ''
            , munkalap_ugyfel_id: ''
            , munkalap_ugyfel_cim_id: ''
            , munkalap_ajanlat_id: ''
            , munkalap_datum: ''
            , munkalap_datum_festes: ''
            , munkalap_datum_festes_kesz: ''
            , munkalap_datumok_varhato_befejezes: ''
            , munkalap_leiras: ''
            , munkalap_szinkod: ''
            , munkalap_megjegyzes: ''
            , munkalap_megnevezes: ''
            , munkalap_lapszabasz_informaciok: ''
            , munkalap_reszletek: ''
            , munkalap_tervezett_osszeszereles: ''
            , munkalap_tervezett_szallitas: ''
            , munkalap_kiirva: ''
            , munkalap_festest_igenyel: ''
            , munkalap_festek_megrendelve: ''
            , munkalap_statusz: ''
            , munkalap_projektvezeto_id: ''
            , munkalap_tervezo_id: ''
            , MunkalapDolgozok: []
            , MTOMunkalapDolgozok: []
            , munkalap_datumok_rogzitve: ''
            , munkalap_datumok_kiirva: ''
            , munkalap_datumok_gyartasbaAdva: ''
            , munkalap_datumok_festesreAtadva: ''
            , munkalap_datumok_festoMuhelyAtvette: ''
            , munkalap_datumok_osszeszerelesreKesz: ''
            , munkalap_datumok_szallitasraKesz: ''
            , munkalap_datumok_kiszallitva: ''

        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getForm = function () {

        var festesKeszDatumBevitel = "";
        var szinkod = "";
        var datum_festes = "";
        var festek_megrendelve = "";
        if (this.state.munkalap_festest_igenyel == 1 || this.state.munkalap_festest_igenyel == '1') {
            festesKeszDatumBevitel = <Col md={6}> 
            <Row>
                <Col md={12}  style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_datum_festes_kesz')}</Col>
                <Col md={12}  style={{textAlign: 'left'}}>
                <DateInsertField 
                    id={'munkalap_datum_festes_kesz'} 
                    value={this.state.munkalap_datum_festes_kesz}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['munkalap_datum_festes_kesz']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            </Col>;

            szinkod = <Row> 
                <Col md={12}>
                <Row>
                    <Col md={12}  style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_szinkod')}</Col>
                    <Col md={12}  style={{textAlign: 'left'}}>
                    <TextAreaInsertField 
                        id={'munkalap_szinkod'} 
                        value={this.state.munkalap_szinkod}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['munkalap_szinkod']}
                        editable={false}
                        theme={this.props.theme}
                        style={{minWidth: '100%'}}
                        />
                    </Col>
                </Row>
                </Col>
            </Row>;

            datum_festes = <Col md={6}> 
            <Row>
                <Col md={12}  style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_datum_festes')}</Col>
                <Col md={12}  style={{textAlign: 'left'}}>
                <DateInsertField 
                    id={'munkalap_datum_festes'} 
                    value={this.state.munkalap_datum_festes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['munkalap_datum_festes']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            </Col>;


            festek_megrendelve = <Row>
                <Col md={12}  style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_festek_megrendelve')}</Col>
                <Col md={12}  style={{textAlign: 'left'}}>
                <CheckBoxInsertField 
                    id={'munkalap_festek_megrendelve'} 
                    value={this.state.munkalap_festek_megrendelve}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['munkalap_festek_megrendelve']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>


        }



        return <Container>
            <Row>
                <Col md={3}> 
                <Row>
                    <Col md={12} style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_ugyfel_id')}</Col>
                    <Col md={12}  style={{textAlign: 'left'}}>
                    <MunkalapUgyfelCombo 
                        id={'munkalap_ugyfel_id'} 
                        value={this.state.munkalap_ugyfel_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Munkalap/ugyfel_idFormComboInsertData.php'}
                        reference={this.references['munkalap_ugyfel_id']}
                        editable={false}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col> 
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_ugyfel_cim_id')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <ArjanlatUgyfelCimCombo
                        id={'munkalap_ugyfel_cim_id'} 
                        value={this.state.munkalap_ugyfel_cim_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Munkalap/ugyfel_cim_idFormComboInsertData.php'}
                        reference={this.references['munkalap_ugyfel_cim_id']}
                        editable={false}
                        theme={this.props.theme}
                        ugyfel_id={this.state.munkalap_ugyfel_id}
                        />
                    </Col>
                </Row>
                </Col>
                <Col md={3}> 
                <Row>
                    <Col md={12} style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_ajanlat_id')}</Col>
                    <Col md={12}  style={{textAlign: 'left'}}>
                    <MunkalapArajanlatCombo
                        id={'munkalap_ajanlat_id'} 
                        value={this.state.munkalap_ajanlat_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Munkalap/arajanlat_idFormComboInsertData.php'}
                        reference={this.references['munkalap_ajanlat_id']}
                        editable={false}
                        munkalap_ugyfel_id={this.state.munkalap_ugyfel_id}
                        theme={this.props.theme}
                        />
        
                    </Col>
                </Row>
                </Col> 
                <Col md={3}> 
                <Row>
                    <Col md={12}  style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_projektvezeto_id')}</Col>
                    <Col md={12}  style={{textAlign: 'left'}}>
                    <ComboBoxInsertField 
                        id={'munkalap_projektvezeto_id'} 
                        value={this.state.munkalap_projektvezeto_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Munkalap/projektvezeto_idFormComboInsertData.php'}
                        reference={this.references['munkalap_projektvezeto_id']}
                        editable={false}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col> 
            </Row>
            <Row>
                <Col md={3}> 
                <Row>
                    <Col md={12}  style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_tervezo_id')}</Col>
                    <Col md={12}  style={{textAlign: 'left'}}>
                    <ComboBoxInsertField 
                        id={'munkalap_tervezo_id'} 
                        value={this.state.munkalap_tervezo_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Munkalap/tervezo_idFormComboInsertData.php'}
                        reference={this.references['munkalap_tervezo_id']}
                        editable={false}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col> 
                <Col md={3}> 
                <Row>
                    <Col md={12}  style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_datumok_varhato_befejezes')}</Col>
                    <Col md={12}  style={{textAlign: 'left'}}>
                    <DateInsertField 
                        id={'munkalap_datumok_varhato_befejezes'} 
                        value={this.state.munkalap_datumok_varhato_befejezes}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['munkalap_datumok_varhato_befejezes']}
                        editable={false}
                        theme={this.props.theme}
                        />
                    </Col>
        
                </Row>
                </Col> 
                <Col md={3}> 
                <Row>
                    <Col md={12}  style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_festest_igenyel')}</Col>
                    <Col md={12}  style={{textAlign: 'left'}}>
                    <CheckBoxInsertField 
                        id={'munkalap_festest_igenyel'} 
                        value={this.state.munkalap_festest_igenyel}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['munkalap_festest_igenyel']}
                        editable={false}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col> 
                <Col md={3}>
                {festek_megrendelve}
                </Col>
            </Row>
            <Row>
                <Col md={12}  style={{textAlign: 'left'}}><b>{lang.getTitleBold('munkalap_megnevezes')}</b></Col>
            </Row>
            <Row>
                <Col md={12}  style={{textAlign: 'left'}}>
                <TextInsertField 
                    id={'munkalap_megnevezes'} 
                    value={this.state.munkalap_megnevezes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['munkalap_megnevezes']}
                    editable={false}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                <Row>
                    {datum_festes}
                    {festesKeszDatumBevitel}
                </Row>
                <Row>
                    <Col md={6}  style={{textAlign: 'left', borderTop: 'solid 1px gray', borderLeft: 'solid 1px gray'}}><b>{lang.getTitleBold('munkalap_statusz')}</b></Col>
                    <Col md={6}  style={{textAlign: 'left', borderTop: 'solid 1px gray', borderRight: 'solid 1px gray'}}>{lang.getTitleBold('munkalap_datum')}</Col>
        
                    <Col md={6}  style={{textAlign: 'left', borderBottom: 'solid 1px gray', borderLeft: 'solid 1px gray'}}>
                    <select 
                        onChange={(e) => {
                                        this.setBack('munkalap_statusz', e.target.value)
                                    }} 
                        value={this.state.munkalap_statusz}  
                        className={(this.props.theme == 'true') ? "componentDark" : "componentLight"}
                        >
                        <option value={1} >Rögzítve</option>
                        <option value={2} >Kiírva</option>
                        <option value={3} >Gyártásra adva</option>
                        <option value={4} >Festésre átadva</option>
                        <option value={5} >Festőműhely átvette</option>
                        <option value={6} >Összeszerelésre kész</option>
                        <option value={7} >Szállításra kész</option>
                        <option value={8} >Kiszállítva</option>
                    </select>
        
                    </Col>
        
                    <Col md={6}  style={{textAlign: 'left', borderBottom: 'solid 1px gray', borderRight: 'solid 1px gray'}}>
                    <DateInsertField 
                        id={'munkalap_datum'} 
                        value={this.state.munkalap_datum}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['munkalap_datum']}
                        editable={false}
                        theme={this.props.theme}
                        />
                    </Col>
                    <Col md={3}  style={{textAlign: 'left'}}></Col>
                    <Col md={3}  style={{textAlign: 'left'}}></Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'right'}}>
                    <div>
                        <MunkalapMunkavallaloHozzarendelese
                            munkalap_id ={this.state.munkalap_id}
                            value={this.state.MunkalapDolgozok} 
                            setBack={this.setBack.bind(this)} 
                            additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : ajax.additionalParamFilter(this.props.additionalParams, 'munkalap_id')}
                            editable={false}
                            formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                            theme={this.props.theme}
                            isInsert={false}
                            />
                    </div>
                    </Col>
                </Row>
                </Col>
                <Col md={4}>
                <table class={'statuszokTabla'} style={{textAlign: 'left', minWidth: '100%', marginTop: 20, border: 'solid 1px grey'}}>
                    <thead>
                        <tr>
                            <th style={{textAlign: 'center'}} colSpan={2}>Státuszok</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Rögzítve</td><td>{this.state.munkalap_datumok_rogzitve}</td>
                        </tr>
                        <tr>
                            <td>Kiírva</td><td>{this.state.munkalap_datumok_kiirva}</td>
                        </tr>
                        <tr>
                            <td>Gyártásra adva</td><td>{this.state.munkalap_datumok_gyartasbaAdva}</td>
                        </tr>
                        <tr>
                            <td>Festésre átadva</td><td>{this.state.munkalap_datumok_festesreAtadva}</td>
                        </tr>
                        <tr>
                            <td>Festőműhely átvette</td><td>{this.state.munkalap_datumok_festoMuhelyAtvette}</td>
                        </tr>
                        <tr>
                            <td>Összeszerelésre kész</td><td>{this.state.munkalap_datumok_osszeszerelesreKesz}</td>
                        </tr>
                        <tr>
                            <td>Szállításra kész</td><td>{this.state.munkalap_datumok_szallitasraKesz}</td>
                        </tr>
                        <tr>
                            <td>Kiszállítva</td><td>{this.state.munkalap_datumok_kiszallitva}</td>
                        </tr>
                    </tbody>
                </table>
                </Col>
            </Row>
        
        
        
            {szinkod}
            <Row>
                <Col md={12}  style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_leiras')}</Col>
                <Col md={12}  style={{textAlign: 'left'}}>
                <TextAreaInsertField 
                    id={'munkalap_leiras'} 
                    value={this.state.munkalap_leiras}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['munkalap_leiras']}
                    editable={false}   
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}  style={{textAlign: 'left'}}>{lang.getTitleBold('munkalap_megjegyzes')}</Col>
                <Col md={12}  style={{textAlign: 'left'}}>
                <TextAreaInsertField 
                    id={'munkalap_megjegyzes'} 
                    value={this.state.munkalap_megjegyzes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['munkalap_megjegyzes']}
                    editable={false}   
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();
    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitleBold('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitleBold(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    update = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;
        this.concatAdditionalParams();
        try {
            const url = ajax.getUrlBase() + "modules/Munkalap/munkalapFormUpdate.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(this.state)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Munkalap/munkalapFormLoader.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{minWidth: 1200}}>
            <div className={'popupWrapper' + className} id="MunkalapView">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('MunkalapView')}&nbsp;
                        <input 
                            type="text" 
                            value={this.state.munkalap_sorszam}
                            style={{maxWidth: 130, borderStyle: 'none'}}
                            onClick={(e) => {
                                            e.target.select();
                                            document.execCommand("copy");
                                        }}
                            />
                        </Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default MunkalapView;

