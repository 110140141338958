
import React, { Component } from 'react';
import ArajanlatTable from './ArajanlatTable.js';

import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';

class ArajanlatContent extends Component {

    constructor(props) {
        super(props);
        this.state = {theme: this.props.theme}
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    setBack = function (id, value) {
        // Nothing to do here
    }
    render() {

        var table = <ArajanlatTable 
            setBack={this.setBack.bind(this)} 
            additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
            formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
            theme={this.state.theme}
            ajanlatStatuszFilter={this.props.ajanlatStatuszFilter}
            />;

        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var headerTextColor = (this.props.theme == "true") ? "wheat" : "grey";
        return (
                <div className={"content" + className} id="ArajanlatContent">
                    <div className={"dropdown-divider" + className}></div> 
                    <div style={{color: headerTextColor}} className={"text-center"}><h6>{lang.getTitle("Arajanlat")}</h6></div> 
                    <div className={"dropdown-divider" + className}></div> 
                
                    {table}
                </div>
                );
    }
}

export default ArajanlatContent;
