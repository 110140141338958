import React, { Component } from 'react';
import ReactQuill from 'react-quill'; // ES6
import './../css/quill.snow.css';
import { Form, Button, Row, Col} from 'react-bootstrap';
import * as ajax from './../ajax.js';


class TextFormatter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            sablonok: []
        }
        // 'HATARIDO', 'GARANCIA', 'LEIRAS', 'MUSZAKI_LEIRAS', 'MEGJEGYZES', 'FIZETESI_FELTETELEK'
        this.kategoria_konstans = this.props.kategoria_konstans;
    }

    componentDidUpdate(prevProps) {
        // Csak a betöltéskor frissíti a state-et.s
        if (prevProps.value === '' && this.props.value !== '' || prevProps.value !=this.props.value) {
            this.setState({
                value: this.props.value
            });
        }
    }

    componentDidMount() {

        this.loadItems();
    }

    loadItems = function () {
        if (typeof this.props.sablonKivalasztoval !== 'undefined' && this.props.sablonKivalasztoval == false) {
            return null;
        }



        if (this.props.editable == false) {
            return false;
        }
        try {
            const url = ajax.getUrlBase() + 'modules/Arajanlat/szovegSablonokComboData.php';
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({filterText: this.kategoria_konstans})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null) {
                            this.setState({sablonok: response.data});
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

        this.setState({dropDownVisible: true, hoveredId: -1});
    }

    loadSzoveg = function (e) {

        if (typeof this.props.sablonKivalasztoval !== 'undefined' && this.props.sablonKivalasztoval == false) {
            return null;
        }



        if (this.props.editable == false) {
            return false;
        }

        if (typeof e == 'undefinded' || typeof e.target == 'undefinded' || typeof e.target.value == 'undefinded') {
            return false;
        }
        if (e.target.value == null) {
            return false;
        }

        var id = e.target.value;

        try {
            const url = ajax.getUrlBase() + 'modules/Arajanlat/szovegSablonokData.php';
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({filterId: id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null) {
                            if (typeof response.data[0] != 'undefined' && response.data[0] != null) {
                                if (typeof response.data[0].szoveg != 'undefined' && response.data[0].szoveg != null) {
                                    this.props.setBack(this.props.id, response.data[0].szoveg);
                                    this.setState({value: response.data[0].szoveg});
                                }
                            }
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

        this.setState({dropDownVisible: true, hoveredId: -1});
    }

    onChange = function (val) {
        this.setState({value: val});
        this.props.setBack(this.props.id, val);
    }

    getSablonItems = function () {
        var items = this.state.sablonok;
        var comboItems = [];

        comboItems.push(<option  
            id={null}  
            key={'sablon_'} 
            value={null} 
            style={{color: '#000000aa'}}
            >{'Sablon kiválasztása'}</option>);

        if (typeof items == 'undefined') {
            return [];
        }

        if (items == null) {
            return [];
        }

        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            comboItems.push(<option id={item.id}  key={'sablon_' + i} value={item.id} >{item.name}</option>);
        }

        return comboItems;

    }

    render() {
        var toolbarOptions = [
            [{'font': []}, {'size': []}],
            ['bold', 'italic', 'underline', 'strike'],
            [{'color': []}, {'background': []}],
            [{'script': 'super'}, {'script': 'sub'}],
            [{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['direction', {'align': []}],
            ['link', 'image', 'video', 'formula'],
            ['clean']                          // remove formatting button
        ];
        var sablonItems = this.getSablonItems();
        var sablonKivalaszto = "";
        var sablonBackground = (this.props.theme == "true") ? '#25323b' : '#FFFFFF'
        if (typeof this.props.sablonKivalasztoval !== 'undefined' && this.props.sablonKivalasztoval == true) {
            sablonKivalaszto = <Row style={{backgroundColor: sablonBackground}}>
                <Col md={12}>
                <Form.Select 
                    onChange={this.loadSzoveg.bind(this)}
                    size="sm"
                    >
                    {sablonItems}
                </Form.Select>
                </Col>
            </Row>;
        }


        return <div > 
        
            {sablonKivalaszto}
            <Row>
                <Col style={{backgroundColor: 'white', color: 'black', textAlign: 'Left', maxWidth: 1028, marginLeft: 15}} md={12}>
                <ReactQuill theme="snow" 
                            value={this.state.value}
                            preserveWhitespace={true}
                            onChange={this.onChange.bind(this)} 
                            modules={{toolbar: toolbarOptions}}
        
                            />
                </Col>           
            </Row>
        </div>
    }
}

export default TextFormatter;
