import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import FormatedNumberCell from './../../components/FormatedNumberCell';



import {Button} from 'react-bootstrap';

import InsertForm from './MTOArajanlatTetelInsertForm.js';

class MTOArajanlatTetelTableMTO extends Component {

    constructor(props) {
        super(props);
        var columnNames = [
            'arajanlatTetelek_id', 
            'arajanlatTetelek_arajanlat_id', 
            'arajanlatTetelek_manualis_tetel_sorszam', 
            'arajanlatTetelek_megnevezes', 
            'arajanlatTetelek_hivatkozas_megjegyzes', 
            'arajanlatTetelek_hivatkozas', 
            'arajanlatTetelek_netto_ar', 
            'arajanlatTetelek_darabszam', 
            'arajanlatTetelek_mennyisegiEgyseg', 
            'arajanlatTetelek_nettoOsszesen', 
            'opcionalis_tetelek_osszesen', 
            'arajanlatTetelek_tetel_kibontas']
        ;
        this.columnStateHandler = ajax.columnStateHandler(columnNames, "MTOArajanlatTetel", "arajanlatTetelek");

        this.state = {
            columnOrder: columnNames,
            openedForm: 'NONE', // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRowId: -1,
            data: []
        };
    }

    componentDidUpdate(prevProps) {

        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        //if (this.state.openedForm != nextState.openedForm) {
        //    return true;
        //}
        //if (this.state.theme != this.props.theme) {
        //    return true;
        //}
        //return false; // equals() is your implementation

        return true;
    }

    setView = function (e) {
        this.setState({openedForm: e.target.id});
    }

    closeForm = function () {
        this.setState({openedForm: 'NONE'});
    }

    addRow = function (row, close) {
        var rowNumber = this.state.data.length + 1;
        row.arajanlatTetelek_id = rowNumber;
        this.state.data.push(row);
        if (close) {
            this.closeForm();
        } else {
            this.setState({});
        }
        this.props.setBack('MTOArajanlatTetel', this.state.data);
    }
    onSelectionChange = function (selected) {
        var selectedRowId = selected.data.arajanlatTetelek_id;
        this.setState({selectedRowId: selectedRowId});
    }

    getRowIndexById = function () {
        for (var i = 0; i < this.state.data.length; i++) {
            var currentData = this.state.data[i];
            if (currentData.arajanlatTetelek_id == this.state.selectedRowId) {
                return i;
            }
        }
        return -1;
    }

    deleteRow = function () {

        if (this.state.selectedRowId == -1) {
            return false;
        }

        var rowIndexById = this.getRowIndexById(this.state.selectedRowId);
        if (rowIndexById == -1) {
            return false;
        }


        this.state.data.splice(rowIndexById, 1);
        this.state.selectedRowId = -1;
        this.setState({});
        this.props.setBack('MTOArajanlatTetel', this.state.data);
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();

    }

    getTetelekOsszesen = function (cellProps) {
        if (typeof cellProps == 'undefined') {
            return 0;
        }
        if (cellProps == null) {
            return 0;
        }


        if (typeof cellProps.data == 'undefined') {
            return 0;
        }
        if (cellProps.data == null) {
            return 0;
        }
        if (cellProps.data.length == 0) {
            return 0;
        }
        var data = cellProps.data;
        if (typeof data.MTOArajanlatTetelOpciok == 'undefined') {
            return 0;
        }
        if (data.MTOArajanlatTetelOpciok == null) {
            return 0;
        }
        if (data.MTOArajanlatTetelOpciok.length == 0) {
            return 0;
        }
        var opciok = data.MTOArajanlatTetelOpciok;
        var osszeg = 0;


        for (var key in opciok) {
            var opcio = opciok[key];
            osszeg += opcio.arajanlatTetelOpciok_nettoOsszesen * 1.0;

        }
        return osszeg;
    }

    render() {
        const columns = [
            {name: 'arajanlatTetelek_id', sortable: true, header: lang.getTitle('arajanlatTetelek_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelek_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'arajanlatTetelek_manualis_tetel_sorszam', sortable: true, header: lang.getTitle('arajanlatTetelek_manualis_tetel_sorszam'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelek_manualis_tetel_sorszam', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true}
            , {name: 'arajanlatTetelek_arajanlat_id', sortable: true, header: lang.getTitle('arajanlatTetelek_arajanlat_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelek_arajanlat_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'arajanlatTetelek_megnevezes', sortable: true, header: lang.getTitle('arajanlatTetelek_megnevezes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelek_megnevezes', 150), defaultVisible: true}
            , {name: 'arajanlatTetelek_hivatkozas_megjegyzes', sortable: true, header: lang.getTitle('arajanlatTetelek_hivatkozas_megjegyzes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelek_hivatkozas_megjegyzes', 150), defaultVisible: true}
            , {name: 'arajanlatTetelek_hivatkozas', sortable: true, header: lang.getTitle('arajanlatTetelek_hivatkozas'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelek_hivatkozas', 150), defaultVisible: true}
            , {name: 'arajanlatTetelek_netto_ar', sortable: true, header: lang.getTitle('arajanlatTetelek_netto_ar'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelek_netto_ar', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value} />
                }
            },
            {name: 'arajanlatTetelek_darabszam', sortable: true, header: lang.getTitle('arajanlatTetelek_darabszam'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelek_darabszam', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value} />
                }
            },
            {name: 'arajanlatTetelek_mennyisegiEgyseg', sortable: true, header: lang.getTitle('arajanlatTetelek_mennyisegiEgyseg'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelek_darabszam', 150), defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <div>{value} </div>
                }
            },
            {name: 'arajanlatTetelek_nettoOsszesen', sortable: true, header: lang.getTitle('arajanlatTetelek_nettoOsszesen'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelek_nettoOsszesen', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value} />
                }
            },
            {
                name: 'opcionalis_tetelek_osszesen',
                sortable: true,
                header: lang.getTitle('opcionalis_tetelek_osszesen'),
                filterDelay: 1000,
                defaultWidth: this.columnStateHandler.getColumnWidth('opcionalis_tetelek_osszesen', 150),
                defaultVisible: true,
                render: ({ value, cellProps }) => {
                    var tetelekOsszesen = this.getTetelekOsszesen(cellProps);
                    return <div>{tetelekOsszesen}</div>;
                }
            }

            , {name: 'arajanlatTetelek_tetel_kibontas', sortable: true, header: lang.getTitle('arajanlatTetelek_tetel_kibontas'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelek_tetel_kibontas', 150), defaultVisible: true}

        ];

        const filters = [
            {name: 'arajanlatTetelek_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlatTetelek_manualis_tetel_sorszam', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlatTetelek_arajanlat_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlatTetelek_megnevezes', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlatTetelek_hivatkozas_megjegyzes', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlatTetelek_hivatkozas', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlatTetelek_netto_ar', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlatTetelek_darabszam', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlatTetelek_mennyisegiEgyseg', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlatTetelek_nettoOsszesen', operator: 'eq', type: 'number', value: ''}
            , {name: 'opcionalis_tetelek_osszesen', operator: 'eq', type: 'number', value: ''}

            , {name: 'arajanlatTetelek_tetel_kibontas', operator: 'contains', type: 'string', value: ''}

        ];



        var openedForm = "";
        //itttt
        if (this.state.openedForm == 'INSERT') {
            openedForm = <InsertForm 
                addRow={this.addRow.bind(this)} 
                closeForm={this.closeForm.bind(this)} 
                additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
                formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                theme={this.props.theme}
                arajanlat_arengedmeny={this.props.arajanlat_arengedmeny}
                />
        }

        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';
        return (
                <div id="MTOArajanlatTetelTable">
                    <div style={{textAlign:'left'}}>
                        &nbsp;<Button className={'btn-sm'} id={'INSERT'} onClick={this.setView.bind(this)} variant="success">{lang.getTitle('Insert')}</Button>
                        &nbsp;<Button className={'btn-sm'} id={'DELETE'} onClick={this.deleteRow.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button>
                    </div>
                    <ReactDataGrid 
                        idProperty="arajanlatTetelek_id" 
                        i18n={ajax.getI18n()}
                        columns={this.columnStateHandler.myColumnsReorder(columns)}
                        dataSource={this.state.data}
                        theme={theme}
                        style={{minHeight:'100vh'}}
                        enableSelection={true}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                        
                        onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
                        onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
                        onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                        />
                
                    {openedForm}            
                </div>
                );
    }
}

export default MTOArajanlatTetelTableMTO;
