import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';
import * as ajax from './../ajax.js';
import ComboBoxInsertField from './../components/ComboBoxInsertField';
import KapcsolattartokInsertForm from './../modules/Kapcsolattartok/KapcsolattartokInsertForm';

class KapcsolattartoComboHozzaadassal extends ComboBoxInsertField {
    openInsertForm = function () {
        this.props.openInsertForm();
        this.insertFormVisible = false;
    }
    closeForm = function () {
        this.insertFormVisible = false;
        this.setState({});
    }
    openForm = function () {
        this.insertFormVisible = true;
        this.setState({});
    }

    setBackRecordData = function (data) {

        this.state.value = data.data.value;
        this.state.text = data.data.text;
        this.setBack();


    }

    render() {

        var comboItems = "";
        if (this.state.dropDownVisible) {
            comboItems = this.comboItems();
        }

        var className = (this.props.theme == "true") ? "Dark" : "Light";

        var insertForm = "";
        if (this.insertFormVisible) {
            var insertForm = <KapcsolattartokInsertForm
                closeForm ={this.closeForm.bind(this)}
                setBackRecordData={this.setBackRecordData.bind(this)}
                theme={this.props.theme}
                />;
        }

        var outerDivStyle = {position: 'relative', display: 'inline-flex', textAlign: 'right'};
        var innerDivStyle = {position: 'relative', display: 'inline-flex', textAlign: 'right'};
        var inputDivStyle = {textAlign: 'left'};

        if (typeof this.props.style != 'undefined' && this.props.style != null) {
            if (this.props.style['width'] == '100%') {
                outerDivStyle['width'] = '100%';
                innerDivStyle['width'] = '100%';
                inputDivStyle['width'] = '100%';
            }

        }


        return  <div style={outerDivStyle} >
            <div style={innerDivStyle}>
                <div style={{borderBottom: 'solid 1px white'}}>
                    <input style={{borderBottom: 'none'}} onClick={this.openForm.bind(this)} type='button' name='add' value='+'/>
                </div>
                <input 
                    type={'text'} 
                    onChange={this.onChange.bind(this)}  
                    onFocus={this.onFocus.bind(this)}  
                    onBlur={this.onBlur.bind(this)}  
                    onKeyDown={this.onKeyDown.bind(this)}
                    id={this.props.id} 
                    value={this.state.text} 
                    ref={this.props.reference}
                    readOnly={this.props.editable == false}
                    style={inputDivStyle}
                    className ={"component" + className}
                    autoComplete={"off"}
                    />
                {comboItems}
            </div>
        
            {insertForm}
        </div>
    }

}

export default KapcsolattartoComboHozzaadassal;