import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import huLocale from '@fullcalendar/core/locales/hu';
import interactionPlugin from '@fullcalendar/interaction';
import { createEventId } from './event-utils';
import "@fullcalendar/daygrid/main.css";
import "./../../css/fullcalendar.css";
import MunkaBeosztasInsertForm from './../MunkaBeosztas/MunkaBeosztasInsertForm';
import MunkaBeosztasUpdateForm from './../MunkaBeosztas/MunkaBeosztasUpdateForm';
import MunkaBeosztasBefoglalasUpdateForm from './../MunkaBeosztas/MunkaBeosztasBefoglalasUpdateForm';
import Dialog from './../../components/Dialog.js';

import EgyebCimek from './EgyebCimek';

import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');
class MunkaBeosztasNaptar extends Component {

    constructor(props) {
        super(props);
        this.NONE = "NONE";
        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_UPDATE_BEFOGLALAS = "UPDATE_BEFOGLALAS";
        this.FORM_VIEW = "VIEW";
        this.calendarRef = React.createRef();
        this.state = {
            weekendsVisible: true,
            currentEvents: [],
            events: [],
            openedForm: this.NONE,
            selectedDate: null,
            selectedId: null,
            dialogText: null
        }

        this.dateRangeStart = null;
        this.dateRangeEnd = null;
        this.uniqueKey = 0;
    }

    getUniqueKey = function () {
        return this.uniqueKey++;
    }

    getInitDateRange = function () {
        var currentDate = new Date();
    }

    componentDidMount() {
        this.dataLoader({start: this.dateRangeStart, end: this.dateRangeEnd});
    }

    closeForm = function () {
        this.setState({openedForm: this.NONE});
        this.dataLoader({start: this.dateRangeStart, end: this.dateRangeEnd});
    }

    addForeignKeyFilter = function (filterValues) {
        var additionalParams = this.props.additionalParams;
        if (typeof additionalParams == 'undefined') {
            return filterValues;
        }

        if (additionalParams == null) {
            return filterValues;
        }

        if (additionalParams.length == null) {
            return filterValues;
        }

        for (var ap = 0; ap < additionalParams.length; ap++) {
            var additionalParam = additionalParams[ap];
            for (const property in additionalParam) {
                var filter = null;
                for (var f = 0; f < filterValues.length; f++) {
                    var currentFilter = filterValues[f];
                    if (currentFilter.name == property) {
                        filter = currentFilter;
                    }
                }
                if (filter != null) {
                    filter.operator = 'eq';
                    filter.type = 'number';
                    filter.value = additionalParam[property];
                } else {
                    filter = {
                        name: property,
                        operator: 'eq',
                        type: 'number',
                        value: additionalParam[property]
                    };
                    filterValues.push(filter);
                }
            }
        }
        return filterValues;
    }

    dataLoader = function (dateRange) {

        this.dateRangeStart = dateRange.start;
        this.dateRangeEnd = dateRange.end;
        var filterValue = {from: this.dateRangeStart, to: this.dateRangeEnd}
        //filterValue = this.addForeignKeyFilter(filterValue);
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/MunkaBeosztasNaptar/naptarSelect.php";
            self.state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(filterValue)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({events: response.data});
                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
        }
    }

    handleWeekendsToggle = function () {
        this.setState({
            weekendsVisible: !this.state.weekendsVisible
        })
    }

    handleDateSelect = function (selectInfo) {
        this.setState({openedForm: this.FORM_INSERT, selectedDate: selectInfo.start});
        return false;
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }

    handleEventClick = function (clickInfo) {
        var eventId = clickInfo.event.id;
        if (eventId) {
            if (eventId.indexOf("bef_") > -1) {
                var id = eventId.replace("bef_", "");

                this.setState({openedForm: this.FORM_UPDATE_BEFOGLALAS, selectedId: id});
                return false;
            }
        }
        this.setState({openedForm: this.FORM_UPDATE, selectedId: clickInfo.event.id});
    }

    handleEvents = function (events) {
        this.setState({
            currentEvents: events
        })
    }

    renderEventContent = function (eventInfo) {

        var befoglalas = eventInfo.event.extendedProps.befoglalas;
        var megjegyzes = eventInfo.event.extendedProps.munkaBeosztas_megjegyzes;
        if (befoglalas == 1) {
            return <div style={{backgroundColor: '#8c0101', fontWeight: 'bold'}}>
            
                <div style={{
                                    whiteSpace: 'break-spaces',
                                    borderBottom: 'solid 1px white',
                                    borderTop: 'solid 1px white',
                                    textAlign: 'center',
                                    textShadow: ' 1px 1px 3px #000000'
                                }}
                     >ESEMÉNY</div>
                <div style={{color: 'white', padding: 10, whiteSpace: 'break-spaces'}}>{megjegyzes}</div>
            </div>
        }


        var ugyfelNev = eventInfo.event.extendedProps.munkaBeosztas_ugyfel_nev;
        var ugyfelCim = eventInfo.event.extendedProps.munkaBeosztas_ugyfel_cim;
        var autoElozoNap = eventInfo.event.extendedProps.munkaBeosztas_auto_elozo_nap;
        var autoKovetkezoNap = eventInfo.event.extendedProps.munkaBeosztas_auto_kovetkezo_nap;


        var rendszam = eventInfo.event.extendedProps.autok_rendszam;
        var dolgozok = eventInfo.event.extendedProps.dolgozok;

        if (megjegyzes.length == 50) {
            megjegyzes += "(...)";
        }


        var pentek_hetfo = eventInfo.event.extendedProps.pentek_hetfo;
        var hetfo_pentek = eventInfo.event.extendedProps.hetfo_pentek;
        var rendszamColor = (autoElozoNap == 1 || autoKovetkezoNap == 1) ? "#FF0000" : "white";
        var rendszamPrefix = (autoElozoNap == 1) ? "<- " : "";
        var rendszamPostfix = (autoKovetkezoNap == 1) ? " -> " : "";
        if (pentek_hetfo) {
            rendszamColor = "#FF0000";
            rendszamPostfix += " -> [Hétfő] ";
        }
        if (hetfo_pentek) {
            rendszamColor = "#FF0000";
            rendszamPrefix += "[Péntek] <- ";
        }

        var events = [];
        events.push(<dt key={"event_ugyfelNeve_" + this.getUniqueKey()}><font style={{whiteSpace: 'break-spaces'}} color = "#d6c369">{ugyfelNev}</font></dt>);
        events.push(<dt key={"event_ugyfelCim_" + this.getUniqueKey()}><font style={{whiteSpace: 'break-spaces'}}  color="white">{ugyfelCim}</font></dt>);
        events.push(<dt key={"event_ugyfeEgyebCimek_" + this.getUniqueKey()}><EgyebCimek data={eventInfo.event.extendedProps} /></dt>);
        events.push(<dt key={"event_rendszam_" + this.getUniqueKey()} ><font color = "#d6c369">Rendszám:</font></dt>);
        events.push(<dt key={"event_rendszam_string_" + this.getUniqueKey()} style={{color: rendszamColor, whiteSpace: 'normal'}} >&nbsp;&nbsp;{rendszamPrefix}{rendszam}{rendszamPostfix}</dt>);

        events.push(<dt key={"event_dolgozok_" + this.getUniqueKey()}><font color = "#d6c369">Dolgozók:</font></dt>);

        for (var i = 0; i < dolgozok.length; i++) {
            var dolgozo = dolgozok[i];

            var prefix = '';
            var postfix = '';

            prefix += (dolgozo.elozo_nap == 1) ? ' <- ' : '';
            postfix += (dolgozo.kovetkezo_nap == 1) ? ' -> ' : '';

            // prefix += (dolgozo.hetfo_pentek==1)?'[Péntek]<- ':'';
            // postfix += (dolgozo.pentek_hetfo==1)?' ->[Hétfő]':'';

            var dolgozoColor = 'white';
            // if(dolgozo.hetfo_pentek==1 || dolgozo.pentek_hetfo==1 || dolgozo.elozo_nap ==1 || dolgozo.kovetkezo_nap ==1){
            //     dolgozoColor = "#6a0404";
            // }
            if (dolgozo.elozo_nap == 1 || dolgozo.kovetkezo_nap == 1) {
                dolgozoColor = "#FF0000";
            }

            var felelos = "";
            if (dolgozo.felelos == 1) {
                felelos = '* ';
            }

            events.push(<dt style={{whiteSpace: 'break-spaces'}} key={"event_dolgozok_" + this.getUniqueKey()}><font color = {dolgozoColor}>{prefix}{felelos}{dolgozo.user_name}{postfix}</font></dt>);
        }

        events.push(<dt key={"event_megjegyzes_felirat_"} key={"event_megjegyzes_felirat_" + this.getUniqueKey()} style={{whiteSpace: 'break-spaces', fontWeight: 'bold', color: '#d6c369'}}><b>Megjegyzés:</b></dt>);
        events.push(<dt key={"event_megjegyzes_"} key={"event_megjegyzes_" + this.getUniqueKey()} style={{whiteSpace: 'break-spaces'}}>{megjegyzes}</dt>);


        var ul = <dl key={"lista_" + eventInfo.event.id}>{events}</dl>

        return <div style={{margin: 2, padding: 2, border: 'solid 1px white'}} key={'event_container_' + eventInfo.event.id}>
            <i>{ul}</i>
        </div>

    }

    renderSidebarEvent = function (event) {

        return (<li  key={event.id}>
            <b>{event.start}</b>
            <i>{event.title}</i>
        
        </li>
                )
    }

    transactionFail = function (reason) {

        this.setState({openedForm: 'ALERT', dialogText: reason});
    }

    update = function (id, toDate) {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/MunkaBeosztasNaptar/naptarUpdate.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: id, toDate: toDate})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.dataLoader({start: this.dateRangeStart, end: this.dateRangeEnd});
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }
    updateBefoglalas = function (id, toDate) {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/MunkaBeosztasNaptar/naptarBefoglalasUpdate.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: id, toDate: toDate})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.dataLoader({start: this.dateRangeStart, end: this.dateRangeEnd});
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    onDrop = function (info) {
        const {start, end} = info.oldEvent._instance.range;
        var id = info.event.id;
        var isBefoglalas = false;

        if (id.indexOf("bef_") > -1) {
            id = id.replace("bef_", "");
            isBefoglalas = true;
        }





        //this.update(id, fromDate, toDate);

        const {
            start: newStart,
            end: newEnd
        } = info.event._instance.range;
        var fromDate = moment(newStart).format('YYYY-MM-DD');
        var toDate = moment(newEnd).format('YYYY-MM-DD');


        if (isBefoglalas == true) {
            this.updateBefoglalas(id, fromDate);
        } else {
            this.update(id, fromDate);
        }


        if (new Date(start).getDate() === new Date(newStart).getDate()) {
            info.revert();
        }

    }

    render() {
        var activeForm = "";
        if (this.state.openedForm == 'ALERT') {
            activeForm = <Dialog 
                type={'ALERT'} 
                dialogText={this.state.dialogText} 
                callback={() => {
                                    this.state.openedForm = 'NONE';
                                    this.dataLoader({start: this.dateRangeStart, end: this.dateRangeEnd});
                                }}
                theme={this.props.theme}
                />;
        }


        if (this.state.openedForm == this.FORM_INSERT) {
            activeForm = <MunkaBeosztasInsertForm 
                theme={this.props.theme} 
                key={'munkaBeosztas_KEY_INSERT'} 
                formType={this.FORM_INSERT} 
                closeForm={this.closeForm.bind(this)}
                additionalParams={[{}]}
                munkaBeosztas_datum={this.state.selectedDate}
                />;
        }
        if (this.state.openedForm == this.FORM_UPDATE) {
            activeForm = <MunkaBeosztasUpdateForm
                theme={this.props.theme} 
                key={'munkaBeosztas_KEY_UPDATE'} 
                formType={this.FORM_UPDATE} 
                closeForm={this.closeForm.bind(this)}
                id ={this.state.selectedId}
                additionalParams={[
                                {munkaBeosztasCimek_munka_beosztas_id: this.state.selectedId},
                                {munkaBeosztasDolgozok_munka_beosztas_id: this.state.selectedId},
                                {munkaBeosztasUgyfelek_munka_beosztas_id: this.state.selectedId}
                            ]} 
                />;
        }
        if (this.state.openedForm == this.FORM_UPDATE_BEFOGLALAS) {
            activeForm = <MunkaBeosztasBefoglalasUpdateForm
                theme={this.props.theme} 
                key={'munkaBeosztas_KEY_UPDATE_BEFOGLALAS'} 
                formType={this.FORM_UPDATE} 
                closeForm={this.closeForm.bind(this)}
                id ={this.state.selectedId}
                additionalParams={[
                                {munkabeosztasNaptarBefoglalas_id: this.state.selectedId}
                            ]} 
                />;
        }

// right: 'dayGridMonth,timeGridWeek,timeGridDay'
        return <div className={'surface'}>
        
            <div style={{minWidth:800}}>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        
        
                    datesSet={(dateInfo) => {
                                    this.dataLoader(dateInfo);
                                }}
        
        
                    headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth dayGridWeek dayGridDay'
                                }}
                    views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}            
                    initialView='dayGridWeek'
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={this.state.weekendsVisible}
                    events={this.state.events}
        
                    initialEvents={this.state.events}
                    select={this.handleDateSelect.bind(this)}
                    eventContent={this.renderEventContent.bind(this)} 
                    eventClick={this.handleEventClick.bind(this)}
                    eventsSet={this.handleEvents.bind(this)} 
                    locale={huLocale}
                    contentHeight={"auto"}
                    height={'auto'}
                    eventDrop={this.onDrop.bind(this)}
                    eventAfterRender={ function(event, element) {element.addTouch()}
        }
                    />
            </div>
            {activeForm}
        </div>
    }
}
export default MunkaBeosztasNaptar;
