import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import PagingToolbar from './../../components/PagingToolbar';

import {Button} from 'react-bootstrap';

import InsertForm from './ArajanlatInsertForm.js';
import UpdateForm from './ArajanlatUpdateForm.js';
import ViewForm from './ArajanlatViewForm.js';
import Dialog from './../../components/Dialog.js';
import ActionCell from './../../components/ActionCellAjanlat';
import GlobalSearch from './../../components/GlobalSearch';
import DriveLinkTableButton from './../../components/DriveLinkTableButton';



import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');



class ArajanlatTable extends Component {

    constructor(props) {
        super(props);

        this.NONE = "NONE";

        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";


        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";

        this.AJANLAT_STATUSZ_OSSZES = null;
        this.AJANLAT_STATUSZ_FOLYAMATBAN = '1';
        this.AJANLAT_STATUSZ_ELFOGADOTT = '2';
        this.AJANLAT_STATUSZ_AKTUALIZALT = '3';
        this.AJANLAT_STATUSZ_LEZART = '4';
        this.AJANLAT_STATUSZ_MEGHIUSULT = '5';

        var columnNames = [
            'action_cell',
            'arajanlat_id',
            'drive_link',
            'ugyfelek_nev',
            'arajanlat_azonosito',
            'kapcsolattartok_nev',

            'usersAsProjektVezeto_id',
            'usersAsTervezo_id',

            'arajanlat_targy',
            'arajanlat_datum',
            'arajanlat_datum_ervenyes',

            'arajanlat_statusz',
            'arajanlat_verzio',
            'arajanlat_ugyfel_id',
            'arajanlat_monogram',

            'usersAsTulajdonos_id',

            'arajanlat_projekt_vezeto_id',
            'arajanlat_tervezo_id',
            'arajanlat_ugyfel_cim_id',
            'arajanlat_szallitasi_cim_id',
            'arajanlat_leiras',
            'arajanlat_arengedmeny',
            'arajanlat_garancia',
            'arajanlat_muszaki_leirasok',
            'arajanlat_megjegyzes',
            'arajanlat_ajanlat_keszito_id',
            'arajanlat_hivatkozas_url',
            'arajanlat_szallitasi_mod_id',
            'arajanlat_nem_tartalmazza',
            'arajanlat_fizetesi_feltetelek',
            'arajanlat_szulo_id',
            'arajanlat_legutolso',

            'usersAsAjanlatKeszito_id',

            'arajanlatTetelSzallitasiMod_megnevezes',
            'arajanlat_createdAt',
            'arajanlat_modifiedAt'
        ];

        this.columnStateHandler = ajax.columnStateHandler(columnNames, "Arajanlat", "arajanlat");




        this.state = {
            gridRef: null,
            columnOrder: columnNames,
            theme: this.props.theme,
            openedForm: this.NONE, // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRow: null,
            sortInfo: null,
            groupBy: false,
            filterValue: null,
            additionalParams: [],
            requestProps: null,
            skip: 0,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 20,
                showingRowFrom: 0,
                showingRowTo: 20,
                totalRowCount: 0,
                skip: 0
            },
            dialogType: this.NONE,
            dialogText: ''
        };
        this.renderPaginationToolbar = null;
        this.ref = React.createRef();
        this.globalSearch = {"name": "globalSearch", "operator": "contains", "type": "string", "value": ''};

        this.gridRef = null;

        this.filterInit = true;
        this.saveFilter = false;

        this.usersAsProjektVezeto_idData = [];
        this.usersAsTervezo_idData = [];
        this.usersAsAjanlatKeszito_idData = [];
        this.usersAsTulajdonos_idData = [];

        this.filtersLoaded = false;


    }

    componentDidMount() {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Arajanlat/filtersData.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        var data = response.data;

                        self.usersAsProjektVezeto_idData = data.usersAsProjektVezeto_id;
                        self.usersAsTervezo_idData = data.usersAsTervezo_id;
                        self.usersAsAjanlatKeszito_idData = data.usersAsAjanlatKeszito_id;
                        self.usersAsTulajdonos_idData = data.usersAsTulajdonos_id;

                        self.filtersLoaded = true;
                        self.forceUpdate();

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    setGridRef = function (val) {
        this.gridRef = val;
        this.setState({gridRef: val});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
        if (this.state.openedForm != nextState.openedForm) {
            return true;
        }
        if (nextProps.theme != nextState.theme) {
            return true;
        }
        return false; // equals() is your implementation
    }

    setView = function (e) {

        var formName = e.target.id;

        var rowSelectionNeed = false;
        var hasSelectedRow = true;

        if (formName == this.FORM_DELETE || formName == this.FORM_UPDATE || formName == this.FORM_VIEW) {
            rowSelectionNeed = true;
        }
        if (this.state.selectedRow == null || this.state.selectedRow == '') {
            hasSelectedRow = false;
        }
        if (rowSelectionNeed == true && hasSelectedRow == false) {
            this.setState({openedForm: this.DIALOG_ALERT, dialogText: lang.getTitle("ROW_SELECTION_NEED")});
        } else {
            var dialogText = "";
            if (formName == this.FORM_DELETE) {
                dialogText = lang.getTitle("DELETE_CONFIRMATION");
            }
            this.setState({openedForm: formName, dialogText: dialogText});
        }



    }

    closeForm = function () {
        this.setState({openedForm: this.NONE});
    }

    customPaging = function (pagingProps) {
        return <PagingToolbar blackTheme={this.props.theme} tableReload={this.reload.bind(this)} paging={this.state.paging} />
    }
    reload = function (skip) {
        this.state.paging.skip = skip;
        this.forceUpdate();
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    addForeignKeyFilter = function (filterValues) {

        var additionalParams = this.props.additionalParams;

        if (typeof additionalParams == 'undefined') {
            return filterValues;
        }

        if (additionalParams == null) {
            return filterValues;
        }

        if (additionalParams.length == null) {
            return filterValues;
        }

        for (var ap = 0; ap < additionalParams.length; ap++) {
            var additionalParam = additionalParams[ap];

            for (const property in additionalParam) {

                var filter = null;
                for (var f = 0; f < filterValues.length; f++) {
                    var currentFilter = filterValues[f];
                    if (currentFilter.name == property) {
                        filter = currentFilter;
                    }
                }

                if (filter != null) {
                    filter.operator = 'eq';
                    filter.type = 'number';
                    filter.value = additionalParam[property];
                } else {
                    filter = {
                        name: property,
                        operator: 'eq',
                        type: 'number',
                        value: additionalParam[property]
                    };
                    filterValues.push(filter);
                }
            }

        }
        return filterValues;
    }

    setGlobalSearch = function (val) {
        this.globalSearch.value = val;
        this.forceUpdate();
    }

    globalSearchHandler = function (filterValue) {

        var hasExistingFilter = this.hasExitsingFilter(filterValue, this.globalSearch);
        if (hasExistingFilter == false) {
            filterValue.push(this.globalSearch);
        }
        return filterValue;
    }

    hasExitsingFilter = function (currentFilters, newFilter) {

        for (var i = 0; i < currentFilters.length; i++) {
            var currentFilter = currentFilters[i];
            if (currentFilter.name == newFilter.name) {
                currentFilter.value = newFilter.value;
                return true;
            }
        }
        return false;
    }

    dataLoader = function ( { skip, limit, sortInfo, groupBy, filterValue }){


        // FILTER ÁLLAPOTOK MENTÉSE ÉS BETÖTÉSE
        if (this.saveFilter == true) {
            this.columnStateHandler.saveFilter(filterValue);
            this.columnStateHandler.saveSortInfo(sortInfo);
            this.columnStateHandler.saveLimit(limit);
            this.saveFilter = false;
        }
        if (this.filterInit == true) {
            sortInfo = this.columnStateHandler.loadSortInfo();
            ajax.setSortInfo(this.gridRef, sortInfo);
            limit = this.columnStateHandler.loadLimit();
            this.state.paging.pageRowCount = limit;
            this.state.paging.showingRowTo = limit;
            ajax.setLimitInfo(this.gridRef, limit);
        }
        // FILTER ÁLLAPOTOK MENTÉSE ÉS BETÖTÉSE


        // A FALSE ÉRTÉK LEGYEN TRUE HA  HA A MENÜPONTOKBÓL SZERETNÉNK SZŰRNI
        if (false) {

            var ajanlatStatuszFilter = this.getAjanalatStatuszFilterValue();
            for (var f = 0; f < filterValue.length; f++) {
                var currentFilter = filterValue[f];
                if (currentFilter.name == 'arajanlat_statusz') {
                    currentFilter['value'] = ajanlatStatuszFilter;

                }
            }

            filterValue["arajanlat_statusz"] = ajanlatStatuszFilter;
        }

        // this.state.gridRef.current.setColumnFilterValue('arajanlat_statusz', ajanlatStatuszFilter);

        this.state.paging.skip = (typeof this.state.paging.skip == 'undefined') ? 0 : this.state.paging.skip;
        var state = this.state;
        filterValue = this.addForeignKeyFilter(filterValue);
        filterValue = this.globalSearchHandler(filterValue);
        this.state.requestProps = {skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};
        try {
            const url = ajax.getUrlBase() + "modules/Arajanlat/arajanlatTableSelect.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        // FILTER ÁLLAPOTOK MENTÉSE ÉS BETÖTÉSE
                        if (this.filterInit == true) {
                            var storedFilter = this.columnStateHandler.loadFilter();
                            ajax.setStoredFilters(this.gridRef, storedFilter);
                            this.filterInit = false;
                        }
                        // FILTER ÁLLAPOTOK MENTÉSE ÉS BETÖTÉSE        



                        var totalCount = response.count;
                        var data = response.data;
                        state.paging.totalRowCount = totalCount;
                        var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                        state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                        state.paging.showingRowFrom = skip;
                        state.paging.showingRowTo = skip + state.pageRowCount;

                        return Promise.resolve({data, count: parseInt(totalCount), skip: state.paging.skip});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                state.defaultUpdateBlocked = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
    }
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Arajanlat/arajanlatFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({arajanlat_id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        if (response.success == true) {
                            window.open(ajax.getUrlBase() + 'modules/Drive/driveHandler.php?state=AT_' + this.state.selectedRow, '_blank');
                            self.setState({openedForm: 'HIDDEN', selectedRow: null});
                        } else {
                            self.setState({openedForm: this.DIALOG_ALERT, dialogText: response.fail});
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    onSelectionChange = function (selection) {
        this.setState({selectedRow: selection.selected});
    }

    onRowClick = function (rowProps, event) {

        if (typeof rowProps == 'undefined') {
            return false;
        }

        if (rowProps == null) {
            return false;
        }

        if (typeof rowProps.data == 'undefined') {
            return false;
        }

        if (rowProps.data == null) {
            return false;
        }

        if (typeof rowProps.data.arajanlat_id == 'undefined') {
            return false;
        }

        if (rowProps.data.arajanlat_id == null) {
            return false;
        }
        this.setState({selectedRow: rowProps.data.arajanlat_id});
    }

    hasSelectedRow = function () {
        if (typeof this.state.selectedRow == 'undefined') {
            return false;
        }
        if (this.state.selectedRow == null) {
            return false;
        }


        if (this.state.selectedRow == -1) {
            return false;
        }
        return true;
    }

    isViewForm = function () {

        if (typeof this.props.formType == 'undefined') {
            return false;
        }
        if (this.props.formType == null) {
            return false;
        }
        if (this.props.formType == '') {
            return false;
        }
        if (this.props.formType == this.FORM_VIEW) {
            return true;
        }
        return false;
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();

    }

    getAjanalatStatuszFilterValue = function () {
        // Lehetséges státuszok 
        // OSSZES=null,FOLYAMATBAN=[1],ELFOGADOTT=[2],AKTUALIZALT=[3],LEZART=[4],MEGHIUSULT=[5]



        if (typeof this.props.ajanlatStatuszFilter == "undefined") {
            return null;
        }
        if (this.props.ajanlatStatuszFilter == null) {
            return null;
        }

        switch (this.props.ajanlatStatuszFilter) {
            case "OSSZES":
                return null;
                break;
            case "FOLYAMATBAN":
                return [this.AJANLAT_STATUSZ_FOLYAMATBAN];
                break;
            case "ELFOGADOTT":
                return [this.AJANLAT_STATUSZ_ELFOGADOTT];
                break;
            case "AKTUALIZALT":
                return [this.AJANLAT_STATUSZ_AKTUALIZALT];
                break;
            case "LEZART":
                return [this.AJANLAT_STATUSZ_LEZART];
                break;
            case "MEGHIUSULT":
                return [this.AJANLAT_STATUSZ_MEGHIUSULT];
                break;
            default :
                return  null;
        }

    }

    render() {
        const statuszok = {
            '1': 'Árajánlat',
            '2': 'Elfogadott árajánlat',
            '3': 'Aktualizált árajánlat',
            '4': 'Lezárt árajánlat',
            '5': 'Meghiúsult árajánlat'
        }

        var isViewForm = this.isViewForm();
        var columns = [
            {
                name: 'action_cell', header: '', minWidth: 250,
                render: ({ value, data }) => {
                    return <ActionCell 
                        stateReference ={this.state}
                        row_id={data.arajanlat_id} 
                        eredetiArajanlatId ={data.arajanlat_eredeti_arajanlat_id}
                        setView={this.setView.bind(this)}
                        isViewForm ={isViewForm}
                        theme={this.props.theme}
                        arajanlat_statusz={data.arajanlat_statusz}
                        />
                },
                sortable: false
            }
            , {
                name: 'drive_link', header: 'Link', maxWidth: 60,
                render: ({ value, data }) => {
                    return <DriveLinkTableButton
                        value={data.arajanlat_safeLink}
                        />
                },
                sortable: false
            }
            , {name: 'arajanlat_id', sortable: true, header: lang.getTitle('arajanlat_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_id', 100), type: 'number', filterEditor: NumberFilter, defaultVisible: true}
            , {name: 'arajanlat_ugyfel_id', sortable: true, header: lang.getTitle('arajanlat_ugyfel_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_ugyfel_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'arajanlat_monogram', sortable: true, header: lang.getTitle('arajanlat_monogram'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_monogram', 150), defaultVisible: false}

            , {name: 'arajanlat_azonosito', sortable: true, header: lang.getTitle('arajanlat_azonosito'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_azonosito', 150), defaultVisible: true}
            , {name: 'arajanlat_verzio', sortable: true, header: lang.getTitle('arajanlat_verzio'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_verzio', 150), defaultVisible: true}
            , {name: 'arajanlat_projekt_vezeto_id', sortable: true, header: lang.getTitle('arajanlat_projekt_vezeto_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_projekt_vezeto_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'arajanlat_tervezo_id', sortable: true, header: lang.getTitle('arajanlat_tervezo_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_tervezo_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}

            , {name: 'arajanlat_ugyfel_cim_id', sortable: true, header: lang.getTitle('arajanlat_ugyfel_cim_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_ugyfel_cim_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'arajanlat_szallitasi_cim_id', sortable: true, header: lang.getTitle('arajanlat_szallitasi_cim_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_szallitasi_cim_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'arajanlat_targy', sortable: true, header: lang.getTitle('arajanlat_targy'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_targy', 150), defaultVisible: true}
            , {name: 'arajanlat_leiras', sortable: true, header: lang.getTitle('arajanlat_leiras'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_leiras', 150), defaultVisible: false}
            , {name: 'arajanlat_arengedmeny', sortable: true, header: lang.getTitle('arajanlat_arengedmeny'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_arengedmeny', 150), defaultVisible: false}
            , {name: 'arajanlat_datum', sortable: true, header: lang.getTitle('arajanlat_datum'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_datum', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {name: 'arajanlat_createdAt', sortable: true, header: lang.getTitle('arajanlat_createdAt'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_createdAt', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'}
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {name: 'arajanlat_modifiedAt', sortable: true, header: lang.getTitle('arajanlat_modifiedAt'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_modifiedAt', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'}
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {name: 'arajanlat_datum_ervenyes', sortable: true, header: lang.getTitle('arajanlat_datum_ervenyes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_datum_ervenyes', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}

            , {name: 'arajanlat_garancia', sortable: true, header: lang.getTitle('arajanlat_garancia'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_garancia', 150), defaultVisible: false}
            , {name: 'arajanlat_muszaki_leirasok', sortable: true, header: lang.getTitle('arajanlat_muszaki_leirasok'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_muszaki_leirasok', 150), defaultVisible: false}
            , {name: 'arajanlat_megjegyzes', sortable: true, header: lang.getTitle('arajanlat_megjegyzes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_megjegyzes', 150), defaultVisible: false}
            , {name: 'arajanlat_ajanlat_keszito_id', sortable: true, header: lang.getTitle('arajanlat_ajanlat_keszito_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_ajanlat_keszito_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {
                name: 'arajanlat_statusz'
                , sortable: true
                , header: lang.getTitle('arajanlat_statusz')
                , filterDelay: 1000
                , defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_statusz', 150)
                , type: 'number'
                , filterEditor: NumberFilter
                , defaultVisible: true
                , operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ["1", "2", "3", "4", "5"].map(c => {
                        return {id: c, label: statuszok[c]}
                    })
                },
                render: ({ value }) => {
                    return <div>{statuszok[value]}</div>
                }
            }
            , {name: 'arajanlat_hivatkozas_url', sortable: true, header: lang.getTitle('arajanlat_hivatkozas_url'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_hivatkozas_url', 150), defaultVisible: false}
            , {name: 'arajanlat_szallitasi_mod_id', sortable: true, header: lang.getTitle('arajanlat_szallitasi_mod_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_szallitasi_mod_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'arajanlat_nem_tartalmazza', sortable: true, header: lang.getTitle('arajanlat_nem_tartalmazza'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_nem_tartalmazza', 150), defaultVisible: false}
            , {name: 'arajanlat_fizetesi_feltetelek', sortable: true, header: lang.getTitle('arajanlat_fizetesi_feltetelek'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_fizetesi_feltetelek', 150), defaultVisible: false}
            , {name: 'arajanlat_szulo_id', sortable: true, header: lang.getTitle('arajanlat_szulo_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_szulo_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'arajanlat_legutolso', sortable: true, header: lang.getTitle('arajanlat_legutolso'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_legutolso', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'ugyfelek_nev', sortable: true, header: lang.getTitle('ugyfelek_nev'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('ugyfelek_nev', 150), defaultVisible: true}
            , {name: 'kapcsolattartok_nev', sortable: true, header: lang.getTitle('kapcsolattartok_nev'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('kapcsolattartok_nev', 150), defaultVisible: true}

            , {
                name: 'usersAsTulajdonos_id',
                sortable: true,
                header: lang.getTitle('Tulajdonos'),
                defaultWidth: this.columnStateHandler.getColumnWidth('usersAsTulajdonos_id', 150),
                defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.usersAsTulajdonos_idData
                },
                render: ({ value }) => {
                    return <div>{ajax.getValueById(this.usersAsTulajdonos_idData, value)}</div>
                }
            }
            , {
                name: 'usersAsProjektVezeto_id',
                sortable: true,
                header: lang.getTitle('Projektvezető'),
                defaultWidth: this.columnStateHandler.getColumnWidth('usersAsProjektVezeto_id', 150),
                defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.usersAsProjektVezeto_idData
                },
                render: ({ value }) => {
                    return <div>{ajax.getValueById(this.usersAsProjektVezeto_idData, value)}</div>
                }
            }
            , {
                name: 'usersAsTervezo_id',
                sortable: true,
                header: lang.getTitle('Tervező'),
                defaultWidth: this.columnStateHandler.getColumnWidth('usersAsTervezo_id', 150),
                defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.usersAsTervezo_idData
                },
                render: ({ value }) => {
                    return <div>{ajax.getValueById(this.usersAsTervezo_idData, value)}</div>
                }
            }
            , {
                name: 'usersAsAjanlatKeszito_id',
                sortable: true,
                header: lang.getTitle('Ajánlat készítő'),
                defaultWidth: this.columnStateHandler.getColumnWidth('usersAsAjanlatKeszito_id', 150),
                defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.usersAsAjanlatKeszito_idData
                },
                render: ({ value }) => {
                    return <div>{ajax.getValueById(this.usersAsAjanlatKeszito_idData, value)}</div>
                }
            }

            , {name: 'arajanlatTetelSzallitasiMod_megnevezes', sortable: true, header: lang.getTitle('arajanlatTetelSzallitasiMod_megnevezes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelSzallitasiMod_megnevezes', 150), defaultVisible: false}

        ];





        const filters = [
            {name: 'arajanlat_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlat_ugyfel_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlat_monogram', operator: 'contains', type: 'string', value: ''}

            , {name: 'arajanlat_azonosito', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlat_verzio', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlat_projekt_vezeto_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlat_tervezo_id', operator: 'eq', type: 'number', value: ''}

            , {name: 'arajanlat_ugyfel_cim_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlat_szallitasi_cim_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlat_targy', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlat_leiras', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlat_arengedmeny', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlat_datum', operator: 'eq', type: 'date', value: ''}
            , {name: 'arajanlat_createdAt', operator: 'eq', type: 'date', value: ''}
            , {name: 'arajanlat_modifiedAt', operator: 'eq', type: 'date', value: ''}
            , {name: 'arajanlat_datum_ervenyes', operator: 'eq', type: 'date', value: ''}

            , {name: 'arajanlat_garancia', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlat_muszaki_leirasok', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlat_megjegyzes', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlat_ajanlat_keszito_id', operator: 'eq', type: 'number', value: ''}
            //, {name: 'arajanlat_statusz', operator: 'inlist', type: 'select', value: ['1', '2', '3', '4', '5']}
            , {name: 'arajanlat_statusz', operator: 'inlist', type: 'select'}
            , {name: 'arajanlat_hivatkozas_url', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlat_szallitasi_mod_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlat_nem_tartalmazza', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlat_fizetesi_feltetelek', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlat_szulo_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlat_legutolso', operator: 'eq', type: 'number', value: ''}
            , {name: 'ugyfelek_nev', operator: 'contains', type: 'string', value: ''}
            , {name: 'kapcsolattartok_nev', operator: 'contains', type: 'string', value: ''}


            , {name: 'usersAsTulajdonos_id', operator: 'inlist', type: 'select', value: ''}
            , {name: 'usersAsProjektVezeto_id', operator: 'inlist', type: 'select', value: ''}
            , {name: 'usersAsTervezo_id', operator: 'inlist', type: 'select', value: ''}
            , {name: 'usersAsAjanlatKeszito_id', operator: 'inlist', type: 'select', value: ''}

            , {name: 'arajanlatTetelSzallitasiMod_megnevezes', operator: 'contains', type: 'string', value: ''}

        ]

        columns = this.columnStateHandler.setColumnsVisibility(columns);

        var openedForm = "";
        if (this.state.openedForm == this.FORM_VIEW) {
            openedForm = <ViewForm theme={this.props.theme} key={'arajanlat_KEY_VIEW'} formType={this.FORM_VIEW} additionalParams={[{arajanlatTetelek_arajanlat_id: this.state.selectedRow}]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_INSERT) {
            openedForm = <InsertForm  theme={this.props.theme} key={'arajanlat_KEY_INSERT'} formType={this.FORM_INSERT} additionalParams={[{arajanlatTetelek_arajanlat_id: this.state.selectedRow}]} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_UPDATE) {

            var hasSelectedRow = this.hasSelectedRow();
            if (hasSelectedRow == true) {
                openedForm = <UpdateForm theme={this.props.theme} key={'arajanlat_KEY_UPDATE'} formType={this.FORM_UPDATE} additionalParams={[{arajanlatTetelek_arajanlat_id: this.state.selectedRow}]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
            } else {
                console.log("Nincs kiválasztott sor");
                // Alert window
            }
        }

        if (this.state.openedForm == this.FORM_DELETE) {
            openedForm = <Dialog 
                type={this.DIALOG_CONFIRMATION} 
                dialogText={this.state.dialogText} 
                callback={this.delete.bind(this)}
                cancel={() => this.setView({target: {id: this.NONE}})}
            
                theme={this.props.theme}
                />;
        }
        if (this.state.openedForm == this.DIALOG_ALERT) {
            openedForm = <Dialog 
                type={this.DIALOG_ALERT} 
                dialogText={this.state.dialogText} 
                callback={() => this.setView({target: {id: this.NONE}})}
                theme={this.props.theme}
                />;
        }


        if (this.renderPaginationToolbar == null) {
            this.renderPaginationToolbar = this.customPaging.bind(this)
        }

        var buttons = [];

        var viewButton = <div  key={'arajanlat_KEY_VIEW_BUTTON'} >&nbsp;<Button className={'btn-sm'} id={this.FORM_VIEW} onClick={this.setView.bind(this)} variant="info">{lang.getTitle('View')}</Button></div>;
        var insertButton = <div key={'arajanlat_KEY_INSERT_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_INSERT} onClick={this.setView.bind(this)} variant="success">{lang.getTitle('Insert')}</Button></div>;
        var updateButton = <div key={'arajanlat_KEY_UPDATE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_UPDATE} onClick={this.setView.bind(this)} variant="warning">{lang.getTitle('Update')}</Button></div>;
        var deleteButton = <div key={'arajanlat_KEY_DELETE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_DELETE} onClick={this.setView.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button></div>;
        var saveFilter = <div key={'arajanlat_KEY_SAVE_FILTER_BUTTON'}>&nbsp;
            <Button className={'btn-sm'} id={"arajanlat_SAVE_FILTER"} 
                    onClick={() => {
                                    this.saveFilter = true;
                                    this.reload();
                                }} variant="outline-info">{lang.getTitle('Szűrések mentése')}
            </Button>
        </div>;

        var isViewForm = this.isViewForm();

        if (isViewForm) {
            buttons = [viewButton];
        } else {
            buttons = [viewButton, insertButton, updateButton, deleteButton, saveFilter];
        }
        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';

        return (
                <div id="ArajanlatTable">
                    <div style={{display: "flex"}}>
                        {buttons}
                        <GlobalSearch 
                            theme={this.props.theme} 
                            globalSearch={this.setGlobalSearch.bind(this)} />
                
                    </div>
                    <ReactDataGrid 
                        onReady={this.setGridRef.bind(this)}
                        idProperty="arajanlat_id" 
                        i18n={ajax.getI18n()}
                        columns={this.columnStateHandler.myColumnsReorder(columns)}
                        dataSource={this.dataLoader.bind(this)}
                        defaultFilterValue={filters}
                        enableSelection={true}
                
                        onRowClick={this.onRowClick.bind(this)}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                        theme={theme}
                        style={{height: window.innerHeight - 180, marginTop: 10}}    
                
                        defaultLimit={100}
                        pagination={true}
                        renderPaginationToolbar={this.renderPaginationToolbar}
                        skip={this.state.skip}
                        limit={this.state.paging.pageRowCount}
                
                        onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
                        onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
                        onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                        nativeScroll={false}
                
                        />
                    {openedForm}            
                </div>
                );
    }
}

export default ArajanlatTable;
