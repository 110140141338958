import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import ArajanlatTetelOpciokContent from './../../modules/ArajanlatTetelOpciok/ArajanlatTetelOpciokContent.js';
import MTOArajanlatTetelOpciokContent from './../../modules/MTOArajanlatTetelOpciok/MTOArajanlatTetelOpciokContent.js';
import TextInsertField from './../../components/TextInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import TextFormatter from './../../components/TextFormatter';
import NumberInsertField from './../../components/NumberInsertField';

class ArajanlatTetelUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            arajanlatTetelek_manualis_tetel_sorszam: ''
            , arajanlatTetelek_arajanlat_id: ''
            , arajanlatTetelek_megnevezes: ''
            , arajanlatTetelek_hivatkozas_megjegyzes: ''
            , arajanlatTetelek_hivatkozas: ''
            , arajanlatTetelek_netto_ar: ''
            , arajanlatTetelek_darabszam: ''
            , arajanlatTetelek_mennyisegiEgyseg: ''
            , arajanlatTetelek_nettoOsszesen: ''

            , arajanlatTetelek_tetel_kibontas: ''
            , ArajanlatTetelOpciok: []
            , MTOArajanlatTetelOpciok: []

        }


        this.references = [];
        this.references['arajanlatTetelek_manualis_tetel_sorszam'] = React.createRef();
        this.references['arajanlatTetelek_arajanlat_id'] = React.createRef();
        this.references['arajanlatTetelek_megnevezes'] = React.createRef();
        this.references['arajanlatTetelek_hivatkozas_megjegyzes'] = React.createRef();
        this.references['arajanlatTetelek_hivatkozas'] = React.createRef();
        this.references['arajanlatTetelek_netto_ar'] = React.createRef();
        this.references['arajanlatTetelek_darabszam'] = React.createRef();
        this.references['arajanlatTetelek_nettoOsszesen'] = React.createRef();


        this.references['arajanlatTetelek_tetel_kibontas'] = React.createRef();
        this.references['arajanlatTetelek_MTO'] = React.createRef();
        this.references['arajanlatTetelek_MTO'] = React.createRef();


        this.validators = [];
        this.validators['arajanlatTetelek_manualis_tetel_sorszam'] = {fieldName: 'arajanlatTetelek_manualis_tetel_sorszam', dataType: 'INT', value: this.state.arajanlatTetelek_manualis_tetel_sorszam, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_arajanlat_id'] = {fieldName: 'arajanlatTetelek_arajanlat_id', dataType: 'INT', value: this.state.arajanlatTetelek_arajanlat_id, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_megnevezes'] = {fieldName: 'arajanlatTetelek_megnevezes', dataType: 'VARCHAR', value: this.state.arajanlatTetelek_megnevezes, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_hivatkozas_megjegyzes'] = {fieldName: 'arajanlatTetelek_hivatkozas_megjegyzes', dataType: 'VARCHAR', value: this.state.arajanlatTetelek_hivatkozas_megjegyzes, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_hivatkozas'] = {fieldName: 'arajanlatTetelek_hivatkozas', dataType: 'VARCHAR', value: this.state.arajanlatTetelek_hivatkozas, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_netto_ar'] = {fieldName: 'arajanlatTetelek_netto_ar', dataType: 'INT', value: this.state.arajanlatTetelek_netto_ar, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_darabszam'] = {fieldName: 'arajanlatTetelek_darabszam', dataType: 'INT', value: this.state.arajanlatTetelek_darabszam, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_mennyisegiEgyseg'] = {fieldName: 'arajanlatTetelek_mennyisegiEgyseg', dataType: 'VARCHAR', value: this.state.arajanlatTetelek_mennyisegiEgyseg, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_nettoOsszesen'] = {fieldName: 'arajanlatTetelek_nettoOsszesen', dataType: 'INT', value: this.state.arajanlatTetelek_nettoOsszesen, mandatory: false, min: null, max: null};

        this.validators['arajanlatTetelek_tetel_kibontas'] = {fieldName: 'arajanlatTetelek_tetel_kibontas', dataType: 'VARCHAR', value: this.state.arajanlatTetelek_tetel_kibontas, mandatory: false, min: null, max: null};
        this.validators['arajanlatTetelek_MTO'] = {fieldName: 'arajanlatTetelek_MTO', dataType: 'MTO', value: this.state.arajanlatTetelek_MTO, mandatory: true, min: null, max: null};
        this.validators['arajanlatTetelek_MTO'] = {fieldName: 'arajanlatTetelek_MTO', dataType: 'MTO', value: this.state.arajanlatTetelek_MTO, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

        this.load();

    }

    componentDidMount() {
        this.setFocusedComponent();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();
        }
    }

    reset = function () {

        this.setState({
            arajanlatTetelek_manualis_tetel_sorszam: ''
            , arajanlatTetelek_arajanlat_id: ''
            , arajanlatTetelek_megnevezes: ''
            , arajanlatTetelek_hivatkozas_megjegyzes: ''
            , arajanlatTetelek_hivatkozas: ''
            , arajanlatTetelek_netto_ar: ''
            , arajanlatTetelek_darabszam: ''
            , arajanlatTetelek_mennyisegiEgyseg: ''
            , arajanlatTetelek_netto_osszesen: ''

            , arajanlatTetelek_tetel_kibontas: ''
            , ArajanlatTetelOpciok: []
            , MTOArajanlatTetelOpciok: []
        });
    }

    setBack = function (id, value) {
        
        if (id == 'arajanlatTetelek_darabszam'  ) {
            var darabszam = (value == null || value == '') ? 1 : value;
            var nettoOsszesen = this.state.arajanlatTetelek_netto_ar * darabszam;
            this.state.arajanlatTetelek_nettoOsszesen = nettoOsszesen;
        }
        
        if (id=='arajanlatTetelek_netto_ar' ) {
            var netto_ar = (value == null || value == '') ? 0.0 : value;
            var nettoOsszesen = netto_ar*this.state.arajanlatTetelek_darabszam;
            this.state.arajanlatTetelek_nettoOsszesen = nettoOsszesen;
        }


        this.setState({[id]: value});
    }
    


    getForm = function () {
       const inactiveButtonStyle = {
            padding: '1px 4px',
            marginLeft: 5,
            borderRadius: '5px',
            border: '2px solid #007BFF',
            backgroundColor: '#007BFF',
            color: 'white',
            cursor: 'pointer',
            textAlign: 'center',
            transition: 'background-color 0.3s ease',
            fontWeight:'bold'
        };
        const selectedButtonStyle = {
            padding: '1px 4px',
            marginLeft: 5,
            borderRadius: '5px',
            border: '2px solid #007BFF',
            backgroundColor: '#b02a37',
            color: 'white',
            cursor: 'pointer',
            textAlign: 'center',
            transition: 'background-color 0.3s ease',
            fontWeight:'bold'
        };

        var dbButtonStyle = (this.state.arajanlatTetelek_mennyisegiEgyseg == 'db') ? selectedButtonStyle : inactiveButtonStyle;
        var fmButtonStyle = (this.state.arajanlatTetelek_mennyisegiEgyseg == 'fm') ? selectedButtonStyle : inactiveButtonStyle;
        var m2ButtonStyle = (this.state.arajanlatTetelek_mennyisegiEgyseg == 'm2') ? selectedButtonStyle : inactiveButtonStyle;

    
    


        return <Container>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_manualis_tetel_sorszam')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelek_manualis_tetel_sorszam'} 
                    value={this.state.arajanlatTetelek_manualis_tetel_sorszam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_manualis_tetel_sorszam']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        
            <Row>
                <Col style={{textAlign: 'left'}}>
                  <div style={{display: 'flex'}}>
                    <div><b>Me.:</b></div>
        
                    <div 
                        onClick={() => this.setState({arajanlatTetelek_mennyisegiEgyseg: 'db'})} 
                        style={dbButtonStyle}>db</div>
        
                    <div onClick={() => this.setState({arajanlatTetelek_mennyisegiEgyseg: 'fm'})}
                         style={fmButtonStyle}>fm</div>
                    <div onClick={() => this.setState({arajanlatTetelek_mennyisegiEgyseg: 'm2'})} 
                         style={m2ButtonStyle}>m2</div>
                </div>
                </Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelek_darabszam'} 
                    value={this.state.arajanlatTetelek_darabszam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_darabszam']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        
        
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_megnevezes')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField
        
                    id={'arajanlatTetelek_megnevezes'} 
                    value={this.state.arajanlatTetelek_megnevezes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_megnevezes']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_hivatkozas_megjegyzes')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'arajanlatTetelek_hivatkozas_megjegyzes'} 
                    value={this.state.arajanlatTetelek_hivatkozas_megjegyzes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_hivatkozas_megjegyzes']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_hivatkozas')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'arajanlatTetelek_hivatkozas'} 
                    value={this.state.arajanlatTetelek_hivatkozas}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_hivatkozas']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_netto_ar')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelek_netto_ar'} 
                    value={this.state.arajanlatTetelek_netto_ar}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_netto_ar']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_nettoOsszesen')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'arajanlatTetelek_nettoOsszesen'} 
                    value={this.state.arajanlatTetelek_nettoOsszesen}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_nettoOsszesen']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlatTetelek_tetel_kibontas')}</Col>
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextFormatter
                    sablonKivalasztoval={false}
                    id={'arajanlatTetelek_tetel_kibontas'} 
                    value={this.state.arajanlatTetelek_tetel_kibontas}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlatTetelek_tetel_kibontas']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        
            <Row>
                <Col style={{textAlign: 'right'}}>
                <div>
                    <ArajanlatTetelOpciokContent   
                        value={this.state.ArajanlatTetelOpciok} 
                        setBack={this.setBack.bind(this)} 
                        additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
                        editable={true}
                        formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                        theme={this.props.theme}
                        arajanlatTetelek_darabszam={this.state.arajanlatTetelek_darabszam}
                        />
                </div>
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitleBold('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitleBold(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    update = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/ArajanlatTetel/arajanlatTetelekFormUpdate.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(this.state)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/ArajanlatTetel/arajanlatTetelekFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{minWidth: 1100}}>
            <div className={'popupWrapper' + className} id="ArajanlatTetelUpdate">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('ArajanlatTetelUpdate')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default ArajanlatTetelUpdate;

