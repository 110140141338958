
import React, { Component } from 'react';
import AutokTable from './AutokTable.js';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';
import './../../css/speechBubble.css';


class MegjegyzesekPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {values: []}
        this.load();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.autok_id !== this.props.autok_id) {
            this.state = {values: []}
            this.load();
        }
    }

    load = function () {

        if (this.props.autok_id == null) {
            return false;
        }
        if (this.props.autok_id == '') {
            return false;
        }

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Autok/megjegyzesek.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id, autok_id: this.props.autok_id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState({values: response.data});
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    getMegjegyzes = function (data) {
        var megjegyzesek_id = data['megjegyzesek_id'];
        var megjegyzesek_megjegyzes = data['megjegyzesek_megjegyzes'];
        var megjegyzesek_datum = data['megjegyzesek_datum'];
        var megjegyzesek_user_id = data['megjegyzesek_user_id'];
        var users_display_name = data['users_display_name'];
        var megjegyzesek_csoport = data['megjegyzesek_csoport'];
        return <div  
    className="speech-bubble"
    key={"megjegyzesek_megjegyzes_" + megjegyzesek_id}
    onClick={() => this.props.setMegjegyzes(megjegyzesek_megjegyzes)}
    >
    <div style={{margin: 10, whiteSpace: 'normal', cursor: 'pointer'}}>
        {megjegyzesek_datum}&nbsp;({users_display_name})<br/>
        <b>{megjegyzesek_megjegyzes}</b>


    </div>
</div>
    }

    getMegjegyesek = function (dataArray) {
        var megjegyzesek = [];
        for (var i = 0; i < dataArray.length; i++) {
            var data = dataArray[i];
            var megjegyzes = this.getMegjegyzes(data);
            megjegyzesek.push(megjegyzes);
        }

        return <div 
            style={{
                        minHeight: '200px',
                        maxHeight: '200px',
                        overflowY: 'auto',
                            overflowX: 'hidden',
                            display: 'flex',
                            flexFlow: 'column',
                        }}>{megjegyzesek}</div>




    }

    render() {

        var megjegyzesek = this.getMegjegyesek(this.state.values);
        return <div>
        {megjegyzesek}
    </div>


    }
}

export default MegjegyzesekPanel;
