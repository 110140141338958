import React, { Component } from 'react';
import MTOArajanlatTetelTable from './MTOArajanlatTetelTable.js';

import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';

class MTOArajanlatTetelContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: (typeof this.props.value != 'undefined' && this.props.value != null) ? this.props.value : [],
            theme: this.props.theme
        }

    }

    shouldComponentUpdate() {
        return true;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    shouldComponentUpdate() {
        return true;
    }

    render() {

        var emptyFunction = () => {
        };
        var setBack = (typeof this.props.setBack != 'undefined') ? this.props.setBack.bind(this) : emptyFunction;

        var table = <MTOArajanlatTetelTable 
            value={this.state.value} 
            setBack={setBack} 
            additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
            formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
            theme={this.props.theme}
            arajanlat_arengedmeny={this.props.arajanlat_arengedmeny}
            />;
        return (
                <div id="MTOArajanlatTetelContent">
                    <div className={"dropdown-divider"}>{lang.getTitle("MTOArajanlatTetel")}</div> 
                    <div className={"text-center"}><h6>{lang.getTitle("MTOArajanlatTetel")}</h6></div> 
                    <div className={"dropdown-divider"}>{lang.getTitle("MTOArajanlatTetel")}</div> 
              
                    {table}
                </div>
                );
    }
}

export default MTOArajanlatTetelContent;

