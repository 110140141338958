import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import BelsoRendelesArajanlatCombo from './BelsoRendelesArajanlatCombo';
import BelsoRendelesUgyfelCombo from './BelsoRendelesUgyfelCombo';
import BelsoRendelesMunkalapCombo from './BelsoRendelesMunkalapCombo';

import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import TextInsertField from './../../components/TextInsertField';
import NumberInsertField from './../../components/NumberInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';
import DateInsertField from './../../components/DateInsertField';


class BelsoRendelesekInsert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            belsoRendelesek_statusz: ''
            , belsoRendelesek_arajanlat_id: ''
            , belsoRendelesek_munkalap_id: ''
            , belsoRendelesek_ugyfel_id: ''
            , belsoRendelesek_cikkcsoport_id: ''
            , belsoRendelesek_cikk_nev: ''
            , belsoRendelesek_cikk_cikkszam: ''
            , belsoRendelesek_cikk_mennyiseg: ''
            , belsoRendelesek_cikk_mennyisegi_egyseg: ''
            , belsoRendelesek_hivatkozasLink: ''
            , belsoRendelesek_megjegyzes: ''
            , belsoRendelesek_altalanos_megjegyzes: ''
            , belsoRendelesek_rogzito_id: ''
            , belsoRendelesek_rogzites_datum: ''
            , belsoRendelesek_feldolgozo_id: ''
            , belsoRendelesek_megrendeles_datum: ''
            , belsoRendelesek_varhato_beerkezes: ''
            , belsoRendelesek_valos_beerkezes: ''

        }


        this.references = [];
        this.references['belsoRendelesek_statusz'] = React.createRef();
        this.references['belsoRendelesek_ugyfel_id'] = React.createRef();
        this.references['belsoRendelesek_arajanlat_id'] = React.createRef();
        this.references['belsoRendelesek_munkalap_id'] = React.createRef();
        this.references['belsoRendelesek_cikkcsoport_id'] = React.createRef();
        this.references['belsoRendelesek_cikk_nev'] = React.createRef();
        this.references['belsoRendelesek_cikk_cikkszam'] = React.createRef();
        this.references['belsoRendelesek_cikk_mennyiseg'] = React.createRef();
        this.references['belsoRendelesek_cikk_mennyisegi_egyseg'] = React.createRef();
        this.references['belsoRendelesek_hivatkozasLink'] = React.createRef();
        this.references['belsoRendelesek_megjegyzes'] = React.createRef();
        this.references['belsoRendelesek_altalanos_megjegyzes'] = React.createRef();
        this.references['belsoRendelesek_rogzito_id'] = React.createRef();
        this.references['belsoRendelesek_rogzites_datum'] = React.createRef();
        this.references['belsoRendelesek_feldolgozo_id'] = React.createRef();
        this.references['belsoRendelesek_megrendeles_datum'] = React.createRef();
        this.references['belsoRendelesek_varhato_beerkezes'] = React.createRef();
        this.references['belsoRendelesek_valos_beerkezes'] = React.createRef();


        this.validators = [];
        this.validators['belsoRendelesek_statusz'] = {fieldName: 'belsoRendelesek_statusz', dataType: 'INT', value: this.state.belsoRendelesek_statusz, mandatory: false, min: null, max: null};
        this.validators['belsoRendelesek_arajanlat_id'] = {fieldName: 'belsoRendelesek_arajanlat_id', dataType: 'INT', value: this.state.belsoRendelesek_arajanlat_id, mandatory: false, min: null, max: null};
        this.validators['belsoRendelesek_munkalap_id'] = {fieldName: 'belsoRendelesek_munkalap_id', dataType: 'INT', value: this.state.belsoRendelesek_munkalap_id, mandatory: false, min: null, max: null};
        this.validators['belsoRendelesek_ugyfel_id'] = {fieldName: 'belsoRendelesek_ugyfel_id', dataType: 'INT', value: this.state.belsoRendelesek_ugyfel_id, mandatory: true, min: null, max: null};
        this.validators['belsoRendelesek_cikkcsoport_id'] = {fieldName: 'belsoRendelesek_cikkcsoport_id', dataType: 'INT', value: this.state.belsoRendelesek_cikkcsoport_id, mandatory: true, min: null, max: null};
        this.validators['belsoRendelesek_cikk_nev'] = {fieldName: 'belsoRendelesek_cikk_nev', dataType: 'VARCHAR', value: this.state.belsoRendelesek_cikk_nev, mandatory: true, min: null, max: null};
        this.validators['belsoRendelesek_cikk_cikkszam'] = {fieldName: 'belsoRendelesek_cikk_cikkszam', dataType: 'VARCHAR', value: this.state.belsoRendelesek_cikk_cikkszam, mandatory: false, min: null, max: null};
        this.validators['belsoRendelesek_cikk_mennyiseg'] = {fieldName: 'belsoRendelesek_cikk_mennyiseg', dataType: 'INT', value: this.state.belsoRendelesek_cikk_mennyiseg, mandatory: true, min: null, max: null};
        this.validators['belsoRendelesek_cikk_mennyisegi_egyseg'] = {fieldName: 'belsoRendelesek_cikk_mennyisegi_egyseg', dataType: 'VARCHAR', value: this.state.belsoRendelesek_cikk_mennyisegi_egyseg, mandatory: true, min: null, max: null};
        this.validators['belsoRendelesek_hivatkozasLink'] = {fieldName: 'belsoRendelesek_hivatkozasLink', dataType: 'VARCHAR', value: this.state.belsoRendelesek_cikk_mennyiseg, mandatory: false, min: null, max: null};
        this.validators['belsoRendelesek_megjegyzes'] = {fieldName: 'belsoRendelesek_megjegyzes', dataType: 'VARCHAR', value: this.state.belsoRendelesek_megjegyzes, mandatory: false, min: null, max: null};
        this.validators['belsoRendelesek_altalanos_megjegyzes'] = {fieldName: 'belsoRendelesek_altalanos_megjegyzes', dataType: 'VARCHAR', value: this.state.belsoRendelesek_altalanos_megjegyzes, mandatory: false, min: null, max: null};
        this.validators['belsoRendelesek_rogzito_id'] = {fieldName: 'belsoRendelesek_rogzito_id', dataType: 'INT', value: this.state.belsoRendelesek_rogzito_id, mandatory: false, min: null, max: null};
        this.validators['belsoRendelesek_rogzites_datum'] = {fieldName: 'belsoRendelesek_rogzites_datum', dataType: 'DATE', value: this.state.belsoRendelesek_rogzites_datum, mandatory: false, min: null, max: null};
        this.validators['belsoRendelesek_feldolgozo_id'] = {fieldName: 'belsoRendelesek_feldolgozo_id', dataType: 'INT', value: this.state.belsoRendelesek_feldolgozo_id, mandatory: false, min: null, max: null};
        this.validators['belsoRendelesek_megrendeles_datum'] = {fieldName: 'belsoRendelesek_megrendeles_datum', dataType: 'DATE', value: this.state.belsoRendelesek_megrendeles_datum, mandatory: false, min: null, max: null};
        this.validators['belsoRendelesek_varhato_beerkezes'] = {fieldName: 'belsoRendelesek_varhato_beerkezes', dataType: 'DATE', value: this.state.belsoRendelesek_varhato_beerkezes, mandatory: false, min: null, max: null};
        this.validators['belsoRendelesek_valos_beerkezes'] = {fieldName: 'belsoRendelesek_valos_beerkezes', dataType: 'DATE', value: this.state.belsoRendelesek_varhato_beerkezes, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";
        this.prevMunkalapId = -1;

    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();

        }
    }

    componentDidMount() {

        if (this.props.id != null) {
            this.load();
        } else {
            this.reset();
            this.setDataByMunkalapId();
        }

    }

    setDataByMunkalapId = function () {
        if (typeof this.props.munkalap == 'undefined') {
            return false;
        }
        if (this.props.munkalap == null) {
            return false;
        }
        if (typeof this.props.munkalap.value == 'undefined') {
            return false;
        }
        if (this.props.munkalap.value == null) {
            return false;
        }
        if (this.prevMunkalapId != this.props.munkalap.value) {
            this.prevMunkalapId = this.props.munkalap.value;
            this.loadByMunkalap(this.props.munkalap.value);
        }
    }
    componentDidUpdate(prevProps) {
        this.setDataByMunkalapId()
    }

    getULRParam = function () {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var id = urlParams.get('id');
        var filterAttr = urlParams.get('filterAttr');

        if (id == null) {
            return {};
        }

        if (filterAttr == 'belsoRendelesek_arajanlat_id') {
            return {belsoRendelesek_arajanlat_id: id};
        }
        if (filterAttr == 'belsoRendelesek_munkalap_id') {
            return {belsoRendelesek_munkalap_id: id};
        }

        return {};
    }

    loadByMunkalap = function (belsoRendelesek_munkalap_id) {
        

        var self = this;


        var urlParam = this.getULRParam();

        try {
            const url = ajax.getUrlBase() + "modules/BelsoRendelesek/belsoRendelesekFormInsertLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(urlParam)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            var data = response.data[0];
                            this.setState({
                                belsoRendelesek_arajanlat_id: data['belsoRendelesek_arajanlat_id'],
                                belsoRendelesek_ugyfel_id: data['arajanlat_ugyfel_id'],
                                belsoRendelesek_munkalap_id: data['belsoRendelesek_munkalap_id']
                            });

                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({belsoRendelesek_statusz: ''
            , belsoRendelesek_arajanlat_id: ''
            , belsoRendelesek_munkalap_id: ''
            , belsoRendelesek_ugyfel_id: ''
            , belsoRendelesek_cikkcsoport_id: ''
            , belsoRendelesek_cikk_nev: ''
            , belsoRendelesek_cikk_cikkszam: ''
            , belsoRendelesek_cikk_mennyiseg: ''
            , belsoRendelesek_cikk_mennyisegi_egyseg: ''
            , belsoRendelesek_hivatkozasLink: ''
            , belsoRendelesek_megjegyzes: ''
            , belsoRendelesek_altalanos_megjegyzes: ''
            , belsoRendelesek_rogzito_id: ''
            , belsoRendelesek_rogzites_datum: ''
            , belsoRendelesek_feldolgozo_id: ''
            , belsoRendelesek_megrendeles_datum: ''
            , belsoRendelesek_varhato_beerkezes: ''
            , belsoRendelesek_valos_beerkezes: ''
        });
    }

    newPageReset = function () {

        this.setState({
            belsoRendelesek_cikk_nev: ''
            , belsoRendelesek_cikk_cikkszam: ''
            , belsoRendelesek_cikk_mennyiseg: ''
            , belsoRendelesek_cikk_mennyisegi_egyseg: ''
            , belsoRendelesek_hivatkozasLink: ''
            , belsoRendelesek_megjegyzes: ''
            , belsoRendelesek_altalanos_megjegyzes: ''
            , belsoRendelesek_rogzito_id: ''
            , belsoRendelesek_rogzites_datum: ''
            , belsoRendelesek_feldolgozo_id: ''
            , belsoRendelesek_megrendeles_datum: ''
            , belsoRendelesek_varhato_beerkezes: ''
            , belsoRendelesek_valos_beerkezes: ''
        });
        this.forceUpdate();
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getForm = function () {
        return <Container>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('belsoRendelesek_statusz')}</Col>
                <Col style={{textAlign: 'left'}}>
                {lang.getTitleBold('belsoRendelesek_rogzitve')}
                </Col>
            </Row>
            <Row>
                <Col sm={3} style={{textAlign: 'left'}}>{lang.getTitleBold('belsoRendelesek_ugyfel_id')}</Col>
                <Col sm={9} style={{textAlign: 'right'}}>
                <BelsoRendelesUgyfelCombo 
                    id={'belsoRendelesek_ugyfel_id'} 
                    value={this.state.belsoRendelesek_ugyfel_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/BelsoRendelesek/ugyfel_idFormComboInsertData.php'}
                    reference={this.references['belsoRendelesek_ugyfel_id']}
                    editable={true}
                    theme={this.props.theme}
        
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={3} style={{textAlign: 'left'}}>{lang.getTitleBold('belsoRendelesek_arajanlat_id')}</Col>
                <Col sm={9} style={{textAlign: 'right'}}>
                <BelsoRendelesArajanlatCombo
                    id={'belsoRendelesek_arajanlat_id'} 
                    value={this.state.belsoRendelesek_arajanlat_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/BelsoRendelesek/arajanlat_idFormComboInsertData.php'}
                    reference={this.references['belsoRendelesek_arajanlat_id']}
                    editable={true}
                    belsoRendelesek_ugyfel_id={this.state.belsoRendelesek_ugyfel_id}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={3} style={{textAlign: 'left'}}>{lang.getTitleBold('belsoRendelesek_munkalap_id')}</Col>
                <Col sm={9} style={{textAlign: 'right'}}>
                <BelsoRendelesMunkalapCombo 
                    id={'belsoRendelesek_munkalap_id'} 
                    value={this.state.belsoRendelesek_munkalap_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/BelsoRendelesek/munkalap_idFormComboInsertData.php'}
                    reference={this.references['belsoRendelesek_munkalap_id']}
                    editable={true}
                    theme={this.props.theme}
                    belsoRendelesek_ugyfel_id={this.state.belsoRendelesek_ugyfel_id}
                    belsoRendelesek_arajanlat_id={this.state.belsoRendelesek_arajanlat_id}
        
                    />
                </Col>
            </Row>
        
            <Row>
                <Col sm={3} style={{textAlign: 'left'}}>{lang.getTitleBold('belsoRendelesek_cikkcsoport_id')}</Col>
                <Col sm={9} style={{textAlign: 'right'}}>
                <ComboBoxInsertField 
                    id={'belsoRendelesek_cikkcsoport_id'} 
                    value={this.state.belsoRendelesek_cikkcsoport_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/BelsoRendelesek/cikkcsoport_idFormComboInsertData.php'}
                    reference={this.references['belsoRendelesek_cikkcsoport_id']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={3} style={{textAlign: 'left'}}>{lang.getTitleBold('belsoRendelesek_cikk_nev')}</Col>
                <Col sm={9} style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'belsoRendelesek_cikk_nev'} 
                    value={this.state.belsoRendelesek_cikk_nev}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['belsoRendelesek_cikk_nev']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={3} style={{textAlign: 'left'}}>{lang.getTitleBold('belsoRendelesek_cikk_cikkszam')}</Col>
                <Col sm={9} style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'belsoRendelesek_cikk_cikkszam'} 
                    value={this.state.belsoRendelesek_cikk_cikkszam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['belsoRendelesek_cikk_cikkszam']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={3} style={{textAlign: 'left'}}>{lang.getTitleBold('belsoRendelesek_cikk_mennyiseg')}</Col>
                <Col sm={9} style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'belsoRendelesek_cikk_mennyiseg'} 
                    value={this.state.belsoRendelesek_cikk_mennyiseg}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['belsoRendelesek_cikk_mennyiseg']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={3} style={{textAlign: 'left'}}>{lang.getTitleBold('belsoRendelesek_cikk_mennyisegi_egyseg')}</Col>
                <Col sm={9} style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'belsoRendelesek_cikk_mennyisegi_egyseg'} 
                    value={this.state.belsoRendelesek_cikk_mennyisegi_egyseg}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['belsoRendelesek_cikk_mennyisegi_egyseg']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={3} style={{textAlign: 'left'}}><a target={'_blank'} href={this.state.belsoRendelesek_hivatkozasLink}>{lang.getTitleBold('belsoRendelesek_hivatkozasLink')}</a></Col>
                <Col sm={9} style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'belsoRendelesek_hivatkozasLink'} 
                    value={this.state.belsoRendelesek_hivatkozasLink}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['belsoRendelesek_hivatkozasLink']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width: '100%'}}
                    placeholder={'https://www.titar.hu'}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('belsoRendelesek_megjegyzes')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'belsoRendelesek_megjegyzes'} 
                    value={this.state.belsoRendelesek_megjegyzes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['belsoRendelesek_megjegyzes']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
        
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('belsoRendelesek_varhato_beerkezes')}</Col>
                <Col style={{textAlign: 'right', display: 'contents'}}>
                <DateInsertField 
                    id={'belsoRendelesek_varhato_beerkezes'} 
                    value={this.state.belsoRendelesek_varhato_beerkezes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['belsoRendelesek_varhato_beerkezes']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('belsoRendelesek_altalanos_megjegyzes')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextAreaInsertField 
                    id={'belsoRendelesek_altalanos_megjegyzes'} 
                    value={this.state.belsoRendelesek_altalanos_megjegyzes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['belsoRendelesek_altalanos_megjegyzes']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitleBold('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitleBold(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                stateClone[property] = additionalParam[property];
            }
        }
        return stateClone;
    }

    insert = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        var params = this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/BelsoRendelesek/belsoRendelesekFormInsert.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(params)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.newPageReset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }
    
    
    
    
    load = function () {
        
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/BelsoRendelesek/belsoRendelesekFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            response.data[0]['belsoRendelesek_statusz']=1;
                            response.data[0]['belsoRendelesek_megrendeles_datum']='';
                            response.data[0]['belsoRendelesek_valos_beerkezes']='';
                            response.data[0]['belsoRendelesek_varhato_beerkezes']='';
                            response.data[0]['belsoRendelesek_rogzites_datum']='';
                            
                            response.data[0]['belsoRendelesek_rogzito_id']='';
                            response.data[0]['belsoRendelesek_feldolgozo_id']='';
                            response.data[0]['belsoRendelesek_altalanos_megjegyzes']='';
                            
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme}>
            <div className={'popupWrapper' + className} id="BelsoRendelesekInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('BelsoRendelesekInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.insert.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        <Button className={'btn-sm'} id={'save_new'} onClick={this.insert.bind(this)} variant="info" style={{marginTop: '5px'}}>{lang.getTitle('new')}</Button>      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default BelsoRendelesekInsert;

