import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import UgyfelCimekKarbantartasContent from './../../modules/UgyfelCimekKarbantartas/UgyfelCimekKarbantartasContent.js';
import MTOUgyfelCimekKarbantartasContent from './../../modules/MTOUgyfelCimekKarbantartas/MTOUgyfelCimekKarbantartasContent.js';
import TextInsertField from './../../components/TextInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import MaskedInsertField from './../../components/MaskedInsertField';
import KapcsolattartokEmbeddedPanel from './../../modules/UgyfelekKarbantartas/KapcsolattartokEmbeddedPanel';

class UgyfelekKarbantartasUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ugyfelek_id: ''
            , ugyfelek_nev: ''
            , ugyfelek_adoszam: ''
            , ugyfelek_eu_adoszam: ''
            , ugyfelek_monogram: ''
            , ugyfelek_cegjegyzek_szam: ''
            , ugyfelek_kapcsolattarto_id: ''
            , ugyfelek_telefon: ''
            , ugyfelek_email: ''
            , ugyfelek_web: ''
            , ugyfelek_megjegyzes: ''
            , UgyfelCimekKarbantartas: []
            , MTOUgyfelCimekKarbantartas: []

        }


        this.references = [];
        this.references['ugyfelek_id'] = React.createRef();
        this.references['ugyfelek_nev'] = React.createRef();
        this.references['ugyfelek_adoszam'] = React.createRef();
        this.references['ugyfelek_eu_adoszam'] = React.createRef();
        this.references['ugyfelek_monogram'] = React.createRef();
        this.references['ugyfelek_cegjegyzek_szam'] = React.createRef();
        this.references['ugyfelek_kapcsolattarto_id'] = React.createRef();
        this.references['ugyfelek_telefon'] = React.createRef();
        this.references['ugyfelek_email'] = React.createRef();
        this.references['ugyfelek_web'] = React.createRef();
        this.references['ugyfelek_megjegyzes'] = React.createRef();
        this.references['ugyfelek_MTO'] = React.createRef();
        this.references['ugyfelek_MTO'] = React.createRef();


        var adoszamValidator = function (value) {
            if (value == null) {
                return true;
            }
            if (value == '') {
                return true;
            }
            const regex = /^\d{8}-\d{1}-\d{2}$/;
            return value.match(regex) != null;
        }


        this.validators = [];
        this.validators['ugyfelek_id'] = {fieldName: 'ugyfelek_id', dataType: 'INT', value: this.state.ugyfelek_id, mandatory: false, min: null, max: null};
        this.validators['ugyfelek_nev'] = {fieldName: 'ugyfelek_nev', dataType: 'VARCHAR', value: this.state.ugyfelek_nev, mandatory: true, min: null, max: null};
        this.validators['ugyfelek_adoszam'] = {fieldName: 'ugyfelek_adoszam', dataType: 'VARCHAR', value: this.state.ugyfelek_adoszam, mandatory: false, min: null, max: null, validatorFunction: adoszamValidator};
        this.validators['ugyfelek_eu_adoszam'] = {fieldName: 'ugyfelek_eu_adoszam', dataType: 'VARCHAR', value: this.state.ugyfelek_eu_adoszam, mandatory: false, min: null, max: null};
        this.validators['ugyfelek_monogram'] = {fieldName: 'ugyfelek_monogram', dataType: 'VARCHAR', value: this.state.ugyfelek_monogram, mandatory: true, min: null, max: null};
        this.validators['ugyfelek_cegjegyzek_szam'] = {fieldName: 'ugyfelek_cegjegyzek_szam', dataType: 'VARCHAR', value: this.state.ugyfelek_cegjegyzek_szam, mandatory: false, min: null, max: null};
        this.validators['ugyfelek_kapcsolattarto_id'] = {fieldName: 'ugyfelek_kapcsolattarto_id', dataType: 'INT', value: this.state.ugyfelek_kapcsolattarto_id, mandatory: false, min: null, max: null};
        this.validators['ugyfelek_telefon'] = {fieldName: 'ugyfelek_telefon', dataType: 'VARCHAR', value: this.state.ugyfelek_telefon, mandatory: false, min: null, max: null};
        this.validators['ugyfelek_email'] = {fieldName: 'ugyfelek_email', dataType: 'VARCHAR', value: this.state.ugyfelek_email, mandatory: false, min: null, max: null};
        this.validators['ugyfelek_web'] = {fieldName: 'ugyfelek_web', dataType: 'VARCHAR', value: this.state.ugyfelek_web, mandatory: false, min: null, max: null};
        this.validators['ugyfelek_megjegyzes'] = {fieldName: 'ugyfelek_megjegyzes', dataType: 'VARCHAR', value: this.state.ugyfelek_megjegyzes, mandatory: false, min: null, max: null};
        this.validators['ugyfelek_MTO'] = {fieldName: 'ugyfelek_MTO', dataType: 'MTO', value: this.state.ugyfelek_MTO, mandatory: false, min: null, max: null};
        this.validators['ugyfelek_MTO'] = {fieldName: 'ugyfelek_MTO', dataType: 'MTO', value: this.state.ugyfelek_MTO, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

        this.load();

    }

    componentDidMount() {
        this.setFocusedComponent();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();
        }
    }

    reset = function () {

        this.setState({ugyfelek_id: ''
            , ugyfelek_nev: ''
            , ugyfelek_adoszam: ''
            , ugyfelek_eu_adoszam: ''
            , ugyfelek_monogram: ''
            , ugyfelek_cegjegyzek_szam: ''
            , ugyfelek_kapcsolattarto_id: ''
            , ugyfelek_telefon: ''
            , ugyfelek_email: ''
            , ugyfelek_web: ''
            , ugyfelek_megjegyzes: ''
            , UgyfelCimekKarbantartas: []
            , MTOUgyfelCimekKarbantartas: []
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getForm = function () {
        return <Container>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelek_nev')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'ugyfelek_nev'} 
                    value={this.state.ugyfelek_nev}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['ugyfelek_nev']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelek_monogram')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'ugyfelek_monogram'} 
                    value={this.state.ugyfelek_monogram}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['ugyfelek_monogram']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelek_adoszam')}</Col>
                <Col style={{textAlign: 'right'}}>
        
                <MaskedInsertField 
                    id={'ugyfelek_adoszam'} 
                    acceptedChars={"0123456789-"}
                    regexPattern={new RegExp(/^\d{8}-\d{1}-\d{2}$/)}
                    placeHolder ={'12345678-9-10'}
                    value={this.state.ugyfelek_adoszam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['ugyfelek_adoszam']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width:'100%'}}
                    />
                </Col>
            </Row>
        
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelek_eu_adoszam')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'ugyfelek_eu_adoszam'} 
                    value={this.state.ugyfelek_eu_adoszam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['ugyfelek_eu_adoszam']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelek_cegjegyzek_szam')}</Col>
                <Col style={{textAlign: 'right'}}>
                <MaskedInsertField 
                    id={'ugyfelek_cegjegyzek_szam'} 
                    regexPattern={new RegExp(/^\d{10}$/)}
                    placeHolder ={'0123456789'}
                    acceptedChars={"0123456789"}
                    value={this.state.ugyfelek_cegjegyzek_szam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['ugyfelek_cegjegyzek_szam']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width:'100%'}}
                    />
                </Col>
            </Row>
        
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelek_telefon')}</Col>
                <Col style={{textAlign: 'right'}}>
        
                <MaskedInsertField 
                    id={'ugyfelek_telefon'} 
                    acceptedChars={"-+0123456789 ()"}
                    regexPattern={new RegExp(/^[+\s(0-9)\s0-9\\s-\s0-9]*$/)}
                    placeHolder ={'+360000000'}
                    value={this.state.ugyfelek_telefon}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['ugyfelek_telefon']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width:'100%'}}
                    /> 
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelek_email')}</Col>
                <Col style={{textAlign: 'right'}}>
                <MaskedInsertField 
                    id={'ugyfelek_email'} 
                    regexPattern={new RegExp(/^.*@.*\.[a-zA-z].*$/)}
                    placeHolder ={'a@b.hu'}
                    value={this.state.ugyfelek_email}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['ugyfelek_email']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelek_web')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'ugyfelek_web'} 
                    value={this.state.ugyfelek_web}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['ugyfelek_web']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelek_megjegyzes')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextAreaInsertField 
                    id={'ugyfelek_megjegyzes'} 
                    value={this.state.ugyfelek_megjegyzes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['ugyfelek_megjegyzes']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width: '100%'}}
                    />
                </Col>
            </Row>
            <Row style={{padding:2, marginLeft:2, marginRight:2,boxShadow:'1px 1px 4px black'}}>
                <Col>
                <Row>
                    <Col style={{textAlign: 'left',marginLeft:20}}>{lang.getTitleBold('ugyfelek_kapcsolattarto_id')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <ComboBoxInsertField 
                        id={'ugyfelek_kapcsolattarto_id'} 
                        value={this.state.ugyfelek_kapcsolattarto_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/UgyfelekKarbantartas/kapcsolattarto_idFormComboInsertData.php'}
                        reference={this.references['ugyfelek_kapcsolattarto_id']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
        
                <Row>
                    <KapcsolattartokEmbeddedPanel 
                        theme={this.props.theme} 
                        key={'kapcsolattartok_KEY_VIEW'} 
                        formType={this.FORM_VIEW} 
                        additionalParams={[]} id={this.state.ugyfelek_kapcsolattarto_id} closeForm={this.closeForm.bind(this)} />
                </Row>
                </Col>
            </Row>
                        
                            
            <Row>
                <Col style={{textAlign: 'right'}}>
                <div>
                    <UgyfelCimekKarbantartasContent   
                        value={this.state.UgyfelCimekKarbantartas} 
                        setBack={this.setBack.bind(this)} 
                        additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
                        editable={true}
                        formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                        theme={this.props.theme}
                        />
                </div>
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitleBold('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitleBold(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    update = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/UgyfelekKarbantartas/ugyfelekFormUpdate.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(this.state)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            if (typeof response.data != 'undefined' && response.data != null && typeof response.data.nevvaltozasTortent != 'undefined') {
                                if (response.data.nevvaltozasTortent == true) {
                                    window.open(ajax.getUrlBase() + 'modules/Drive/driveHandler.php?state=PA_' + this.state.ugyfelek_id, '_blank');
                                }
                            }
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/UgyfelekKarbantartas/ugyfelekFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme}>
            <div className={'popupWrapper' + className} id="UgyfelekKarbantartasUpdate">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('UgyfelekKarbantartasUpdate')}&nbsp;({this.state.ugyfelek_id})</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default UgyfelekKarbantartasUpdate;

