import React, { Component } from 'react';
import './../css/menu.css';


import dropDownClosedWhite from "./../img/white/arrow_drop_down_white_24dp.svg";
import dropDownOpenedWhite from "./../img/white/arrow_right_white_24dp.svg";

import dropDownClosedBlack from "./../img/black/arrow_drop_down_black_24dp.svg";
import dropDownOpenedBlack from "./../img/black/arrow_right_black_24dp.svg";


import docOtherWhite from "./../img/white/note_add_white_24dp.svg";
import docOtherBlack from "./../img/black/note_add_black_24dp.svg";

import usersWhite from "./../img/white/people_white_24dp.svg";
import usersBlack from "./../img/black/people_black_24dp.svg";

import autoWhite from "./../img/white/local_shipping_white_24dp.svg";
import autoBlack from "./../img/black/local_shipping_black_24dp.svg";

import ajanlatWhite from "./../img/white/request_quote_white_24dp.svg";
import ajanlatBlack from "./../img/black/request_quote_black_24dp.svg";

import calendarBlack from "./../img/black/calendar_month_black_24dp.svg";
import calendarWhite from "./../img/white/calendar_month_white_24dp.svg";

import autoBeosztasWhite from "./../img/white/departure_board_white_24dp.svg";
import autoBeosztasBlack from "./../img/black/departure_board_black_24dp.svg";

import munkalapWhite from "./../img/white/engineering_white_24dp.svg";
import munkalapBlack from "./../img/black/engineering_black_24dp.svg";

import rendelesWhite from "./../img/white/local_grocery_store_white_24dp.svg";
import rendelesBlack from "./../img/black/local_grocery_store_black_24dp.svg";

import festekWhite from "./../img/white/color_lens_white_24dp.svg";
import festekBlack from "./../img/black/color_lens_black_24dp.svg";

import belsoRendelesekWhite from "./../img/white/store_white_24dp.svg";
import belsoRendelesekBlack from "./../img/black/store_black_24dp.svg";

import irszWhite from "./../img/white/contact_mail_white_24dp.svg";
import irszBlack from "./../img/black/contact_mail_black_24dp.svg";

import cikkcsoportWhite from "./../img/white/inventory_2_white_24dp.svg";
import cikkcsoportBlack from "./../img/black/inventory_2_black_24dp.svg";

import sablonWhite from "./../img/white/copy_all_white_24dp.svg";
import sablonBlack from "./../img/black/copy_all_black_24dp.svg";
import projektTablaBlack from "./../img/black/preview_black_24dp.svg";
import projektTablaWhite from "./../img/white/preview_white_24dp.svg";

import folyamatbanBlack from "./../img/black/currency_exchange_black_24dp.svg";
import folyamatbanWhite from "./../img/white/currency_exchange_white_24dp.svg";

import elfogadvaBlack from "./../img/black/monetization_on_black_24dp.svg";
import elfogadvaWhite from "./../img/white/monetization_on_white_24dp.svg";

import aktualizaltBlack from "./../img/black/update_black_24dp.svg";
import aktualizaltWhite from "./../img/white/update_white_24dp.svg";


import cikkcsoportokFelhasznalokBlack from "./../img/black/join_full_black_24dp.svg";
import cikkcsoportokFelhasznalokWhite from "./../img/white/join_full_white_24dp.svg";

import szabadsagBlack from "./../img/black/surfing_black_24dp.svg";
import szabadsagWhite from "./../img/white/surfing_white_24dp.svg";


import jogosultsagokBlack from "./../img/black/admin_panel_settings_black_24dp.svg";
import jogosultsagokWhite from "./../img/white/admin_panel_settings_white_24dp.svg";

import driveBlack from "./../img/black/add_to_drive_black_24dp.svg";
import driveWhite from "./../img/white/add_to_drive_white_24dp.svg";

import meghiusultBlack from "./../img/black/delete_black_24dp.svg";
import meghiusultWhite from "./../img/white/delete_white_24dp.svg";

import lezartBlack from "./../img/black/lock_black_24dp.svg";
import lezartWhite from "./../img/white/lock_white_24dp.svg";

import mobilBlack from "./../img/black/phone_iphone_black_24dp.svg";
import mobilWhite from "./../img/white/phone_iphone_white_24dp.svg";






class MenuItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            onMouseOver: false,
            dropDownOpened: false
        }
    }

    getMenuIconByName = function (iconName) {

        var docOther = (this.props.theme == "true") ? docOtherWhite : docOtherBlack;
        var users = (this.props.theme == "true") ? usersWhite : usersBlack;
        var auto = (this.props.theme == "true") ? autoWhite : autoBlack;
        var ajanlat = (this.props.theme == "true") ? ajanlatWhite : ajanlatBlack;
        var folyamatban = (this.props.theme == "true") ? folyamatbanWhite : folyamatbanBlack;
        var elfogadva = (this.props.theme == "true") ? elfogadvaWhite : elfogadvaBlack;
        var aktualizalt = (this.props.theme == "true") ? aktualizaltWhite : aktualizaltBlack;
        var calendar = (this.props.theme == "true") ? calendarWhite : calendarBlack;
        var autoBeosztas = (this.props.theme == "true") ? autoBeosztasWhite : autoBeosztasBlack;
        var munkalap = (this.props.theme == "true") ? munkalapWhite : munkalapBlack;
        var rendeles = (this.props.theme == "true") ? rendelesWhite : rendelesBlack;
        var festek = (this.props.theme == "true") ? festekWhite : festekBlack;
        var belsoRendelesek = (this.props.theme == "true") ? belsoRendelesekWhite : belsoRendelesekBlack;
        var irsz = (this.props.theme == "true") ? irszWhite : irszBlack;
        var cikkcsoport = (this.props.theme == "true") ? cikkcsoportWhite : cikkcsoportBlack;
        var sablon = (this.props.theme == "true") ? sablonWhite : sablonBlack;
        var projektTabla = (this.props.theme == "true") ? projektTablaWhite : projektTablaBlack;
        var cikkcsoportokFelhasznalok = (this.props.theme == "true") ? cikkcsoportokFelhasznalokWhite : cikkcsoportokFelhasznalokBlack;
        var szabadsag = (this.props.theme == "true") ? szabadsagWhite : szabadsagBlack;
        var szabadsagNaptar = (this.props.theme == "true") ?  calendarWhite : calendarBlack;
        var jogosultsagok = (this.props.theme == "true") ?  jogosultsagokWhite : jogosultsagokBlack;
        var drive = (this.props.theme == "true") ?  driveWhite : driveBlack;
        var meghiusult = (this.props.theme == "true") ?  meghiusultWhite : meghiusultBlack;
        var lezart = (this.props.theme == "true") ?  lezartWhite : lezartBlack;
        var mobil = (this.props.theme == "true") ?  mobilWhite : mobilBlack;

        switch (iconName) {
            case 'doc'    :
                return docOther;
                break;
            case 'users' :
                return users;
                break;
            case 'auto' :
                return auto;
                break;
            case 'ajanlat' :
                return ajanlat;
                break;
            case 'folyamatban' :
                return folyamatban;
                break;
            case 'elfogadva' :
                return elfogadva;
                break;
            case 'aktualizalt' :
                return aktualizalt;
                break;
            case 'meghiusult' :
                return meghiusult;
                break;
            case 'lezart' :
                return lezart;
                break;
            case 'mobil' :
                return mobil;
                break;
            case 'calendar' :
                return calendar;
                break;
            case 'autoBeosztas' :
                return autoBeosztas;
                break;
            case 'munkalap' :
                return munkalap;
                break;
            case 'rendeles' :
                return rendeles;
                break;
            case 'festek' :
                return festek;
                break;
            case 'belsoRendelesek' :
                return belsoRendelesek;
                break;
            case 'irsz' :
                return irsz;
                break;
            case 'cikkcsoport' :
                return cikkcsoport;
                break;
            case 'sablon' :
                return sablon;
                break;
            case 'projektTabla' :
                return projektTabla;
                break;
            case 'cikkcsoportokFelhasznalok' :
                return cikkcsoportokFelhasznalok;
                break;
            case 'szabadsag' :
                return szabadsag;
                break;
            case 'szabadsagNaptar' :
                return szabadsagNaptar;
                break;
            case 'jogosultsagok' :
                return jogosultsagok;
                break;
            case 'googleDrive' :
                return drive;
                break;
        }

    }

    onMouseOver = function (e) {
        this.setState({onMouseOver: true});
    }
    onMouseLeave = function (e) {
        this.setState({onMouseOver: false});
    }

    setActiveMenuItem = function (e) {
        if (window.event.ctrlKey) {
            window.open('./?menu=' + this.props.item.id,'_blank');
        } else {
            this.props.setActiveMenu(this.props.item.id);
        }
    }

    childMenuOpener = function () {
        this.setState({dropDownOpened: !this.state.dropDownOpened});
    }

    openNode = function () {
        this.setState({dropDownOpened: true});
    }

    render() {
        var dropDownClosed = (this.props.theme == "true") ? dropDownClosedWhite : dropDownClosedBlack;
        var dropDownOpened = (this.props.theme == "true") ? dropDownOpenedWhite : dropDownOpenedBlack;
        var menuIcon = this.getMenuIconByName(this.props.item.ico);
        var isSelected = this.props.activeMenuId == this.props.item.id;

        var selectionColor = (this.props.theme == "true") ? "#00000000" : "#FFFFFF";
        var selectionBackground = (this.props.theme == "true") ? "#313943" : "#FFFFFF";
        if (isSelected) {
            selectionColor = (this.props.theme == "true") ? "#ffc107" : "#ffc107";
            selectionBackground = (this.props.theme == "true") ? "#374350" : "#dfe2ef";
        }

        if (this.state.onMouseOver) {
            selectionBackground = (this.props.theme == "true") ? "#374350" : "#dfe2ef";
        }

        var collapsed = this.props.collapsed;

        var title = (collapsed) ? "" : this.props.item.title;

        var hasChildren = (typeof this.props.children != 'undefined' && this.props.children != null && this.props.children.length > 0) ? true : false;

        var childMenuOpener = "";
        if (hasChildren) {
            childMenuOpener = <img  onClick={this.childMenuOpener.bind(this)} src={(this.state.dropDownOpened) ? dropDownClosed : dropDownOpened }/>;
        }

        var childMenu = (this.props.isChild) ? <div style={{width: "25px"}} /> : "";
        var menuItemTextClass = (this.props.theme == "true") ? "menuItemTextDark" : "menuItemTextLight";

        var menuSeparator = (this.props.theme == "true") ? 'solid 1px #ffffff11' : 'solid 1px #e4e3e2';

        return <div style={{borderBottom: menuSeparator}}>
            <div 
                style={{backgroundColor: selectionBackground}} 
                className={"menuItem"} 
                onClick={this.setActiveMenuItem.bind(this)}
                onMouseOver={this.onMouseOver.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                >
                <div style={{minWidth: '10px', backgroundColor: selectionColor, marginRight: '5px'}} >&nbsp;</div>
                {childMenu}
                <img title={this.props.item.title} src={menuIcon}/><div onClick={this.openNode.bind(this)} className={menuItemTextClass}>{title}</div>
                &nbsp;{childMenuOpener}
        
            </div>
            <div className={(this.state.dropDownOpened) ? 'fadeIn' : 'fadeOut'}>
                {(this.state.dropDownOpened) ? this.props.children : ""}
        
            </div>
        </div>
                ;
    }
}

export default MenuItem;
