import React, { Component } from 'react';
import './../../css/light.css';
import './../../css/dark.css';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';
import MunkalapMunkavallaloCombo from './MunkalapMunkavallaloCombo';
import {Row, Col, Button} from 'react-bootstrap';
import deleteBlack  from './../../img/black/delete_black_24dp.svg';
import deleteWhite  from './../../img/white/delete_white_24dp.svg';

class MunkalapMunkavallaloHozzarendelese extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }

        this.references = [];
        this.references['munkalap_ugyfel_cim_id'] = React.createRef();

        this.currentDolgozo = {
            munkalapDolgozok_munkalap_id: this.props.munkalap_id,
            munkalapDolgozok_user_id: null,
            munkalapDolgozok_user_name: null,
            munkalapDolgozok_felelos: 0
        };

        this.autokLista = [];
        this.loadAutok();


    }

    getSzallithatoSzemelyekSzama = function (autoId) {
        for (var i = 0; i < this.autokLista.length; i++) {
            var auto = this.autokLista[i];
            if (auto.autok_id == autoId) {
                return auto.autok_szemelyek_szama;
            }
        }
        return 0;
    }

    loadAutok() {
        try {
            const url = ajax.getUrlBase() + '/modules/MunkaBeosztas/getAutok.php';
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        if (typeof response.data != 'undefined' && response.data != null) {
                            this.autokLista = response.data;
                            this.setState({});

                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

        this.setState({dropDownVisible: true, hoveredId: -1});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.munkalap_id !== this.props.munkalap_id) {
            this.setState({value: this.props.value});
        }
    }
    duplicated = function (id) {
        for (var i = 0; i < this.state.value.length; i++) {
            var user = this.state.value[i];
            if (user.munkalapDolgozok_user_id == id) {
                return true;
            }
        }
        return false;
    }

    addMunkavallalo() {
        if (typeof this.state.value == 'undefined') {
            this.state.value = [];
        }
        if (this.state.value == null) {
            this.state.value = [];
        }
        
        var selectionNeed = (this.currentDolgozo.munkalapDolgozok_user_id == null) ? true : false;
        var duplicated = this.duplicated(this.currentDolgozo.munkalapDolgozok_user_id);

        if (duplicated == false && selectionNeed == false) {
            var cloned = Object.assign({}, this.currentDolgozo);
            this.state.value.push(cloned);
            this.setState({});
        }


        this.writeBack();
    }

    writeBack = function () {
        if (this.props.isInsert) {
            this.props.setBack("MTOMunkalapDolgozok", this.state.value);
        } else {
            this.props.setBack("MunkalapDolgozok", this.state.value);
        }
    }

    removeMunkavallalo(user_id) {
        var munkavallalok = [];
        for (var i = 0; i < this.state.value.length; i++) {
            var munkavallalo = this.state.value[i];
            if (munkavallalo.munkalapDolgozok_user_id != user_id) {
                munkavallalok.push(munkavallalo);
            }
        }
        this.state.value = munkavallalok;
        this.setState({});


        this.writeBack();
    }

    getMunkavallalo = function (munkavallalo) {
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var style = {textAlign: 'left'};
        var deleteIcon = (this.props.theme == "true") ? deleteWhite : deleteBlack;


        if (typeof this.props.style != "undefined" && this.props.style != null) {
            style = this.props.style;
        }

        return (
                <div key={'row_' + munkavallalo.munkalapDolgozok_user_id}>
                    <div style={{display: 'inline-block', marginRight: 20}}>
                        <img 
                            style={{cursor: 'pointer'}}   
                            id={munkavallalo.munkalapDolgozok_user_id}  
                            src={deleteIcon}  
                            onClick={() => {
                                    this.removeMunkavallalo(munkavallalo.munkalapDolgozok_user_id);
                                }}
                            />
                    </div>
                    
                    <div style={{display: 'inline-block', marginRight: 20}}>
                        {munkavallalo.munkalapDolgozok_user_name}
                    </div>
                </div>
                )
    }

    getMunkavallalok = function () {
        var munkavallalok = [];
        for (var i = 0; i < this.state.value.length; i++) {
            var munkavallalo = this.state.value[i];
            munkavallalok.push(this.getMunkavallalo(munkavallalo));
        }
        return <div style={{minHeight: 100}}>{munkavallalok}</div>;
    }

    setBack = function (id, value) {
        this.currentDolgozo[id] = value;
        this.setState({});
    }

    render() {
        var munkavallalok = this.getMunkavallalok();
        

        var szallithatoSzemelyekSzama = this.getSzallithatoSzemelyekSzama(this.props.autoId);

        return <div style={{marginTop: 20, marginBottom: 20}}>
        
            <div style={{textAlign: 'center'}}  ><h6>{lang.getTitle('MunkalapMunkavallaloHozzarendelese')}</h6></div>
            <Row style={{textAlign: 'left'}}>
                <Col>
                <div style={{display: 'inline-block'}}>
                    <MunkalapMunkavallaloCombo 
                        id={'munkalapDolgozok_user_id'} 
                        value={this.currentDolgozo.munkalapDolgozok_user_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Munkalap/user_idMunkalapMunkavallaloCombo.php'}
                        ugyfel_id={this.currentDolgozo.munkalapDolgozok_user_id}
                        editable={true}
                        theme={this.props.theme}
                        munkalap_datum ={this.props.munkalap_datum}
                        />
                </div>
        
                <div style={{display: 'inline-block', margin: 10, maxHeight: 20}}>
                    <Button 
                        disabled ={this.currentDolgozo.munkalapDolgozok_user_id == null}
                        onClick={this.addMunkavallalo.bind(this)}
                        variant={'success'}>{lang.getTitle('add_selected')}</Button>
                </div>
                </Col>
                <Col>
                <div>{munkavallalok}</div>
                </Col>
            </Row>
        </div>
    }
}

export default MunkalapMunkavallaloHozzarendelese;


