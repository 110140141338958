import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';


class PopUpWindow extends React.Component {

    tryToClose = function (content) {
        if (typeof content == 'undefined') {
            return false;
        }
        if (typeof content._self == 'undefined') {
            return false;
        }
        if (typeof content._self.closeForm == 'undefined') {
            return false;
        }
        content._self.closeForm();
    }

    render() {

        var style = {};
        if (typeof this.props.style != "undefined" && this.props.style != null) {
            style = this.props.style;
        }
        var className = (this.props.theme == "true") ? "Dark" : "Light";

        return  <div className={'popupFrame' + className}>
            <div style={style}  className={'form' + className}>{this.props.children}</div>
        </div>;
    }
}

export default PopUpWindow;
