import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';


class DateTimeInsertField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }

    getDatabaseCompatibleDateTime = function (value) {
        if (typeof value == 'undefined') {
            return '';
        }
        if (value == null) {
            return '';
        }
        if (value == '') {
            return '';
        }
        var dateTimeArray = value.split('T');
        var date = dateTimeArray[0];
        var time = dateTimeArray[1];

        return date + " " + time;
    }

    onChange = function (e) {
        var value = this.getDatabaseCompatibleDateTime(e.target.value);
        this.props.setBack(e.target.id, value);
        this.setState({value: e.target.value});
    }

    render() {
        var className = (this.props.theme=="true") ? "Dark" : "Light";

        return  <div>
            <input 
                onChange={this.onChange.bind(this)} 
                type={'datetime-local'} 
                id={this.props.id} 
                value={this.state.value} 
                ref={this.props.reference}
                readOnly={this.props.editable == false}
                className = {"component" + className}
                />
        </div>;
    }
}

export default DateTimeInsertField;

