import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import FormatedNumberCell from './../../components/FormatedNumberCell';



import {Button} from 'react-bootstrap';

import InsertForm from './MTOArajanlatTetelOpciokInsertForm.js';

class MTOArajanlatTetelOpciokTableMTO extends Component {

    constructor(props) {
        super(props);
        var columnNames = [
            'arajanlatTetelOpciok_id', 
            'arajanlatTetelOpciok_arajanlat_tetel_id',
            'arajanlatTetelOpciok_manualis_sorszam', 
            'arajanlatTetelOpciok_megnevezes', 
            'arajanlatTetelOpciok_hivatkozas', 
            'arajanlatTetelOpciok_netto_ar',
            'arajanlatTetelOpciok_darabszam',
            'arajanlatTetelOpciok_nettoOsszesen'
        ];
        this.columnStateHandler = ajax.columnStateHandler(columnNames, "MTOArajanlatTetelOpciok", "arajanlatTetelOpciok");

        this.state = {
            columnOrder: columnNames,
            openedForm: 'NONE', // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRowId: -1,
            data: []
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.openedForm != nextState.openedForm) {
            return true;
        }
        if (this.state.theme != this.props.theme) {
            return true;
        }
        return false; // equals() is your implementation
    }

    setView = function (e) {
        this.setState({openedForm: e.target.id});
    }

    closeForm = function () {
        this.setState({openedForm: 'NONE'});
    }

    addRow = function (row, close) {
        var rowNumber = this.state.data.length + 1;
        row.arajanlatTetelOpciok_id = rowNumber;
        this.state.data.push(row);
        if (close) {
            this.closeForm();
        } else {
            this.setState({});
        }
        this.props.setBack('MTOArajanlatTetelOpciok', this.state.data);
    }
    onSelectionChange = function (selected) {
        var selectedRowId = selected.data.arajanlatTetelOpciok_id;
        this.setState({selectedRowId: selectedRowId});
    }

    getRowIndexById = function () {
        for (var i = 0; i < this.state.data.length; i++) {
            var currentData = this.state.data[i];
            if (currentData.arajanlatTetelOpciok_id == this.state.selectedRowId) {
                return i;
            }
        }
        return -1;
    }

    deleteRow = function () {

        if (this.state.selectedRowId == -1) {
            return false;
        }

        var rowIndexById = this.getRowIndexById(this.state.selectedRowId);
        if (rowIndexById == -1) {
            return false;
        }


        this.state.data.splice(rowIndexById, 1);
        this.state.selectedRowId = -1;
        this.setState({});
        this.props.setBack('MTOArajanlatTetelOpciok', this.state.data);
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();

    }

    render() {
        const columns = [
            {name: 'arajanlatTetelOpciok_id', sortable: true, header: lang.getTitle('arajanlatTetelOpciok_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelOpciok_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'arajanlatTetelOpciok_arajanlat_tetel_id', sortable: true, header: lang.getTitle('arajanlatTetelOpciok_arajanlat_tetel_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelOpciok_arajanlat_tetel_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'arajanlatTetelOpciok_manualis_sorszam', sortable: true, header: lang.getTitle('arajanlatTetelOpciok_manualis_sorszam'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelOpciok_manualis_sorszam', 150), defaultVisible: true}
            , {name: 'arajanlatTetelOpciok_megnevezes', sortable: true, header: lang.getTitle('arajanlatTetelOpciok_megnevezes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelOpciok_megnevezes', 150), defaultVisible: true}
            , {name: 'arajanlatTetelOpciok_hivatkozas', sortable: true, header: lang.getTitle('arajanlatTetelOpciok_hivatkozas'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelOpciok_hivatkozas', 150), defaultVisible: true}
            , {name: 'arajanlatTetelOpciok_netto_ar', sortable: true, header: lang.getTitle('arajanlatTetelOpciok_netto_ar'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelOpciok_netto_ar', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
            render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value} />
                }
            }
            , {name: 'arajanlatTetelOpciok_darabszam', sortable: true, header: lang.getTitle('arajanlatTetelOpciok_darabszam'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelOpciok_darabszam', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
            render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value} />
                }
            }
            , {name: 'arajanlatTetelOpciok_nettoOsszesen', sortable: true, header: lang.getTitle('arajanlatTetelOpciok_nettoOsszesen'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlatTetelOpciok_nettoOsszesen', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
            render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value} />
                }
            }
            

        ];

        const filters = [
            {name: 'arajanlatTetelOpciok_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlatTetelOpciok_arajanlat_tetel_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlatTetelOpciok_manualis_sorszam', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlatTetelOpciok_megnevezes', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlatTetelOpciok_hivatkozas', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlatTetelOpciok_netto_ar', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlatTetelOpciok_darabszam', operator: 'eq', type: 'number', value: ''}
            , {name: 'arajanlatTetelOpciok_nettoOsszesen', operator: 'eq', type: 'number', value: ''}


        ];



        var openedForm = "";
        if (this.state.openedForm == 'INSERT') {
            openedForm = <InsertForm 
                addRow={this.addRow.bind(this)} 
                closeForm={this.closeForm.bind(this)} 
                additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
                formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                theme={this.props.theme}
                arajanlatTetelek_darabszam={this.props.arajanlatTetelek_darabszam}
                />
        }

        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';

        return (
                <div id="MTOArajanlatTetelOpciokTable">
                    <div>
                        &nbsp;<Button className={'btn-sm'} id={'INSERT'} onClick={this.setView.bind(this)} variant="success">{lang.getTitle('Insert')}</Button>
                        &nbsp;<Button className={'btn-sm'} id={'DELETE'} onClick={this.deleteRow.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button>
                    </div>
                    <ReactDataGrid 
                        idProperty="arajanlatTetelOpciok_id" 
                        i18n={ajax.getI18n()}
                        columns={this.columnStateHandler.myColumnsReorder(columns)}
                        dataSource={this.state.data}
                        theme={theme}
                        style={{height: 200, marginTop: 10}}  
                        enableSelection={true}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                
                        onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
                        onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
                        onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                        />
                
                    {openedForm}            
                </div>
                );
    }
}

export default MTOArajanlatTetelOpciokTableMTO;
