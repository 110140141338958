import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';
import * as ajax from './../ajax.js';
import * as lang from './../lang.js';
import { Container, Row, Col, Button } from 'react-bootstrap';
import printBlack from './../img/black/print_black_24dp.svg';
import printWhite from './../img/white/print_white_24dp.svg';

class PrintButton extends React.Component {

    constructor(props) {
        super(props);
    }

    openPDF = function () {
        window.open(ajax.getUrlBase() +this.props.url + "?id=" + this.props.id);
    }

    render() {
      //  return <Button size="sm" onClick={this.openPDF.bind(this)} ><img src={printWhite}/>{lang.getTitle('print')}</Button>
      return <Button variant="secondary" size="sm" onClick={this.openPDF.bind(this)} >{lang.getTitle('print')}</Button>
    }
}

export default PrintButton;


