import React, { Component } from 'react';
import * as ajax from './../ajax.js';
import * as lang from './../lang.js';
import {Button} from 'react-bootstrap';
import deleteIcon from './../img/black/delete_black_24dp.svg';
import updateIcon from './../img/black/edit_note_black_24dp.svg';
import viewIcon from './../img/black/preview_black_24dp.svg';
import munkalapokIkon from './../img/black/engineering_black_24dp.svg';
import belsoRendelesekIkon from './../img/black/store_black_24dp.svg';

import aktualizaltBlack from "./../img/black/update_black_24dp.svg";

class ActionCellAjanlat extends Component {

    constructor(props) {
        super(props);
        this.FORM_DELETE = "ACTION_CELL_DELETE";
        this.FORM_INSERT = "ACTION_CELL_INSERT";
        this.FORM_UPDATE = "ACTION_CELL_UPDATE";
        this.FORM_VIEW = "ACTION_CELL_VIEW";


    }

    setView = function (e) {
        var actionId = e.target.id;
        this.props.stateReference.selectedRow = this.props.row_id;
        var action = '';
        switch (actionId) {
            case 'ACTION_CELL_DELETE':
                action = 'DELETE';
                break;
            case 'ACTION_CELL_UPDATE':
                action = 'UPDATE';
                break;
            case 'ACTION_CELL_VIEW':
                action = 'VIEW';
                break;
        }


        this.props.setView({target: {id: action}});

    }

    munkalapokMegnyitasa = function (id) {
        window.open('./?menu=Munkalap&id=' + id, '_blank');
    }

    belsoRendelesekMegnyitasa = function (id, filterAttr) {
        window.open('./?menu=BelsoRendelesek&id=' + id + '&filterAttr=' + filterAttr, '_blank');
    }
    
    
    aktualizalas=function(arajanlatId){
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Arajanlat/aktualizaltArajanlatLetrehozasa.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({saveAsActualized:true,arajanlat_id:arajanlatId})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                             self.props.stateReference.selectedRow =response.data.uj_ajanlat_id;
                             self.props.setView({target: {id: 'UPDATE'}});
                        } else {
                            self.transactionFail(response.fail);
                        }
                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }
    

    
    getButtons = function () {
        var buttons = [];
        var viewButton = <div  key={'ACTION_CELL_KEY_VIEW_BUTTON_' + this.props.row_id} >&nbsp;
    <Button 
        title={lang.getTitle('View')}
        className={'btn-sm'} 
        id={this.FORM_VIEW} 
        onClick={this.setView.bind(this)} 
        variant="info">
        <img
            title={lang.getTitle('View')}
            id={this.FORM_VIEW} 
            src={viewIcon} 
            onClick={this.setView.bind(this)} 
            />
    </Button>
</div>;
        var updateButton = <div key={'ACTION_CELL_KEY_UPDATE_BUTTON_' + this.props.row_id}>&nbsp;
    <Button 
        title={lang.getTitle('Update')}
        className={'btn-sm'} 
        id={this.FORM_UPDATE} 
        onClick={this.setView.bind(this)} 
        variant="warning">
        <img 
            title={lang.getTitle('Update')}
            id={this.FORM_UPDATE} 
            src={updateIcon} 
            onClick={this.setView.bind(this)} 
            />
    </Button>
</div>;
        var deleteButton = <div key={'ACTION_CELL_KEY_DELETE_BUTTON_' + this.props.row_id}>&nbsp;
    <Button 
        title={lang.getTitle('Delete')}
        className={'btn-sm'} 
        id={this.FORM_DELETE} 
        onClick={this.setView.bind(this)} 
        variant="danger">
        <img 
            title={lang.getTitle('Delete')}
            id={this.FORM_DELETE} 
            src={deleteIcon} 
            onClick={this.setView.bind(this)}  />
    </Button>
</div>;

        var munkalapButton = <div key={'ACTION_CELL_KEY_MUNKALAP_BUTTON_' + this.props.row_id}>&nbsp;
    <Button 
        title={lang.getTitle('Munkalap')}
        className={'btn-sm'} 
        onClick={() => {
                        this.munkalapokMegnyitasa(this.props.row_id)
                    }} 
        variant="secondary">
        <img 
            title={lang.getTitle('Munkalap')}
            src={munkalapokIkon} 

            />
    </Button>
</div>;

        var belsoRendelesekButton = <div key={'ACTION_CELL_KEY_BELSO_RENDELESEK_BUTTON_' + this.props.eredetiArajanlatId}>&nbsp;
            <Button 
                title={lang.getTitle('BelsoRendelesek')}
                className={'btn-sm'} 
                onClick={() => {
                        //this.belsoRendelesekMegnyitasa(this.props.eredetiArajanlatId, 'belsoRendelesek_arajanlat_id')
                        this.belsoRendelesekMegnyitasa(this.props.row_id, 'belsoRendelesek_arajanlat_id')
                    }} 
                variant="secondary">
                <img 
                    title={lang.getTitle('BelsoRendelesek')}
                    src={belsoRendelesekIkon} 
        
                    />
            </Button>
        </div>;


        var aktualizalButton = <div key={'ACTION_CELL_KEY_AKTUALIZAL_BUTTON_' + this.props.eredetiArajanlatId}>&nbsp;
    <Button 
        title={lang.getTitle('Aktualizal')}
        className={'btn-sm'} 
        onClick={() => {
                        this.aktualizalas(this.props.row_id)
                    }} 
        variant="secondary">
        <img 
            title={lang.getTitle('Aktualizál')}
            src={aktualizaltBlack} 
            />
    </Button>
</div>;


        var isViewForm = this.props.isViewForm;
        if (isViewForm) {
            buttons = [viewButton];
        } else {
            buttons = [viewButton, updateButton, deleteButton ];
        }
        if(this.props.arajanlat_statusz == 2 || this.props.arajanlat_statusz == 3|| this.props.arajanlat_statusz == 4){
            buttons.push(munkalapButton);
            buttons.push(belsoRendelesekButton);
        }
        
        
        return buttons;
    }

    render() {
        var isViewForm = this.props.isViewForm;
        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';
        var buttons = this.getButtons();
        return <div style={{display: 'flex'}}>{buttons}</div>
    }
}

export default ActionCellAjanlat;

