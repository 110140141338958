import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import CellRendererBoolean from './../../components/CellRendererBoolean';
import PagingToolbar from './../../components/PagingToolbar';
import {Button} from 'react-bootstrap';
import InsertForm from './FelhasznalokInsertForm.js';
import UpdateForm from './FelhasznalokUpdateForm.js';
import ViewForm from './FelhasznalokViewForm.js';
import Dialog from './../../components/Dialog.js';
import ActionCell from './../../components/ActionCell';
import GlobalSearch from './../../components/GlobalSearch';


import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');

class FelhasznalokTable extends Component {

    constructor(props) {
        super(props);
        this.NONE = "NONE";
        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";
        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";
        var columnNames = ['action_cell', 'users_id', 'users_name', 'users_display_name', 'users_email', 'users_password', 'users_user_type', 'users_telefon', 'users_irsz', 'irszam_nev', 'users_cim', 'users_megjegyzes', 'users_aktiv','users_szuneteltetve', 'users_belepes_datum', 'users_szul_hely', 'users_szul_ido', 'users_anyja_neve', 'users_taj_szam', 'users_adoazonosito', 'users_kulsos_munkavallalo', 'users_projekt_vezeto', 'users_tervezo','users_munkalaphoz_rendelheto'];
        this.columnStateHandler = ajax.columnStateHandler(columnNames, "Felhasznalok", "users");
        this.state = {
            columnOrder: columnNames,
            theme: this.props.theme,
            openedForm: this.NONE, // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRow: null,
            sortInfo: null,
            groupBy: false,
            filterValue: null,
            additionalParams: [],
            requestProps: null,
            skip: 0,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 50,
                showingRowFrom: 0,
                showingRowTo: 50,
                totalRowCount: 0,
                skip: 0
            },
            dialogType: this.NONE,
            dialogText: ''
        };
        this.renderPaginationToolbar = null;
        this.ref = React.createRef();

        this.globalSearch = {"name": "globalSearch", "operator": "contains", "type": "string", "value": ''};
    }
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.openedForm != nextState.openedForm) {
            return true;
        }
        if (nextProps.theme != nextState.theme) {
            return true;
        }
        return false; // equals() is your implementation
    }

    setView = function (e) {

        var formName = e.target.id;
        var rowSelectionNeed = false;
        var hasSelectedRow = true;
        if (formName == this.FORM_DELETE || formName == this.FORM_UPDATE || formName == this.FORM_VIEW) {
            rowSelectionNeed = true;
        }
        if (this.state.selectedRow == null || this.state.selectedRow == '') {
            hasSelectedRow = false;
        }
        if (rowSelectionNeed == true && hasSelectedRow == false) {
            this.setState({openedForm: this.DIALOG_ALERT, dialogText: lang.getTitle("ROW_SELECTION_NEED")});
        } else {
            var dialogText = "";
            if (formName == this.FORM_DELETE) {
                dialogText = lang.getTitle("DELETE_CONFIRMATION");
            }
            this.setState({openedForm: formName, dialogText: dialogText});
        }



    }

    closeForm = function () {
        this.setState({openedForm: this.NONE});
    }

    customPaging = function (pagingProps) {
        return <PagingToolbar blackTheme={this.props.theme} tableReload={this.reload.bind(this)} paging={this.state.paging} />
    }
    reload = function (skip) {
        this.state.paging.skip = skip;
        this.forceUpdate();
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    addForeignKeyFilter = function (filterValues) {

        var additionalParams = this.props.additionalParams;
        if (typeof additionalParams == 'undefined') {
            return filterValues;
        }

        if (additionalParams == null) {
            return filterValues;
        }

        if (additionalParams.length == null) {
            return filterValues;
        }

        for (var ap = 0; ap < additionalParams.length; ap++) {

            var additionalParam = additionalParams[ap];
            for (const property in additionalParam) {

                var filter = null;
                for (var f = 0; f < filterValues.length; f++) {
                    var currentFilter = filterValues[f];
                    if (currentFilter.name == property) {
                        filter = currentFilter;
                    }
                }

                if (filter != null) {
                    filter.operator = 'eq';
                    filter.type = 'number';
                    filter.value = additionalParam[property];
                } else {
                    filter = {
                        name: property,
                        operator: 'eq',
                        type: 'number',
                        value: additionalParam[property]
                    };
                    filterValues.push(filter);
                }
            }

        }
        return filterValues;
    }

    setGlobalSearch = function (val) {
        this.globalSearch.value = val;
        this.forceUpdate();
    }

    globalSearchHandler = function (filterValue) {

        var hasExistingFilter = this.hasExitsingFilter(filterValue, this.globalSearch);
        if (hasExistingFilter == false) {
            filterValue.push(this.globalSearch);
        }
        return filterValue;
    }

    hasExitsingFilter = function (currentFilters, newFilter) {

        for (var i = 0; i < currentFilters.length; i++) {
            var currentFilter = currentFilters[i];
            if (currentFilter.name == newFilter.name) {
                currentFilter.value = newFilter.value;
                return true;
            }
        }
        return false;
    }

    dataLoader = function ( { skip, limit, sortInfo, groupBy, filterValue }){
        this.state.paging.skip = (typeof this.state.paging.skip == 'undefined') ? 0 : this.state.paging.skip;
        var state = this.state;
        filterValue = this.addForeignKeyFilter(filterValue);
        filterValue = this.globalSearchHandler(filterValue);
        this.state.requestProps = {skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};
        try {
            const url = ajax.getUrlBase() + "modules/Felhasznalok/usersTableSelect.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        
                        var totalCount = response.count;
                        var data = response.data;
                        state.paging.totalRowCount = totalCount;
                        var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                        state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                        state.paging.showingRowFrom = skip;
                        state.paging.showingRowTo = skip + state.pageRowCount;
                        return Promise.resolve({data, count: parseInt(totalCount), skip: state.paging.skip});
                    }).catch(function (jsonError) {
                        
                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                state.defaultUpdateBlocked = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
    }
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Felhasznalok/usersFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({users_id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({openedForm: 'HIDDEN', selectedRow: null});
                    }).catch(function (jsonError) {
                        
                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            
            console.error("Fetch exception:", String(ex));
        }
    }

    onSelectionChange = function (selection) {
        this.setState({selectedRow: selection.selected});
    }

    onRowClick = function (rowProps, event) {

        if (typeof rowProps == 'undefined') {
            return false;
        }

        if (rowProps == null) {
            return false;
        }

        if (typeof rowProps.data == 'undefined') {
            return false;
        }

        if (rowProps.data == null) {
            return false;
        }

        if (typeof rowProps.data.users_id == 'undefined') {
            return false;
        }

        if (rowProps.data.users_id == null) {
            return false;
        }
        this.setState({selectedRow: rowProps.data.users_id});
    }

    hasSelectedRow = function () {
        if (typeof this.state.selectedRow == 'undefined') {
            return false;
        }
        if (this.state.selectedRow == null) {
            return false;
        }


        if (this.state.selectedRow == -1) {
            return false;
        }
        return true;
    }

    isViewForm = function () {

        if (typeof this.props.formType == 'undefined') {
            return false;
        }
        if (this.props.formType == null) {
            return false;
        }
        if (this.props.formType == '') {
            return false;
        }
        if (this.props.formType == this.FORM_VIEW) {
            return true;
        }
        return false;
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();
    }

    render() {


        const felhasznaloTipusok = {
            '0': 'Felhasználó',
            '2': 'Adminisztrátor'
        }


        var isViewForm = this.isViewForm();
        var columns = [
            {
                name: 'action_cell', header: '', minWidth: 160,
                render: ({ value, data }) => {
                    return <ActionCell 
                        stateReference ={this.state}
                        row_id={data.users_id}   
                        setView={this.setView.bind(this)}
                        isViewForm ={isViewForm}
                        theme={this.props.theme}
                        />
                },
                sortable: false
            },
            {name: 'users_id', sortable: true, header: lang.getTitle('users_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true}
            , {name: 'users_name', sortable: true, header: lang.getTitle('users_name'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_name', 150), defaultVisible: true}
            , {name: 'users_display_name', sortable: true, header: lang.getTitle('users_display_name'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_display_name', 150), defaultVisible: true}
            , {name: 'users_email', sortable: true, header: lang.getTitle('users_email'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_email', 150), defaultVisible: true}
            , {name: 'users_password', sortable: true, header: lang.getTitle('users_password'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_password', 150), defaultVisible: true}
            , {name: 'users_user_type', sortable: true,
                header: lang.getTitle('users_user_type'),
                defaultWidth: this.columnStateHandler.getColumnWidth('users_user_type', 150),
                defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ["0", "2"].map(c => {
                        return {id: c, label: felhasznaloTipusok[c]}
                    })
                },
                render: ({ value }) => {
                    return <div>{felhasznaloTipusok[value]}</div>
                }
            }
            , {name: 'users_telefon', sortable: true, header: lang.getTitle('users_telefon'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_telefon', 150), defaultVisible: true}
            , {name: 'users_irsz', sortable: true, header: lang.getTitle('users_irsz'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_irsz', 150),  defaultVisible: true}
            , {name: 'irszam_nev', sortable: true, header: lang.getTitle('irszam_nev'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('irszam_nev', 150), defaultVisible: true}
            , {name: 'users_cim', sortable: true, header: lang.getTitle('users_cim'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_cim', 150), defaultVisible: true}
            , {name: 'users_megjegyzes', sortable: true, header: lang.getTitle('users_megjegyzes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_megjegyzes', 150), defaultVisible: true}
            , {name: 'users_aktiv', sortable: true, header: lang.getTitle('users_aktiv'), defaultWidth: this.columnStateHandler.getColumnWidth('users_aktiv', 150), defaultVisible: true,
                filterEditor: BoolFilter,
                render: ({ value, cellProps }) => {
                    return <CellRendererBoolean selected={value} theme={this.props.theme}/>
                }
            }
            , {name: 'users_szuneteltetve', sortable: true, header: lang.getTitle('users_szuneteltetve'), defaultWidth: this.columnStateHandler.getColumnWidth('users_szuneteltetve', 150), defaultVisible: true,
                filterEditor: BoolFilter,
                render: ({ value, cellProps }) => {
                    return <CellRendererBoolean selected={value} theme={this.props.theme}/>
                }
            }
            , {name: 'users_belepes_datum', sortable: true, header: lang.getTitle('users_belepes_datum'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_belepes_datum', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {name: 'users_szul_hely', sortable: true, header: lang.getTitle('users_szul_hely'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_szul_hely', 150), defaultVisible: true}
            , {name: 'users_szul_ido', sortable: true, header: lang.getTitle('users_szul_ido'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_szul_ido', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {name: 'users_anyja_neve', sortable: true, header: lang.getTitle('users_anyja_neve'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_anyja_neve', 150), defaultVisible: true}
            , {name: 'users_taj_szam', sortable: true, header: lang.getTitle('users_taj_szam'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_taj_szam', 150), defaultVisible: true}
            , {name: 'users_adoazonosito', sortable: true, header: lang.getTitle('users_adoazonosito'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('users_adoazonosito', 150), defaultVisible: true}
            , {name: 'users_kulsos_munkavallalo', sortable: true, header: lang.getTitle('users_kulsos_munkavallalo'), defaultWidth: this.columnStateHandler.getColumnWidth('users_kulsos_munkavallalo', 150), defaultVisible: true,
                filterEditor: BoolFilter,
                render: ({ value, cellProps }) => {
                    return <CellRendererBoolean selected={value} theme={this.props.theme}/>
                }}
            , {name: 'users_projekt_vezeto', sortable: true, header: lang.getTitle('users_projekt_vezeto'), defaultWidth: this.columnStateHandler.getColumnWidth('users_projekt_vezeto', 150), defaultVisible: true,
                filterEditor: BoolFilter,
                render: ({ value, cellProps }) => {
                    return <CellRendererBoolean selected={value} theme={this.props.theme}/>
                }}
            , {name: 'users_tervezo', sortable: true, header: lang.getTitle('users_tervezo'), defaultWidth: this.columnStateHandler.getColumnWidth('users_tervezo', 150), defaultVisible: true,
                filterEditor: BoolFilter,
                render: ({ value, cellProps }) => {
                    return <CellRendererBoolean selected={value} theme={this.props.theme}/>
                }}
            , {name: 'users_munkalaphoz_rendelheto', sortable: true, header: lang.getTitle('users_munkalaphoz_rendelheto'), defaultWidth: this.columnStateHandler.getColumnWidth('users_munkalaphoz_rendelheto', 150), defaultVisible: true,
                filterEditor: BoolFilter,
                render: ({ value, cellProps }) => {
                    return <CellRendererBoolean selected={value} theme={this.props.theme}/>
                }}

        ];
        const filters = [
            {name: 'users_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'users_name', operator: 'contains', type: 'string', value: ''}
            , {name: 'users_display_name', operator: 'contains', type: 'string', value: ''}
            , {name: 'users_email', operator: 'contains', type: 'string', value: ''}
            , {name: 'users_password', operator: 'contains', type: 'string', value: ''}
            , {name: 'users_user_type', operator: 'inlist', type: 'select', value: null}
            , {name: 'users_telefon', operator: 'contains', type: 'string', value: ''}
            , {name: 'users_irsz', operator: 'contains', type: 'string', value: ''}
            , {name: 'irszam_nev', operator: 'contains', type: 'string', value: ''}
            , {name: 'users_cim', operator: 'contains', type: 'string', value: ''}
            , {name: 'users_megjegyzes', operator: 'contains', type: 'string', value: ''}
            , {name: 'users_aktiv', operator: 'eq', type: 'boolean', value: null}
            , {name: 'users_szuneteltetve', operator: 'eq', type: 'boolean', value: false}
            , {name: 'users_belepes_datum', operator: 'eq', type: 'date', value: ''}
            , {name: 'users_szul_hely', operator: 'contains', type: 'string', value: ''}
            , {name: 'users_szul_ido', operator: 'eq', type: 'date', value: ''}
            , {name: 'users_anyja_neve', operator: 'contains', type: 'string', value: ''}
            , {name: 'users_taj_szam', operator: 'contains', type: 'string', value: ''}
            , {name: 'users_adoazonosito', operator: 'contains', type: 'string', value: ''}
            , {name: 'users_kulsos_munkavallalo', operator: 'eq', type: 'boolean', value: null}
            , {name: 'users_projekt_vezeto', operator: 'eq', type: 'boolean', value: null}
            , {name: 'users_tervezo', operator: 'eq', type: 'boolean', value: null}
            , {name: 'users_munkalaphoz_rendelheto', operator: 'eq', type: 'boolean', value: null}

        ]

        columns = this.columnStateHandler.setColumnsVisibility(columns);
        var openedForm = "";
        if (this.state.openedForm == this.FORM_VIEW) {
            openedForm = <ViewForm theme={this.props.theme} key={'users_KEY_VIEW'} formType={this.FORM_VIEW} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_INSERT) {
            openedForm = <InsertForm  theme={this.props.theme} key={'users_KEY_INSERT'} formType={this.FORM_INSERT} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_UPDATE) {

            var hasSelectedRow = this.hasSelectedRow();
            if (hasSelectedRow == true) {
                openedForm = <UpdateForm theme={this.props.theme} key={'users_KEY_UPDATE'} formType={this.FORM_UPDATE} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
            } else {
                console.log("Nincs kiválasztott sor");
                // Alert window
            }
        }

        if (this.state.openedForm == this.FORM_DELETE) {
            openedForm = <Dialog 
                type={this.DIALOG_CONFIRMATION} 
                dialogText={this.state.dialogText} 
                callback={this.delete.bind(this)}
                cancel={() => this.setView({target: {id: this.NONE}})}
            
                theme={this.props.theme}
                />;
        }
        if (this.state.openedForm == this.DIALOG_ALERT) {
            openedForm = <Dialog 
                type={this.DIALOG_ALERT} 
                dialogText={this.state.dialogText} 
                callback={() => this.setView({target: {id: this.NONE}})}
                theme={this.props.theme}
                />;
        }


        if (this.renderPaginationToolbar == null) {
            this.renderPaginationToolbar = this.customPaging.bind(this)
        }

        var buttons = [];
        var viewButton = <div  key={'users_KEY_VIEW_BUTTON'} >&nbsp;<Button className={'btn-sm'} id={this.FORM_VIEW} onClick={this.setView.bind(this)} variant="info">{lang.getTitle('View')}</Button></div>;
        var insertButton = <div key={'users_KEY_INSERT_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_INSERT} onClick={this.setView.bind(this)} variant="success">{lang.getTitle('Insert')}</Button></div>;
        var updateButton = <div key={'users_KEY_UPDATE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_UPDATE} onClick={this.setView.bind(this)} variant="warning">{lang.getTitle('Update')}</Button></div>;
        var deleteButton = <div key={'users_KEY_DELETE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_DELETE} onClick={this.setView.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button></div>;
        var isViewForm = this.isViewForm();
        if (isViewForm) {
            buttons = [viewButton];
        } else {
            buttons = [viewButton, insertButton, updateButton, deleteButton];
        }
        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';
        return (
                <div id="FelhasznalokTable">
                    <div style={{display: "flex"}}>
                        {buttons}
                        <GlobalSearch 
                            theme={this.props.theme} 
                            globalSearch={this.setGlobalSearch.bind(this)} />
                
                    </div>
                    <ReactDataGrid 
                        idProperty="users_id" 
                        i18n={ajax.getI18n()}
                        columns={this.columnStateHandler.myColumnsReorder(columns)}
                        dataSource={this.dataLoader.bind(this)}
                        defaultFilterValue={filters}
                        enableSelection={true}
                
                        onRowClick={this.onRowClick.bind(this)}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                        theme={theme}
                        style={{height: window.innerHeight - 180, marginTop: 10}}    
                
                        defaultLimit={50}
                        pagination={true}
                        renderPaginationToolbar={this.renderPaginationToolbar}
                        skip={this.state.skip}
                        limit={this.state.paging.pageRowCount}
                
                        onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
                        onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
                        onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                        
                        />
                    {openedForm}            
                </div>
                );
    }
}

export default FelhasznalokTable;
