import React, { Component } from 'react';

import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

class Felhasznalok extends Component {

    constructor(props) {
        super(props);
        this.state = {
            theme: this.props.theme,
            data: []
        }
        this.dataLoader();
    }

    shouldComponentUpdate() {
        return true;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    dataLoader = function () {
        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Jogosultsagok/selectFelhasznalok.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({data: response.data});
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    getUser = function (user) {
        return <div  
            key={"user_key_" + user.user_id} 
            style={{display: 'flex', margin: 2, padding: 3}}>
            <div onClick={()=>{this.props.felhasznaloBesorolasa(user.user_id)}} style={{cursor: 'pointer'}}>[+]</div>
            <div>&nbsp;{user.user_name}</div>
        </div>;
    }

    getUsers = function (data) {
        var users = [];
        for (var i = 0; i < data.length; i++) {
            var user = data[i];
            var userComponent = this.getUser(user);
            users.push(userComponent);
        }
        return users;
    }

    render() {
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var headerTextColor = (this.props.theme == "true") ? "wheat" : "grey";
        var users = this.getUsers(this.state.data);
        return <lu>                
                    {users}
                </lu>
               
    }
}

export default Felhasznalok;
