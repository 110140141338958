import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import DateTimeInsertField from './../../components/DateTimeInsertField';
import TextInsertField from './../../components/TextInsertField';
import DateInsertField from './../../components/DateInsertField';
import TextFormatter from './../../components/TextFormatter';
import MaskedInsertField from './../../components/MaskedInsertField';

class FestekRendelesekInsert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            festekRendelesek_datum: ''
            , festekRendelesek_email_cim: ''
            , festekRendelesek_email_forward: ''
            , festekRendelesek_cikkek: ''
            , festekRendelesek_elkuldve: ''

        }


        this.references = [];
        this.references['festekRendelesek_datum'] = React.createRef();
        this.references['festekRendelesek_email_cim'] = React.createRef();
        this.references['festekRendelesek_email_forward'] = React.createRef();
        this.references['festekRendelesek_cikkek'] = React.createRef();
        this.references['festekRendelesek_elkuldve'] = React.createRef();


        this.validators = [];
        this.validators['festekRendelesek_datum'] = {fieldName: 'festekRendelesek_datum', dataType: 'DATETIME', value: this.state.festekRendelesek_datum, mandatory: false, min: null, max: null};
        this.validators['festekRendelesek_email_cim'] = {fieldName: 'festekRendelesek_email_cim', dataType: 'VARCHAR', value: this.state.festekRendelesek_email_cim, mandatory: true, min: null, max: null};
        this.validators['festekRendelesek_email_forward'] = {fieldName: 'festekRendelesek_email_forward', dataType: 'VARCHAR', value: this.state.festekRendelesek_email_forward, mandatory: false, min: null, max: null};
        this.validators['festekRendelesek_cikkek'] = {fieldName: 'festekRendelesek_cikkek', dataType: 'VARCHAR', value: this.state.festekRendelesek_cikkek, mandatory: true, min: null, max: null};
        this.validators['festekRendelesek_elkuldve'] = {fieldName: 'festekRendelesek_elkuldve', dataType: 'DATETIME', value: this.state.festekRendelesek_elkuldve, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

    }

    componentDidMount() {
        this.reset();

    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.load();
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/FestekRendelesek/getLastEmail.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            if (self.props.copyData != null) {
                                
                                var data = self.props.copyData;
                                self.setState({
                                      festekRendelesek_datum: data.festekRendelesek_datum 
                                    , festekRendelesek_email_cim: data.festekRendelesek_email_cim
                                    , festekRendelesek_email_forward:data.festekRendelesek_email_forward
                                    , festekRendelesek_cikkek: data.festekRendelesek_cikkek_formated
                                    , festekRendelesek_elkuldve: data.festekRendelesek_elkuldve
                                });
                            } else {
                                self.setState({
                                    festekRendelesek_datum: ''
                                    , festekRendelesek_email_cim: response.data['festekRendelesek_email_cim']
                                    , festekRendelesek_email_forward: response.data['festekRendelesek_email_forward']
                                    , festekRendelesek_cikkek: ''
                                    , festekRendelesek_elkuldve: ''
                                });
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    getForm = function () {
        return <Container>
            <Row>
        
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('festekRendelesek_email_cim')}</Col>
                <Col style={{textAlign: 'right'}}>
        
                <MaskedInsertField 
                    id={'festekRendelesek_email_cim'} 
                    regexPattern={new RegExp(/^.*@.*\.[a-zA-z].*$/)}
                    placeHolder ={'a@b.hu'}
                    value={this.state.festekRendelesek_email_cim}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['festekRendelesek_email_cim']}
                    editable={true}
                    theme={this.props.theme}
                    />        
        
                </Col>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('festekRendelesek_email_forward')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'festekRendelesek_email_forward'} 
                    value={this.state.festekRendelesek_email_forward}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['festekRendelesek_email_forward']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('festekRendelesek_cikkek')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextFormatter 
                    sablonKivalasztoval={false}
                    id={'festekRendelesek_cikkek'} 
                    kategoria_konstans={'HATARIDO'}
                    value={this.state.festekRendelesek_cikkek}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['festekRendelesek_cikkek']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitleBold('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitleBold(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                stateClone[property] = additionalParam[property];
            }
        }
        return stateClone;
    }

    insert = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        var params = this.concatAdditionalParams();
        params['method'] = method;

        try {
            const url = ajax.getUrlBase() + "modules/FestekRendelesek/festekRendelesekFormInsert.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(params)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'saveAndSendingEmail') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{maxHeight: 600, minWidth: 1100}}>
            <div className={'popupWrapper' + className} id="FestekRendelesekInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('FestekRendelesekInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button key="rendeles_safe" id={"save"} className={'btn-sm'}  onClick={()=>this.insert({target:{id:'save'}})} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;&nbsp;      
                        <Button key="rendeles_safe_and_send" id={"saveAndSendingEmail"} className={'btn-sm'}   onClick={()=>this.insert({target:{id:'saveAndSendingEmail'}})} variant="info" style={{marginTop: '5px'}}>{lang.getTitle('saveAndSendingEmail')}</Button>      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default FestekRendelesekInsert;

