import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';


class ArengedmenyInsertField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: (this.props.value=='')?0:this.props.value
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }

    onChange = function (e) {
        
        this.props.setBack(e.target.id, e.target.value);
        this.setState({value: e.target.value});
    }

    render() {
        var className = (this.props.theme=="true") ? "Dark" : "Light";
        var kedvezmenyTipus="";
        if(this.state.value==null || this.state.value==''){
            kedvezmenyTipus="?";
        }
       
        if((this.state.value!=null && this.state.value!='') && this.state.value>=0){
            kedvezmenyTipus="%";
        }
        if((this.state.value!=null && this.state.value!='') && this.state.value>100){
            kedvezmenyTipus="Ft";
        }
        
        return  <div style={{display:'flex',justifyContent: 'right'}}>
                <div style={{color:'darkcyan',fontSize:20,fontWeight:'bold'}}>
                ({kedvezmenyTipus})
                </div>
                <input 
                onChange={this.onChange.bind(this)} 
                type={'number'} 
                id={this.props.id} 
                value={this.state.value} 
                ref={this.props.reference}
                readOnly={this.props.editable == false}
                className ={"component" + className}
                />
                
                </div>;
    }
}

export default ArengedmenyInsertField;

