import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';
import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import MunkabeosztasIrszField from './../../componentsSpecial/MunkabeosztasIrszField';
import MunkabeosztasTelepulesCombo from './../../componentsSpecial/MunkabeosztasTelepulesCombo';
import NumberInsertField from './../../components/NumberInsertField';
import TextInsertField from './../../components/TextInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';


class MTOMunkaBeosztasCimekInsertMTO extends Component {

    constructor(props) {
        super(props);
        this.state = {
            munkaBeosztasCimek_ugyfel_id: ''
            , munkaBeosztasCimek_ugyfel_nev: ''
            , munkaBeosztasCimek_irsz: ''
            , munkaBeosztasCimek_telepules: ''
            , munkaBeosztasCimek_telepulesNev: ''
            , munkaBeosztasCimek_cim: ''
            , munkaBeosztasCimek_megjegyzes: ''

        }

        this.references = [];
        this.references['munkaBeosztasCimek_ugyfel_id'] = React.createRef();
        this.references['munkaBeosztasCimek_irsz'] = React.createRef();
        this.references['munkaBeosztasCimek_telepules'] = React.createRef();
        this.references['munkaBeosztasCimek_cim'] = React.createRef();
        this.references['munkaBeosztasCimek_megjegyzes'] = React.createRef();

    }
    componentDidMount() {
        this.reset();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        //this.setFocusedComponent();
        this.setState({
            munkaBeosztasCimek_ugyfel_id: ''
            , munkaBeosztasCimek_ugyfel_nev: ''
            , munkaBeosztasCimek_irsz: ''
            , munkaBeosztasCimek_telepules: ''
            , munkaBeosztasCimek_telepulesNev: ''
            , munkaBeosztasCimek_cim: ''
            , munkaBeosztasCimek_megjegyzes: ''
        });
    }

    loadUgyfelCim = function (ugyfel_id) {
        try {
            const url = ajax.getUrlBase() + "modules/UgyfelCimekKarbantartas/ugyfelCimSuggestion.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({ugyfel_id: ugyfel_id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        var data = response.data;
                        this.setState({
                              munkaBeosztasCimek_irsz: data.ugyfelCimek_irsz
                            , munkaBeosztasCimek_telepules: data.ugyfelCimek_telepules
                            , munkaBeosztasCimek_telepulesNev: data.munkaBeosztasCimek_telepulesNev
                            , munkaBeosztasCimek_cim: data.ugyfelCimek_cim
                        });


                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    setBack = function (id, value) {
        if (id == "munkaBeosztasCimek_ugyfel_id") {
            if (isNaN(value) == false) {
                this.loadUgyfelCim(value);
            }
            var ugyfelNev = this.references['munkaBeosztasCimek_ugyfel_id'].current.defaultValue;
            this.setState({[id]: value, munkaBeosztasCimek_ugyfel_nev: ugyfelNev});

        } else {
            this.setState({[id]: value});

        }

    }

    getForm = function () {
        return <Container  theme={this.props.theme}>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztasCimek_ugyfel_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <ComboBoxInsertField 
                    id={'munkaBeosztasCimek_ugyfel_id'} 
                    value={this.state.munkaBeosztasCimek_ugyfel_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/MTOMunkaBeosztasCimek/ugyfel_idFormComboInsertData.php'}
                    reference={this.references['munkaBeosztasCimek_ugyfel_id']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztasCimek_irsz')}</Col>
                <Col style={{textAlign: 'right'}}>
                <MunkabeosztasIrszField 
                    id={'munkaBeosztasCimek_irsz'} 
                    value={this.state.munkaBeosztasCimek_irsz}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/MTOMunkaBeosztasCimek/irsz_FromComboInsertData.php'}
                    reference={this.references['munkaBeosztasCimek_irsz']}
                    editable={true}
                    theme={this.props.theme}
                    telepulesAttrName={"munkaBeosztasCimek_telepules"}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztasCimek_telepules')}</Col>
                <Col style={{textAlign: 'right'}}>
                <MunkabeosztasTelepulesCombo 
                    id={'munkaBeosztasCimek_telepules'} 
                    value={this.state.munkaBeosztasCimek_telepules}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/MTOMunkaBeosztasCimek/telepules_FromComboInsertData.php'}
                    reference={this.references['munkaBeosztasCimek_telepules']}
                    editable={true}
                    theme={this.props.theme}
                    irsz={this.state.munkaBeosztasCimek_irsz}
        
                    />
        
        
        
                </Col>
        
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztasCimek_cim')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'munkaBeosztasCimek_cim'} 
                    value={this.state.munkaBeosztasCimek_cim}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['munkaBeosztasCimek_cim']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('munkaBeosztasCimek_megjegyzes')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextAreaInsertField 
                    id={'munkaBeosztasCimek_megjegyzes'} 
                    value={this.state.munkaBeosztasCimek_megjegyzes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['munkaBeosztasCimek_megjegyzes']}
                    editable={true}   
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();
    }

    addRow = function () {
        var ugyfelNev = this.references['munkaBeosztasCimek_ugyfel_id'].current.defaultValue;
        this.state['munkaBeosztasCimek_ugyfel_nev'] = ugyfelNev;
        this.props.addRow(this.state, false);
        this.reset();
    }

    addRowAndClose = function () {
        var ugyfelNev = this.references['munkaBeosztasCimek_ugyfel_id'].current.defaultValue;
        this.state['munkaBeosztasCimek_ugyfel_nev'] = ugyfelNev;
        this.props.addRow(this.state, true);
        this.reset();
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{maxHeight: 500}}>
            <div className={'popupWrapper' + className} id="MTOMunkaBeosztasCimekInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('MTOMunkaBeosztasCimekInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Button className={'btn-sm'} onClick={this.addRowAndClose.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                    <Button className={'btn-sm'} onClick={this.addRow.bind(this)} variant="info" style={{marginTop: '5px'}}>{lang.getTitle('new')}</Button>      
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default MTOMunkaBeosztasCimekInsertMTO;


