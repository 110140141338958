import React, { Component } from 'react';
import * as ajax from './../ajax.js';
import * as lang from './../lang.js';
import {Button} from 'react-bootstrap';


class FormatedNumberCell extends Component {

    formatter = function (number) {

        if (number == null) {
            return '';
        }
        if (number == '') {
            return '';
        }
        number = number + "";
        if (number.slice(-1) == ',') {
            return number;
        }

        var negativeSign = "";
        if (number.length > 0 && number.charAt(0) == '-') {
            negativeSign = "-";
            number = number.substring(1);
        }



        var values = number.split('.');
        var integers = values[0];
        var decimals = (values.length > 1) ? values[1] : '';

        var formatted = "";
        var thousandCounter = 0;
        for (var i = integers.length - 1; i > -1; i--) {
            thousandCounter++;
            formatted = integers.charAt(i) + formatted;

            if (i > 0 && thousandCounter > 0 && thousandCounter % 3 == 0) {

                formatted = '.' + formatted;
            }

        }

        return negativeSign + formatted + ((decimals.length > 0) ? "," + decimals : '');
    }

    render() {
        var value = this.formatter(this.props.value);
        return <div style={{textAlign: 'right'}}>{value}</div>;
    }
}

export default FormatedNumberCell;