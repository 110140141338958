import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';


class TextAreaInsertField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: (this.props.value==null)?'':this.props.value
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: (this.props.value==null)?'':this.props.value});
        }
    }
    onChange = function (e) {
        this.props.setBack(e.target.id, e.target.value);
        this.setState({value: e.target.value});
    }

    render() {
        var style = {textAlign:'left'};
        if (typeof this.props.style != "undefined" && this.props.style != null) {
            style = Object.assign({},this.props.style);
            
            style['textAlign'] = 'left';
        }
        

        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return  <div>
            <textarea 
                onChange={this.onChange.bind(this)}  
                id={this.props.id} 
                value={this.state.value} 
                ref={this.props.reference}
                readOnly={this.props.editable == false}
                className ={"component" + className}
                style={style}
                />
        </div>;
    }
}

export default TextAreaInsertField;

