import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';


class TextInsertField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: (this.props.value==null)?'':this.props.value
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: (this.props.value==null)?'':this.props.value});
        }
    }

    onChange = function (e) {
        this.props.setBack(e.target.id, e.target.value);
        this.setState({value: e.target.value});
    }

    render() {

        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var style = {textAlign: 'left'};
        if (typeof this.props.style != "undefined" && this.props.style != null) {
            style = this.props.style;
        }
        
        return  <div>
            <input 
                onChange={this.onChange.bind(this)} 
                type={'text'} 
                id={this.props.id} 
                value={this.state.value} 
                ref={this.props.reference}
                readOnly={this.props.editable == false}
                style={style}
                className ={"component" + className}
                />
        </div>;
    }
}

export default TextInsertField;
