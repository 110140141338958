import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';
import './../css/mobileTable.css';

import { Container, Row, Col, Button } from 'react-bootstrap';
import * as lang from './../lang.js';
import * as ajax from './../ajax.js';

import MobileTableCell from './../componentsMobile/MobileTableCell';

class MobileTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openedForm:null,
            data: this.props.data
        }
        
         this.filters = [];

        this.filterFormUgyfelId = null;
        this.filterFormUgyfelCimId = null;
        this.filterFormKapcsolattartoId = null;


    }

    componentDidUpdate(prevProps) {
     
        if (prevProps.refreshTime !== this.props.refreshTime) {
            
            this.setState({data: this.props.data});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    getMobileTableCells = function () {
        var data = this.state.data;
        var mobileTableCells = [];
        for (var i = 0; i < data.length; i++) {
            var row = data[i];
            mobileTableCells.push(<MobileTableCell 
                row={row} 
                theme={this.props.theme} 
                showForm={this.props.showForm.bind(this)}
                />
                    );
        }
        return mobileTableCells;
    }

    render() {
        var style = {maxWidth: 300, maxHeight: 200};
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        if (typeof this.props.style != 'undefined') {
            style = this.props.style;
        }
         
         
        var mobileTableCells = this.getMobileTableCells(this.props.data);


        return  <div  className={"mobileTable"}>{mobileTableCells}</div>;
                

    }
}

export default MobileTable;
