import React, { Component } from 'react';
import * as ajax from './../ajax.js';
import * as lang from './../lang.js';



class ToggleButton extends Component {

    constructor(props) {
        super(props);
    }

    onClick = function () {
        this.props.onlyMyItemsSetter(!this.props.value);
    }

    render() {

        var buttonColor = (this.props.value)?'#4CAF50':'#FFAF50';
        var color=(this.props.value)?'white':'black';

        var title = (this.props.value==true) ? this.props.onStateTitle : this.props.offStateTitle;
        return <div 
            style={{
                            display: 'flex',
                            backgroundColor: buttonColor,
                            border: 'none',
                            color: color,
                            padding: '2px 4px',
                            textAlign: 'center',
                            textDecoration: 'none',
                            display: 'inline-block',
                            fontSize: '14px',
                            margin: '2px 1px',
                            cursor: 'pointer',
                            WebkitTransitionDuration: '0.4s', /* Safari */
                            transitionDuration: '0.4s',
                            boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2), 0 1px 2px 0 rgba(0,0,0,0.19)'
                        }}
            onClick={this.onClick.bind(this)}
            >{title}</div>
    }
}

export default ToggleButton;

