import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';

class EgyebCimek extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    address = function (address) {

    }

    addresses = function (data) {
        var addresses = [];
        for (var i = 0; i < data.length; i++) {
            var address = data[i];
            addresses.push(<div 
                key={'cim_' + i}
                style={{
                                    whiteSpace: 'break-spaces',
                                    margin: '4px',
                                    padding: '2px',

                                    boxShadow: '1px 1px 4px black'

                                }}>{address.cim}</div>);
        }

        addresses.push(<div
            style={{
                display:'flex',
                textAlign:'center',
                justifyContent:'center',
                borderBottom: '1px solid white'
                
            }}
        >
        <div
                key={'close_button'}
                onMouseOver={()=>{this.setState({open:false})}}
                style={{
                            width: 0,
                            height: 0,
                            marginTop:10,
                            borderLeft: '20px solid transparent',
                            borderRight: '20px solid transparent',
                            borderBottom: '10px solid white',
                            textAlign: 'center'
                }}
                >
            </div>
        </div>);
        return addresses;
    }

    opener = function (data) {
        var content = null;
        var opener = "";

        opener = <div style={{
                        width: '100%',
                        border: 'solid 1px white',
                        maxHeight: 1,
                        textAlign: 'center',
                        justifyContent: 'center',
                        display: 'grid'
                         }} >
            <div 
                onMouseOver={() => {
                                this.setState({open: true});
                            }}
        
                style={{
                                borderRadius: "50%",
                                minWidth: 50,
                                maxWidth: 50,
                                marginTop: -16,
                                border: '4px solid white',
                                backgroundColor: '#3788d8',
                                align: 'center',
                                position: 'relative',
                                boxShadow: '2px 2px 2px #00000099',
                                zIndex: 1000
                            }}>
                +{data.length}
            </div>
        </div>

        if (this.state.open == true) {
            content = this.addresses(data);
        }

        return <div>
            <div
                style={{
                                marginTop: 15,
                                marginBottom: 20
                            }}   
        
                >{opener}</div>
            {content}
        </div>

    }

    render() {

        if (typeof this.props.data.egyebCimek == 'undefined') {
            return '';
        }

        if (this.props.data.egyebCimek == null) {
            return '';
        }

        if (this.props.data.egyebCimek.length == 0) {
            return '';
        }
        return this.opener(this.props.data.egyebCimek);
    }
}

export default EgyebCimek;
