import React, { Component } from 'react';
import * as lang from './../lang.js';
import {Button} from 'react-bootstrap';
import mapsIcon from './../img/black/public_black_24dp.svg';

class GoogleMapsTableButton extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (typeof this.props.value == 'undefined') {
            return '';
        }
        if (this.props.value == null) {
            return '';
        }
        if (this.props.value == '') {
            return '';
        }

        return <Button 
            title={lang.getTitle('Google Maps')}
            className={'btn-sm'} 
            variant="info"
            onClick={() => {
                          //  window.open('https://www.google.hu/maps/search/'+this.props.value, '_blank');
                        }}
            >
            <img
                title={lang.getTitle('View')}
                style={{maxHeight: 20, maxWidth: 20}}
                onClick={() => {
                                window.open('https://www.google.hu/maps/search/'+this.props.value, '_blank');
                            }}
                src={mapsIcon} 
        
                />
        </Button>
    }
}

export default GoogleMapsTableButton;

