import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TextInsertField from './../../components/TextInsertField';
import NumberInsertField from './../../components/NumberInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';
import CheckBoxInsertField from './../../components/CheckBoxInsertField';
import DateInsertField from './../../components/DateInsertField';
import FelhasznalokIrszField from './../../componentsSpecial/FelhasznalokIrszField';
import FelhasznalokTelepulesCombo from './../../componentsSpecial/FelhasznalokTelepulesCombo';
import MaskedInsertField from './../../components/MaskedInsertField';

class FelhasznalokUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {

            users_name: ''
            , users_display_name: ''
            , users_email: ''
            , users_password: ''
            , users_user_type: ''
            , users_telefon: ''
            , users_irsz: ''
            , users_telepules: ''
            , users_cim: ''
            , users_megjegyzes: ''
            , users_aktiv: ''
            , users_szuneteltetve: ''
            , users_belepes_datum: ''
            , users_szul_hely: ''
            , users_szul_ido: ''
            , users_anyja_neve: ''
            , users_taj_szam: ''
            , users_adoazonosito: ''
            , users_kulsos_munkavallalo: ''
            , users_projekt_vezeto: ''
            , users_tervezo: ''
            , users_munkalaphoz_rendelheto: ''

        }


        this.references = [];
        this.references['users_name'] = React.createRef();
        this.references['users_display_name'] = React.createRef();
        this.references['users_email'] = React.createRef();
        this.references['users_password'] = React.createRef();
        this.references['users_user_type'] = React.createRef();
        this.references['users_telefon'] = React.createRef();
        this.references['users_irsz'] = React.createRef();
        this.references['users_telepules'] = React.createRef();
        this.references['users_cim'] = React.createRef();
        this.references['users_megjegyzes'] = React.createRef();
        this.references['users_aktiv'] = React.createRef();
        this.references['users_szuneteltetve'] = React.createRef();
        this.references['users_belepes_datum'] = React.createRef();
        this.references['users_szul_hely'] = React.createRef();
        this.references['users_szul_ido'] = React.createRef();
        this.references['users_anyja_neve'] = React.createRef();
        this.references['users_taj_szam'] = React.createRef();
        this.references['users_adoazonosito'] = React.createRef();
        this.references['users_kulsos_munkavallalo'] = React.createRef();
        this.references['users_projekt_vezeto'] = React.createRef();
        this.references['users_tervezo'] = React.createRef();
        this.references['users_munkalaphoz_rendelheto'] = React.createRef();


        this.validators = [];
        this.validators['users_name'] = {fieldName: 'users_name', dataType: 'VARCHAR', value: this.state.users_name, mandatory: true, min: null, max: null};
        this.validators['users_display_name'] = {fieldName: 'users_display_name', dataType: 'VARCHAR', value: this.state.users_display_name, mandatory: true, min: null, max: null};
        this.validators['users_email'] = {fieldName: 'users_email', dataType: 'VARCHAR', value: this.state.users_email, mandatory: true, min: null, max: null};
        this.validators['users_password'] = {fieldName: 'users_password', dataType: 'VARCHAR', value: this.state.users_password, mandatory: true, min: null, max: null};
        this.validators['users_user_type'] = {fieldName: 'users_user_type', dataType: 'INT', value: this.state.users_user_type, mandatory: true, min: null, max: null};
        this.validators['users_telefon'] = {fieldName: 'users_telefon', dataType: 'VARCHAR', value: this.state.users_telefon, mandatory: true, min: null, max: null};
        this.validators['users_irsz'] = {fieldName: 'users_irsz', dataType: 'VARCHAR', value: this.state.users_irsz, mandatory: true, min: null, max: null};
        this.validators['users_telepules'] = {fieldName: 'users_telepules', dataType: 'INT', value: this.state.users_telepules, mandatory: true, min: null, max: null};
        this.validators['users_cim'] = {fieldName: 'users_cim', dataType: 'VARCHAR', value: this.state.users_cim, mandatory: true, min: null, max: null};
        this.validators['users_megjegyzes'] = {fieldName: 'users_megjegyzes', dataType: 'VARCHAR', value: this.state.users_megjegyzes, mandatory: false, min: null, max: null};
        this.validators['users_aktiv'] = {fieldName: 'users_aktiv', dataType: 'TINYINT', value: this.state.users_aktiv, mandatory: false, min: null, max: null};
        this.validators['users_szuneteltetve'] = {fieldName: 'users_szuneteltetve', dataType: 'TINYINT', value: this.state.users_szuneteltetve, mandatory: false, min: null, max: null};
        this.validators['users_belepes_datum'] = {fieldName: 'users_belepes_datum', dataType: 'DATE', value: this.state.users_belepes_datum, mandatory: true, min: null, max: null};
        this.validators['users_szul_hely'] = {fieldName: 'users_szul_hely', dataType: 'VARCHAR', value: this.state.users_szul_hely, mandatory: true, min: null, max: null};
        this.validators['users_szul_ido'] = {fieldName: 'users_szul_ido', dataType: 'DATE', value: this.state.users_szul_ido, mandatory: true, min: null, max: null};
        this.validators['users_anyja_neve'] = {fieldName: 'users_anyja_neve', dataType: 'VARCHAR', value: this.state.users_anyja_neve, mandatory: true, min: null, max: null};
        this.validators['users_taj_szam'] = {fieldName: 'users_taj_szam', dataType: 'VARCHAR', value: this.state.users_taj_szam, mandatory: true, min: 9, max: 9};
        this.validators['users_adoazonosito'] = {fieldName: 'users_adoazonosito', dataType: 'VARCHAR', value: this.state.users_adoazonosito, mandatory: true, min: 10, max: 10};
        this.validators['users_kulsos_munkavallalo'] = {fieldName: 'users_kulsos_munkavallalo', dataType: 'TINYINT', value: this.state.users_kulsos_munkavallalo, mandatory: false, min: null, max: null};
        this.validators['users_projekt_vezeto'] = {fieldName: 'users_projekt_vezeto', dataType: 'TINYINT', value: this.state.users_projekt_vezeto, mandatory: false, min: null, max: null};
        this.validators['users_tervezo'] = {fieldName: 'users_tervezo', dataType: 'TINYINT', value: this.state.users_tervezo, mandatory: false, min: null, max: null};
        this.validators['users_munkalaphoz_rendelheto'] = {fieldName: 'users_munkalaphoz_rendelheto', dataType: 'TINYINT', value: this.state.users_munkalaphoz_rendelheto, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

        this.load();

    }

    componentDidMount() {
        this.setFocusedComponent();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();
        }
    }

    reset = function () {

        this.setState({users_name: ''
            , users_display_name: ''
            , users_email: ''
            , users_password: ''
            , users_user_type: ''
            , users_telefon: ''
            , users_irsz: ''
            , users_telepules: ''
            , users_cim: ''
            , users_megjegyzes: ''
            , users_aktiv: ''
            , users_szuneteltetve: ''
            , users_belepes_datum: ''
            , users_szul_hely: ''
            , users_szul_ido: ''
            , users_anyja_neve: ''
            , users_taj_szam: ''
            , users_adoazonosito: ''
            , users_kulsos_munkavallalo: ''
            , users_projekt_vezeto: ''
            , users_tervezo: ''
            , users_munkalaphoz_rendelheto: ''
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getForm = function () {
        return <Container>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_name')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'users_name'} 
                    value={this.state.users_name}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_name']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
        
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_display_name')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'users_display_name'} 
                    value={this.state.users_display_name}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_display_name']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_email')}</Col>
                <Col style={{textAlign: 'right'}}>
                <MaskedInsertField 
                    id={'users_email'} 
                    regexPattern={new RegExp(/^.*@.*\.[a-zA-z].*$/)}
                    placeHolder ={'a@b.hu'}
                    value={this.state.users_email}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_email']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
        
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_password')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'users_password'} 
                    value={this.state.users_password}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_password']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_user_type')}</Col>
                <Col style={{textAlign: 'right'}}>
                <select 
                    id={'users_user_type'} 
                    value={this.state.users_user_type}  
                    onChange={(e) => {this.setBack('users_user_type', e.target.value)}}
                    ref={this.references['users_user_type']}
                    editable={false}
                    theme={this.props.theme}
                    >
                    <option value={''}></option>
                    <option value={'0'}>Felhasználó</option>
                    <option value={'2'}>Adminisztrátor</option>
                </select>
        
                </Col>
        
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_telefon')}</Col>
                <Col style={{textAlign: 'right'}}>
                <MaskedInsertField 
                    id={'users_telefon'} 
                    acceptedChars={"-+0123456789 ()"}
                    regexPattern={new RegExp(/^[+\s(0-9)\s0-9\\s-\s0-9]*$/)}
                    placeHolder ={'+360000000'}
                    value={this.state.users_telefon}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_telefon']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_irsz')}</Col>
                <Col style={{textAlign: 'right'}}>
                <FelhasznalokIrszField
                    id={'users_irsz'} 
                    value={this.state.users_irsz}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/Felhasznalok/irsz_FromComboInsertData.php'}
                    reference={this.references['users_irsz']}
                    editable={false}
                    theme={this.props.theme}
                    telepulesAttrName={"users_telepules"}
                    />
                </Col>
        
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_telepules')}</Col>
                <Col style={{textAlign: 'right'}}>
                <FelhasznalokTelepulesCombo 
                    id={'users_telepules'} 
                    value={this.state.users_telepules}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/Felhasznalok/telepules_FromComboInsertData.php'}
                    reference={this.references['users_telepules']}
                    editable={false}
                    theme={this.props.theme}
                    irsz={this.state.users_irsz}
        
        
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_cim')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'users_cim'} 
                    value={this.state.users_cim}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_cim']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
        
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_megjegyzes')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextAreaInsertField 
                    id={'users_megjegyzes'} 
                    value={this.state.users_megjegyzes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_megjegyzes']}
                    editable={false}   
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
        
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_adoazonosito')}</Col>
                <Col style={{textAlign: 'right'}}>
                <MaskedInsertField 
                    id={'users_adoazonosito'} 
                    acceptedChars={"0123456789"}
                    regexPattern={new RegExp(/^\d{10}$/)}
                    placeHolder ={'0123456789'}
                    value={this.state.users_adoazonosito}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_adoazonosito']}
                    editable={false}
                    theme={this.props.theme}
                    /> 
                </Col>
        
        
        
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_belepes_datum')}</Col>
                <Col style={{textAlign: 'right'}}>
                <DateInsertField 
                    id={'users_belepes_datum'} 
                    value={this.state.users_belepes_datum}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_belepes_datum']}
                    editable={false}  
                    theme={this.props.theme} 
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_szul_hely')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'users_szul_hely'} 
                    value={this.state.users_szul_hely}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_szul_hely']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
        
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_szul_ido')}</Col>
                <Col style={{textAlign: 'right'}}>
                <DateInsertField 
                    id={'users_szul_ido'} 
                    value={this.state.users_szul_ido}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_szul_ido']}
                    editable={false}  
                    theme={this.props.theme} 
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_anyja_neve')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'users_anyja_neve'} 
                    value={this.state.users_anyja_neve}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_anyja_neve']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
        
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_taj_szam')}</Col>
                <Col style={{textAlign: 'right'}}>
                <MaskedInsertField 
                    id={'users_taj_szam'} 
                    regexPattern={new RegExp(/^\d{9}$/)}
                    placeHolder ={'123456789'}
                    acceptedChars={"0123456789"}
                    value={this.state.users_taj_szam}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_taj_szam']}
                    editable={false}
                    theme={this.props.theme}
                    /> 
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_aktiv')}</Col>
                <Col style={{textAlign: 'right'}}>
                <CheckBoxInsertField 
                    id={'users_aktiv'} 
                    value={this.state.users_aktiv}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_aktiv']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
        
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_kulsos_munkavallalo')}</Col>
                <Col style={{textAlign: 'right'}}>
                <CheckBoxInsertField 
                    id={'users_kulsos_munkavallalo'} 
                    value={this.state.users_kulsos_munkavallalo}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_kulsos_munkavallalo']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>

            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_projekt_vezeto')}</Col>
                <Col style={{textAlign: 'right'}}>
                <CheckBoxInsertField 
                    id={'users_projekt_vezeto'} 
                    value={this.state.users_projekt_vezeto}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_projekt_vezeto']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
        
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_tervezo')}</Col>
                <Col style={{textAlign: 'right'}}>
                <CheckBoxInsertField 
                    id={'users_tervezo'} 
                    value={this.state.users_tervezo}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_tervezo']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_munkalaphoz_rendelheto')}</Col>
                <Col style={{textAlign: 'right'}}>
                <CheckBoxInsertField 
                    id={'users_munkalaphoz_rendelheto'} 
                    value={this.state.users_munkalaphoz_rendelheto}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_munkalaphoz_rendelheto']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
                  <Col style={{textAlign: 'left'}}>{lang.getTitleBold('users_szuneteltetve')}</Col>
                <Col style={{textAlign: 'right'}}>
                <CheckBoxInsertField 
                    id={'users_szuneteltetve'} 
                    value={this.state.users_szuneteltetve}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['users_szuneteltetve']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitleBold('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitleBold(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Felhasznalok/usersFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{minWidth: 1200}}>
            <div className={'popupWrapper' + className} id="FelhasznalokUpdate">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('FelhasznalokUpdate')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                         <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default FelhasznalokUpdate;

