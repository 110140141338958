import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TextInsertField from './../../components/TextInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import DateInsertField from './../../components/DateInsertField';
import TimeInsertField from './../../components/TimeInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';
import Dialog from './../../components/Dialog.js';

class SzabadsagokUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            szabadsagok_id: ''
            , szabadsagok_dolgozoId: ''
            , szabadsagok_nap: ''
            , szabadsagok_nap_ig: ''
            , szabadsagok_idoTol: ''
            , szabadsagok_idoIg: ''
            , szabadsagok_megjegyzes: ''

        }


        this.references = [];
        this.references['szabadsagok_id'] = React.createRef();
        this.references['szabadsagok_dolgozoId'] = React.createRef();
        this.references['szabadsagok_nap'] = React.createRef();
        this.references['szabadsagok_idoTol'] = React.createRef();
        this.references['szabadsagok_idoIg'] = React.createRef();
        this.references['szabadsagok_megjegyzes'] = React.createRef();

        this.validators = [];
        this.validators['szabadsagok_id'] = {fieldName: 'szabadsagok_id', dataType: 'INT', value: this.state.szabadsagok_id, mandatory: false, min: null, max: null};
        this.validators['szabadsagok_dolgozoId'] = {fieldName: 'szabadsagok_dolgozoId', dataType: 'INT', value: this.state.szabadsagok_dolgozoId, mandatory: true, min: null, max: null};
        this.validators['szabadsagok_nap'] = {fieldName: 'szabadsagok_nap', dataType: 'DATE', value: this.state.szabadsagok_nap, mandatory: true, min: null, max: null};
        this.validators['szabadsagok_idoTol'] = {fieldName: 'szabadsagok_idoTol', dataType: 'TIME', value: this.state.szabadsagok_idoTol, mandatory: false, min: null, max: null};
        this.validators['szabadsagok_idoIg'] = {fieldName: 'szabadsagok_idoIg', dataType: 'TIME', value: this.state.szabadsagok_idoIg, mandatory: false, min: null, max: null};
        this.validators['szabadsagok_megjegyzes'] = {fieldName: 'szabadsagok_megjegyzes', dataType: 'VARCHAR', value: this.state.szabadsagok_megjegyzes, mandatory: false, min: null, max: null};



        this.error = false;
        this.msg = "";
        this.showDelete = '';
        this.load();

    }

    componentDidMount() {
        this.setFocusedComponent();
    }

    setView = function (e) {
        this.showDelete = e.target.id;
        this.setState({});
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();
        }
    }

    reset = function () {

        this.setState({szabadsagok_id: ''
            , szabadsagok_dolgozoId: ''
            , szabadsagok_nap: ''
            , szabadsagok_idoTol: ''
            , szabadsagok_idoIg: ''
            , szabadsagok_megjegyzes: ''
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getForm = function () {
        return <Container>
    <Row>
        <Col style={{textAlign: 'left'}}>{lang.getTitleBold('szabadsagok_dolgozoId')}</Col>
        <Col style={{textAlign: 'right'}}>
        <ComboBoxInsertField 
            id={'szabadsagok_dolgozoId'} 
            value={this.state.szabadsagok_dolgozoId}  
            setBack={this.setBack.bind(this)}
            dataSourceUrl={'modules/Szabadsagok/dolgozoIdFormComboInsertData.php'}
            reference={this.references['szabadsagok_dolgozoId']}
            editable={true}
            theme={this.props.theme}
            />
        </Col>
    </Row>
    <Row>
        <Col style={{textAlign: 'left'}}>{lang.getTitleBold('szabadsagok_nap')}</Col>
        <Col style={{textAlign: 'right', display: 'contents'}}>
        <DateInsertField 
            id={'szabadsagok_nap'} 
            value={this.state.szabadsagok_nap}  
            setBack={this.setBack.bind(this)}
            reference={this.references['szabadsagok_nap']}
            editable={true}
            theme={this.props.theme}
            />
        </Col>
    </Row>
    <Row>
        <Col style={{textAlign: 'left'}}>{lang.getTitleBold('szabadsagok_idoTol')}</Col>
        <Col style={{textAlign: 'right'}}><TimeInsertField 
            id={'szabadsagok_idoTol'} 
            value={this.state.szabadsagok_idoTol}  
            setBack={this.setBack.bind(this)}
            reference={this.references['szabadsagok_idoTol']}
            editable={true}   
            theme={this.props.theme}
            />
        </Col>
    </Row>
    <Row>
        <Col style={{textAlign: 'left'}}>{lang.getTitleBold('szabadsagok_idoIg')}</Col>
        <Col style={{textAlign: 'right'}}><TimeInsertField 
            id={'szabadsagok_idoIg'} 
            value={this.state.szabadsagok_idoIg}  
            setBack={this.setBack.bind(this)}
            reference={this.references['szabadsagok_idoIg']}
            editable={true}   
            theme={this.props.theme}
            />
        </Col>
    </Row>
    <Row>
        <Col style={{textAlign: 'left'}}>{lang.getTitleBold('szabadsagok_megjegyzes')}</Col>
    </Row>
    <Row>
        <Col style={{textAlign: 'right'}}>
        <TextAreaInsertField 
            id={'szabadsagok_megjegyzes'} 
            value={this.state.szabadsagok_megjegyzes}  
            setBack={this.setBack.bind(this)}
            reference={this.references['szabadsagok_megjegyzes']}
            editable={true}   
            theme={this.props.theme}
            style={{minWidth: '100%'}}
            />
        </Col>
    </Row>
</Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitleBold('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitleBold(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }
    
    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Szabadsagok/szabadsagokFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({szabadsagok_id: this.state.szabadsagok_id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == true) {
                            this.showDelete = '';
                            self.setState({});
                            self.closeForm();

                        } else {
                            alert(response.fail);
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    update = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/Szabadsagok/szabadsagokFormUpdate.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(this.state)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Szabadsagok/szabadsagokFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var openedForm = "";

        if (this.showDelete == 'DELETE') {
            openedForm = <Dialog 
                type={'CONFIRMATION'} 
                dialogText={'Biztosan törölni szeretné a szabadságot?'} 
                callback={this.delete.bind(this)}
                cancel={() => this.setView({target: {id: ''}})}
                theme={this.props.theme}
                />;
        }


        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{maxHeight: 600}}>
        <div className={'popupWrapper' + className} id="SzabadsagokUpdate">
            <div  className={"popupHeader" + className} >
                <Row>
                    <Col></Col>
                    <Col style={{textAlign: 'center'}}>{lang.getTitleBold('SzabadsagokUpdate')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                    </Col>
                </Row>
            </div>
            <div  className={"popupBody" + className} >{form}</div>
            <div  className={"popupFooter" + className} >
                <Row>
                    <Col>
                    <div style={{whiteSpace: 'break-spaces'}}>
                        {this.msg} 
                    </div>
    
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Button className={'btn-sm'} id={'save'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                    <Button className={'btn-sm'} id={'DELETE'} onClick={this.setView.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button>
                    </Col>
                </Row>
                <Row>
                    <Col style={{minHeight: '10px'}}>
    
                    </Col>
                </Row>
                
            </div>
            {openedForm}
        </div>
    </PopUpWindow>);
    }
}

export default SzabadsagokUpdate;

