import React, { Component } from 'react';
import * as ajax from './../ajax.js';
import * as lang from './../lang.js';
import {Button} from 'react-bootstrap';
import deleteIcon from './../img/black/delete_black_24dp.svg';
import updateIcon from './../img/black/edit_note_black_24dp.svg';
import viewIcon from './../img/black/preview_black_24dp.svg';

import belsoRendelesekIkon from './../img/black/store_black_24dp.svg';


class ActionCellMunkalap extends Component {

    constructor(props) {
        super(props);
        this.FORM_DELETE = "ACTION_CELL_DELETE";
        this.FORM_INSERT = "ACTION_CELL_INSERT";
        this.FORM_UPDATE = "ACTION_CELL_UPDATE";
        this.FORM_VIEW = "ACTION_CELL_VIEW";

    }

    setView = function (e) {
        var actionId = e.target.id;
        this.props.stateReference.selectedRow = this.props.row_id;
        var action = '';
        switch (actionId) {
            case 'ACTION_CELL_DELETE':
                action = 'DELETE';
                break;
            case 'ACTION_CELL_UPDATE':
                action = 'UPDATE';
                break;
            case 'ACTION_CELL_VIEW':
                action = 'VIEW';
                break;
        }


        this.props.setView({target: {id: action}});

    }
    
    
    belsoRendelesekMegnyitasa = function(id,filterAttr){
        window.open('./?menu=BelsoRendelesek&id='+id+'&filterAttr='+filterAttr,'_blank');
    }


    getButtons = function () {
        var buttons = [];
        var viewButton = <div  
            key={'ACTION_CELL_KEY_VIEW_BUTTON_' + this.props.row_id} >&nbsp;
            <Button 
                title={lang.getTitle('View')}
                className={'btn-sm'} 
                id={this.FORM_VIEW} 
                onClick={this.setView.bind(this)} 
                variant="info">
                <img
                    title={lang.getTitle('View')}
                    id={this.FORM_VIEW} 
                    src={viewIcon} 
                    onClick={this.setView.bind(this)} 
                    />
            </Button>
        </div>;
        var updateButton = <div key={'ACTION_CELL_KEY_UPDATE_BUTTON_' + this.props.row_id}>&nbsp;
            <Button 
                title={lang.getTitle('Update')}
                className={'btn-sm'} 
                id={this.FORM_UPDATE} 
                onClick={this.setView.bind(this)} 
                variant="warning">
                <img 
                    title={lang.getTitle('Update')}
                    id={this.FORM_UPDATE} 
                    src={updateIcon} 
                    onClick={this.setView.bind(this)} 
                    />
            </Button>
        </div>;
        var deleteButton = <div key={'ACTION_CELL_KEY_DELETE_BUTTON_' + this.props.row_id}>&nbsp;
            <Button 
                title={lang.getTitle('Delete')}
                className={'btn-sm'} 
                id={this.FORM_DELETE} 
                onClick={this.setView.bind(this)} 
                variant="danger">
                <img 
                    title={lang.getTitle('Delete')}
                    id={this.FORM_DELETE} 
                    src={deleteIcon} 
                    onClick={this.setView.bind(this)}  />
            </Button>
        </div>;
        
        
        
        var belsoRendelesekButton = <div key={'ACTION_CELL_KEY_BELSO_RENDELESEK_BUTTON_' + this.props.row_id}>&nbsp;
            <Button 
                title={lang.getTitle('BelsoRendelesek')}
                className={'btn-sm'} 
                onClick={()=>{this.belsoRendelesekMegnyitasa(this.props.row_id,'belsoRendelesek_munkalap_id')}} 
                variant="secondary">
                <img 
                    title={lang.getTitle('BelsoRendelesek')}
                    src={belsoRendelesekIkon} 
                    
                      />
            </Button>
        </div>;
        

        var isViewForm = this.props.isViewForm;
        if (isViewForm) {
            buttons = [viewButton];
        } else {
            buttons = [viewButton, updateButton, deleteButton,belsoRendelesekButton ];
        }
        return buttons;
    }

    render() {
        var isViewForm = this.props.isViewForm;
        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';
        var buttons = this.getButtons();
        return <div style={{display: 'flex'}}>{buttons}</div>
    }
}

export default ActionCellMunkalap;

