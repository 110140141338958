import './App.css';
import React, {Component} from 'react';
import Contents from './mainScreen/Contents';
import Login from './modules/Login/Login';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            firstName: '',
            lastName: '',
            blackTheme: this.loadTheme()
        }

        this.skeepLogin = this.getContentFromParam() != null;
    }
    getContentFromParam = function () {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var contentName = urlParams.get('menu');
        return contentName;
    }

    setLoginState = function (response) {
        this.setState({loggedIn: response.success, firstName: response.data.firstName, lastName: response.data.lastName});
    }

    setTheme = function (blackTheme) {

        this.storeTheme(blackTheme);
        this.setState({blackTheme: blackTheme});
    }

    storeTheme = function (blackTheme) {
        localStorage.setItem("blackTheme", blackTheme);
    }

    loadTheme = function () {
        var blackTheme = localStorage.getItem("blackTheme");
        return (typeof blackTheme != "undefined" && blackTheme != null && blackTheme != '' && blackTheme == "true") ? "true" : "false";
    }

    render() {


        var currentPanel = "";
        if (this.state.loggedIn || this.skeepLogin) {
            currentPanel = <Contents theme={this.state.blackTheme} setTheme={this.setTheme.bind(this)}  />
        } else {
            currentPanel = <Login setLoginState={this.setLoginState.bind(this)} />

        }
        return <div>{currentPanel}</div>
    }
}

export default  App;
