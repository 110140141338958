import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';
import * as ajax from './../ajax.js';
import ComboBoxInsertField from './../components/ComboBoxInsertField';
import UgyfelekKarbantartasInsertForm from './../modules/UgyfelekKarbantartas/UgyfelekKarbantartasInsertForm';

class AjanlatUgyfelComboHozzaadassal extends ComboBoxInsertField {
    
    
    
    openInsertForm = function () {
        this.props.openInsertForm();
        this.insertFormVisible = false;
    }
    closeForm = function () {
        this.insertFormVisible = false;
        this.setState({});
    }
    openForm = function () {
        this.insertFormVisible = true;
        this.setState({});
    }
    
    setBackRecordData=function(data){
        this.props.stateRef.arajanlat_kapcsolattarto_id = data.data.kapcsolattarto_id;
        this.state.value=data.data.value;
        this.state.text=data.data.text;
        this.setBack();
    }
    
    render() {
        
        var comboItems = "";
        if (this.state.dropDownVisible) {
            comboItems = this.comboItems();
        }

        var className = (this.props.theme == "true") ? "Dark" : "Light";

        var insertForm = "";
        if (this.insertFormVisible) {
            var insertForm = <UgyfelekKarbantartasInsertForm
                closeForm ={this.closeForm.bind(this)}
                setBackRecordData={this.setBackRecordData.bind(this)}
                theme={this.props.theme}
                />;
        }
        console.log('Editable',this.props.editable);
        return  <div style={{position: 'relative', display: 'inline-flex', textAlign: 'right'}} >
            <div style={{display: 'inline-flex', textAlign: 'right'}}>
                <div style={{borderBottom: 'solid 1px white'}}>
                    <input disabled={this.props.editable == false} style={{borderBottom: 'none'}} onClick={this.openForm.bind(this)} type='button' name='add' value='+'/>
                </div>
                <input 
                    type={'text'} 
                    onChange={this.onChange.bind(this)}  
                    onFocus={this.onFocus.bind(this)}  
                    onBlur={this.onBlur.bind(this)}  
                    onKeyDown={this.onKeyDown.bind(this)}
                    id={this.props.id} 
                    value={this.state.text} 
                    ref={this.props.reference}
                    disabled={this.props.editable == false}
                    style={{textAlign: 'left'}}
                    className ={"component" + className}
                    autoComplete={"off"}
                    />
                {comboItems}
            </div>
        
            {insertForm}
        </div>
    }

}

export default AjanlatUgyfelComboHozzaadassal;