import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col} from 'react-bootstrap';
import * as lang from './../lang.js';
import mini_logo_light from './../css/mini_logo_light.svg';
import mini_logo_dark from './../css/mini_logo_dark.svg';

import menuCloseWhite from './../img/white/toggle_off_white_24dp.svg';
import menuCloseBlack from './../img/black/toggle_off_black_24dp.svg';

import menuOpenWhite from './../img/white/toggle_on_white_24dp.svg';
import menuOpenBlack from './../img/black/toggle_on_black_24dp.svg';


import Login from './../modules/Login/Login';
import Menu from './../mainScreen/Menu';


import IranyitoszamokContent from './../modules/Iranyitoszamok/IranyitoszamokContent';
import MunkaBeosztasContent from './../modules/MunkaBeosztas/MunkaBeosztasContent';
import MunkaBeosztasCimekContent from './../modules/MunkaBeosztasCimek/MunkaBeosztasCimekContent';
import AutokContent from './../modules/Autok/AutokContent';
import ArajanlatContent from './../modules/Arajanlat/ArajanlatContent';
import ArajanlatTetelContent from './../modules/ArajanlatTetel/ArajanlatTetelContent';
import ArajanlatTetelOpciokContent from './../modules/ArajanlatTetelOpciok/ArajanlatTetelOpciokContent';
import CikkcsoportokContent from './../modules/Cikkcsoportok/CikkcsoportokContent';
import CikkcsoportokFelhasznalokContent from './../modules/CikkcsoportokFelhasznalok/CikkcsoportokFelhasznalokContent';
import FelhasznalokContent from './../modules/Felhasznalok/FelhasznalokContent';
import FestekRendelesekContent from './../modules/FestekRendelesek/FestekRendelesekContent';
import SzovegSablonokContent from './../modules/SzovegSablonok/SzovegSablonokContent';
import UgyfelekKarbantartasContent from './../modules/UgyfelekKarbantartas/UgyfelekKarbantartasContent';
import UgyfelCimekKarbantartasContent from './../modules/UgyfelCimekKarbantartas/UgyfelCimekKarbantartasContent';
import MunkalapContent from './../modules/Munkalap/MunkalapContent';

import KapcsolattartokContent from './../modules/Kapcsolattartok/KapcsolattartokContent';
import BelsoRendelesekContent from './../modules/BelsoRendelesek/BelsoRendelesekContent';
import MunkaBeosztasNaptar from './../modules/MunkaBeosztasNaptar/MunkaBeosztasNaptarContent';

import ProjektTabla from './../modules/ProjektTabla/ProjektTabla';
import ProjektJarmuvekTabla from './../modules/ProjektJarmuvekTabla/ProjektJarmuvekTabla';
import ProjektDolgozokTabla from './../modules/ProjektDolgozokTabla/ProjektDolgozokTabla';

import AktualizalSzovegbox from './../modules/Arajanlat/AktualizalSzovegbox';

import SzabadsagokContent from './../modules/Szabadsagok/SzabadsagokContent';
import SzabadsagokNaptarContent from './../modules/SzabadsagokNaptar/SzabadsagokContent';
import Jogosultsagok from './../modules/Jogosultsagok/JogosultsagokContent';
import UserPanel from './UserPanel';

import GoogleDriveFoldersDRIVEContent from './../modules/GoogleDriveFoldersDRIVE/GoogleDriveFoldersDRIVEContent';
import GoogleDriveFoldersSAFEContent from './../modules/GoogleDriveFoldersSAFE/GoogleDriveFoldersSAFEContent';
import GoogleDriveSubFoldersDRIVEContent from './../modules/GoogleDriveSubFoldersDRIVE/GoogleDriveSubFoldersDRIVEContent';
import MobilUgyfelekKarbantartas from './../modules/MobilUgyfelekKarbantartas/MobilUgyfelekKarbantartas';


class Contents extends Component {
    constructor() {
        super();

        this.state = {
            content: 'MunkaBeosztasNaptar',
            menuCollapsed: false,
            loggedIn: false,
            moduleRolesArray: []
        }

        this.BLACK_THEME = true;
        this.WHITE_THEME = false;

        this.skeepLogin = this.getContentFromParam() != null;
    }

    getModuleRoles = function (moduleName) {
        var moduleRolesArray = this.state.moduleRolesArray;
        for (var i = 0; i < moduleRolesArray.length; i++) {
            var moduleRole = moduleRolesArray[i];
            if (moduleRole.module_name == moduleName) {
                return moduleRole;
            }
        }

        return null;
    }

    switch = function (e) {
        this.setState({content: e.target.id});
    }

    changeTheme = function () {
        if (this.props.theme == "true") {
            this.props.setTheme("false")
        } else {
            this.props.setTheme("true")
        }
    }

    navbar = function () {
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var logo = (this.props.theme == "true") ? mini_logo_dark : mini_logo_light;
        return (<Navbar className={"contentHeader" + className} expand="lg">
        
            <div style={{display: 'flex', minWidth: '95%'}}>
                <div style={{flex: 5}}>
                    <img 
                        syle={{maxWidth: '50px', flex: 100}} 
                        src={logo} 
                        onClick={this.changeTheme.bind(this)} 
        
                        />
                </div>
        
                <div style={{flex: 10, textAlign: 'right'}}  >
                    <UserPanel theme={this.props.theme} />        
                </div>
            </div>
        
        </Navbar>)
    }

    setContent = function (contentName) {
        this.setState({content: contentName});
    }

    hideMenu = function () {
        this.setState({menuCollapsed: !this.state.menuCollapsed});
    }

    getContentFromParam = function () {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var contentName = urlParams.get('menu');
        return contentName;
    }
    setLoginState = function (data) {
        this.setState({loggedIn: data.success, moduleRolesArray: data.data});
    }

    render() {
        document.body.style.height = "95vh";
        document.body.style.backgroundColor = (this.props.theme == "true") ? "#313943" : "#FFFFFF";
        var contentFromParam = this.getContentFromParam();
        if (contentFromParam != null) {
            this.state.content = contentFromParam;
            this.state.menuCollapsed = true;
        } else {
            if (this.state.loggedIn == false) {
                return <Login  setLoginState={this.setLoginState.bind(this)} />
            }
        }
        var content = "";
        switch (this.state.content) {
            case 'MunkaBeosztas' :
                content = <MunkaBeosztasContent theme={this.props.theme} />;
                break;
            case 'Autok' :
                content = <AutokContent theme={this.props.theme} />;
                break;
            case 'Arajanlat' :
                content = <ArajanlatContent ajanlatStatuszFilter="OSSZES" theme={this.props.theme} />;
                break;
            case 'Folyamatban' :
                content = <ArajanlatContent ajanlatStatuszFilter="FOLYAMATBAN" theme={this.props.theme} />;
                break;
            case 'Elfogadott' :
                content = <ArajanlatContent ajanlatStatuszFilter="ELFOGADOTT" theme={this.props.theme} />;
                break;
            case 'Aktualizalt' :
                content = <ArajanlatContent ajanlatStatuszFilter="AKTUALIZALT" theme={this.props.theme} />;
                break;
            case 'Lezart' :
                content = <ArajanlatContent ajanlatStatuszFilter="LEZART" theme={this.props.theme} />;
                break;
            case 'Meghiusult' :
                content = <ArajanlatContent ajanlatStatuszFilter="MEGHIUSULT" theme={this.props.theme} />;
                break;
            case 'AktualizaltSzovegbox' :
                content = <AktualizalSzovegbox  theme={this.props.theme} />;
                break;

            case 'ArajanlatTetel' :
                content = <ArajanlatTetelContent theme={this.props.theme} />;
                break;
            case 'ArajanlatTetelOpciok' :
                content = <ArajanlatTetelOpciokContent theme={this.props.theme} />;
                break;
            case 'Cikkcsoportok' :
                content = <CikkcsoportokContent theme={this.props.theme} />;
                break;
            case 'cikkcsoportokFelhasznalok' :
                content = <CikkcsoportokFelhasznalokContent theme={this.props.theme} />;
                break;
            case 'Felhasznalok' :
                content = <FelhasznalokContent theme={this.props.theme} />;
                break;
            case 'Szabadsag' :
                content = <SzabadsagokContent theme={this.props.theme} />;
                break;
            case 'SzabadsagNaptar' :
                content = <SzabadsagokNaptarContent theme={this.props.theme} />;
                break;
            case 'FestekRendelesek' :
                content = <FestekRendelesekContent theme={this.props.theme} />;
                break;
            case 'SzovegSablonok' :
                content = <SzovegSablonokContent theme={this.props.theme} />;
                break;
            case 'UgyfelekKarbantartas' :
                content = <UgyfelekKarbantartasContent theme={this.props.theme} />;
                break;
            case 'UgyfelCimekKarbantartas' :
                content = <UgyfelCimekKarbantartasContent theme={this.props.theme} />;
                break;
            case 'Iranyitoszamok' :
                content = <IranyitoszamokContent theme={this.props.theme} />;
                break;
            case 'Munkalap' :
                content = <MunkalapContent theme={this.props.theme} />;
                break;
            case 'Kapcsolattartok' :
                content = <KapcsolattartokContent theme={this.props.theme} />;
                break;
            case 'BelsoRendelesek' :
                content = <BelsoRendelesekContent theme={this.props.theme} />;
                break;
            case 'MunkaBeosztasNaptar' :
                content = <MunkaBeosztasNaptar theme={this.props.theme} />;
                break;
            case 'ProjektTabla' :
                content = <ProjektTabla theme={this.props.theme} />;
                break;
            case 'ProjektDolgozokTabla' :
                content = <ProjektDolgozokTabla theme={this.props.theme} />;
                break;
            case 'ProjektJarmuvekTabla' :
                content = <ProjektJarmuvekTabla theme={this.props.theme} />;
                break;
            case 'Jogosultsagok' :
                content = <Jogosultsagok theme={this.props.theme} />;
                break;
            case 'MobilUgyfelekKarbantartas' :
                content = <MobilUgyfelekKarbantartas theme={this.props.theme} />;
                break;
            case 'GoogleDriveFolders' :
                content = <div style={{display: 'flex'}}>
                    <div style={{flex: 1}}>
                        <GoogleDriveFoldersDRIVEContent theme={this.props.theme} />
                    </div>;
                    <div style={{flex: 1}}>
                        <GoogleDriveSubFoldersDRIVEContent theme={this.props.theme} />
                    </div>;
                    <div style={{flex: 1}}>
                        <GoogleDriveFoldersSAFEContent theme={this.props.theme} />
                    </div>;
                
                </div>;
                break;
        }

        var menuWidth = (this.state.menuCollapsed) ? '5%' : '10%';
        var contentWidth = (this.state.menuCollapsed) ? '95%' : '90%';

        var menuClose = (this.props.theme == "true") ? menuCloseWhite : menuCloseBlack;
        var menuOpen = (this.props.theme == "true") ? menuOpenWhite : menuOpenBlack;

        var menuOpenerIcon = ((this.state.menuCollapsed) ? menuClose : menuOpen);

        var menuOpener = "";
        var menu = "";
        if (contentFromParam == null) {

            menuOpener = <div style={{textAlign: 'right'}} onClick={this.hideMenu.bind(this)}><img src={menuOpenerIcon} /></div>;
            menu = <Menu 
                theme={this.props.theme} 
                activeMenuId={this.state.content} 
                setContent={this.setContent.bind(this)} 
                collapsed={this.state.menuCollapsed}
                moduleRolesArray={this.state.moduleRolesArray}
                />;
        } else {
            menuWidth = "0%";
            contentWidth = "100%";
        }


        return (<div>
            {this.navbar()}
            <table style={{width: '100%'}}>
                <tbody>
                    <tr>
                        <td style={{verticalAlign: 'top', width: menuWidth}} >
                            <div >
                                {menuOpener}
                                {menu}
                            </div>
                        </td>
                        <td style={{verticalAlign: 'top', width: contentWidth}}>
                            {content}        
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);
    }
}

export default Contents;
