import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';
import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import CheckBoxInsertField from './../../components/CheckBoxInsertField';
import NumberInsertField from './../../components/NumberInsertField';
import TextInsertField from './../../components/TextInsertField';
import UgyfelcimekIrszField from './../../componentsSpecial/UgyfelcimekIrszField';
import UgyfelcimekTelepulesCombo from './../../componentsSpecial/UgyfelcimekTelepulesCombo';

class MTOUgyfelCimekKarbantartasInsertMTO extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ugyfelCimek_elsodleges: '0'
            , ugyfelCimek_irsz: ''
            , ugyfelCimek_telepules: ''
            , ugyfelCimek_telepulesNev: ''
            , ugyfelCimek_cim: ''
            , ugyfelCimek_cim_tipus: ''

        }

        this.references = [];
        this.references['ugyfelCimek_elsodleges'] = React.createRef();
        this.references['ugyfelCimek_irsz'] = React.createRef();
        this.references['ugyfelCimek_telepules'] = React.createRef();
        this.references['ugyfelCimek_cim'] = React.createRef();
        this.references['ugyfelCimek_cim_tipus'] = React.createRef();

    }
    componentDidMount() {
        this.reset();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({ugyfelCimek_elsodleges: '0'
            , ugyfelCimek_irsz: ''
            , ugyfelCimek_telepules: ''
            , ugyfelCimek_telepulesNev: ''
            , ugyfelCimek_cim: ''
            , ugyfelCimek_cim_tipus: ''
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});

    }

    getForm = function () {
        return <Container  theme={this.props.theme}>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelCimek_elsodleges')}</Col>
                <Col style={{textAlign: 'right'}}>
                <CheckBoxInsertField 
                    id={'ugyfelCimek_elsodleges'} 
                    value={this.state.ugyfelCimek_elsodleges}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['ugyfelCimek_elsodleges']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelCimek_irsz')}</Col>
                <Col style={{textAlign: 'right'}}>
                <UgyfelcimekIrszField 
                    id={'ugyfelCimek_irsz'} 
                    value={this.state.ugyfelCimek_irsz}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/MTOMunkaBeosztasCimek/irsz_FromComboInsertData.php'}
                    reference={this.references['ugyfelCimek_irsz']}
                    editable={true}
                    theme={this.props.theme}
                    telepulesAttrName={"ugyfelCimek_telepules"}
                    style={{width:'100%'}}
                    />
        
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelCimek_telepules')}</Col>
                <Col style={{textAlign: 'right'}}>
                <UgyfelcimekTelepulesCombo 
                    id={'ugyfelCimek_telepules'} 
                    value={this.state.ugyfelCimek_telepules}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/MTOMunkaBeosztasCimek/telepules_FromComboInsertData.php'}
                    reference={this.references['ugyfelCimek_telepules']}
                    editable={true}
                    theme={this.props.theme}
                    irsz={this.state.ugyfelCimek_irsz}
                    style={{width:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelCimek_cim')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'ugyfelCimek_cim'} 
                    value={this.state.ugyfelCimek_cim}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['ugyfelCimek_cim']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('ugyfelCimek_cim_tipus')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'ugyfelCimek_cim_tipus'} 
                    value={this.state.ugyfelCimek_cim_tipus}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['ugyfelCimek_cim_tipus']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width:'100%'}}
                    />
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();
    }

    addRow = function () {
        this.props.addRow(this.state, false);
        this.reset();
    }

    addRowAndClose = function () {
        this.props.addRow(this.state, true);
        this.reset();
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme}>
            <div className={'popupWrapper' + className} id="MTOUgyfelCimekKarbantartasInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('MTOUgyfelCimekKarbantartasInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Button className={'btn-sm'} onClick={this.addRowAndClose.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                    <Button className={'btn-sm'} onClick={this.addRow.bind(this)} variant="info" style={{marginTop: '5px'}}>{lang.getTitle('new')}</Button>      
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default MTOUgyfelCimekKarbantartasInsertMTO;


