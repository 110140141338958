import React, { Component } from 'react';
import './../css/light.css';
import './../css/dark.css';
import * as ajax from './../ajax.js';
import * as lang from './../lang.js';
import MunkabeosztasMunkavallaloCombo from './MunkabeosztasMunkavallaloCombo';
import {Row, Col, Button} from 'react-bootstrap';
import deleteBlack  from './../img/black/delete_black_24dp.svg';
import deleteWhite  from './../img/white/delete_white_24dp.svg';

class MunkabeosztasMunkavallaloHozzarendelese extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }

        this.references = [];
        this.references['munkaBeosztas_ugyfel_cim_id'] = React.createRef();

        this.currentDolgozo = {
            munkaBeosztasDolgozok_munka_beosztas_id: this.props.munka_beosztas_id,
            munkaBeosztasDolgozok_user_id: null,
            munkaBeosztasDolgozok_user_name: null,
            munkaBeosztasDolgozok_felelos: 0
        };

        this.autokLista = [];
        this.loadAutok();


    }

    getSzallithatoSzemelyekSzama = function (autoId) {
        for (var i = 0; i < this.autokLista.length; i++) {
            var auto = this.autokLista[i];
            if (auto.autok_id == autoId) {
                return auto.autok_szemelyek_szama;
            }
        }
        return 0;
    }

    loadAutok() {
        try {
            const url = ajax.getUrlBase() + '/modules/MunkaBeosztas/getAutok.php';
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        if (typeof response.data != 'undefined' && response.data != null) {
                            this.autokLista = response.data;
                            this.setState({});

                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

        this.setState({dropDownVisible: true, hoveredId: -1});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.munkaBeosztas_id !== this.props.munkaBeosztas_id) {
            this.setState({value: this.props.value});
        }
    }
    duplicated = function (id) {
        for (var i = 0; i < this.state.value.length; i++) {
            var user = this.state.value[i];
            if (user.munkaBeosztasDolgozok_user_id == id) {
                return true;
            }
        }
        return false;
    }

    addMunkavallalo() {
        if (typeof this.state.value == 'undefined') {
            this.state.value = [];
        }
        if (this.state.value == null) {
            this.state.value = [];
        }
        
        var szallithatoSzemelyekSzama = this.getSzallithatoSzemelyekSzama(this.props.autoId);
        if(this.state.value.length >= szallithatoSzemelyekSzama){
            alert("Nem adható hozzá: Szállítható személyek száma miatt");
            return false;
        }
        
        
        
        
        var selectionNeed = (this.currentDolgozo.munkaBeosztasDolgozok_user_id == null) ? true : false;
        var duplicated = this.duplicated(this.currentDolgozo.munkaBeosztasDolgozok_user_id);

        if (duplicated == false && selectionNeed == false) {
            var cloned = Object.assign({}, this.currentDolgozo);
            this.state.value.push(cloned);
            this.setState({});
        }


        this.writeBack();
    }

    writeBack = function () {
        if (this.props.isInsert) {
            this.props.setBack("MTOMunkaBeosztasDolgozok", this.state.value);
        } else {
            this.props.setBack("MunkaBeosztasDolgozok", this.state.value);
        }
    }

    removeMunkavallalo(user_id) {
        var munkavallalok = [];
        for (var i = 0; i < this.state.value.length; i++) {
            var munkavallalo = this.state.value[i];
            if (munkavallalo.munkaBeosztasDolgozok_user_id != user_id) {
                munkavallalok.push(munkavallalo);
            }
        }
        this.state.value = munkavallalok;
        this.setState({});


        this.writeBack();
    }

    setFelelos = function (e) {
        var userId = e.target.id;
        var felelos = e.target.value;
        for (var i = 0; i < this.state.value.length; i++) {
            var user = this.state.value[i];
            if (user.munkaBeosztasDolgozok_user_id == userId) {
                this.state.value[i].munkaBeosztasDolgozok_felelos = felelos;
            }else{
                this.state.value[i].munkaBeosztasDolgozok_felelos = 0;
            }
        }
        this.setState({});

        this.writeBack();
    }

    getMunkavallalo = function (munkavallalo) {
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var style = {textAlign: 'left'};
        var deleteIcon = (this.props.theme == "true") ? deleteWhite : deleteBlack;


        if (typeof this.props.style != "undefined" && this.props.style != null) {
            style = this.props.style;
        }

        return (
                <div key={'row_' + munkavallalo.munkaBeosztasDolgozok_user_id}>
                    <div style={{display: 'inline-block', marginRight: 20}}>
                        <img 
                            style={{cursor: 'pointer'}}   
                            id={munkavallalo.munkaBeosztasDolgozok_user_id}  
                            src={deleteIcon}  
                            onClick={() => {
                                    this.removeMunkavallalo(munkavallalo.munkaBeosztasDolgozok_user_id);
                                }}
                            />
                    </div>
                    <div style={{display: 'inline-block', marginRight: 20}}>
                        <select 
                            id={munkavallalo.munkaBeosztasDolgozok_user_id}
                            value={munkavallalo.munkaBeosztasDolgozok_felelos}
                            onChange={this.setFelelos.bind(this)}
                            className ={"component" + className}
                            >
                            <option value={0}>{lang.getTitle('dolgozo')}</option>
                            <option value={1}>{lang.getTitle('felelos')}</option>
                        </select>
                    </div>
                    <div style={{display: 'inline-block', marginRight: 20}}>
                        {munkavallalo.munkaBeosztasDolgozok_user_name}
                    </div>
                </div>
                )
    }

    getMunkavallalok = function () {
        var munkavallalok = [];
        for (var i = 0; i < this.state.value.length; i++) {
            var munkavallalo = this.state.value[i];
            munkavallalok.push(this.getMunkavallalo(munkavallalo));
        }
        return <div style={{minHeight: 100}}>{munkavallalok}</div>;
    }

    setBack = function (id, value) {
        this.currentDolgozo[id] = value;
        this.setState({});
    }

    render() {
        var munkavallalok = this.getMunkavallalok();
        if (this.props.autoId == null || this.props.autoId == '') {
            return "";
        }

        var szallithatoSzemelyekSzama = this.getSzallithatoSzemelyekSzama(this.props.autoId);

        return <div style={{marginTop: 20, marginBottom: 20}}>
        
            <div style={{textAlign: 'center'}}  ><h6>{lang.getTitle('MunkabeosztasMunkavallaloHozzarendelese')}</h6></div>
            <div style={{textAlign: 'center'}}  >{lang.getTitle('autok_szemelyek_szama')}({szallithatoSzemelyekSzama})</div>
            <Row style={{textAlign: 'left'}}>
                <Col>
                <div style={{display: 'inline-block'}}>
                    <MunkabeosztasMunkavallaloCombo 
                        id={'munkaBeosztasDolgozok_user_id'} 
                        value={this.currentDolgozo.munkaBeosztasDolgozok_user_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/MunkaBeosztasNaptar/user_idMunkabeosztasMunkavallaloCombo.php'}
                        ugyfel_id={this.currentDolgozo.munkaBeosztasDolgozok_user_id}
                        editable={true}
                        theme={this.props.theme}
                        munkaBeosztas_datum ={this.props.munkaBeosztas_datum}
                        />
                </div>
        
                <div style={{display: 'inline-block', margin: 10, maxHeight: 20}}>
                    <Button 
                        disabled ={this.currentDolgozo.munkaBeosztasDolgozok_user_id == null}
                        onClick={this.addMunkavallalo.bind(this)}
                        variant={'success'}>{lang.getTitle('add_selected')}</Button>
                </div>
                </Col>
                <Col>
                <div>{munkavallalok}</div>
                </Col>
            </Row>
        </div>
    }
}

export default MunkabeosztasMunkavallaloHozzarendelese;


