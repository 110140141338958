import React, { Component } from 'react';
import * as lang from './../lang.js';

class GlobalSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {value: ''}
        this.loadTimeOutId = null;
    }

    setSearch = function (value) {
        this.props.globalSearch(value);
    }

    globalSearch = function (e) {
        var value = e.target.value;
        this.setState({value:value});
        var self = this;
        
        clearTimeout(self.loadTimeOutId);
        this.loadTimeOutId = setTimeout(() => self.setSearch(value), 1200);
    }

    render() {
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var style = {textAlign: 'left', minHeight: '100%'};
        if (typeof this.props.style != "undefined" && this.props.style != null) {
            style = this.props.style;
        }

        return <div style={{display: 'flex'}}>
            <div>&nbsp;<input style={style}
                              className ={"component" + className} placeholder={lang.getTitle('search')} style={style} value={this.state.value} type="text" onChange={this.globalSearch.bind(this)} /></div>
        </div>
    }
}

export default GlobalSearch;

