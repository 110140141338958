import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';


class JogosultsagTipusok extends Component {

    constructor(props) {
        super(props);
        this.state = {
            theme: this.props.theme,
            data: []
        }
    }

    shouldComponentUpdate() {
        return true;
    }

    componentDidUpdate(prevProps) {

        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

  
    setActiveJogosultsagTipus = function (id) {
        this.props.setActiveJogosultsagTipus(id);
    }

    getJogosultsagFelhasznalo = function (data) {
        var jogosultsagFelhasznalo_id = data.jogosultsagFelhasznalo_id;
        var jogosultsagFelhasznalo_jogosultsagTipus_id = data.jogosultsagFelhasznalo_jogosultsagTipus_id;
        var jogosultsagFelhasznalo_user_id = data.jogosultsagFelhasznalo_user_id;
        var users_name = data.users_name;

        return <div style={{display: 'flex', textAlign: 'left', marginLeft: 10, fontStyle: 'italic'}} key={'id_' + jogosultsagFelhasznalo_id} id={jogosultsagFelhasznalo_id}>
            <div style={{cursor:'pointer'}} onClick={()=>this.props.felhasznaloBesorolasTorlese(jogosultsagFelhasznalo_id)}>[-]</div>
            <div>&nbsp;{users_name}</div>

        </div>

    }

    getJogosultsagTipus = function (data) {

        var felhasznalok = [];
        var active = false;
        if (this.props.activeJogosultsagTipus != null && this.props.activeJogosultsagTipus == data.jogosultsagTipus_id) {
            active = true;


            for (var i = 0; i < data.felhasznalok.length; i++) {
                var felhasznalo = this.getJogosultsagFelhasznalo(data.felhasznalok[i]);
                felhasznalok.push(felhasznalo);

            }

        }



        return <div   key={"jogosultsag_tipus_id_" + data.jogosultsagTipus_id}>
            <div
                id={data.jogosultsagTipus_id}
                onClick={()=>this.setActiveJogosultsagTipus(data.jogosultsagTipus_id)}  
                style={{textAlign: 'left', 
                        margin: 2, 
                        padding: 3, 
                        boxShadow: '1px 1px 3px #00000066', 
                        fontWeight: (active) ? 'bold' : 'normal',
                        cursor:'pointer',
                        display:'flex'
                        
                        
                    }}>
                    
                <div 
                style={{flex:10}}
                onClick={()=>this.setActiveJogosultsagTipus(data.jogosultsagTipus_id)}  
                >{data.jogosultsagTipus_nev}</div>
                <div 
                onClick={()=>this.props.jogosultsagTipusTorlese(data.jogosultsagTipus_id)}
                title={lang.getTitle('JogosultsagTipusTorlese')}
                style={{flex:1}} >[-]</div>
                
            </div>
            <div style={{whiteSpace: 'nowrap', display: 'flow'}}>
                {felhasznalok}
            </div>
        </div>;
    }

    getJogosultsagTipusok = function (data) {
        var tipusok = [];
        for (var i = 0; i < data.length; i++) {
            var tipus = this.getJogosultsagTipus(data[i]);
            tipusok.push(tipus);
        }
        return <div>{tipusok}</div>;
    }

    render() {

        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var headerTextColor = (this.props.theme == "true") ? "wheat" : "grey";
        var jogosultsagTipusok = this.getJogosultsagTipusok(this.props.data);
        return (
                <div>
                    {jogosultsagTipusok}
                </div>
                );
    }
}

export default JogosultsagTipusok;
