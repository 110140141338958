import React, { Component } from 'react';

import {Button, Row, Col, ToggleButton} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';

class Modulok extends Component {

    constructor(props) {
        super(props);
        this.state = {
            theme: this.props.theme,
            mouseOverId: -1
        }



    }

    shouldComponentUpdate() {
        return true;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    getRole = function (modulId, roleType, value) {
        var nextAction = "";
        switch (roleType) {
            case 'roleSelect' :
                nextAction = (value == 0) ?
                        this.props.parent.ACTION_TYPE_SINGLE_ROLE_SELECT_ADD :
                        this.props.parent.ACTION_TYPE_SINGLE_ROLE_SELECT_REMOVE;
                ;
                break;
            case 'roleInsert' :
                nextAction = (value == 0) ?
                        this.props.parent.ACTION_TYPE_SINGLE_ROLE_INSERT_ADD :
                        this.props.parent.ACTION_TYPE_SINGLE_ROLE_INSERT_REMOVE;
                ;
                break;
            case 'roleUpdate' :
                nextAction = (value == 0) ?
                        this.props.parent.ACTION_TYPE_SINGLE_ROLE_UPDATE_ADD :
                        this.props.parent.ACTION_TYPE_SINGLE_ROLE_UPDATE_REMOVE;
                ;
                break;
            case 'roleDelete' :
                nextAction = (value == 0) ?
                this.props.parent.ACTION_TYPE_SINGLE_ROLE_DELETE_ADD:
                this.props.parent.ACTION_TYPE_SINGLE_ROLE_DELETE_REMOVE;
                break;
        }

        return <ToggleButton
            style={{maxHeight: 20}}    
            id={"toggle-check_" + modulId + "_" + roleType + "_" + value}
            type="checkbox"
            variant="outline-success"
            checked={(value == 0) ? false : true}
            value={value}
            size="sm"
            onClick={() => this.props.parent.jogosultsagBeallitasa(this.props.parent.jogosultsagBeallitasa(nextAction, this.props.parent.state.activeJogosultsagTipus, modulId))}
        
            >&nbsp;</ToggleButton>

    }

    getModul = function (modul) {
        var id = modul.id;
        var jogosultsagTipus_id = modul.jogosultsagTipus_id;
        var module_name = modul.module_name;
        var roleSelect = modul.roleSelect;
        var roleInsert = modul.roleInsert;
        var roleUpdate = modul.roleUpdate;
        var roleDelete = modul.roleDelete;

        var moduleStyle = {cursor: 'pointer', minHeight: 30, justifyContent: 'center'}

        if (this.state.mouseOverId == id) {
            moduleStyle['boxShadow'] = '1px 1px 3px #00000066';
        }

        return <Row id={id}
         style={moduleStyle}    
         onMouseOver={() => this.setState({mouseOverId: id})}
         onMouseLeave={() => this.setState({mouseOverId: -1})}
         >
        <Col  style={{textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}} md="4">
        <div>{lang.getTitle(module_name)}</div>
        </Col>
        <Col style={{textAlign: 'center'}} md="2"><div>{this.getRole(id, 'roleSelect', roleSelect)}</div></Col>
        <Col style={{textAlign: 'center'}} md="2"><div>{this.getRole(id, 'roleInsert', roleInsert)}</div></Col>
        <Col style={{textAlign: 'center'}} md="2"><div>{this.getRole(id, 'roleUpdate', roleUpdate)}</div></Col>
        <Col style={{textAlign: 'center'}} md="2"><div>{this.getRole(id, 'roleDelete', roleDelete)}</div></Col>
    </Row>



    }

    getModulok = function (data) {
        var modulok = [];
        for (var i = 0; i < data.length; i++) {
            var modulData = data[i];
            var modul = this.getModul(modulData);
            modulok.push(modul);
        }
        return <div>{modulok}</div>
    }

    render() {
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var headerTextColor = (this.props.theme == "true") ? "wheat" : "grey";
        var modulok = this.getModulok(this.props.data);
        return (
                <div>  
                    <Row key={'modulok_header'} id={'modulok_header'}>
                        <Col  style={{textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}} md="4">
                        <div>MODUL NEVE</div>
                        </Col>
                        <Col style={{textAlign: 'center', marginTop: 'auto', marginBottom: 'auto'}} md="2">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{cursor: 'pointer'}} onClick={() => this.props.parent.jogosultsagBeallitasa(this.props.parent.jogosultsagBeallitasa(this.props.parent.ACTION_TYPE_FULL_SELECT_ADD, this.props.parent.state.activeJogosultsagTipus, -1))} >[+]</div>
                            <div>MEGTEKINT</div>
                            <div style={{cursor: 'pointer'}}  onClick={() => this.props.parent.jogosultsagBeallitasa(this.props.parent.jogosultsagBeallitasa(this.props.parent.ACTION_TYPE_FULL_SELECT_REMOVE, this.props.parent.state.activeJogosultsagTipus, -1))}>[-]</div>
                        </div>
                        </Col>
                        <Col style={{textAlign: 'center', marginTop: 'auto', marginBottom: 'auto'}} md="2">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{cursor: 'pointer'}}  onClick={() => this.props.parent.jogosultsagBeallitasa(this.props.parent.jogosultsagBeallitasa(this.props.parent.ACTION_TYPE_FULL_INSERT_ADD, this.props.parent.state.activeJogosultsagTipus, -1))} >[+]</div>
                            <div>LÉTREHOZ</div>
                            <div
                                style={{cursor: 'pointer'}} onClick={() => this.props.parent.jogosultsagBeallitasa(this.props.parent.jogosultsagBeallitasa(this.props.parent.ACTION_TYPE_FULL_INSERT_REMOVE, this.props.parent.state.activeJogosultsagTipus, -1))}
                                >[-]</div>
                        </div>
                        </Col>
                        <Col style={{textAlign: 'center', marginTop: 'auto', marginBottom: 'auto'}} md="2">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{cursor: 'pointer'}}  onClick={() => this.props.parent.jogosultsagBeallitasa(this.props.parent.jogosultsagBeallitasa(this.props.parent.ACTION_TYPE_FULL_UPDATE_ADD, this.props.parent.state.activeJogosultsagTipus, -1))} >
                                [+]</div>
                            <div>MÓDOSÍT</div>
                            <div
                                style={{cursor: 'pointer'}} onClick={() => this.props.parent.jogosultsagBeallitasa(this.props.parent.jogosultsagBeallitasa(this.props.parent.ACTION_TYPE_FULL_UPDATE_REMOVE, this.props.parent.state.activeJogosultsagTipus, -1))}
                                >[-]</div>
                        </div>
                        </Col>
                        <Col style={{textAlign: 'center', marginTop: 'auto', marginBottom: 'auto'}} md="2">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{cursor: 'pointer'}}  onClick={() => this.props.parent.jogosultsagBeallitasa(this.props.parent.jogosultsagBeallitasa(this.props.parent.ACTION_TYPE_FULL_DELETE_ADD, this.props.parent.state.activeJogosultsagTipus, -1))} >
                                [+]</div>
                            <div>TÖRÖL</div>
                            <div style={{cursor: 'pointer'}} 
                                 onClick={() => this.props.parent.jogosultsagBeallitasa(this.props.parent.jogosultsagBeallitasa(this.props.parent.ACTION_TYPE_FULL_DELETE_REMOVE, this.props.parent.state.activeJogosultsagTipus, -1))}
                                 >[-]</div>
                        </div>
                        </Col>
                    </Row>
                
                
                
                
                    {modulok}
                </div>
                );
    }
}

export default Modulok;
