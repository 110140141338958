import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';
import PagingToolbar from './../../components/PagingToolbar';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import FormatedNumberCell from './../../components/FormatedNumberCell';


import {Button, Row, Col} from 'react-bootstrap';

import InsertForm from './BelsoRendelesekInsertForm.js';
import UpdateForm from './BelsoRendelesekUpdateForm.js';
import ViewForm from './BelsoRendelesekViewForm.js';
import Dialog from './../../components/Dialog.js';
import ActionCell from './../../components/ActionCell';
import MultiComment from './../../componentsSpecial/MultiComment';
import GlobalSearch from './../../components/GlobalSearch';
import ToggleButton from './../../components/ToggleButton';
import repeat from './../../img/black/event_repeat_black_24dp.svg';


import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');

class BelsoRendelesekTable extends Component {

    constructor(props) {
        super(props);

        this.NONE = "NONE";

        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";

        this.MULTI_COMMENT = "MULTI_COMMENT";
        this.EXPORT_XLS = "EXPORT_XLS";
        this.EXPORT_CSV = "EXPORT_CSV";
        this.EXPORT_PDF = "EXPORT_PDF";
        this.EXPORT_CLIPBOARD = "EXPORT_CLIPBOARD";


        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";
        this.columnNames = [

            'action_cell',
            'copy',
            'checkbox',
            'belsoRendelesek_id',
            'belsoRendelesek_hivatkozasLink',
            'belsoRendelesek_statusz',
            'cikkcsoportok_id',
            'belsoRendelesek_cikk_nev',
            'belsoRendelesek_cikk_cikkszam',
            'belsoRendelesek_cikk_mennyiseg',
            'belsoRendelesek_cikk_mennyisegi_egyseg',
            'ugyfelek_nev',
            'arajanlat_azonosito',
            'munkalap_megnevezes',

            'belsoRendelesek_rogzito_id',

            'belsoRendelesek_feldolgozo_id',
            'belsoRendelesek_erkezteto_id',
            'belsoRendelesek_megjegyzes',
            'belsoRendelesek_rogzites_datum',
            'belsoRendelesek_megrendeles_datum',
            'belsoRendelesek_varhato_beerkezes',
            'belsoRendelesek_valos_beerkezes',
            'belsoRendelesek_altalanos_megjegyzes'
        ];
        this.columnStateHandler = ajax.columnStateHandler(this.columnNames, "BelsoRendelesek", "belsoRendelesek");
        this.tomegesBelsoRendelesekRef = React.createRef();


        this.state = {
            tomegesBelsoRendelesek_statusz: null,
            columnOrder: this.columnNames,
            theme: this.props.theme,
            openedForm: this.NONE, // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRow: null,
            sortInfo: null,
            groupBy: false,
            filterValue: null,
            additionalParams: [],
            requestProps: null,
            onlyMyItems: true,
            skip: 0,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 50,
                showingRowFrom: 0,
                showingRowTo: 50,
                totalRowCount: 0,
                skip: 0
            },
            dialogType: this.NONE,
            dialogText: ''
        };
        this.renderPaginationToolbar = null;
        this.ref = React.createRef();
        this.multiSelection = [];
        this.gridData = [];

        this.onlyMyItems = false;

        this.globalSearch = {"name": "globalSearch", "operator": "contains", "type": "string", "value": ''};


        this.gridRef = null;

        this.cikkcsoportok_idData = [];
        this.belsoRendelesek_rogzito_idData = [];
        this.belsoRendelesek_feldolgozo_idData = [];
        this.belsoRendelesek_statuszData = [];
        this.belsoRendelesek_erkezteto_idData = [];

        this.filtersLoaded = false;
        this.copyData = null;


    }

    componentDidMount() {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/BelsoRendelesek/filtersData.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        var data = response.data;

                        self.cikkcsoportok_idData = data.cikkcsoportok_id;
                        self.belsoRendelesek_rogzito_idData = data.belsoRendelesek_rogzito_id;
                        self.belsoRendelesek_feldolgozo_idData = data.belsoRendelesek_feldolgozo_id;
                        self.belsoRendelesek_statuszData = data.belsoRendelesek_statusz;
                        self.belsoRendelesek_erkezteto_idData = data.belsoRendelesek_erkezteto_id;

                        self.filtersLoaded = true;

                        self.forceUpdate();
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.openedForm != nextState.openedForm) {
            return true;
        }
        if (nextProps.theme != nextState.theme) {
            return true;
        }
        return false; // equals() is your implementation
    }

    setView = function (e) {

        var formName = e.target.id;
        var rowSelectionNeed = false;
        var hasSelectedRow = true;
        this.copyData = null;

        if (formName == this.FORM_DELETE || formName == this.FORM_UPDATE || formName == this.FORM_VIEW) {
            rowSelectionNeed = true;
        }
        if (this.state.selectedRow == null || this.state.selectedRow == '') {
            hasSelectedRow = false;
        }
        if (rowSelectionNeed == true && hasSelectedRow == false) {
            this.setState({openedForm: this.DIALOG_ALERT, dialogText: lang.getTitle("ROW_SELECTION_NEED")});
        } else {
            var dialogText = "";
            if (formName == this.FORM_DELETE) {
                dialogText = lang.getTitle("DELETE_CONFIRMATION");
            }
            this.setState({openedForm: formName, dialogText: dialogText});
        }
    }

    closeForm = function () {
        this.setState({openedForm: this.NONE});
    }

    customPaging = function (pagingProps) {
        return <PagingToolbar blackTheme={this.props.theme} tableReload={this.reload.bind(this)} paging={this.state.paging} />
    }
    reload = function (skip) {
        this.state.paging.skip = skip;
        this.forceUpdate();
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    addForeignKeyFilter = function (filterValues) {

        var additionalParams = this.props.additionalParams;

        if (typeof additionalParams == 'undefined') {
            return filterValues;
        }

        if (additionalParams == null) {
            return filterValues;
        }

        if (additionalParams.length == null) {
            return filterValues;
        }

        for (var ap = 0; ap < additionalParams.length; ap++) {

            var additionalParam = additionalParams[ap];

            for (const property in additionalParam) {

                var filter = null;
                for (var f = 0; f < filterValues.length; f++) {
                    var currentFilter = filterValues[f];
                    if (currentFilter.name == property) {
                        filter = currentFilter;
                    }
                }

                if (filter != null) {
                    filter.operator = 'eq';
                    filter.type = 'number';
                    filter.value = additionalParam[property];
                } else {
                    filter = {
                        name: property,
                        operator: 'eq',
                        type: 'number',
                        value: additionalParam[property]
                    };
                    filterValues.push(filter);
                }
            }

        }
        return filterValues;
    }

    getIdFromParam = function () {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var id = urlParams.get('id');
        var filterAttr = urlParams.get('filterAttr');
        var filterParam = {"name": filterAttr, "operator": "eq", "type": "number", "value": id};
        return filterParam;
    }

    hasExitsingFilter = function (currentFilters, newFilter) {

        for (var i = 0; i < currentFilters.length; i++) {
            var currentFilter = currentFilters[i];
            if (currentFilter.name == newFilter.name) {
                currentFilter.value = newFilter.value;
                return true;
            }
        }
        return false;
    }

    setGlobalSearch = function (val) {
        this.globalSearch.value = val;
        this.forceUpdate();
    }

    globalSearchHandler = function (filterValue) {

        var hasExistingFilter = this.hasExitsingFilter(filterValue, this.globalSearch);
        if (hasExistingFilter == false) {
            filterValue.push(this.globalSearch);
        }
        return filterValue;
    }

    dataLoader = function ( { skip, limit, sortInfo, groupBy, filterValue }){


        var newFilter = this.getIdFromParam();
        var belso_rendelesek_ajanlat_id = newFilter.value;
        if (typeof belso_rendelesek_ajanlat_id != 'undefined' && belso_rendelesek_ajanlat_id != null && belso_rendelesek_ajanlat_id != '') {
            var hasExitsingFilter = this.hasExitsingFilter(filterValue, newFilter);
            if (hasExitsingFilter == false) {
                filterValue.push(newFilter);
            }

        }

        if (this.state.onlyMyItems) {
            filterValue.push({"name": "onlyMyItems", "operator": "eq", "type": "int", "value": 1});
        }

        this.state.paging.skip = (typeof this.state.paging.skip == 'undefined') ? 0 : this.state.paging.skip;
        var state = this.state;
        filterValue = this.addForeignKeyFilter(filterValue);
        filterValue = this.globalSearchHandler(filterValue);

        this.state.requestProps = {skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};
        try {
            const url = ajax.getUrlBase() + "modules/BelsoRendelesek/belsoRendelesekTableSelect.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        var totalCount = response.count;
                        var data = response.data;
                        state.paging.totalRowCount = totalCount;
                        var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                        state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                        state.paging.showingRowFrom = skip;
                        state.paging.showingRowTo = skip + state.pageRowCount;
                        this.gridData = data;
                        return Promise.resolve({data, count: parseInt(totalCount), skip: state.paging.skip});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                state.defaultUpdateBlocked = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
    }
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/BelsoRendelesek/belsoRendelesekFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({belsoRendelesek_id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        self.setState({openedForm: 'HIDDEN', selectedRow: null});
                        if (response.success == false) {
                            alert(response.fail);
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }
    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    getVisibleColumns = function () {
        var visibleColumns = {};
        var handler = ajax.columnStateHandler(this.columnNames, "BelsoRendelesek", "belsoRendelesek");
        for (var i = 0; i < this.columnNames.length; i++) {
            var columnName = this.columnNames[i];

            var visibleColumn = handler.getColumnVisibility(columnName);
            visibleColumns[[columnName]] = visibleColumn;
        }
        return visibleColumns;
    }

    exportFile = function (e) {
        var self = this;
        var visibleColumns = this.getVisibleColumns();

        try {
            const url = ajax.getUrlBase() + "ExportToFile/belsoRendelesek/index.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({ids: this.multiSelection, filetype: e.target.id, visibleColumns: visibleColumns})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            if (e.target.id == 'EXPORT_XLS') {
                                window.open(ajax.getUrlBase() + response.data.fileUrl);
                            }
                            if (e.target.id == 'EXPORT_PDF') {
                                window.open(ajax.getUrlBase() + response.data.fileUrl);
                            }
                            if (e.target.id == 'EXPORT_CSV') {
                                window.open(ajax.getUrlBase() + response.data.fileUrl);
                            }
                            if (e.target.id == 'EXPORT_CLIPBOARD') {
                                navigator.clipboard.writeText(response.data.data);
                            }

                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    onSelectionChange = function (selection) {
        this.setState({selectedRow: selection.selected});

    }

    isSelected = function (id) {
        const index = this.multiSelection.indexOf(id);
        if (index > -1) {
            return true;
        }
        return false;
    }

    onRowClick = function (rowProps, event) {

        if (typeof rowProps == 'undefined') {
            return false;
        }

        if (rowProps == null) {
            return false;
        }

        if (typeof rowProps.data == 'undefined') {
            return false;
        }

        if (rowProps.data == null) {
            return false;
        }

        if (typeof rowProps.data.belsoRendelesek_id == 'undefined') {
            return false;
        }

        if (rowProps.data.belsoRendelesek_id == null) {
            return false;
        }
        this.setState({selectedRow: rowProps.data.belsoRendelesek_id});
    }

    hasSelectedRow = function () {
        if (typeof this.state.selectedRow == 'undefined') {
            return false;
        }
        if (this.state.selectedRow == null) {
            return false;
        }


        if (this.state.selectedRow == -1) {
            return false;
        }
        return true;
    }

    isViewForm = function () {

        if (typeof this.props.formType == 'undefined') {
            return false;
        }
        if (this.props.formType == null) {
            return false;
        }
        if (this.props.formType == '') {
            return false;
        }
        if (this.props.formType == this.FORM_VIEW) {
            return true;
        }
        return false;
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();

    }

    isCheckboxSelected = function (id) {
        return this.multiSelection.indexOf(id) > -1;
    }

    handleCheckBox = function (e) {
        var lastSelectedId = e.target.id * 1;
        const index = this.multiSelection.indexOf(lastSelectedId);
        if (index > -1) {
            this.multiSelection.splice(index, 1);
        } else {
            this.multiSelection.push(lastSelectedId);
        }
        this.forceUpdate();
    }

    onlyMyItemsSetter = function (value) {
        this.state.onlyMyItems = value;
        this.reload();

    }

    checkAll = function (e) {
        var action = e.target.id;
        if (action == "remove") {
            for (var i = 0; i < this.gridData.length; i++) {
                var gridRow = this.gridData[i];
                var id = gridRow.belsoRendelesek_id;
                const index = this.multiSelection.indexOf(id);
                if (index > -1) {
                    this.multiSelection.splice(index, 1);
                }

            }
        }
        if (action == "add") {
            for (var i = 0; i < this.gridData.length; i++) {
                var gridRow = this.gridData[i];
                var id = gridRow.belsoRendelesek_id;
                if (this.multiSelection.indexOf(id) == -1) {
                    this.multiSelection.push(id);
                }
            }
        }
        this.forceUpdate();
    }

    setMultiStatusz = function () {
        
        if(this.state.tomegesBelsoRendelesek_statusz==null || this.state.tomegesBelsoRendelesek_statusz ==''){
            
            this.setState({openedForm: this.DIALOG_ALERT, dialogText:'A funkció használatához ki kell választani egy státuszt a listából.'});
            return false;
        }
        
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/BelsoRendelesek/belsoRendelesekSetMultiStatusz.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({ids: this.multiSelection, tomegesBelsoRendelesek_statusz: this.state.tomegesBelsoRendelesek_statusz})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.forceUpdate();
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }

    }

    setTomegesStatusz = function (id, value) {
        this.setState({tomegesBelsoRendelesek_statusz: value});
    }

    setGridRef = function (val) {
        this.gridRef = val;
    }
    copy = function (cellProps) {
        if (typeof cellProps.data == 'undefined') {
            return false;
        }
        if (cellProps.data == null) {
            return false;
        }

        this.copyData = cellProps.data;
        this.setState({openedForm: this.FORM_INSERT});
    }

    render() {

        if (this.filtersLoaded == false) {
            return "";
        }

        var isViewForm = this.isViewForm();
        var columns = [
            {
                name: 'action_cell', header: '', minWidth: 160,
                render: ({ value, data }) => {
                    return <ActionCell 
                        stateReference ={this.state}
                        row_id={data.belsoRendelesek_id}   
                        setView={this.setView.bind(this)}
                        isViewForm ={isViewForm}
                        theme={this.props.theme}
                        />
                },
                sortable: false
            },
            {name: 'copy', sortable: false, header: 'Másol', defaultWidth: 70, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <img 
                        title={"Másolat készítése"}
                        src={repeat} 
                        onClick={() => this.copy(cellProps)} 
                        style={{maxWidth: 60, cursor: 'pointer'}}
                        />
                }
            },
            {name: 'checkbox', sortable: false,
                header: () => {
                    return <div style={{display: "flex"}}>
                        <div 
                            id={"add"}
                            style={{fontSize: 14, cursor: 'pointer', marginRight: 10}} 
                            onClick={this.checkAll.bind(this)}
                    
                            >&#9746;</div>
                        <div 
                            id={"remove"}
                            style={{fontSize: 14, cursor: 'pointer', marginRight: 20}} 
                            onClick={this.checkAll.bind(this)}
                            >&#9744;</div>
                    </div>
                },
                render: ({ data }) => {
                    return <input 
                        id={data.belsoRendelesek_id}
                        key={"checkbox_key_" + data.belsoRendelesek_id} 
                        type="checkbox" 
                        onChange={this.handleCheckBox.bind(this)}
                        checked={this.isCheckboxSelected(data.belsoRendelesek_id)}
                        style={{cursor: 'pointer'}}
                        />
                },
                align: 'center',
                filterDelay: 1000,
                defaultWidth: this.columnStateHandler.getColumnWidth('checkbox', 100),
                type: 'number',
                defaultVisible: true
            }
            , {name: 'belsoRendelesek_id', sortable: true, header: lang.getTitle('belsoRendelesek_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true}
            , {name: 'ugyfelek_nev', sortable: true, header: lang.getTitle('ugyfelek_nev'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('ugyfelek_nev', 150), defaultVisible: true}
            , {name: 'arajanlat_azonosito', sortable: false, header: lang.getTitle('arajanlat_azonosito'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_azonosito', 150), defaultVisible: true}
            , {name: 'munkalap_megnevezes', sortable: true, header: lang.getTitle('Munkalap azonosító'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_megnevezes', 150), defaultVisible: true}
            , {
                name: 'belsoRendelesek_rogzito_id',
                sortable: true,
                header: lang.getTitle('belsoRendelesek_rogzito_id'),
                defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_rogzito_id', 150),
                defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.belsoRendelesek_rogzito_idData
                },
                render: ({ value }) => {
                    return <div>{ajax.getValueById(this.belsoRendelesek_rogzito_idData, value)}</div>
                }
            }
            , {name: 'belsoRendelesek_feldolgozo_id', sortable: true,
                header: lang.getTitle('belsoRendelesek_feldolgozo_id'),
                defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_feldolgozo_id', 150),
                defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.belsoRendelesek_feldolgozo_idData
                },
                render: ({ value }) => {
                    return <div>{ajax.getValueById(this.belsoRendelesek_feldolgozo_idData, value)}</div>
                }
            }
            , {name: 'belsoRendelesek_statusz', sortable: true,
                header: lang.getTitle('belsoRendelesek_statusz'),
                defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_statusz', 150),
                defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.belsoRendelesek_statuszData
                },
                render: ({ value }) => {
                    return <div>{ajax.getValueById(this.belsoRendelesek_statuszData, value)}</div>
                }
            }
            , {
                name: 'cikkcsoportok_id',
                sortable: true,
                header: lang.getTitle('Cikkcsoportok'),
                defaultWidth: this.columnStateHandler.getColumnWidth('cikkcsoportok_id', 150),
                defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.cikkcsoportok_idData
                },
                render: ({ value }) => {
                    return <div>{ajax.getValueById(this.cikkcsoportok_idData, value)}</div>
                }
            }

            , {name: 'belsoRendelesek_cikk_nev', sortable: true, header: lang.getTitle('belsoRendelesek_cikk_nev'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_cikk_nev', 150), defaultVisible: true}
            , {name: 'belsoRendelesek_cikk_cikkszam', sortable: true, header: lang.getTitle('belsoRendelesek_cikk_cikkszam'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_cikk_cikkszam', 150), defaultVisible: true}
            , {name: 'belsoRendelesek_cikk_mennyiseg', sortable: true, header: lang.getTitle('belsoRendelesek_cikk_mennyiseg'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_cikk_mennyiseg', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value} />
                }}
            , {name: 'belsoRendelesek_cikk_mennyisegi_egyseg', sortable: true, header: lang.getTitle('belsoRendelesek_cikk_mennyisegi_egyseg'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_cikk_mennyisegi_egyseg', 150), defaultVisible: true}
            , {name: 'belsoRendelesek_hivatkozasLink', sortable: true, header: lang.getTitle('belsoRendelesek_hivatkozasLink'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_hivatkozasLink', 150), defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <a target={'_blank'} href={value}>Link</a>
                }}
            , {name: 'belsoRendelesek_megjegyzes', sortable: true, header: lang.getTitle('belsoRendelesek_megjegyzes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_megjegyzes', 150), defaultVisible: true}
            , {name: 'belsoRendelesek_altalanos_megjegyzes', sortable: true, header: lang.getTitle('belsoRendelesek_altalanos_megjegyzes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_altalanos_megjegyzes', 150), defaultVisible: true}
            , {name: 'belsoRendelesek_rogzites_datum', sortable: true, header: lang.getTitle('belsoRendelesek_rogzites_datum'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_rogzites_datum', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {name: 'belsoRendelesek_megrendeles_datum', sortable: true, header: lang.getTitle('belsoRendelesek_megrendeles_datum'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_megrendeles_datum', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {name: 'belsoRendelesek_varhato_beerkezes', sortable: true, header: lang.getTitle('belsoRendelesek_varhato_beerkezes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_varhato_beerkezes', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true
            }
            , {
                name: 'belsoRendelesek_erkezteto_id',
                sortable: true,
                header: lang.getTitle('belsoRendelesek_erkezteto_id'),
                defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_erkezteto_id', 150),
                defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.belsoRendelesek_erkezteto_idData
                },
                render: ({ value }) => {
                    return <div>{ajax.getValueById(this.belsoRendelesek_erkezteto_idData, value)}</div>
                }
            }
            , {name: 'belsoRendelesek_valos_beerkezes', sortable: true, header: lang.getTitle('belsoRendelesek_valos_beerkezes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('belsoRendelesek_valos_beerkezes', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}

        ];

        const filters = [
            {name: 'belsoRendelesek_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'belsoRendelesek_statusz', operator: 'inlist', type: 'select', value: ''}
            , {name: 'cikkcsoportok_id', operator: 'inlist', type: 'select', value: ''}
            , {name: 'belsoRendelesek_cikk_nev', operator: 'contains', type: 'string', value: ''}
            , {name: 'belsoRendelesek_erkezteto_id', operator: 'inlist', type: 'select', value: ''}
            , {name: 'belsoRendelesek_cikk_cikkszam', operator: 'contains', type: 'string', value: ''}
            , {name: 'belsoRendelesek_cikk_mennyiseg', operator: 'eq', type: 'number', value: ''}
            , {name: 'belsoRendelesek_cikk_mennyisegi_egyseg', operator: 'contains', type: 'string', value: ''}
            , {name: 'belsoRendelesek_hivatkozasLink', operator: 'contains', type: 'string', value: ''}
            , {name: 'ugyfelek_nev', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlat_azonosito', operator: 'contains', type: 'string', value: ''}
            , {name: 'munkalap_megnevezes', operator: 'contains', type: 'string', value: ''}
            , {name: 'belsoRendelesek_feldolgozo_id', operator: 'inlist', type: 'select', value: ''}
            , {name: 'belsoRendelesek_rogzito_id', operator: 'inlist', type: 'select', value: ''}
            , {name: 'belsoRendelesek_megjegyzes', operator: 'contains', type: 'string', value: ''}
            , {name: 'belsoRendelesek_altalanos_megjegyzes', operator: 'contains', type: 'string', value: ''}
            , {name: 'belsoRendelesek_rogzites_datum', operator: 'eq', type: 'date', value: ''}
            , {name: 'belsoRendelesek_megrendeles_datum', operator: 'eq', type: 'date', value: ''}
            , {name: 'belsoRendelesek_varhato_beerkezes', operator: 'eq', type: 'date', value: ''}
            , {name: 'belsoRendelesek_valos_beerkezes', operator: 'eq', type: 'date', value: ''}

        ]

        columns = this.columnStateHandler.setColumnsVisibility(columns);

        var openedForm = "";
        if (this.state.openedForm == this.FORM_VIEW) {
            openedForm = <ViewForm theme={this.props.theme} key={'belsoRendelesek_KEY_VIEW'} formType={this.FORM_VIEW} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_INSERT) {
      
            
            openedForm = <InsertForm  
                theme={this.props.theme} 
                key={'belsoRendelesek_KEY_INSERT'} 
                formType={this.FORM_INSERT} 
                additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} closeForm={this.closeForm.bind(this)} 
                munkalap ={this.getIdFromParam()}
                id={(this.copyData == null) ? null : this.copyData['belsoRendelesek_id']}
                
                />
        }

        if (this.state.openedForm == this.FORM_UPDATE) {

            var hasSelectedRow = this.hasSelectedRow();
            if (hasSelectedRow == true) {
                openedForm = <UpdateForm 
                    theme={this.props.theme} 
                    key={'belsoRendelesek_KEY_UPDATE'} 
                    formType={this.FORM_UPDATE} 
                    additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} 
                    id={this.state.selectedRow} 
                    closeForm={this.closeForm.bind(this)} />
            } else {
                console.log("Nincs kiválasztott sor");
                // Alert window
            }
        }

        if (this.state.openedForm == this.FORM_DELETE) {
            openedForm = <Dialog 
                type={this.DIALOG_CONFIRMATION} 
                dialogText={this.state.dialogText} 
                callback={this.delete.bind(this)}
                cancel={() => this.setView({target: {id: this.NONE}})}
            
                theme={this.props.theme}
                />;
        }
        if (this.state.openedForm == this.DIALOG_ALERT) {
            openedForm = <Dialog 
                type={this.DIALOG_ALERT} 
                dialogText={this.state.dialogText} 
                callback={() => this.setView({target: {id: this.NONE}})}
                theme={this.props.theme}
                />;
        }
        if (this.state.openedForm == this.MULTI_COMMENT) {
            openedForm = <MultiComment 
                header={lang.getTitle('MultiComment')}
                ids={this.multiSelection}
                callback={this.closeForm.bind(this)}
                cancel={() => this.setView({target: {id: this.NONE}})}
                theme={this.props.theme}
                style={{minWidth: 400, minHeight: 400, maxWidth: 400, maxHeight: 400}}
                />;
        }


        if (this.renderPaginationToolbar == null) {
            this.renderPaginationToolbar = this.customPaging.bind(this)
        }

        var buttons = [];

        var viewButton = <div  key={'belsoRendelesek_KEY_VIEW_BUTTON'} >&nbsp;<Button className={'btn-sm'} id={this.FORM_VIEW} onClick={this.setView.bind(this)} variant="info">{lang.getTitle('View')}</Button></div>;
        var insertButton = <div key={'belsoRendelesek_KEY_INSERT_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_INSERT} onClick={this.setView.bind(this)} variant="success">{lang.getTitle('Insert')}</Button></div>;
        var updateButton = <div key={'belsoRendelesek_KEY_UPDATE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_UPDATE} onClick={this.setView.bind(this)} variant="warning">{lang.getTitle('Update')}</Button></div>;
        var deleteButton = <div key={'belsoRendelesek_KEY_DELETE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_DELETE} onClick={this.setView.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button></div>;


        var tomegesStatusz = <ComboBoxInsertField 
    id={'belsoRendelesek_statusz'} 
    value={this.state.tomegesBelsoRendelesek_statusz}  
    setBack={this.setTomegesStatusz.bind(this)}
    dataSourceUrl={'modules/BelsoRendelesek/statuszFormComboInsertData.php'}
    reference={this.tomegesBelsoRendelesekRef}
    editable={true}
    style={{maxWidth: 180}}
    theme={this.props.theme}

    />;

        var multiStatuszButton = <div key={'belsoRendelesek_MULTI_STATUSZ'}>&nbsp;<Button disabled={this.multiSelection.length == 0} className={'btn-sm'} id={this.MULTI_STATUSZ} onClick={this.setMultiStatusz.bind(this)} variant="light">{lang.getTitle('MultiStatusz')}</Button></div>;
        var multiCommentButton = <div key={'belsoRendelesek_MULTI_COMMENT'}>&nbsp;<Button disabled={this.multiSelection.length == 0} className={'btn-sm'} id={this.MULTI_COMMENT} onClick={this.setView.bind(this)} variant="light">{lang.getTitle('MultiComment')}</Button></div>;
        var exportXlsButton = <div key={'belsoRendelesek_EXPORT_XLS'}>&nbsp;<Button disabled={this.multiSelection.length == 0} className={'btn-sm'} id={this.EXPORT_XLS} onClick={this.exportFile.bind(this)} variant="light">{lang.getTitle('exportXLS')}</Button></div>;
        var exportCsvButton = <div key={'belsoRendelesek_EXPORT_CSV'}>&nbsp;<Button disabled={this.multiSelection.length == 0} className={'btn-sm'} id={this.EXPORT_CSV} onClick={this.exportFile.bind(this)} variant="light">{lang.getTitle('exportCSV')}</Button></div>;
        var exportPdfButton = <div key={'belsoRendelesek_EXPORT_PDF'}>&nbsp;<Button disabled={this.multiSelection.length == 0} className={'btn-sm'} id={this.EXPORT_PDF} onClick={this.exportFile.bind(this)} variant="light">{lang.getTitle('exportPDF')}</Button></div>;
        var exportClipboardButton = <div key={'belsoRendelesek_EXPORT_CLIPBOARD'}>&nbsp;<Button disabled={this.multiSelection.length == 0} className={'btn-sm'} id={this.EXPORT_CLIPBOARD} onClick={this.exportFile.bind(this)} variant="light">{lang.getTitle('exportClipboard')}</Button></div>;

        var isViewForm = this.isViewForm();

        if (isViewForm) {
            buttons = [viewButton];
        } else {
            buttons = <table>
        <tbody>
            <tr>
    
                <td style={{verticalAlign: 'bottom'}}>{viewButton}</td>
                <td style={{verticalAlign: 'bottom'}}>{insertButton}</td>
                <td style={{verticalAlign: 'bottom'}}>{updateButton}</td>
                <td style={{verticalAlign: 'bottom'}}>{deleteButton}</td>
                <td style={{verticalAlign: 'bottom'}}>{tomegesStatusz}</td>
                <td style={{verticalAlign: 'bottom'}}>{multiStatuszButton}</td>
                <td style={{verticalAlign: 'bottom'}}>{multiCommentButton}</td>
                <td style={{verticalAlign: 'bottom'}}>{exportXlsButton}</td>
                <td style={{verticalAlign: 'bottom'}}>{exportCsvButton}</td>
                <td style={{verticalAlign: 'bottom'}}>{exportPdfButton}</td>
                <td style={{verticalAlign: 'bottom'}}>{exportClipboardButton}</td>
            </tr>
            <tr>
                <td colspan={2}>
        <ToggleButton 
            onStateTitle={"Saját cikkcsoport"} 
            offStateTitle={"Összes cikkcsoport"} 
            onlyMyItemsSetter={this.onlyMyItemsSetter.bind(this)}
            value={this.state.onlyMyItems}
            />
    </td>
    <td colspan={3}>
    <GlobalSearch 
        theme={this.props.theme} 
        globalSearch={this.setGlobalSearch.bind(this)} />
    </td>
    
    <td colspan={6}>
    </td>
    </tr>
    </tbody>
    </table>;
        }
        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';

        return (
                <div id="BelsoRendelesekTable">
                    <div style={{display: "flex"}}>
                        <Row>
                            <Col>
                            {buttons}
                            </Col>
                        </Row>    
                        <Row>    
                            <Col>
                
                            </Col>       
                        </Row>    
                
                    </div>
                
                    <ReactDataGrid 
                
                        onSelectionChange={this.onSelectionChange.bind(this)}
                
                        onReady={this.setGridRef.bind(this)}
                        idProperty="belsoRendelesek_id" 
                        i18n={ajax.getI18n()}
                        columns={this.columnStateHandler.myColumnsReorder(columns)}
                        dataSource={this.dataLoader.bind(this)}
                        defaultFilterValue={filters}
                        enableSelection={true}
                        onRowClick={this.onRowClick.bind(this)}
                        theme={theme}
                        style={{height: window.innerHeight - 180, marginTop: 10}}   
                        defaultLimit={50}
                        pagination={true}
                        renderPaginationToolbar={this.renderPaginationToolbar}
                        skip={this.state.skip}
                        limit={this.state.paging.pageRowCount}
                
                        onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
                        onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
                        onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                
                        />
                    {openedForm}            
                </div>
                );
    }
}

export default BelsoRendelesekTable;
