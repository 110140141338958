import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TextInsertField from './../../components/TextInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';
import CheckBoxInsertField from './../../components/CheckBoxInsertField';
import MaskedInsertField from './../../components/MaskedInsertField';

class KapcsolattartokInsert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            kapcsolattartok_nev: ''
            , kapcsolattartok_telefon: ''
            , kapcsolattartok_email: ''
            , kapcsolattartok_web: ''
            , kapcsolattartok_megjegyzes: ''
            , kapcsolattartok_aktiv: '0'

        }


        this.references = [];
        this.references['kapcsolattartok_nev'] = React.createRef();
        this.references['kapcsolattartok_telefon'] = React.createRef();
        this.references['kapcsolattartok_email'] = React.createRef();
        this.references['kapcsolattartok_web'] = React.createRef();
        this.references['kapcsolattartok_megjegyzes'] = React.createRef();
        this.references['kapcsolattartok_aktiv'] = React.createRef();


        this.validators = [];
        this.validators['kapcsolattartok_nev'] = {fieldName: 'kapcsolattartok_nev', dataType: 'VARCHAR', value: this.state.kapcsolattartok_nev, mandatory: true, min: null, max: null};
        this.validators['kapcsolattartok_telefon'] = {fieldName: 'kapcsolattartok_telefon', dataType: 'VARCHAR', value: this.state.kapcsolattartok_telefon, mandatory: true, min: null, max: null};
        this.validators['kapcsolattartok_email'] = {fieldName: 'kapcsolattartok_email', dataType: 'VARCHAR', value: this.state.kapcsolattartok_email, mandatory: true, min: null, max: null};
        this.validators['kapcsolattartok_web'] = {fieldName: 'kapcsolattartok_web', dataType: 'VARCHAR', value: this.state.kapcsolattartok_web, mandatory: false, min: null, max: null};
        this.validators['kapcsolattartok_megjegyzes'] = {fieldName: 'kapcsolattartok_megjegyzes', dataType: 'VARCHAR', value: this.state.kapcsolattartok_megjegyzes, mandatory: false, min: null, max: null};
        this.validators['kapcsolattartok_aktiv'] = {fieldName: 'kapcsolattartok_aktiv', dataType: 'TINYINT', value: this.state.kapcsolattartok_aktiv, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

    }

    componentDidMount() {
        this.reset();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({kapcsolattartok_nev: ''
            , kapcsolattartok_telefon: ''
            , kapcsolattartok_email: ''
            , kapcsolattartok_web: ''
            , kapcsolattartok_megjegyzes: ''
            , kapcsolattartok_aktiv: '0'
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getForm = function () {
        return <Container>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('kapcsolattartok_nev')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'kapcsolattartok_nev'} 
                    value={this.state.kapcsolattartok_nev}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['kapcsolattartok_nev']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('kapcsolattartok_telefon')}</Col>
                <Col style={{textAlign: 'right'}}>
        
                <MaskedInsertField 
                    id={'kapcsolattartok_telefon'} 
                    acceptedChars={"-+0123456789 ()"}
                    regexPattern={new RegExp(/^[+\s(0-9)\s0-9\\s-\s0-9]*$/)}
                    placeHolder ={'+360000000'}
                    value={this.state.kapcsolattartok_telefon}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['kapcsolattartok_telefon']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth:'100%'}}
                    />    
        
        
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('kapcsolattartok_email')}</Col>
                <Col style={{textAlign: 'right'}}>
                <MaskedInsertField 
                    id={'kapcsolattartok_email'} 
                    regexPattern={new RegExp(/^.*@.*\.[a-zA-z].*$/)}
                    placeHolder ={'a@b.hu'}
                    value={this.state.kapcsolattartok_email}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['kapcsolattartok_email']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('kapcsolattartok_web')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'kapcsolattartok_web'} 
                    value={this.state.kapcsolattartok_web}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['kapcsolattartok_web']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('kapcsolattartok_megjegyzes')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextAreaInsertField 
                    id={'kapcsolattartok_megjegyzes'} 
                    value={this.state.kapcsolattartok_megjegyzes}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['kapcsolattartok_megjegyzes']}
                    editable={true}   
                    theme={this.props.theme}
                    style={{minWidth:'100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitleBold('kapcsolattartok_aktiv')}</Col>
                <Col style={{textAlign: 'right'}}>
                <CheckBoxInsertField 
                    id={'kapcsolattartok_aktiv'} 
                    value={this.state.kapcsolattartok_aktiv}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['kapcsolattartok_aktiv']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth:'100%'}}
                    />
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitleBold('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitleBold(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                stateClone[property] = additionalParam[property];
            }
        }
        return stateClone;
    }

    insert = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        var params = this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/Kapcsolattartok/kapcsolattartokFormInsert.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(params)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            if (typeof self.props.setBackRecordData != 'undefined') {
                                self.props.setBackRecordData(response);
                            }

                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{maxHeight: 480}}>
            <div className={'popupWrapper' + className} id="KapcsolattartokInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('KapcsolattartokInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.insert.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        <Button className={'btn-sm'} id={'save_new'} onClick={this.insert.bind(this)} variant="info" style={{marginTop: '5px'}}>{lang.getTitle('new')}</Button>      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default KapcsolattartokInsert;

