import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button} from 'react-bootstrap';
import * as lang from './../lang.js';
import * as ajax from './../ajax.js';
import Csengo from './../img/csengo.svg';
import CsengoInaktiv from './../img/csengo_inaktiv.svg';
import SzabadsagokComboBoxInsertField from './../componentsSpecial/SzabadsagokComboBoxInsertField';
import TextInsertField from './../components/TextInsertField';
import sendBack from './../img/black/send_black_24dp.svg';
import sendWhite from './../img/white/send_white_24dp.svg';


class Contents extends Component {
    constructor() {
        super();
        this.state = {
            timerID: null,
            loggedInUserId: null,
            loggedInUserName: '',
            messageTargetUserId: null,
            messagesVisible: false,
            incomingMessage: false,
            messages: [],
            currentMessage: ""

        }

        this.messageTargetUserReference = React.createRef();
        this.currentMessage = React.createRef();

        this.error = false;
        this.msg = "";
        this.load();
        
         if (this.state.timerID === null) {
            this.state.timerID = setInterval(() => this.load(), 60000);
        }
    }
    
    

    checkMessages = function (response) {
        if (typeof response == 'undefined' || response == null) {
            return false;
        }
        if (typeof response.messages == 'undefined' || response.messages == null) {
            return false;
        }

        var hasUnreadMessage = false;
        var messages = response.messages;
        for (var i = 0; i < messages.length; i++) {
            var message = messages[i];
            if (message.readed == 0 && message.message_to_user_id == response.loggedInUserId) {
                hasUnreadMessage = true;
            }
        }
        this.setState({messages: messages, incomingMessage: hasUnreadMessage, loggedInUserId: response.loggedInUserId});
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

  logOut = function(){
        var self = this;
        try {
            const url = ajax.getUrlBase() + "secure/logOut.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            window.location.reload(); 
                        } else {
                            self.transactionFail(response.fail);
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
        
        
    }


    send = function(){
        var self = this;
        if(this.state.messageTargetUserId == null){
            alert("Kérem adja meg a címzettet");
        }
        
        if(this.state.currentMessage == null || this.state.currentMessage.trim() ==''){
            alert("Nem írt üzenetet.");
        }
        
        
        try {
            const url = ajax.getUrlBase() + "modules/Messages/send.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({messageTargetUserId:this.state.messageTargetUserId,message: this.state.currentMessage})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.state.currentMessage ="" ;
                            self.load();
                        } else {
                            self.transactionFail(response.fail);
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
        
        
    }



    setMessagesReaded = function () {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Messages/setMessagesReaded.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.state.messagesVisible = true;
                            self.load();
                        } else {
                            self.transactionFail(response.fail);
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }

    }

    load = function () {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Messages/select.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.state.loggedInUserName=response.data.loggedInUserName;
                            self.checkMessages(response.data);
                        } else {
                            self.transactionFail(response.fail);
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    setMessageTargetUserId=function(e){
        this.setState({messageTargetUserId:e.target.id});
    }


    getMessages = function () {
        var loggedInUserId = this.state.loggedInUserId;
        var messagesArray = this.state.messages;
        var messages = [];
        for (var i = 0; i < messagesArray.length; i++) {
            var messageData = messagesArray[i];
            var messageStyle = {backgroundColor:'white', color:'black',marginTop: 10, padding: 10, maxWidth: '80%', borderRadius: '8px'};
            if (messageData.message_from_user_id == loggedInUserId) {
                messageStyle['marginLeft'] = '5%';
                messageStyle['textAlign'] = 'left';
                messageStyle['border'] = 'solid 1px #aa882277';
                messageStyle['boxShadow'] = '0px 0px 4px #999999aa';
            } else {
                messageStyle['marginLeft'] = '15%';
                messageStyle['textAlign'] = 'right';
                messageStyle['border'] = 'solid 1px #2288aa77';
                messageStyle['boxShadow'] = '0px 0px 6px #999999aa';
            }

            var fromUser = (messageData.message_from_user_id == loggedInUserId) ? "Én:" : messageData.message_from_user_name;

            messages.push(
                    <div style={messageStyle} key={"message_key" + messageData.id}>
                        <div 
                            id={messageData.message_from_user_id}
                            style={{color: 'grey', textDecoration: 'underline', cursor: 'pointer'}}
                            onClick={this.setMessageTargetUserId.bind(this)}
                            >
                            {fromUser}&nbsp;&nbsp;&nbsp;{messageData.createdAt}
                        </div>
                        <div style={{color:'black'}}>{messageData.message}</div>
                    </div>)
        }
        return messages;
    }
    
    render() {

        var fontColor = (this.props.theme == "true") ? "white" : "black";
        var backgroundColor = (this.props.theme == "true") ? "white" : "black";
        var sendImg = (this.props.theme == "true") ? sendWhite : sendBack;
        var messages = "";

        if (this.state.messagesVisible == true) {
            messages = <div style={{
                                margin: 5,
                                padding: 5,
                                position: 'absolute',
                                zIndex: '1000',
                                minHeight: '500px',
                                maxHeight: '500px',
                                boxShadow: '0px 7px 20px black',
                                width: '650px',
                                top: '40px',
                                background: 'white',
                                textAlign: 'center',
                                overflowY: 'auto'

                                 }}>
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1}}></div>
                    <div style={{flex: 8}}>Üzenetek</div>
                    <div style={{flex: 1}}>
                        <Button onClick={()=>{this.setState({messagesVisible:false})}} size="sm" variant="danger">X</Button>
                    </div>
                </div>
                <div>{this.getMessages()}</div>
                <div></div>
            </div>
        }

        return <div style={{display: 'flex', color: fontColor}}>
            <div style={{display: 'flex'}}>
                <div>
                    Címzett:&nbsp;&nbsp;
                </div>
                <div style={{zIndex: 1000}}>
                    <SzabadsagokComboBoxInsertField
                        id={'messageTargetUserId'} 
                        value={this.state.messageTargetUserId}  
                        reference ={this.messageTargetUserReference}
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/CikkcsoportokFelhasznalok/user_idFormComboInsertData.php'}
                        editable={true}
                        theme={this.props.theme}
                        />
                </div>
                <div>
                    &nbsp;&nbsp;Üzentet:&nbsp;&nbsp;
                </div>
                <div>
                    <TextInsertField 
                        id={'currentMessage'} 
                        value={this.state.currentMessage}  
                        setBack={this.setBack.bind(this)}
                        reference={this.currentMessage}
                        editable={true}
                        theme={this.props.theme}
                        />
                </div>
                <div>
                <img 
                style={{marginLeft:10,marginRight:10,cursor:'pointer'}}
                onClick={this.send.bind(this)}
                src={sendImg} />
                </div>
                <div>
                    <img 
                        style={{cursor: 'pointer'}} 
                        src={(this.state.incomingMessage == true) ? Csengo : CsengoInaktiv} 
                        onClick={() => {
                                        this.setMessagesReaded()
                                    }}
                        />
        
                </div>
                <div style={{whiteSpace:'nowrap'}} >
                    {this.state.loggedInUserName}
                </div>
                <div style={{cursor:'pointer',textAlign:'right',color:'#00000099',fontWeight:'bold'}} onClick={this.logOut.bind(this)} >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[KIJELENTKEZÉS]
                </div>
            </div>
            {messages}
        </div>
    }
}

export default Contents;
