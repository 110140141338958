import React, { Component } from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

class ArajanlatKalkulator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            theme: this.props.theme
        }
        this.tetelOsszFt = 0;
        this.opcTetelekFt = 0;
        this.kedvezmeny = 0;
        this.tetelekOsszesen = 0;
        this.vegosszesen = 0;
        this.szuloArajanlatNetto = 0;

        this.data = null;

        this.tetelOsszFtRef = React.createRef();
        this.opcTetelekFtRef = React.createRef();
        this.kedvezmenyRef = React.createRef();
        this.tetelekOsszesenRef = React.createRef();
        //this.vegosszesenRef = React.createRef();
        this.arajanlatTetelek_eredeti_netto_ar = 0.0;
        this.arajanlatTetelek_eredeti_netto_arRef = React.createRef();

        this.loadTimeOutId = 0;
        this.updateTimeOutId = 0;

    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    delayLoad = function () {
        var self = this;
        
            clearTimeout(self.loadTimeOutId);
            this.loadTimeOutId = setTimeout(()=>self.load(self.props.stateRef.arajanlat_id), 2000);
        
    }
    delayUpdate = function () {
        var self = this;
        window.clearTimeout(self.timeOutId);
        self.updateTimeOutId = window.setTimeout(function () {
            self.updateArengedmeny(self.props.stateRef.arajanlat_id, self.props.stateRef.arajanlat_arengedmeny)
        }, 1000);
    }

    componentDidUpdate(prevProps) {

        if (this.props.stateRef.arajanlat_id != null) {
            if (this.props.stateRef.arajanlat_arengedmeny != this.kedvezmeny) {
                this.delayUpdate();
                // this.updateArengedmeny(this.props.stateRef.arajanlat_id, this.props.stateRef.arajanlat_arengedmeny);
            } else {
                //this.load(this.props.stateRef.arajanlat_id);
                this.delayLoad();
            }
        }

        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    updateArengedmeny = function (id, value) {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Arajanlat/arajanlatKedvezmenyValueUpdate.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: id, value})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.load(id);
                        } else {
                            self.load(id);
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    load = function (id) {
        if(typeof id == 'undefined'){
            return false;
        }
        if(id == null){
            return false;
        }
        if(id == ''){
            return false;
        }
        
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Arajanlat/arajanlatOsszesito.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {

                            var kedvezmenyTipusString = self.calc(response.data);

                            this.tetelOsszFtRef.current.innerHTML = this.formatter(self.tetelOsszFt);
                            this.opcTetelekFtRef.current.innerHTML = this.formatter(self.opcTetelekFt);
                            this.kedvezmenyRef.current.innerHTML = this.formatter(self.kedvezmeny) + kedvezmenyTipusString;
                            this.tetelekOsszesenRef.current.innerHTML = this.formatter(self.tetelekOsszesen);

                            this.arajanlatTetelek_eredeti_netto_arRef.current.innerHTML = this.formatter(response.data.arajanlatTetelek_eredeti_netto_ar);



                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    calcOpcTetelek = function (tetelOpciokArray) {
        for (var i = 0; i < tetelOpciokArray.length; i++) {
            var tetel = tetelOpciokArray[i];
            var netto = tetel.arajanlatTetelOpciok_netto_ar * 1.0;
            this.opcTetelekFt += netto;

        }
    }

    calcTetelek = function (tetelArray) {
        for (var i = 0; i < tetelArray.length; i++) {
            var tetel = tetelArray[i];
            var darab = (tetel.arajanlatTetelek_darabszam ==null || tetel.arajanlatTetelek_darabszam=='')?1.0:tetel.arajanlatTetelek_darabszam;
            var netto = tetel.arajanlatTetelek_netto_ar * darab;
            this.tetelOsszFt += netto;

            this.calcOpcTetelek(tetel.ArajanlatTetelOpciok);
            this.calcOpcTetelek(tetel.MTOArajanlatTetelOpciok);
        }
    }

    calc = function (data) {


        this.tetelOsszFt = 0;
        this.kedvezmeny = 0;
        this.tetelekOsszesen = 0;
        this.opcTetelekFt = 0;
        this.vegosszesen = 0;

        this.calcTetelek(data.ArajanlatTetel);
        this.calcTetelek(data.MTOArajanlatTetel);



        this.kedvezmeny = data.arajanlat_arengedmeny;

        var hasKedvezmeny = (this.kedvezmeny != 'undefined' && this.kedvezmeny != null && this.kedvezmeny != '' && this.kedvezmeny != '0' && this.kedvezmeny != 0) ? true : false;
        var kedvezmenyTipusString = "";

        if (hasKedvezmeny && this.kedvezmeny > 100) {
            this.tetelekOsszesen = this.tetelOsszFt - this.kedvezmeny;
            kedvezmenyTipusString = "-Ft";
        }

        if (hasKedvezmeny && this.kedvezmeny <= 100) {
            this.tetelekOsszesen = this.tetelOsszFt - ((this.tetelOsszFt / 100) * this.kedvezmeny);
            kedvezmenyTipusString = "%";
        }

        if (hasKedvezmeny == false) {
            this.tetelekOsszesen = this.tetelOsszFt;
        }

        this.vegosszesen = this.tetelekOsszesen;//+ this.opcTetelekFt;
        return kedvezmenyTipusString;

    }

    formatter = function (number) {
        if (number == null) {
            return '';
        }
        if (number == '') {
            return '';
        }
        number = number + "";
        if (number.slice(-1) == ',') {
            return number;
        }

        var negativeSign = "";



        if (number.length > 0 && number.charAt(0) == '-') {

            negativeSign = "-";
            number = number.substring(1);

        }
        var values = number.split('.');
        var integers = values[0];
        var decimals = (values.length > 1) ? values[1] : '';

        var formatted = "";
        var thousandCounter = 0;
        for (var i = integers.length - 1; i > -1; i--) {
            thousandCounter++;
            formatted = integers.charAt(i) + formatted;

            if (i > 0 && thousandCounter > 0 && thousandCounter % 3 == 0) {

                formatted = '.' + formatted;
            }

        }

        return negativeSign + formatted + ((decimals.length > 0) ? "," + decimals : '');
    }

    render() {
        var kedvezmenyTipusString = "";
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        if (this.props.stateRef.arajanlat_id == null || this.props.stateRef.arajanlat_id == '') {
            kedvezmenyTipusString = this.calc(this.props.stateRef);
            
        }



        return (
                <div style={{marginTop: 0, marginBottom: 15}}  className={"content" + className} id="ArajanlatContent">
                    <Row>
                        <Col md="10">
                        <Row>
                            <Col md="3">{lang.getTitle("tetelekOsszege")}</Col>
                            <Col md="3">{lang.getTitle("tetelekKedvezmenyMerteke")}</Col>
                            <Col md="3">{lang.getTitle("tetelekOsszesen")}</Col>
                            <Col md="3">{lang.getTitle("opcionalisTetelekOsszege")}</Col>
                        </Row>
                        <Row>
                            <Col md="3"><div ref={this.tetelOsszFtRef}>{this.formatter(this.tetelOsszFt)}</div></Col>
                            <Col md="3"><div ref={this.kedvezmenyRef}>{this.formatter(this.kedvezmeny) + kedvezmenyTipusString}</div></Col>
                            <Col md="3"><div ref={this.tetelekOsszesenRef}>{this.formatter(this.tetelekOsszesen)}</div></Col>
                            <Col md="3"><div ref={this.opcTetelekFtRef}>{this.formatter(this.opcTetelekFt)}</div></Col>
                        </Row>
                        </Col>
                        <Col   md="2">
                        <Row>
                            <Col>{lang.getTitle("arajanlatTetelek_eredeti_netto_ar")}</Col>
                        </Row>
                        <Row>
                            <Col> <div  ref={this.arajanlatTetelek_eredeti_netto_arRef}><u>{this.formatter(this.arajanlatTetelek_eredeti_netto_ar) }</u></div></Col>
                        </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        &nbsp;
                        </Col>
                    </Row>
                </div>
                );
    }
}

export default ArajanlatKalkulator;
